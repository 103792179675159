import React from 'react';
import Chip from '@mui/material/Chip';

type Props = {
  readonly dataTestId?: string;
  readonly className?: string;
  readonly onClick?: () => void;
  readonly disabled?: boolean;
  readonly children: React.ReactNode;
};

export default function Status({ children, dataTestId, ...props }: Props): JSX.Element {
  return <Chip size="small" label={children} color="primary" variant="outlined" {...props} data-testid={dataTestId} />;
}
