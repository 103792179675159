import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import Formular from './common/Formular';
import WarningSign from './common/WarningSign';
import UnternehmenVerwaltung from './UnternehmenVerwaltung';
import UnternehmenAnzeige from './UnternehmenAnzeige';
import { AKTION_UNFALLDATEN_ERFASSEN } from '../frontendConstants';
import { useUser } from '../hooks/useUser';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as mutations from '../graphql/mutations';
import { Unternehmen, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import SearchPersonUnternehmen from './common/SearchPersonUnternehmen';
import { PersonenTyp, UnternehmenTyp } from '../shared/constants';
import { getMessageFromError } from '../shared/throw';
import { ERROR_MESSAGE } from './common/Alert';
import { useSnackbar } from 'notistack';
import { aktualisierePerson } from '../domain/aktualisierePerson';

type Props = {
  readonly vorgang: Vorgang;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly setLoading: (value: boolean) => void;
};

export function Werkstatt({ vorgang, setzeVorgang, speichereVorgang, setLoading }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setDialog] = useState({ anlegen: false, aendern: false });
  const { gruppenVonMandant } = useUser();

  const handleWerkstattAusgewaehlt = (werkstatt: Unternehmen) => {
    speichereVorgang({ werkstattId: werkstatt.id });
  };

  const handleAktualisiereWerkstatt = async (unternehmen: Unternehmen) => {
    const aktualisierteWerkstatt = await makeGraphqlQuery(mutations.updateUnternehmen, { input: unternehmen });
    setzeVorgang({ ...vorgang, werkstatt: aktualisierteWerkstatt });
    setDialog({ anlegen: false, aendern: false });
  };

  const handleLegeWerkstattAn = async (unternehmen: Unternehmen, vermittlerAnlage: boolean) => {
    setLoading(true);
    const angelegtesUnternehmen = await makeGraphqlQuery(mutations.legeUnternehmenAn, { unternehmen: JSON.stringify(unternehmen) });

    speichereVorgang({ werkstattId: angelegtesUnternehmen.id });

    if (vermittlerAnlage) {
      //@ts-ignore
      aktualisierePerson({
        ...unternehmen,
        mandant: vorgang.mandant,
        weitereBearbeiter: [],
        typ: [PersonenTyp.VERMITTLER]
      })
        .then(() => {
          setDialog({ anlegen: false, aendern: false });
          setLoading(false);
        })
        .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Vermittler konnte nicht gespeichert werden', ERROR_MESSAGE))
        .finally(() => setLoading(false));
    } else {
      setDialog({ anlegen: false, aendern: false });
      setLoading(false);
    }
  };

  const handleWerkstattAendernDialog = () => {
    setDialog((current) => ({ ...current, aendern: true }));
  };

  const handleWerkstattAnlegenDialog = () => {
    setDialog((current) => ({ ...current, anlegen: true }));
  };

  const handleWerkstattLoeschen = () => {
    speichereVorgang({ werkstattId: null });
  };

  const isDisabled = !vorgang || !aktionErlaubt(AKTION_UNFALLDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  return (
    <Formular ueberschrift="Werkstatt">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          <SearchPersonUnternehmen
            personenUnternehmenTyp={UnternehmenTyp.WERKSTATT}
            isDisabled={isDisabled}
            label="Werkstatt suchen"
            onSelect={(unternehmen) => handleWerkstattAusgewaehlt(unternehmen as Unternehmen)}
            testPraefix="werkstatt"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            disabled={isDisabled || !vorgang?.werkstattId}
            onClick={() => handleWerkstattAendernDialog()}
            data-testid="werkstatt-bearbeiten"
            size="large"
          >
            <Tooltip title="Ändern">
              <EditIcon />
            </Tooltip>
          </IconButton>
          <IconButton disabled={isDisabled} onClick={() => handleWerkstattAnlegenDialog()} data-testid="werkstatt-anlegen" size="large">
            <Tooltip title="Anlegen">
              <AddBoxIcon />
            </Tooltip>
          </IconButton>
          <IconButton disabled={isDisabled || !vorgang?.werkstattId} onClick={() => handleWerkstattLoeschen()} data-testid="werkstatt-loeschen" size="large">
            <Tooltip title="Zuorgnung löschen">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <WarningSign
            show={vorgang?.werkstatt === null && vorgang?.werkstattId !== null}
            text="Der Unfallgegner wurde gelöscht oder Sie haben nicht die erforderlichen Rechte, diesen zu sehen."
          />
          {vorgang.werkstatt?.typ && <UnternehmenAnzeige unternehmen={vorgang.werkstatt} testPraefix="werkstatt" />}
        </Grid>
      </Grid>
      {(dialog.anlegen || dialog.aendern) && (
        <UnternehmenVerwaltung
          unternehmenObjekt={dialog.aendern ? vorgang?.werkstatt : {}}
          typ={UnternehmenTyp.WERKSTATT}
          aktualisiereUnternehmen={handleAktualisiereWerkstatt}
          legeUnternehmenAn={handleLegeWerkstattAn}
          open={dialog.anlegen || dialog.aendern}
          onClose={() => setDialog({ anlegen: false, aendern: false })}
          testPraefix="werkstatt"
        />
      )}
    </Formular>
  );
}
