import React from 'react';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_BOXES } from '../../components/common/spacings';
import Formular from '../../components/common/Formular';
import Bereifung from '../../components/Bereifung';
import Fahrzeugschaeden from '../../components/Fahrzeugschaeden';
import { Fahrzeugidentifikation } from '../../components/Fahrzeugidentifikation';
import { Stundenverrechnungssaetze } from '../../components/Stundenverrechnungssaetze';
import { Fahrzeugdaten } from '../../components/Fahrzeugdaten';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { Gutachtenart } from '../../shared/constants';

export default function Fahrzeug(): JSX.Element {
  const { vorgang, setLoading, isLoading, setzeVorgang, aktualisiereVorgang, speichereVorgang } = useVorgangContext();
  return (
    <Grid container spacing={SPACING_BETWEEN_BOXES}>
      <Grid item xs={12}>
        <Fahrzeugidentifikation
          vorgang={vorgang}
          setLoading={setLoading}
          isLoading={isLoading}
          setzeVorgang={setzeVorgang}
          aktualisiereVorgang={aktualisiereVorgang}
          speichereVorgang={speichereVorgang}
        />
      </Grid>
      <Grid item xs={12}>
        <Fahrzeugdaten vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
      </Grid>
      {vorgang?.gutachtenart !== Gutachtenart.FAHRZEUGBEWERTUNG && (
        <>
          {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
            <Grid item xs={12} lg={6}>
              <Stundenverrechnungssaetze vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
            </Grid>
          )}
          <Grid item xs={12}>
            <Formular ueberschrift="Fahrzeugzustand">
              {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
                <Bereifung vorgang={vorgang} aktualisiereVorgang={aktualisiereVorgang} isLoading={isLoading} />
              )}
              <Fahrzeugschaeden vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
            </Formular>
          </Grid>
        </>
      )}
    </Grid>
  );
}
