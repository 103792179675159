import React from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SPACING_BETWEEN_COLUMNS, SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import Formular from './common/Formular';
import { useUser } from '../hooks/useUser';
import { isAenderbar } from '../domain/isAenderbar';
import { Fahrzeugdaten as FahrzeugdatenType, Vorgang } from '../types';
import { textfieldInlineButton } from '../assets/sharedStyles';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_FAHRZEUGDATEN_ERFASSEN } from '../frontendConstants';
import Box from '@mui/material/Box';

type Props = { readonly vorgang: Vorgang; readonly isLoading: boolean; readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void };

export function Fahrzeugdaten({ vorgang, isLoading, aktualisiereVorgang }: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();

  const isDisabled =
    !vorgang || isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_FAHRZEUGDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const hersteller = vorgang?.fahrzeugdaten?.herstellername;
  const haupttyp = vorgang?.fahrzeugdaten?.haupttypname;
  const untertyp = vorgang?.fahrzeugdaten?.untertypname;

  const aktualisiereFahrzeugdaten = (attribut: keyof FahrzeugdatenType, wert: unknown) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        [attribut]: wert
      }
    });
  };

  return (
    <Formular
      ueberschrift={
        <>
          Fahrzeugdaten{' '}
          <Box
            sx={{
              fontSize: '0.8rem'
            }}
          >
            {hersteller} {haupttyp} {untertyp}
          </Box>
        </>
      }
    >
      <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
        <Grid item container xs={12}>
          <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                label="Leistung in KW"
                value={vorgang.fahrzeugdaten.leistungKw ?? ''}
                onChange={(event) => {
                  aktualisiereFahrzeugdaten('leistungKw', event.target.value);
                }}
                fullWidth
                data-testid="leistungKw"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.leistungKw ?? '0'}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <Tooltip title="Leistung aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereFahrzeugdaten('leistungKw', vorgang?.dat?.leistungKw);
                          }}
                          disabled={!vorgang?.dat?.leistungKw || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATLeistungKw"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                label="Anzahl Türen"
                value={vorgang.fahrzeugdaten.anzahlTueren ?? ''}
                onChange={(event) => aktualisiereFahrzeugdaten('anzahlTueren', event.target.value)}
                fullWidth
                data-testid="anzahlTueren"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.anzahlTueren ?? '0'}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <Tooltip title="Anzahl Türen aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereFahrzeugdaten('anzahlTueren', vorgang?.dat?.anzahlTueren);
                          }}
                          disabled={!vorgang?.dat?.anzahlTueren || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATAnzahlTueren"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                label="Hubraum"
                value={vorgang.fahrzeugdaten.hubraum ?? ''}
                onChange={(event) => aktualisiereFahrzeugdaten('hubraum', event.target.value)}
                fullWidth
                data-testid="hubraum"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.hubraum ?? '0'}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <Tooltip title="Hubraum aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereFahrzeugdaten('hubraum', vorgang?.dat?.hubraum);
                          }}
                          disabled={!vorgang?.dat?.hubraum || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATHubraum"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                label="Anzahl Sitze"
                value={vorgang.fahrzeugdaten.anzahlSitze ?? ''}
                onChange={(event) => aktualisiereFahrzeugdaten('anzahlSitze', event.target.value)}
                fullWidth
                data-testid="anzahlSitze"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.anzahlSitze ?? '0'}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <Tooltip title="Anzahl Sitze aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereFahrzeugdaten('anzahlSitze', vorgang?.dat?.anzahlSitze);
                          }}
                          disabled={!vorgang?.dat?.anzahlSitze || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATAnzahlSitze"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                label="Antriebsart"
                value={vorgang.fahrzeugdaten.antriebsart ?? ''}
                onChange={(event) => aktualisiereFahrzeugdaten('antriebsart', event.target.value)}
                fullWidth
                data-testid="antriebsart"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.antriebsart ?? ''}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <Tooltip title="Antriebsart aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereFahrzeugdaten('antriebsart', vorgang?.dat?.antriebsart);
                          }}
                          disabled={!vorgang?.dat?.antriebsart || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATAntriebsart"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                label="Farbe"
                value={vorgang.fahrzeugdaten.farbe ?? ''}
                onChange={(event) => aktualisiereFahrzeugdaten('farbe', event.target.value)}
                fullWidth
                data-testid="farbe"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.farbe ?? ''}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <Tooltip title="Farbe aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereFahrzeugdaten('farbe', vorgang?.dat?.farbe);
                          }}
                          disabled={!vorgang?.dat?.farbe || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATFarbe"
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                label="Aufbauart"
                value={vorgang.fahrzeugdaten.aufbauart ?? ''}
                onChange={(event) => aktualisiereFahrzeugdaten('aufbauart', event.target.value)}
                fullWidth
                data-testid="aufbauart"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.aufbauart ?? ''}
                disabled={true}
                InputProps={{
                  startAdornment: (
                    <Tooltip title="Aufbauart aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereFahrzeugdaten('aufbauart', vorgang?.dat?.aufbauart);
                          }}
                          disabled={!vorgang?.dat?.aufbauart || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATAufbauart"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Formular>
  );
}
