import React, { Suspense, useEffect, useState } from 'react';
import Chip from '@mui/material/Chip';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { toDateTimeString } from '../shared/dateTime';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { FOTOKATEGORIEN } from '../shared/domain/vorgang';
import { FotoLoeschenBestaetigungsdialog } from './dialog/FotoLoeschenBestaetigungsdialog';
import { KategorieHinzufuegenDialog } from './dialog/KategorieHinzufuegenDialog';
import CommentIcon from '@mui/icons-material/Comment';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import { Feld, TexterkennungKategorie } from '../shared/constants';
import { useUser } from '../hooks/useUser';
import { ImageTooltip } from './common/ImageTooltip';
import { isAenderbar } from '../domain/isAenderbar';
import { Datei, Foto as FotoType, Person, Vorgang } from '../types';
import { TexterkennungModalProps } from './modal/TexterkennungModal';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_FOTO_ERFASSEN } from '../frontendConstants';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles/createTheme';

const chips = (theme: Theme) => ({
  position: 'absolute',
  bottom: 0,
  width: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  cursor: 'pointer',
  '& > *': {
    marginRight: theme.spacing(0.5),
    marginBottom: theme.spacing(0.5)
  },
  marginBottom: -theme.spacing(1.5)
});

const cardHeaderText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginTop: '0.5rem',
  marginBottom: '0.5rem',
  marginLeft: '1rem'
};

const imgToolTip = {
  maxWidth: '500px'
};

const TexterkennungModal = React.lazy(() => import('./modal/TexterkennungModal'));

function LazyTexterkennungModal(props: TexterkennungModalProps) {
  return (
    <Suspense fallback={'Lade...'}>
      <TexterkennungModal {...props} />
    </Suspense>
  );
}

export type Props = {
  readonly fotoIndex: number;
  readonly vorgang: Vorgang;
  readonly foto: FotoType;
  readonly blob: string;
  readonly onDelete: (value: FotoType[]) => void;
  readonly onAddKategorien: (foto: FotoType, kategorien: string[]) => void;
  readonly onDeleteKategorie: (foto: FotoType, kategorien: string[]) => void;
  readonly isLoading: boolean;
  readonly setFahrzeughalter: (person: Person) => void;
  readonly fahrzeughalter: Person;
  readonly setShowPersonEingabeMaske: (value: boolean) => void;
  readonly showKategorieAuswahl: boolean;
  readonly setBildbearbeitenModalOpen: (value: number) => void;
  readonly showBeschreibungEdit: boolean;
  readonly editBeschreibung: (foto: FotoType, value: string) => void;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
};

export function Foto({
  fotoIndex,
  vorgang,
  foto,
  blob,
  onDelete,
  onAddKategorien,
  onDeleteKategorie,
  isLoading,
  setFahrzeughalter,
  fahrzeughalter,
  setShowPersonEingabeMaske,
  showKategorieAuswahl,
  setBildbearbeitenModalOpen,
  showBeschreibungEdit,
  editBeschreibung,
  speichereVorgang
}: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();
  const url = useDateiUrl(foto.key);

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [fotoLoeschenBestaetigungsdialogOpen, setFotoLoeschenBestaetigungsdialogOpen] = useState(false);
  const [texterkennungModalOpen, setTexterkennungModalOpen] = useState(false);
  const [kategorieAuswahl, setKategorieAuswahl] = useState(showKategorieAuswahl || false);
  const [kategorieHinzufuegenDialogOpen, setKategorieHinzufuegenDialogOpen] = useState(false);

  const isDisabled = isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_FOTO_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  useEffect(() => {
    setKategorieAuswahl(showKategorieAuswahl);
  }, [showKategorieAuswahl]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenFotoLoeschenBestaetigungsdialog = () => {
    setFotoLoeschenBestaetigungsdialogOpen(true);
    setAnchorEl(null);
  };

  const handleCloseFotoLoeschenBestaetigungsdialog = () => {
    setFotoLoeschenBestaetigungsdialogOpen(false);
  };

  const handleOpenBildbearbeitenModal = (index: number) => {
    setBildbearbeitenModalOpen(index);
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setFotoLoeschenBestaetigungsdialogOpen(false);
    onDelete([foto]);
  };

  const handleOpenTexterkennungModal = () => {
    setTexterkennungModalOpen(true);
    setAnchorEl(null);
  };

  const handleCloseTexterkennungModal = () => {
    setTexterkennungModalOpen(false);
  };

  const handleIdentifyText = (zugeordneteFelder: Feld[], zugeordneteKategorien: TexterkennungKategorie[], fotoType: FotoType) => {
    setTexterkennungModalOpen(false);

    const fotos = (vorgang.fotos ?? []).map(fuegeKategorienZuFotoHinzu(fotoType, zugeordneteKategorien));
    const zuAktualisierenderVorgang: Partial<Vorgang> = { id: vorgang.id, fotos };

    for (const zugeordnetesFeld of zugeordneteFelder) {
      // @ts-ignore
      if (!zuAktualisierenderVorgang[zugeordnetesFeld.objekt]) {
        // @ts-ignore
        zuAktualisierenderVorgang[zugeordnetesFeld.objekt] = vorgang[zugeordnetesFeld.objekt];
      }
      // @ts-ignore
      zuAktualisierenderVorgang[zugeordnetesFeld.objekt][zugeordnetesFeld.feld] = zugeordnetesFeld.value;
    }

    speichereVorgang({ ...zuAktualisierenderVorgang });
  };

  const handleOpenKategorieHinzufuegenDialog = () => {
    setKategorieHinzufuegenDialogOpen(true);
    setAnchorEl(null);
  };

  const handleCloseKategorieHinzufuegenDialog = () => {
    setKategorieHinzufuegenDialogOpen(false);
    setAnchorEl(null);
  };

  const handleAddKategorie = (kategorie: string) => {
    setKategorieHinzufuegenDialogOpen(false);
    onAddKategorien(foto, [kategorie]);
  };

  const handleDeleteKategorie = (kategorie: string) => {
    onDeleteKategorie(foto, [kategorie]);
  };

  const handleChangeBeschreibung = (value: string) => {
    editBeschreibung(foto, value);
  };

  const kategorien = FOTOKATEGORIEN.filter((kategorie) => {
    return !(
      (foto.kategorien ?? []).includes(kategorie) ||
      (kategorie === FOTOKATEGORIEN[1] && // Angebotsanfrage
        !(foto.kategorien ?? []).includes(FOTOKATEGORIEN[0]))
    );
  });
  const beschreibung =
    'Bitte wählen Sie eine Kategorie aus, die dem Foto hinzugefügt werden soll. Bilder mit der Kategorie "Gutachtenrelevant" tauchen im Gutachten auf. Die Kategorie "Angebotsanfrage" kann nur auf Fotos, die bereits die Kategorie "Gutachtenrelevant" haben, vergeben werden.';

  const handleKategorieCheckbox = (k: string[]) => {
    onAddKategorien(foto, k);
  };

  return (
    <Card
      sx={{
        position: 'relative',
        maxWidth: '100%',
        width: '320px'
      }}
    >
      <>
        {kategorieAuswahl ? (
          <>
            <FormControlLabel
              control={
                <Checkbox
                  checked={foto?.kategorien?.includes('Gutachtenrelevant') ?? false}
                  onChange={(event) => {
                    handleKategorieCheckbox(
                      event.target.checked && !foto?.kategorien?.includes('Angebotsanfrage') ? ['Gutachtenrelevant', 'Angebotsanfrage'] : ['Gutachtenrelevant']
                    );
                  }}
                  name="Gutachtenrelevant"
                  color="primary"
                />
              }
              label="Gutachtenrelevant"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={foto?.kategorien?.includes('Angebotsanfrage') ?? false}
                  onChange={() => handleKategorieCheckbox(['Angebotsanfrage'])}
                  name="Angebotsanfrage"
                  color="primary"
                />
              }
              label="Angebotsanfrage"
            />
          </>
        ) : showBeschreibungEdit ? (
          <TextField
            variant="standard"
            fullWidth
            label="Beschreibung"
            multiline
            maxRows={4}
            value={foto.beschreibung}
            onChange={(event) => handleChangeBeschreibung(event.target.value)}
          />
        ) : (
          <Box component="div" sx={{ display: 'flex' }}>
            <Box component="div" sx={{ maxWidth: '80%' }}>
              <Tooltip title={foto.dateiname ?? ''}>
                <Box component="p" sx={cardHeaderText} data-testid="fotoTitle">
                  {foto.dateiname}
                </Box>
              </Tooltip>
              <Box component="p" sx={cardHeaderText}>
                {toDateTimeString(foto.uploaddatum)}
              </Box>
            </Box>
            <IconButton sx={{ marginLeft: 'auto' }} aria-label="settings" onClick={handleClick} disableRipple disableFocusRipple size="large">
              <MoreVertIcon />
            </IconButton>
          </Box>
        )}
      </>

      <ImageTooltip sx={imgToolTip} src={url ?? ''} alt="Kein Bild vorhanden">
        <CardActionArea onClick={() => (!isDisabled ? handleOpenBildbearbeitenModal(fotoIndex) : '')}>
          {foto.beschreibung && foto.beschreibung !== '' && (
            <Tooltip
              sx={{
                position: 'absolute',
                top: 0,
                display: 'flex',
                justifyContent: 'left'
              }}
              title={foto.beschreibung}
            >
              <CommentIcon />
            </Tooltip>
          )}

          <CardMedia
            sx={{
              height: 0,
              paddingTop: '56.25%' // 16:9
            }}
            image={blob}
          >
            {!blob && <CircularProgress />}
          </CardMedia>
        </CardActionArea>
      </ImageTooltip>
      <ImageTooltip sx={imgToolTip} src={url ?? ''} alt="Kein Bild vorhanden">
        {(foto.kategorien ?? []).length > 0 && (
          <CardContent sx={chips} onClick={() => (!isDisabled ? handleOpenBildbearbeitenModal(fotoIndex) : '')}>
            {foto.kategorien?.map((kategorie) => (
              <Chip key={kategorie} label={kategorie} color="primary" size="small" onDelete={() => handleDeleteKategorie(kategorie)} disabled={isDisabled} />
            ))}
          </CardContent>
        )}
      </ImageTooltip>

      <FotoMenu
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        onOpenFotoLoeschenBestaetigungsdialog={handleOpenFotoLoeschenBestaetigungsdialog}
        onOpenTexterkennungModal={handleOpenTexterkennungModal}
        url={url ?? ''}
        onOpenKategorieHinzufuegenDialog={handleOpenKategorieHinzufuegenDialog}
        disableAddKategorie={kategorien.length === 0}
        isDisabled={isDisabled}
      />
      {fotoLoeschenBestaetigungsdialogOpen && (
        <FotoLoeschenBestaetigungsdialog
          open={fotoLoeschenBestaetigungsdialogOpen}
          onClose={handleCloseFotoLoeschenBestaetigungsdialog}
          onDelete={handleDelete}
        />
      )}
      {
        <LazyTexterkennungModal
          open={texterkennungModalOpen}
          onClose={handleCloseTexterkennungModal}
          onIdentifyText={handleIdentifyText}
          isLoading={isLoading}
          vorgang={vorgang}
          foto={foto}
          url={url ?? ''}
          setFahrzeughalter={setFahrzeughalter}
          fahrzeughalter={fahrzeughalter}
          setShowPersonEingabeMaske={setShowPersonEingabeMaske}
        />
      }
      {kategorieHinzufuegenDialogOpen && (
        <KategorieHinzufuegenDialog
          open={kategorieHinzufuegenDialogOpen}
          onClose={handleCloseKategorieHinzufuegenDialog}
          onAdd={handleAddKategorie}
          kategorien={kategorien}
          text={beschreibung}
        />
      )}
    </Card>
  );
}

type FotoMenuProps = {
  readonly anchorEl: (EventTarget & HTMLButtonElement) | null;
  readonly onClose: () => void;
  readonly onOpenFotoLoeschenBestaetigungsdialog: () => void;
  readonly onOpenKategorieHinzufuegenDialog: () => void;
  readonly onOpenTexterkennungModal: () => void;
  readonly url: string;
  readonly disableAddKategorie: boolean;
  readonly isDisabled: boolean;
};

function FotoMenu({
  anchorEl,
  onClose,
  onOpenFotoLoeschenBestaetigungsdialog,
  onOpenKategorieHinzufuegenDialog,
  onOpenTexterkennungModal,
  url,
  disableAddKategorie,
  isDisabled
}: FotoMenuProps): JSX.Element {
  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem disabled={disableAddKategorie || isDisabled} onClick={onOpenKategorieHinzufuegenDialog}>
        <ListItemIcon>
          <AddIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Kategorie hinzufügen" />
      </MenuItem>
      <MenuItem onClick={onOpenTexterkennungModal} disabled={isDisabled}>
        <ListItemIcon>
          <ImageSearchIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Text erkennen" />
      </MenuItem>
      <MenuItem onClick={() => window.open(url, '_blank')}>
        <ListItemIcon>
          <ImageSearchIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Bild anzeigen" />
      </MenuItem>
      <MenuItem onClick={onOpenFotoLoeschenBestaetigungsdialog} disabled={isDisabled}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Löschen" />
      </MenuItem>
    </Menu>
  );
}

export function fuegeKategorienZuFotoHinzu(foto: FotoType, zugeordneteKategorien: TexterkennungKategorie[]) {
  return (datei: Datei) => {
    if (datei.key === foto.key) {
      const array = [];
      array.push(...(datei.kategorien ?? []));
      array.push(...zugeordneteKategorien.map((kategorie) => kategorie.kategorie));
      const uniqueArray = [...new Set(array)];

      return {
        ...datei,
        kategorien: uniqueArray
      };
    }
    return datei;
  };
}
