import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import CardContent from '@mui/material/CardContent';
import TableSortLabel from '@mui/material/TableSortLabel';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import { SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import { GUTACHTENARTEN_TEXTE } from '../../shared/domain/vorgang';
import { STATUS_TEXTE } from '../../frontendConstants';
import Status from '../../components/common/Status';
import { toDateString } from '../../shared/dateTime';
import { Thumbnail } from '../../components/common/Thumbnail';
import { useUser } from '../../hooks/useUser';
import { useSnackbar } from 'notistack';
import { Vorgang, VorgangDashboard } from '../../types';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';
import { aktualisiereVorgang } from '../../domain/aktualisiereVorgang';
import { Gutachtenart, Status as StatusEnum } from '../../shared/constants';
import { S3Client } from '../../shared/s3Client';
import { MenuCell } from '../../components/Tabelle/MenuCell';
import Box from '@mui/material/Box';

type Props = {
  readonly areVorgaengeLoading: boolean;
  readonly vorgaenge: VorgangDashboard[];
  readonly setLoading: (value: boolean) => void;
  readonly vorgangAktualisiert: (vorgang: Vorgang) => void;
  readonly handleSort: (property: string) => void;
  readonly sortBy: string;
  readonly order: 'asc' | 'desc';
};

export function Vorgangstabelle({ areVorgaengeLoading, vorgaenge, setLoading, vorgangAktualisiert, handleSort, sortBy, order }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [openVorgangDialog, setOpenVorgangDialog] = useState(false);
  const [dialogVorgang, setDialogVorgang] = useState<VorgangDashboard | null>(null);

  const headCells: {
    id: string;
    label?: string;
    className?: string;
    sortable?: true;
  }[] = [
    { id: 'foto', label: 'Foto' },
    { id: 'vorgangsnummer', label: 'Vorgangsnummer', sortable: true },
    { id: 'bearbeiterNachname', label: 'Bearbeiter', sortable: true },
    { id: 'status', label: 'Status', sortable: true },
    { id: 'gutachtenart', label: 'Gutachtenart', sortable: true },
    { id: 'kennzeichen', label: 'Kennzeichen', sortable: true },
    { id: 'auftraggeberAnzeigename', label: 'Auftraggeber', sortable: true },
    { id: 'createdAt', label: 'Erstellungsdatum', sortable: true },
    { id: 'verwaltungscheckbox', label: 'Verwaltung', sortable: true },
    { id: 'aktionen' }
  ];

  const { hatGruppeVonMandant } = useUser();

  const handleLegeReparaturnachweisAnClick = (vorgang: VorgangDashboard) => {
    setLoading(true);

    makeGraphqlQuery<Vorgang>(mutations.erzeugeReparaturnachweis, {
      mandant: vorgang.mandant,
      vorgangsnummer: vorgang.vorgangsnummer
    })
      .then((vorgang) => {
        enqueueSnackbar(`Der Reparaturnachweis ${vorgang.vorgangsnummer} wurde erfolgreich angelegt.`, SUCCESS_MESSAGE_AUTO_HIDE);
        setLoading(false);
        history.push(`/vorgang/${vorgang.id}/kunde`);
      })
      .catch((error) => {
        enqueueSnackbar(error.message ?? 'Es konnte kein Reparaturnachweis angelegt werden.', ERROR_MESSAGE);
        setLoading(false);
      });
  };

  const loescheWiederherstelleVorgang = function () {
    setLoading(true);

    aktualisiereVorgang({
      id: dialogVorgang?.id,
      geloescht: !dialogVorgang?.geloescht
    })
      .then((vorgang) => {
        setOpenVorgangDialog(false);
        setDialogVorgang(null);
        vorgangAktualisiert && vorgangAktualisiert(vorgang);
        enqueueSnackbar(
          `Der Vorgang ${vorgang.vorgangsnummer} wurde erfolgreich ${vorgang.geloescht ? 'gelöscht' : 'wiederhergestellt'}.`,
          SUCCESS_MESSAGE_AUTO_HIDE
        );
        setLoading(false);
      })
      .catch((error) => {
        let message = `Der Vorgang konnte nicht ${dialogVorgang?.geloescht ? 'wiederhergestellt' : 'gelöscht'} werden.`;
        if (error.errors[0]?.message) {
          message = error.errors[0].message;
        }

        enqueueSnackbar(message, ERROR_MESSAGE);
        setLoading(false);
      });
  };

  const setzeUrlParameterBesichtigung = (vorgang: VorgangDashboard) => {
    if (vorgang.status === StatusEnum.BESICHTIGUNG) {
      return '#istBesichtigung';
    }
    return '';
  };

  const navigiereZuVorgang = (vorgang: VorgangDashboard) => {
    history.push(`/vorgang/${vorgang.id}/kunde${setzeUrlParameterBesichtigung(vorgang)}`);
  };

  return (
    <>
      {openVorgangDialog && (
        <VorgangLoeschenWiederherstellenDialog
          vorgang={dialogVorgang}
          onJa={loescheWiederherstelleVorgang}
          onNeinOderClose={() => {
            setOpenVorgangDialog(false);
            setDialogVorgang(null);
          }}
        />
      )}
      <Hidden mdDown>
        <TableContainer component={Paper}>
          <Table data-busy={areVorgaengeLoading} data-testid="vorgangstabelle">
            <TableHead>
              <TableRow>
                {headCells.map((headCell) => (
                  <Hidden key={headCell.id}>
                    <TableCell key={headCell.id} sortDirection={sortBy === headCell.id ? order : false} className={headCell.className}>
                      {headCell.sortable ? (
                        <TableSortLabel
                          active={sortBy === headCell.id}
                          direction={sortBy === headCell.id ? order : 'asc'}
                          onClick={() => handleSort(headCell.id)}
                        >
                          {headCell.label}
                        </TableSortLabel>
                      ) : (
                        headCell.label
                      )}
                    </TableCell>
                  </Hidden>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {vorgaenge.map((vorgang) => (
                <TableRow hover key={vorgang.id} data-testid={vorgang.vorgangsnummer} onClick={() => navigiereZuVorgang(vorgang)} sx={{ cursor: 'pointer' }}>
                  <TableCell data-testid="fotos">
                    {(vorgang?.fotos ?? []).length > 0 && <Thumbnail height={50} thumbnailkey={vorgang?.fotos[0].thumbnailkey ?? ''} />}
                  </TableCell>
                  <TableCell data-testid="vorgangsnummer">
                    <Link
                      to={{
                        pathname: `/vorgang/${vorgang.id}/kunde`,
                        hash: setzeUrlParameterBesichtigung(vorgang)
                      }}
                      component={RouterLink}
                      onClick={(event) => event.stopPropagation()}
                      underline="hover"
                    >
                      {vorgang.vorgangsnummer}
                    </Link>
                  </TableCell>
                  <TableCell data-testid="bearbeiter">
                    {vorgang.bearbeiterVorname} {vorgang.bearbeiterNachname}
                  </TableCell>
                  <TableCell data-testid="status">
                    <Status> {(STATUS_TEXTE as Record<string, string>)[vorgang.status]}</Status>
                  </TableCell>
                  <TableCell data-testid="gutachtenart">{(GUTACHTENARTEN_TEXTE as Record<string, string>)[vorgang.gutachtenart]}</TableCell>
                  <TableCell data-testid="kennzeichen">{vorgang.kennzeichen}</TableCell>
                  <TableCell data-testid="auftraggeber">
                    {vorgang.auftraggeberFirmenname ? vorgang.auftraggeberFirmenname : vorgang.auftraggeberNachname}
                  </TableCell>
                  <TableCell data-testid="createdAt">{toDateString(vorgang.createdAt)}</TableCell>
                  <TableCell padding="checkbox" data-testid="verwaltungscheckbox">
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        justifyContent: 'center'
                      }}
                    >
                      <Checkbox
                        data-testid={'verwaltungscheckbox'}
                        edge="start"
                        color="primary"
                        checked={Boolean(vorgang?.verwaltungscheckbox)}
                        disabled={true}
                      />
                    </Box>
                  </TableCell>
                  <MenuCell>
                    {hatGruppeVonMandant(vorgang.mandant, 'Admin') ? (
                      <MenuItem
                        onClick={() => {
                          setDialogVorgang(vorgang);
                          setOpenVorgangDialog(true);
                        }}
                      >
                        {vorgang.geloescht ? 'Wiederherstellen' : 'Löschen'}
                      </MenuItem>
                    ) : null}

                    {vorgang.status === StatusEnum.ABGESCHLOSSEN && (
                      <MenuItem key="Reparaturnachweis" onClick={() => handleLegeReparaturnachweisAnClick(vorgang)}>
                        Reparaturnachweis anlegen
                      </MenuItem>
                    )}
                  </MenuCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Hidden>
      <Hidden mdUp>
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          {vorgaenge.map((vorgang) => (
            <Grid key={vorgang.id} item xs={12}>
              <Card>
                <CardActionArea onClick={() => navigiereZuVorgang(vorgang)}>
                  <CardHeader
                    title={`${vorgang.vorgangsnummer} ${vorgang?.kennzeichen ? vorgang?.kennzeichen : ''}`}
                    subheader={`${vorgang.bearbeiterVorname} ${vorgang.bearbeiterNachname}`}
                  />
                  {vorgang?.fotos && vorgang?.fotos?.length > 0 && <ThumbnailCard thumbnailkey={vorgang.fotos[0].thumbnailkey ?? ''} />}

                  <CardContent>
                    <Grid container spacing={1} justifyContent="space-between">
                      <Grid item>
                        <Status>{STATUS_TEXTE[vorgang.status as StatusEnum]}</Status>
                      </Grid>
                      <Grid item>
                        <Typography variant="body1" component="p">
                          {GUTACHTENARTEN_TEXTE[vorgang.gutachtenart as Gutachtenart]}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant="body2" component="p" sx={{ display: 'flex', alignItems: 'flex-start' }}>
                          <CalendarTodayIcon
                            color="secondary"
                            fontSize="small"
                            sx={(theme) => ({
                              marginRight: theme.spacing(1)
                            })}
                          />
                          {toDateString(vorgang.createdAt)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Hidden>
    </>
  );
}

type ThumbnailCardProps = {
  thumbnailkey: string;
};

function ThumbnailCard({ thumbnailkey }: ThumbnailCardProps): JSX.Element | null {
  const [url, setUrl] = useState('');
  useEffect(() => {
    const loadUrl = async (key: string) => {
      const url = await S3Client.get(key.replace(/^public\//, ''));
      setUrl(url.toString());
    };
    if (thumbnailkey) {
      loadUrl(thumbnailkey);
    }
  }, [thumbnailkey]);
  return url ? <CardMedia sx={{ height: '150px' }} image={url} src={url} /> : null;
}

type VorgangLoeschenWiederherstellenDialogProps = {
  readonly vorgang: VorgangDashboard | null;
  readonly onJa: () => void;
  readonly onNeinOderClose: () => void;
};

function VorgangLoeschenWiederherstellenDialog({ vorgang, onJa, onNeinOderClose }: VorgangLoeschenWiederherstellenDialogProps) {
  return (
    <Dialog open={true} onClose={onNeinOderClose}>
      <DialogTitle>Vorgang {vorgang?.geloescht ? 'wiederherstellen' : 'löschen'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Soll der Vorgang {vorgang?.vorgangsnummer} {vorgang?.geloescht ? 'wiederhergestellt' : 'gelöscht'} werden?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="contained" color="primary" onClick={onNeinOderClose}>
          Abbrechen
        </Button>
        <Button fullWidth variant="contained" color="primary" onClick={onJa}>
          {vorgang?.geloescht ? 'wiederherstellen' : 'löschen'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
