/* eslint sonarjs/no-duplicate-string: 0 */
export const config = {
  aws_project_region: 'eu-central-1',
  aws_appsync_graphqlEndpoint: 'https://zubdsxsqn5blhg47yegojsy6dy.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id: 'eu-central-1:3fb83740-ae13-44c4-9df1-12ab9def9b7d',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_J9QQABkcq',
  aws_user_pools_web_client_id: '66efbphnal397m3unfb3d5r6e4',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'dateien75246-cypress',
  aws_user_files_s3_bucket_region: 'eu-central-1',
  aws_dynamodb_all_tables_region: 'eu-central-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'laufnummer-cypress',
      region: 'eu-central-1'
    }
  ],
  predictions: {
    identify: {
      identifyText: {
        proxy: false,
        region: 'eu-central-1',
        defaults: {
          format: 'ALL'
        }
      }
    }
  },
  aws_cognito_login_mechanisms: ['PREFERRED_USERNAME'],
  apiGateway: 'https://9acvcwa80j.execute-api.eu-central-1.amazonaws.com/cypress/'
};
