import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { LAENDER } from '../countries';

const LAENDER_BY_LAND: string[] = LAENDER.map((it) => it.land);

const filterOptions = createFilterOptions<string>({
  matchFrom: 'start',
  trim: true
});

type Props = {
  value: string | null | undefined;
  disabled: boolean;
  onChange: (value: string | null) => void;
  dataTestId?: string;
};

export function LandAutocomplete({ value, disabled, onChange, dataTestId }: Props): JSX.Element {
  return (
    <Autocomplete
      disabled={disabled}
      filterOptions={filterOptions}
      freeSolo
      options={LAENDER_BY_LAND}
      value={value ?? 'Deutschland'}
      onChange={(_, value) => onChange(value)}
      renderInput={(params) => (
        <TextField
          variant="standard"
          {...params}
          label="Land"
          disabled={disabled}
          fullWidth
          data-testid={dataTestId}
          InputProps={{
            ...params.InputProps
          }}
        />
      )}
    />
  );
}
