import './Fotos.css';
import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { DateiErfolgreichHochgeladenAlert, ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import { Foto, Props } from '../../components/Foto';
import { AKTION_FOTO_ERFASSEN, getDefaultPersonMitTyp, FOTOTYPEN } from '../../frontendConstants';
import BildbearbeitenModal from '../../components/modal/BildbearbeitenModal';
import { useUser } from '../../hooks/useUser';
import { isAenderbar } from '../../domain/isAenderbar';
import { Foto as FotoType, Person } from '../../types';
import { useSnackbar } from 'notistack';
import { ladeFotosHoch } from '../../domain/dateiUpload';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { aktionErlaubt } from '../../domain/aktionErlaubt';
import { Dateityp, PersonenTyp } from '../../shared/constants';
import { S3Client } from '../../shared/s3Client';
import { FileInput } from '../../components/FileInput/FileInput';
import { getMessageFromError } from '../../shared/throw';
import { aktualisierePerson } from '../../domain/aktualisierePerson';
import { PersonEingabeMaske } from '../../components/PersonEingabeMaske/PersonEingabeMaske';

export async function loescheFotos(vorgangFotos: FotoType[], zuLoeschendeFotos: FotoType[]): Promise<FotoType[]> {
  const uebrigeFotos = vorgangFotos.filter((vorgangFoto) => {
    return !zuLoeschendeFotos.find((foto) => foto.key === vorgangFoto.key);
  });

  await Promise.all(
    zuLoeschendeFotos.map(async (foto) => {
      if (foto.key) {
        await S3Client.remove(foto.key);
      }
    })
  );
  return uebrigeFotos;
}

export const sortFotos = (fotos: FotoType[]) => {
  return [...(fotos || [])].sort((foto1, foto2) => {
    const scoreFoto1 = foto1.kategorien?.find((kategorie) => kategorie === 'Gutachtenrelevant') ? 1 : 0;
    const scoreFoto2 = foto2.kategorien?.find((kategorie) => kategorie === 'Gutachtenrelevant') ? 1 : 0;
    return scoreFoto2 - scoreFoto1;
  });
};

export async function getBlobsForFotos(fotos: FotoType[]): Promise<Record<string, string>> {
  const result: Record<string, string> = {};
  for (const foto of fotos) {
    const blob = await S3Client.get((foto.thumbnailkey ?? '').replace(/^public\//, ''));
    if (foto.key && blob) {
      result[foto.key] = blob;
    }
  }
  return result;
}

const SortItem = SortableElement(({ ...props }: Props): JSX.Element => {
  return (
    <Grid item xs={12} sm={3} md={3} lg={2} key={props.foto.key}>
      <Foto {...props} />
    </Grid>
  );
});

type SortableListProps = {
  readonly isDisabled: boolean;
  readonly getBlobForFoto: (foto: FotoType) => string;
} & Omit<Props, 'blob' | 'foto' | 'fotoIndex'>;

const SortableList = SortableContainer<SortableListProps>(({ getBlobForFoto, ...props }: SortableListProps): JSX.Element => {
  return (
    <Grid container spacing={3} className="fotos_fotobereich">
      {props?.vorgang?.fotos?.map((foto, index) => (
        <SortItem key={`item-${foto.key}`} index={index} foto={foto} blob={getBlobForFoto(foto)} {...props} fotoIndex={index} />
      ))}
    </Grid>
  );
});

export default function Fotos(): JSX.Element | null {
  const { vorgang, isLoading, setLoading, speichereVorgang, aktualisiereVorgang } = useVorgangContext();
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();

  const isDisabled = isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_FOTO_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const [isEditorSaving, setIsEditorSaving] = useState(false);
  const [fahrzeughalter, setFahrzeughalter] = useState<Person>(getDefaultPersonMitTyp(PersonenTyp.PERSON));
  const [showPersonEingabeMaske, setShowPersonEingabeMaske] = useState(false);
  const [bildbearbeitenModalOpen, setBildbearbeitenModalOpen] = useState(false);
  const [showKategorieAuswahl, setShowKategorieAuswahl] = useState(false);
  const [showBeschreibungEdit, setShowBeschreibungEdit] = useState(false);
  const [bildBearbeitenIndex, setBildBearbeitenIndex] = useState(0);
  const [blobsByKey, setBlobsByKey] = useState<Record<string, string> | null>(null);

  useEffect(() => {
    // Nur dann alle Foto-Blobs holen, wenn es noch keine gibt
    // (Aktuell noch (wie vorher) auch wenn ein neues Foto hochgeladen wird)
    if (vorgang.fotos && blobsByKey === null) {
      if (!vorgang.fotos) return;
      getBlobsForFotos(vorgang.fotos).then((blobMap) => {
        setBlobsByKey(blobMap);
      });
    }
  }, [vorgang.fotos, blobsByKey]);

  const getBlobForFoto = useCallback(
    (foto: FotoType) => {
      if (foto.key && blobsByKey) {
        return blobsByKey[foto.key] || '';
      }
      return '';
    },
    [blobsByKey]
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: { oldIndex: number; newIndex: number }) => {
      const fotosAfterMove = arrayMove([...(vorgang.fotos ?? [])], oldIndex, newIndex);
      aktualisiereVorgang({ fotos: sortFotos(fotosAfterMove) });
    },
    [vorgang.fotos, aktualisiereVorgang]
  );

  const handleOpenBildbearbeitenModal = useCallback((fotoIndex: number) => {
    setBildBearbeitenIndex(fotoIndex);
    setBildbearbeitenModalOpen(true);
  }, []);

  const handleFotoLoeschen = useCallback(
    (fotosToDelete: FotoType[]) => {
      setLoading(true);

      loescheFotos(vorgang.fotos ?? [], fotosToDelete)
        .then((uebrigeFotos) => speichereVorgang({ fotos: uebrigeFotos }))
        .then(() => enqueueSnackbar('Das Foto wurde erfolgreich gelöscht.', SUCCESS_MESSAGE_AUTO_HIDE))
        .catch(() => enqueueSnackbar('Die Datei konnte nicht gelöscht werden.', ERROR_MESSAGE))
        .finally(() => setLoading(false));
    },
    [enqueueSnackbar, setLoading, speichereVorgang, vorgang.fotos]
  );

  const handleKategorienUmschalten = useCallback(
    (foto: FotoType, kategorien: string[]) => {
      const aktualisierteFotos = (vorgang.fotos ?? []).map((vorgangFoto) => {
        if (vorgangFoto.key === foto.key) {
          kategorien.forEach((kategorie) => {
            if ((vorgangFoto.kategorien ?? []).includes(kategorie)) {
              vorgangFoto.kategorien = (vorgangFoto.kategorien ?? []).filter((kat) => kat !== kategorie);
            } else {
              (vorgangFoto.kategorien ?? []).push(kategorie);
            }
          });
          return { ...vorgangFoto };
        }
        return vorgangFoto;
      });

      aktualisiereVorgang({ fotos: sortFotos(aktualisierteFotos) });
    },
    [aktualisiereVorgang, vorgang.fotos]
  );

  const handleDropFileUpload = useCallback(
    (files: File[]) => {
      setLoading(true);

      ladeFotosHoch(vorgang, files)
        .then((aktualisierteFotos) => speichereVorgang({ fotos: aktualisierteFotos }))
        .then(() => {
          setBlobsByKey(null);
          enqueueSnackbar(<DateiErfolgreichHochgeladenAlert vorgang={vorgang} multiple={files.length > 1} typ={Dateityp.FOTO} />, SUCCESS_MESSAGE_AUTO_HIDE);
        })
        .catch(() => enqueueSnackbar('Das Foto konnte nicht hochgeladen werden.', ERROR_MESSAGE))
        .finally(() => setLoading(false));
    },
    [enqueueSnackbar, setLoading, speichereVorgang, vorgang]
  );

  const handleLegeFahrzeughalterAn = useCallback(
    async (person: Person) => {
      setLoading(true);
      let angelegtePerson;

      if (!person.id) {
        try {
          angelegtePerson = await aktualisierePerson({
            ...person,
            mandant: vorgang.mandant
          });
        } catch (error) {
          enqueueSnackbar(getMessageFromError(error) ?? 'Vermittler konnte nicht angelegt werden', ERROR_MESSAGE);
          setShowPersonEingabeMaske(true); // wieder auf true setzen, da irgendwo der Dialog geschlossen wird
          setLoading(false);
          return;
        }
      } else {
        angelegtePerson = await aktualisierePerson(person);
      }

      const fahrzeughalterIstAuftraggeber = vorgang?.auftraggeberId === angelegtePerson.id;
      if (!vorgang?.auftraggeberId) {
        await speichereVorgang({
          auftraggeberId: angelegtePerson.id,
          fahrzeughalterIstAuftraggeber
        });
      } else {
        await speichereVorgang({
          fahrzeughalterId: angelegtePerson.id,
          fahrzeughalterIstAuftraggeber
        });
      }

      setShowPersonEingabeMaske(false);
      setLoading(false);
    },
    [enqueueSnackbar, setLoading, speichereVorgang, vorgang?.auftraggeberId, vorgang.mandant]
  );

  const handleBildSpeichern = useCallback(
    (bilddaten: File) => {
      setIsEditorSaving(true);

      ladeFotosHoch(vorgang, [bilddaten])
        .then((zuAktualisierendeFotos) => speichereVorgang({ fotos: zuAktualisierendeFotos }))
        .then(() => {
          setBlobsByKey(null);
          enqueueSnackbar('Das Foto wurde erfolgreich gespeichert.', SUCCESS_MESSAGE_AUTO_HIDE);
        })
        .catch(() => enqueueSnackbar('Das Foto konnte nicht gespeichert werden.', ERROR_MESSAGE))
        .finally(() => setIsEditorSaving(false));
    },
    [enqueueSnackbar, speichereVorgang, vorgang]
  );

  const onEditBeschreibung = useCallback(
    (foto: FotoType, beschreibung: string) => {
      const zuAktualisierendeFotos = handleBeschreibungZuFoto(vorgang.fotos ?? [], foto, beschreibung);
      aktualisiereVorgang({ fotos: zuAktualisierendeFotos });
    },
    [aktualisiereVorgang, vorgang.fotos]
  );

  const handleShowKategorien = useCallback(() => {
    setShowBeschreibungEdit(false);
    setShowKategorieAuswahl(!showKategorieAuswahl);
  }, [showKategorieAuswahl]);

  const handleShowBeschreibung = useCallback(() => {
    setShowKategorieAuswahl(false);
    setShowBeschreibungEdit(!showBeschreibungEdit);
  }, [showBeschreibungEdit]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <div className="fotos__aktionen">
        <FileInput
          onChange={(acceptedFiles) => handleDropFileUpload(acceptedFiles)}
          accept={FOTOTYPEN.join(',')}
          dataTestid="fotoFileInput"
          disabled={isDisabled}
        >
          Fotos hochladen
        </FileInput>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleOpenBildbearbeitenModal(0)}
          startIcon={<EditIcon />}
          disabled={!vorgang.fotos || vorgang.fotos.length === 0 || isDisabled}
        >
          Fotos&nbsp;bearbeiten
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowKategorien}
          startIcon={showKategorieAuswahl ? <RemoveIcon /> : <AddIcon />}
          disabled={!vorgang.fotos || vorgang.fotos.length === 0 || isDisabled}
        >
          Kategorien
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleShowBeschreibung}
          startIcon={showBeschreibungEdit ? <RemoveIcon /> : <AddIcon />}
          disabled={!vorgang.fotos || vorgang.fotos.length === 0 || isDisabled}
        >
          Beschreibung
        </Button>
      </div>

      {vorgang.fotos && vorgang.fotos.length > 0 ? (
        <SortableList
          axis="xy"
          pressDelay={300}
          onSortEnd={onSortEnd}
          useWindowAsScrollContainer={true}
          isDisabled={isDisabled}
          getBlobForFoto={getBlobForFoto}
          vorgang={vorgang}
          onDelete={handleFotoLoeschen}
          onAddKategorien={handleKategorienUmschalten}
          onDeleteKategorie={handleKategorienUmschalten}
          isLoading={isLoading}
          setFahrzeughalter={setFahrzeughalter}
          fahrzeughalter={fahrzeughalter}
          setShowPersonEingabeMaske={setShowPersonEingabeMaske}
          showKategorieAuswahl={showKategorieAuswahl}
          setBildbearbeitenModalOpen={handleOpenBildbearbeitenModal}
          showBeschreibungEdit={showBeschreibungEdit}
          editBeschreibung={onEditBeschreibung}
          speichereVorgang={speichereVorgang}
        />
      ) : (
        <Alert sx={{ marginTop: '3rem' }} severity="info">
          Zu diesem Vorgang gibt es keine Fotos.
        </Alert>
      )}
      {showPersonEingabeMaske && (
        <PersonEingabeMaske
          personDatensatz={fahrzeughalter}
          typ={PersonenTyp.PERSON}
          onClose={() => setShowPersonEingabeMaske(false)}
          aktualisierePerson={handleLegeFahrzeughalterAn}
          isDisabled={isDisabled}
          mandant={vorgang.mandant}
          testPraefix="fahrzeughalter"
        />
      )}
      {bildbearbeitenModalOpen && (
        <BildbearbeitenModal
          open={bildbearbeitenModalOpen}
          onClose={() => setBildbearbeitenModalOpen(false)}
          fotos={vorgang.fotos ?? []}
          onSave={handleBildSpeichern}
          fotoIndex={bildBearbeitenIndex}
          isEditorSaving={isEditorSaving}
        />
      )}
    </>
  );
}

export function handleBeschreibungZuFoto(vorgangFotos: FotoType[], foto: FotoType, beschreibung: string): FotoType[] {
  return (vorgangFotos || []).map((vorgangFoto) => {
    if (vorgangFoto.key === foto.key) {
      vorgangFoto.beschreibung = beschreibung;
      return { ...vorgangFoto };
    }
    return vorgangFoto;
  });
}
