import { Land } from './shared/constants';

export const LAENDER: Land[] = [
  { nationalitaet: 'afghanisch', land: 'Afghanistan' },
  { nationalitaet: 'ägyptisch', land: 'Ägypten' },
  { nationalitaet: 'albanisch', land: 'Albanien' },
  { nationalitaet: 'algerisch', land: 'Algerien' },
  { nationalitaet: 'andorranisch', land: 'Andorra' },
  { nationalitaet: 'angolanisch', land: 'Angola' },
  { nationalitaet: 'antiguanisch', land: 'Antigua und Barbuda' },
  { nationalitaet: 'äquatorialguineisch', land: 'Äquatorialguinea' },
  { nationalitaet: 'argentinisch', land: 'Argentinien' },
  { nationalitaet: 'armenisch', land: 'Armenien' },
  { nationalitaet: 'aserbaidschanisch', land: 'Aserbaidschan' },
  { nationalitaet: 'äthiopisch', land: 'Äthiopien' },
  { nationalitaet: 'australisch', land: 'Australien' },
  { nationalitaet: 'bahamaisch', land: 'Bahamas' },
  { nationalitaet: 'bahrainisch', land: 'Bahrain' },
  { nationalitaet: 'bangladeschisch', land: 'Bangladesch' },
  { nationalitaet: 'barbadisch', land: 'Barbados' },
  { nationalitaet: 'belgisch', land: 'Belgien' },
  { nationalitaet: 'belizisch', land: 'Belize' },
  { nationalitaet: 'beninisch', land: 'Benin' },
  { nationalitaet: 'bhutanisch', land: 'Bhutan' },
  { nationalitaet: 'bolivianisch', land: 'Plurinationaler Staat Bolivien' },
  { nationalitaet: 'bosnisch-herzegowinisch', land: 'Bosnien und Herzegowina' },
  { nationalitaet: 'botsuanisch', land: 'Botsuana' },
  { nationalitaet: 'brasilianisch', land: 'Brasilien' },
  { nationalitaet: 'bruneiisch', land: 'Brunei Darussalam' },
  { nationalitaet: 'bulgarisch', land: 'Bulgarien' },
  { nationalitaet: 'burkinisch', land: 'Burkina Faso' },
  { nationalitaet: 'burundisch', land: 'Burundi' },
  { nationalitaet: 'chilenisch', land: 'Chile' },
  { nationalitaet: 'chinesisch', land: 'China' },
  { nationalitaet: 'costa-ricanisch', land: 'Costa Rica' },
  { nationalitaet: 'ivorisch', land: 'Côte d’Ivoire' },
  { nationalitaet: 'dänisch', land: 'Dänemark' },
  { nationalitaet: 'deutsch', land: 'Deutschland' },
  { nationalitaet: 'dominicanisch', land: 'Dominica' },
  { nationalitaet: 'dominikanisch', land: 'Dominikanische Republik' },
  { nationalitaet: 'dschibutisch', land: 'Dschibuti' },
  { nationalitaet: 'ecuadorianisch', land: 'Ecuador' },
  { nationalitaet: 'salvadorianisch', land: 'El Salvador' },
  { nationalitaet: 'eritreisch', land: 'Eritrea' },
  { nationalitaet: 'estnisch', land: 'Estland' },
  { nationalitaet: 'fidschianisch', land: 'Fidschi' },
  { nationalitaet: 'finnisch', land: 'Finnland' },
  { nationalitaet: 'französisch', land: 'Frankreich' },
  { nationalitaet: 'gabunisch', land: 'Gabun' },
  { nationalitaet: 'gambisch', land: 'Gambia' },
  { nationalitaet: 'georgisch', land: 'Georgien' },
  { nationalitaet: 'ghanaisch', land: 'Ghana' },
  { nationalitaet: 'grenadisch', land: 'Grenada' },
  { nationalitaet: 'griechisch', land: 'Griechenland' },
  { nationalitaet: 'guatemaltekisch', land: 'Guatemala' },
  { nationalitaet: 'guineisch', land: 'Guinea' },
  { nationalitaet: 'guinea-bissauisch', land: 'Guinea-Bissau' },
  { nationalitaet: 'guyanisch', land: 'Guyana' },
  { nationalitaet: 'haitianisch', land: 'Haiti' },
  { nationalitaet: 'honduranisch', land: 'Honduras' },
  { nationalitaet: 'indisch', land: 'Indien' },
  { nationalitaet: 'indonesisch', land: 'Indonesien' },
  { nationalitaet: 'irakisch', land: 'Irak' },
  { nationalitaet: 'iranisch', land: 'Islamische Republik Iran' },
  { nationalitaet: 'irisch', land: 'Irland' },
  { nationalitaet: 'isländisch', land: 'Island' },
  { nationalitaet: 'israelisch', land: 'Israel' },
  { nationalitaet: 'italienisch', land: 'Italien' },
  { nationalitaet: 'jamaikanisch', land: 'Jamaika' },
  { nationalitaet: 'japanisch', land: 'Japan' },
  { nationalitaet: 'jemenitisch', land: 'Jemen' },
  { nationalitaet: 'jordanisch', land: 'Jordanien' },
  { nationalitaet: 'kambodschanisch', land: 'Kambodscha' },
  { nationalitaet: 'kamerunisch', land: 'Kamerun' },
  { nationalitaet: 'kanadisch', land: 'Kanada' },
  { nationalitaet: 'kap-verdisch', land: 'Kap Verde' },
  { nationalitaet: 'kasachisch', land: 'Kasachstan' },
  { nationalitaet: 'katarisch', land: 'Katar' },
  { nationalitaet: 'kenianisch', land: 'Kenia' },
  { nationalitaet: 'kirgisisch', land: 'Kirgisistan' },
  { nationalitaet: 'kiribatisch', land: 'Kiribati' },
  { nationalitaet: 'kolumbianisch', land: 'Kolumbien' },
  { nationalitaet: 'komorisch', land: 'Komoren' },
  { nationalitaet: 'kongolesisch', land: 'Kongo' },
  { nationalitaet: 'der Demokratischen Republik Kongo', land: 'Kongo, Demokratische Republik"' },
  { nationalitaet: 'der Demokratischen Volksrepublik Korea', land: 'Korea, Demokratische Volksrepublik"' },
  { nationalitaet: 'der Republik Korea', land: 'Republik"' },
  { nationalitaet: 'kosovarisch', land: 'Kosovo' },
  { nationalitaet: 'kroatisch', land: 'Kroatien' },
  { nationalitaet: 'kubanisch', land: 'Kuba' },
  { nationalitaet: 'kuwaitisch', land: 'Kuwait' },
  { nationalitaet: 'laotisch', land: 'Demokratische Volksrepublik Laos' },
  { nationalitaet: 'lesothisch', land: 'Lesotho' },
  { nationalitaet: 'lettisch', land: 'Lettland' },
  { nationalitaet: 'libanesisch', land: 'Libanon' },
  { nationalitaet: 'liberianisch', land: 'Liberia' },
  { nationalitaet: 'libysch', land: 'Libyen' },
  { nationalitaet: 'liechtensteinisch', land: 'Liechtenstein' },
  { nationalitaet: 'litauisch', land: 'Litauen' },
  { nationalitaet: 'luxemburgisch', land: 'Luxemburg' },
  { nationalitaet: 'madagassisch', land: 'Madagaskar' },
  { nationalitaet: 'malawisch', land: 'Malawi' },
  { nationalitaet: 'malaysisch', land: 'Malaysia' },
  { nationalitaet: 'maledivisch', land: 'Malediven' },
  { nationalitaet: 'malisch', land: 'Mali' },
  { nationalitaet: 'maltesisch', land: 'Malta' },
  { nationalitaet: 'marokkanisch', land: 'Marokko' },
  { nationalitaet: 'marshallisch', land: 'Marshallinseln' },
  { nationalitaet: 'mauretanisch', land: 'Mauretanien' },
  { nationalitaet: 'mauritisch', land: 'Mauritius' },
  { nationalitaet: 'mazedonisch', land: 'ehemalige jugoslawische Republik Mazedonien' },
  { nationalitaet: 'mexikanisch', land: 'Mexiko' },
  { nationalitaet: 'mikronesisch', land: 'Föderierte Staaten von Mikronesien' },
  { nationalitaet: 'moldauisch', land: 'Republik Moldau' },
  { nationalitaet: 'monegassisch', land: 'Monaco' },
  { nationalitaet: 'mongolisch', land: 'Mongolei' },
  { nationalitaet: 'montenegrinisch', land: 'Montenegro' },
  { nationalitaet: 'mosambikanisch', land: 'Mosambik' },
  { nationalitaet: 'myanmarisch', land: 'Myanmar' },
  { nationalitaet: 'namibisch', land: 'Namibia' },
  { nationalitaet: 'nauruisch', land: 'Nauru' },
  { nationalitaet: 'nepalesisch', land: 'Nepal' },
  { nationalitaet: 'neuseeländisch', land: 'Neuseeland' },
  { nationalitaet: 'nicaraguanisch', land: 'Nicaragua' },
  { nationalitaet: 'niederländisch', land: 'Niederlande' },
  { nationalitaet: 'nigrisch', land: 'Niger' },
  { nationalitaet: 'nigerianisch', land: 'Nigeria' },
  { nationalitaet: 'norwegisch', land: 'Norwegen' },
  { nationalitaet: 'omanisch', land: 'Oman' },
  { nationalitaet: 'österreichisch', land: 'Österreich' },
  { nationalitaet: 'pakistanisch', land: 'Pakistan' },
  { nationalitaet: 'palauisch', land: 'Palau' },
  { nationalitaet: 'panamaisch', land: 'Panama' },
  { nationalitaet: 'papua-neuguineisch', land: 'Papua-Neuguinea' },
  { nationalitaet: 'paraguayisch', land: 'Paraguay' },
  { nationalitaet: 'peruanisch', land: 'Peru' },
  { nationalitaet: 'philippinisch', land: 'Philippinen' },
  { nationalitaet: 'polnisch', land: 'Polen' },
  { nationalitaet: 'portugiesisch', land: 'Portugal' },
  { nationalitaet: 'ruandisch', land: 'Ruanda' },
  { nationalitaet: 'rumänisch', land: 'Rumänien' },
  { nationalitaet: 'russisch', land: 'Russische Föderation' },
  { nationalitaet: 'salomonisch', land: 'Salomonen' },
  { nationalitaet: 'sambisch', land: 'Sambia' },
  { nationalitaet: 'samoanisch', land: 'Samoa' },
  { nationalitaet: 'san-marinesisch', land: 'San Marino' },
  { nationalitaet: 'são-toméisch', land: 'São Tomé und Príncipe' },
  { nationalitaet: 'saudi-arabisch', land: 'Saudi-Arabien' },
  { nationalitaet: 'schwedisch', land: 'Schweden' },
  { nationalitaet: 'schweizerisch', land: 'Schweiz' },
  { nationalitaet: 'senegalesisch', land: 'Senegal' },
  { nationalitaet: 'serbisch', land: 'Serbien' },
  { nationalitaet: 'seychellisch', land: 'Seychellen' },
  { nationalitaet: 'sierra-leonisch', land: 'Sierra Leone' },
  { nationalitaet: 'simbabwisch', land: 'Simbabwe' },
  { nationalitaet: 'singapurisch', land: 'Singapur' },
  { nationalitaet: 'slowakisch', land: 'Slowakei' },
  { nationalitaet: 'slowenisch', land: 'Slowenien' },
  { nationalitaet: 'somalisch', land: 'Somalia' },
  { nationalitaet: 'spanisch', land: 'Spanien' },
  { nationalitaet: 'sri-lankisch', land: 'Sri Lanka' },
  { nationalitaet: 'von St. Kitts und Nevis', land: 'St. Kitts und Nevis' },
  { nationalitaet: 'lucianisch', land: 'St. Lucia' },
  { nationalitaet: 'vincentisch', land: 'St. Vincent und die Grenadinen' },
  { nationalitaet: 'südafrikanisch', land: 'Südafrika' },
  { nationalitaet: 'sudanesisch', land: 'Sudan' },
  { nationalitaet: 'südsudanesisch', land: 'Südsudan' },
  { nationalitaet: 'surinamisch', land: 'Suriname' },
  { nationalitaet: 'swasiländisch', land: 'Swasiland' },
  { nationalitaet: 'syrisch', land: 'Arabische Republik Syrien' },
  { nationalitaet: 'tadschikisch', land: 'Tadschikistan' },
  { nationalitaet: 'tansanisch', land: 'Vereinigte Republik Tansania' },
  { nationalitaet: 'thailändisch', land: 'Thailand' },
  { nationalitaet: 'von Timor-Leste', land: 'Timor-Leste' },
  { nationalitaet: 'togoisch', land: 'Togo' },
  { nationalitaet: 'tongaisch', land: 'Tonga' },
  { nationalitaet: 'von Trinidad und Tobago', land: 'Trinidad und Tobago' },
  { nationalitaet: 'tschadisch', land: 'Tschad' },
  { nationalitaet: 'tschechisch', land: 'Tschechische Republik' },
  { nationalitaet: 'tunesisch', land: 'Tunesien' },
  { nationalitaet: 'türkisch', land: 'Türkei' },
  { nationalitaet: 'turkmenisch', land: 'Turkmenistan' },
  { nationalitaet: 'tuvaluisch', land: 'Tuvalu' },
  { nationalitaet: 'ugandisch', land: 'Uganda' },
  { nationalitaet: 'ukrainisch', land: 'Ukraine' },
  { nationalitaet: 'ungarisch', land: 'Ungarn' },
  { nationalitaet: 'uruguayisch', land: 'Uruguay' },
  { nationalitaet: 'usbekisch', land: 'Usbekistan' },
  { nationalitaet: 'vanuatuisch', land: 'Vanuatu' },
  { nationalitaet: 'vatikanisch', land: 'Vatikanstadt' },
  { nationalitaet: 'venezolanisch', land: 'Bolivarische Republik Venezuela' },
  { nationalitaet: 'der Vereinigten Arabischen Emirate', land: 'Vereinigte Arabische Emirate' },
  { nationalitaet: 'amerikanisch', land: 'Vereinigten Staaten' },
  { nationalitaet: 'britisch', land: 'Vereinigtes Königreich' },
  { nationalitaet: 'vietnamesisch', land: 'Vietnam' },
  { nationalitaet: 'weißrussisch', land: 'Weißrussland' },
  { nationalitaet: 'zentralafrikanisch', land: 'Zentralafrikanische Republik' },
  { nationalitaet: 'zyprisch', land: 'Zypern' }
];
