import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SPACING_BETWEEN_BOXES } from '../common/spacings';
import { HonorarEintrag, Mandant } from '../../types';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import { Euro } from '../common/Euro';
import { Modal } from '../Modal';
import Box from '@mui/material/Box';

const table = {
  borderSpacing: '0px',
  width: '100%',
  '& td': {
    border: '1px solid black'
  },
  '& th': {
    padding: '2px',
    border: '1px solid black'
  },
  '& thead': {
    textAlign: 'left'
  },
  '& tbody': {
    textAlign: 'center'
  }
};

type Props = {
  readonly onClose: () => void;
  readonly aktuelleMandantId: string;
};

export default function HonorarTabelleModal({ onClose, aktuelleMandantId }: Props): JSX.Element {
  const [honorartabelle, setHonorartabelle] = useState<HonorarEintrag[]>([]);

  useEffect(() => {
    if (aktuelleMandantId !== '') {
      makeGraphqlQuery(queries.holeMandant, {
        mandant: aktuelleMandantId
      }).then((mandant: Mandant) => {
        if (mandant.honorartabelle) {
          setHonorartabelle(mandant.honorartabelle);
        }
      });
    }
  }, [aktuelleMandantId]);

  const middleIndex = Math.ceil(honorartabelle.length / 2);
  const firstHalf = honorartabelle.splice(0, middleIndex);
  const secondHalf = honorartabelle.splice(-middleIndex);

  return (
    <Modal
      openModal={true}
      setOpenModal={onClose}
      ariaLabelledby="honorarTabelleModalUeberschrift"
      title="Honorartabelle"
      body={
        <Grid container spacing={SPACING_BETWEEN_BOXES}>
          <Grid item xs={12}>
            <Typography variant="body2">
              Die nachfolgend ausgewiesenen Werte beruhen auf einer Befragung des Bundesverbandes der freiberuflichen und unabhängigen Sachverständigen für das
              Kraftfahrzeugwesen e.V. -BVSK-, aus dem Jahr 2022, von etwa 916 Sachverständige über deren Höhe der üblicherweise berechneten Honorare bei
              Schadengutachten im PKW-Bereich. Diese Honorarbefragung ist ein wichtiger Anhaltspunkt für die Angemessenheit von Sachverständigenhonorare und
              Grundlage vieler Gerichtsentscheidungen. Das Honorar besteht aus dem Grundhonorar und den Nebenkosten. Das Grundhonorar wird in Abhängigkeit zu
              der Höhe der ermittelten Reparaturkosten bzw. im Totalschadenfall zum Wiederbeschaffungswert brutto berechnet (Urteile des BGH vom 04.04.2006 Az.
              X ZR 80/05 oder X ZR 122/05, Urteil des LG Coburg 32 S 26/10 und 33 S 27/10). Schäden über 30.000,00 Euro werden im gleichen Verhältnis degressiv
              weitergeführt. Sonderleistungen werden gesondert zusätzlich berechnet.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box component="table" sx={table}>
              <thead>
                <tr>
                  <th>Schadenhöhe netto bis</th>
                  <th>Grundhonorar netto</th>
                </tr>
              </thead>
              <tbody>
                {firstHalf.map((entry, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Euro value={(entry?.schadenhoehe ?? 0) / 100} />
                      </td>
                      <td>
                        <Euro value={(entry?.grundhonorar ?? 0) / 100} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box component="table" sx={table}>
              <thead>
                <tr>
                  <th>Schadenhöhe netto bis</th>
                  <th>Grundhonorar netto</th>
                </tr>
              </thead>
              <tbody>
                {secondHalf.map((entry, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Euro value={(entry?.schadenhoehe ?? 0) / 100} />
                      </td>
                      <td>
                        <Euro value={(entry?.grundhonorar ?? 0) / 100} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Box>
          </Grid>
          <Grid item xs={2}>
            Nebenkosten:
          </Grid>
          <Grid item xs={5}>
            1. Fotosatz je Foto 2,00 €
            <br />
            Fahrtkosten pauschal 25,00 €
            <br />
            Schreibkosten + Kopien 26,80 €
          </Grid>
          <Grid item xs={5}>
            2. Fotosatz je Foto 1,00 €
            <br />
            Porto/Telefon pauschal 15,00 €
            <br />
            alle Preise ohne MwSt.
          </Grid>
        </Grid>
      }
    ></Modal>
  );
}
