import React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Formular from './common/Formular';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { useUser } from '../hooks/useUser';
import { isAenderbar } from '../domain/isAenderbar';
import { Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_SCHADENBEMERKUNG_ERFASSEN } from '../frontendConstants';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

const auswahl = [
  'teilweise repariert',
  'sach- und fachgerecht repariert',
  'nicht fachgerecht repariert',
  'gemäß Gutachten repariert',
  'gemäß vorliegender Rechnung repariert'
];

export function ReparaturhinweisAuswahl({ vorgang, isLoading, aktualisiereVorgang }: Props) {
  const { gruppenVonMandant } = useUser();

  const isDisabled =
    isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_SCHADENBEMERKUNG_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  return (
    <Formular ueberschrift="Reparaturstatus">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              disabled={isDisabled}
              options={auswahl}
              value={vorgang?.bemerkungenZumSchaden?.reparaturnachweisAuswahl ?? ''}
              disableClearable={true}
              filterSelectedOptions={false}
              filterOptions={() => auswahl}
              onChange={(event, value) => {
                aktualisiereVorgang({ bemerkungenZumSchaden: { ...vorgang.bemerkungenZumSchaden, reparaturnachweisAuswahl: value } });
              }}
              renderInput={(params) => <TextField variant="standard" {...params} label="Zustand" disabled={isDisabled} fullWidth data-testid="zustand" />}
            />
          </FormControl>
        </Grid>
      </Grid>
    </Formular>
  );
}
