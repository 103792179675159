import React from 'react';
import ReactDOM from 'react-dom';
import { Vorgang } from '../../types';
import { useSnackbar } from 'notistack';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { SPACING_BETWEEN_BOXES, SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';
import { SUCCESS_MESSAGE_AUTO_HIDE } from '../common/Alert';

type Props = {
  vorgang: Vorgang;
  speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
};

export function Aufgabenliste({ vorgang, speichereVorgang }: Props): JSX.Element | null {
  const { enqueueSnackbar } = useSnackbar();
  const aufgaben = vorgang.aufgaben ?? {};
  const el = document.querySelector('#portal-aufgabenliste');

  const aufgabenZuVorgang = (vorgang: Vorgang) => [
    { label: 'Finale Datenerfassung', to: `/vorgang/${vorgang.id}/kunde`, checked: Boolean(aufgaben.finaleDatenerfassung), feld: 'finaleDatenerfassung' },
    { label: 'Bildbearbeitung', to: `/vorgang/${vorgang.id}/fotos`, checked: Boolean(aufgaben.bildbearbeitung), feld: 'bildbearbeitung' },
    {
      label: 'Kalkulationserstellung',
      to: `/vorgang/${vorgang.id}/bewertung`,
      checked: Boolean(aufgaben.kalkulationserstellung),
      feld: 'kalkulationserstellung'
    },
    {
      label: 'Wiederbeschaffungswert und Schadensumfang',
      to: `/vorgang/${vorgang.id}/bewertung`,
      checked: Boolean(aufgaben.wiederbeschaffungswertUndSchadensumfang),
      feld: 'wiederbeschaffungswertUndSchadensumfang'
    },
    {
      label: 'merkantiler Minderwert',
      to: `/vorgang/${vorgang.id}/bewertung`,
      checked: Boolean(aufgaben.merkantilerMinderwert),
      feld: 'merkantilerMinderwert'
    },
    { label: 'Restwert', to: `/vorgang/${vorgang.id}/bewertung`, checked: Boolean(aufgaben.restwert), feld: 'restwert' },
    { label: 'Wertverbesserung', to: `/vorgang/${vorgang.id}/bewertung`, checked: Boolean(aufgaben.wertverbesserung), feld: 'wertverbesserung' },
    { label: 'Textbearbeitung', to: `/vorgang/${vorgang.id}/gutachten`, checked: Boolean(aufgaben.textbearbeitung), feld: 'textbearbeitung' }
  ];

  const createHandleMarkiereAlsErledigt = (feld: string, label: string, erledigt: boolean) => {
    speichereVorgang({
      aufgaben: {
        ...vorgang.aufgaben,
        [feld]: erledigt
      }
    });
    enqueueSnackbar(`Die Aufgabe ${label} wurde als ${erledigt ? '' : 'nicht '}erledigt markiert.`, SUCCESS_MESSAGE_AUTO_HIDE);
  };

  return el
    ? ReactDOM.createPortal(
        <Box p={SPACING_BETWEEN_BOXES}>
          <Paper elevation={5}>
            <Box pt={SPACING_BETWEEN_FORM_FIELDS}>
              <Typography align="center" variant="h6">
                Aufgaben
              </Typography>
            </Box>
            <List dense>
              {aufgabenZuVorgang(vorgang).map(({ feld, label, to, checked }, key) => (
                <ListItem key={key}>
                  <ListItemIcon sx={{ minWidth: '30px' }}>
                    <Checkbox
                      color="primary"
                      data-testid={label}
                      edge="start"
                      checked={checked}
                      onChange={() => {
                        createHandleMarkiereAlsErledigt(feld, label, !checked);
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Box>,
        el
      )
    : null;
}
