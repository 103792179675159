import React, { useEffect } from 'react';
import { Reparaturweg } from '../../components/Reparaturweg';
import { SchadenbedingterFahrzeugzustand } from '../../components/SchadenbedingterFahrzeugzustand';
import { ReparaturhinweisAuswahl } from '../../components/ReparaturhinweisAuswahl';
import { useHistory } from 'react-router';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { Gutachtenart } from '../../shared/constants';

export default function Schadenbemerkungen(): JSX.Element {
  const { vorgang, isLoading, aktualisiereVorgang } = useVorgangContext();
  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname.includes('schadenbemerkungen') && vorgang?.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG) {
      history.push(history.location.pathname.replace('/schadenbemerkungen', ''));
    }
  }, [history, vorgang]);

  return (
    <>
      {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS ? (
        <>
          <SchadenbedingterFahrzeugzustand vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
          <br />
          <Reparaturweg vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
        </>
      ) : (
        <ReparaturhinweisAuswahl vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
      )}
    </>
  );
}
