import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { SPACING_BETWEEN_BOXES } from './spacings';

type Props = {
  readonly ueberschrift: React.ReactNode | string;
  readonly onSubmit?: (event: any) => void;
  readonly children: React.ReactNode;
};

export default function Formular({ ueberschrift, children, onSubmit = (event) => event.preventDefault(), ...props }: Props): JSX.Element {
  return (
    <Paper
      sx={(theme) => ({
        padding: theme.spacing(SPACING_BETWEEN_BOXES)
      })}
      {...props}
    >
      <form onSubmit={onSubmit}>
        <Typography variant="h6" gutterBottom>
          {ueberschrift}
        </Typography>
        <Box pt={2}>{children}</Box>
      </form>
    </Paper>
  );
}
