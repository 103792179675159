import React from 'react';

export function Logo() {
  const color = '#00a651';

  return (
    <svg viewBox="0 0 418.59 94.4" height="100%">
      <rect fill="none" width="418.59" height="94.4" />
      <path
        fill={color}
        d="M130.08,27.44H108.72a.8.8,0,0,0-.87.83l-4,32.39c-.07.55.16.83.68.83h21.35a.82.82,0,0,0,.88-.83l.53-4.23c.07-.55-.16-.83-.68-.83H111.4c-.16,0-.24-.1-.24-.29l1-7.78a.25.25,0,0,1,.29-.29h9.87a.88.88,0,0,0,.93-.83l.49-4.23c.09-.55-.13-.83-.69-.83h-9.87c-.16,0-.24-.1-.24-.29l.92-7.44a.25.25,0,0,1,.1-.22.31.31,0,0,1,.19-.08h15.23a.8.8,0,0,0,.87-.82l.54-4.23C130.82,27.71,130.6,27.44,130.08,27.44Z"
      />
      <path
        fill={color}
        d="M149.53,27.39H136.25a.81.81,0,0,0-.87.83l-4,32.44c-.06.55.16.83.68.83h5.06a.86.86,0,0,0,.92-.83l1.56-13c.07-.19.18-.29.34-.29h4.28c.2,0,.29.08.29.24l4.19,13.24a.87.87,0,0,0,.87.63h5.35a.73.73,0,0,0,.54-.2.59.59,0,0,0,.19-.43.68.68,0,0,0,0-.34L151,47.09a.28.28,0,0,1,0-.22.29.29,0,0,1,.2-.17,10.11,10.11,0,0,0,4.81-3.36,11.31,11.31,0,0,0,2.34-5.69c.06-.71.09-1.23.09-1.55a8.5,8.5,0,0,0-2.4-6.35A9,9,0,0,0,149.53,27.39Zm.83,13.3a5.07,5.07,0,0,1-3.65,1.39h-6.13c-.16,0-.24-.1-.24-.29l1-8.17a.26.26,0,0,1,.29-.3h6.18a4,4,0,0,1,2.9,1,3.69,3.69,0,0,1,1.09,2.8A4.65,4.65,0,0,1,150.36,40.69Z"
      />
      <path
        fill={color}
        d="M186.94,27.44h-23.3a.81.81,0,0,0-.88.83l-.53,4.28a.8.8,0,0,0,.12.6.65.65,0,0,0,.56.22h7.93c.23,0,.31.1.24.29l-3.3,27c-.07.55.17.83.72.83h5a.84.84,0,0,0,.93-.83l3.31-27a.35.35,0,0,1,.34-.29h8.12a.83.83,0,0,0,.92-.82l.54-4.28C187.73,27.71,187.49,27.44,186.94,27.44Z"
      />
      <path
        fill={color}
        d="M208.32,28.19a14.36,14.36,0,0,0-5.91-1.14,14.13,14.13,0,0,0-8.66,2.6,9.88,9.88,0,0,0-4,7c-.06.58-.1,1-.1,1.22A6.94,6.94,0,0,0,191,42.23,10.16,10.16,0,0,0,194.21,45,38.42,38.42,0,0,0,199,47.14c1.69.68,2.93,1.21,3.72,1.58a6.2,6.2,0,0,1,1.9,1.29,2.47,2.47,0,0,1,.71,1.8,3.83,3.83,0,0,1-1.39,3A6,6,0,0,1,199.88,56a6.39,6.39,0,0,1-4.33-1.34,3.5,3.5,0,0,1-1.31-3.28l.09-.68a.46.46,0,0,0-.14-.46.85.85,0,0,0-.59-.17h-5a.86.86,0,0,0-.92.82l-.1.83c-.06.58-.1,1-.1,1.31a7.84,7.84,0,0,0,3,6.47c2,1.59,4.71,2.39,8.15,2.39a16.76,16.76,0,0,0,6.54-1.22,11.37,11.37,0,0,0,4.65-3.45A10.15,10.15,0,0,0,211.94,52a11.54,11.54,0,0,0,.1-1.26,6.59,6.59,0,0,0-1.36-4.26,9.6,9.6,0,0,0-3-2.53q-1.65-.84-4.72-2.06c-1.78-.65-3.12-1.2-4-1.63a6.71,6.71,0,0,1-2-1.41,2.61,2.61,0,0,1-.68-1.78,3.64,3.64,0,0,1,1.39-3,6,6,0,0,1,3.82-1.09,5.83,5.83,0,0,1,4.3,1.43,4,4,0,0,1,1.19,3.48l-.05.48c-.06.33.18.49.73.49h5a.81.81,0,0,0,.88-.83l.1-.48c.06-.59.09-1,.09-1.27a8.51,8.51,0,0,0-1.38-4.84A9.08,9.08,0,0,0,208.32,28.19Z"
      />
      <path
        fill={color}
        d="M67,52q-1.64-3.27-3.29-6.54a.32.32,0,0,1-.05-.19.46.46,0,0,1,.07-.14l1.34-1.95L75.21,28.41a.76.76,0,0,0,.19-.49q0-.48-.63-.48h-5.4A1.22,1.22,0,0,0,68.3,28L61,38.92c0,.09-.09.14-.17.14a.26.26,0,0,1-.22-.14L56,28a.92.92,0,0,0-.93-.58H49.53q-.78,0-.78.63a2.1,2.1,0,0,0,.09.34l7,15.86a.59.59,0,0,1-.05.39L44.9,60.52a.75.75,0,0,0-.19.48c0,.33.21.49.63.49h5.45a1.15,1.15,0,0,0,1.07-.59l7.27-10.73,3.65,7.28,2,3.92.08.12h7l-1-2Z"
      />
      <path
        fill={color}
        d="M93.4,27.39H80.32a.81.81,0,0,0-.88.83l-4,32.44c-.06.55.16.83.68.83h5.06a.88.88,0,0,0,.93-.83l1.46-12.11c.06-.2.17-.29.34-.29h6.66A12.64,12.64,0,0,0,96.32,47a11.12,11.12,0,0,0,4.21-3.63,11.92,11.92,0,0,0,2-5.4q.09-1.15.09-1.65a8.57,8.57,0,0,0-2.5-6.49A9.3,9.3,0,0,0,93.4,27.39Zm1,13.84a5.32,5.32,0,0,1-3.89,1.53H84.6c-.2,0-.28-.1-.25-.29l1.07-8.85c.07-.2.18-.3.34-.3H91.7a4.36,4.36,0,0,1,3.11,1.1A3.83,3.83,0,0,1,96,37.36,5.19,5.19,0,0,1,94.42,41.23Z"
      />
      <path
        fill={color}
        d="M241.27,63H218.79a1.83,1.83,0,0,1-1.93-2.17l.58-4.44a2.83,2.83,0,0,1,.91-1.76c2.17-2,5.26-5,9.21-8.95L231,42.27c4.13-4.12,5.17-6.22,5.31-7.24l0-.47a2.21,2.21,0,0,0-.74-1.73,3.3,3.3,0,0,0-2.27-.71,4,4,0,0,0-2.71.91,3.67,3.67,0,0,0-1.27,2.41l-.1,1a2.11,2.11,0,0,1-2.14,1.92h-5.52a1.86,1.86,0,0,1-1.45-.59,2,2,0,0,1-.43-1.58L220,34a11.78,11.78,0,0,1,4.89-7.72,15.53,15.53,0,0,1,9.25-2.82,14.19,14.19,0,0,1,6.24,1.3,9.82,9.82,0,0,1,4.16,3.7,10.11,10.11,0,0,1,1.43,5.3,12.35,12.35,0,0,1-1.5,5.69,27.36,27.36,0,0,1-4.34,5.88c-1.51,1.7-4.26,4.43-8.16,8.11l-1,.92h11a1.9,1.9,0,0,1,1.5.61,1.92,1.92,0,0,1,.43,1.57l-.58,4.59A2,2,0,0,1,241.27,63Zm-22.09-2.29h21.91l.52-4.08h-13a1.32,1.32,0,0,1-.85-2.38l2.59-2.48c3.85-3.65,6.55-6.34,8-8a25.46,25.46,0,0,0,4-5.39,10.12,10.12,0,0,0,1.24-4.64,7.75,7.75,0,0,0-1.1-4.11,7.31,7.31,0,0,0-3.21-2.83,11.84,11.84,0,0,0-5.23-1.07,13.24,13.24,0,0,0-7.92,2.39,9.38,9.38,0,0,0-4,6.2L222,36.1H227l.1-.92a5.94,5.94,0,0,1,2.06-3.89,6.24,6.24,0,0,1,4.19-1.46,5.5,5.5,0,0,1,3.78,1.29,4.44,4.44,0,0,1,1.52,3.49v.1l-.05.58c-.29,2.11-2.19,4.82-6,8.6h0l-3.42,3.42c-4,4-7.09,7-9.25,9a.64.64,0,0,0-.22.36Z"
      />
      <path
        fill={color}
        d="M267.3,63h-5.47a1.84,1.84,0,0,1-1.45-.59,2,2,0,0,1-.43-1.58l.89-7.31H246.4a1.82,1.82,0,0,1-1.51-.68,2,2,0,0,1-.36-1.5l.47-3.79a6.09,6.09,0,0,1,.41-1.43l11.73-21a2.28,2.28,0,0,1,2.05-1.27H265a1.65,1.65,0,0,1,1.82,1.67,2.37,2.37,0,0,1-.27,1l0,0-10,18.05h5.38l.7-5.53a2,2,0,0,1,2.08-1.9h5.48a1.87,1.87,0,0,1,1.5.63,1.91,1.91,0,0,1,.36,1.61l-.61,5.19h.85a1.81,1.81,0,0,1,1.51.67,1.94,1.94,0,0,1,.36,1.51l-.57,4.78a2,2,0,0,1-2.14,1.9h-1.11l-.91,7.58A2.11,2.11,0,0,1,267.3,63Zm-5-2.29h4.9l1-8.1a1.44,1.44,0,0,1,1.51-1.37h1.67l.52-4.3h-1.4a1.43,1.43,0,0,1-1.16-.57,1.34,1.34,0,0,1-.22-1.13l.67-5.72H264.9l-.77,6.06A1.44,1.44,0,0,1,262.62,47H255a1.38,1.38,0,0,1-1.25-.66,1.37,1.37,0,0,1,.05-1.42l10.38-18.71h-5a.21.21,0,0,0-.05.08l-11.7,20.92a4.69,4.69,0,0,0-.19.74l-.41,3.35h15a1.4,1.4,0,0,1,1.18.56,1.32,1.32,0,0,1,.2,1.12ZM255.83,46h0Z"
      />
      <path
        fill={color}
        d="M40.71,60.66l.54-4.23c.06-.55-.17-.83-.69-.83H25.34c-.16,0-.24-.1-.24-.29l1-7.78a.25.25,0,0,1,.29-.29h9.88a.88.88,0,0,0,.92-.83l.49-4.23c.09-.55-.13-.83-.69-.83H27.09c-.16,0-.24-.1-.24-.29l.92-7.44a.25.25,0,0,1,.1-.22.31.31,0,0,1,.19-.08H43.29a.8.8,0,0,0,.87-.82l.54-4.23c.06-.56-.16-.83-.68-.83H22.66a.81.81,0,0,0-.87.83l-4,32.39c-.07.55.16.83.68.83H39.84A.82.82,0,0,0,40.71,60.66Z"
      />
      <path
        fill="#fbcccc"
        d="M311.25,55.05a1.52,1.52,0,0,1,.25.91,1.06,1.06,0,0,1-.47.79,5.6,5.6,0,0,1-.49.36,9.57,9.57,0,0,1-1.21.66,12.23,12.23,0,0,1-1.85.66,8.83,8.83,0,0,1-2.39.3,10,10,0,0,1-1.71-.16,12,12,0,0,1-2-.53,6.06,6.06,0,0,1-1.46-.74,6.26,6.26,0,0,1-1.15-1l-1.19-1.38a.41.41,0,0,0-.27-.16h-.16a3.72,3.72,0,0,0-.88.49,10.5,10.5,0,0,0-1.05,1.1,23.37,23.37,0,0,0-1.48,2,48.88,48.88,0,0,0-2.54,4.32c-.76,1.48-1.53,3-2.28,4.45s-1.49,3-2.22,4.48-1.53,3-2.37,4.4a1.34,1.34,0,0,1-.52.61,1.21,1.21,0,0,1-.58.16,1,1,0,0,1-.49-.11,1.69,1.69,0,0,1-1-1.1,2.81,2.81,0,0,1-.14-1.37l5.39-19.25a1.46,1.46,0,0,1,1.43-1.21,1.57,1.57,0,0,1,1.18.57,1.51,1.51,0,0,1,.25,1.52c-.48,1.57-1,3.32-1.54,5.22s-1.08,3.76-1.59,5.56q1.75-3.3,3.13-5.81c.92-1.66,1.76-3.08,2.53-4.23a14.82,14.82,0,0,1,2.17-2.67,3.62,3.62,0,0,1,2-1h.22a2,2,0,0,1,1.38.57c.4.39.83.82,1.29,1.3a13.62,13.62,0,0,0,1.62,1.4,5.35,5.35,0,0,0,2.42.91,3,3,0,0,0,.47,0h.47a5.81,5.81,0,0,0,2-.35,12.78,12.78,0,0,0,1.65-.77,8.7,8.7,0,0,0,1.48-1.13,1.12,1.12,0,0,1,.72-.22A1.14,1.14,0,0,1,311.25,55.05Z"
      />
      <path
        fill="#fbcccc"
        d="M340.12,54.39c.26.25.34.49.25.71a2.2,2.2,0,0,1-.46.66l-1.05,1a18,18,0,0,1-2.64,2,26.79,26.79,0,0,1-4.1,2.12,19.07,19.07,0,0,1-5.47,1.35,26.38,26.38,0,0,1-1.37,4.26,19,19,0,0,1-2.37,4.18,13.72,13.72,0,0,1-3.3,3.16,7.34,7.34,0,0,1-4.23,1.27,5.51,5.51,0,0,1-2.73-.63,5.36,5.36,0,0,1-1.81-1.66,7.14,7.14,0,0,1-1-2.33,11.09,11.09,0,0,1-.33-2.7,19.67,19.67,0,0,1,.74-5.17,17.91,17.91,0,0,1,2.18-4.92A12.92,12.92,0,0,1,315.9,54a7.78,7.78,0,0,1,4.64-1.46,1.1,1.1,0,0,1,.39,0l.11,0a5.21,5.21,0,0,1,1.26-.17,3.87,3.87,0,0,1,3.14,1.49,6.13,6.13,0,0,1,1.21,3,16.81,16.81,0,0,1,.11,3.82A19.06,19.06,0,0,0,332,59.25a24,24,0,0,0,3.72-2,19.23,19.23,0,0,0,3-2.67,1.24,1.24,0,0,1,.83-.44A.76.76,0,0,1,340.12,54.39ZM316.2,72.59a5.19,5.19,0,0,0,3.16-1,11,11,0,0,0,2.48-2.61,16.24,16.24,0,0,0,1.76-3.42,18.91,18.91,0,0,0,1-3.38A7,7,0,0,1,320,60.57,5.56,5.56,0,0,1,318.07,57a6.16,6.16,0,0,0-2.15,2.06,14.11,14.11,0,0,0-1.54,3.14,18.57,18.57,0,0,0-.85,3.57,13.08,13.08,0,0,0,0,3.35,5.59,5.59,0,0,0,.85,2.48A2.13,2.13,0,0,0,316.2,72.59Zm8.42-11.82a13.12,13.12,0,0,0,0-3.25,4.67,4.67,0,0,0-.8-2.31,2.22,2.22,0,0,0-.55-.58,1.35,1.35,0,0,0-.87-.3,1.91,1.91,0,0,0-1.71,1,3,3,0,0,0-.44,2.07,3.46,3.46,0,0,0,.36,1.15,3.54,3.54,0,0,0,.85,1.1,4.86,4.86,0,0,0,1.32.83A4.59,4.59,0,0,0,324.62,60.77Z"
      />
      <path
        fill="#fbcccc"
        d="M366.91,54.39a1.3,1.3,0,0,1,.55-.17c.33,0,.59.22.77.66a1.28,1.28,0,0,1,.08.58c0,.2-.19.39-.52.58q-6.21,3.18-11.5,6.19c-3.52,2-6.83,4.06-10,6.18a16.08,16.08,0,0,1,1.07,5.83,16.53,16.53,0,0,1-1.07,6.08,12.61,12.61,0,0,1-3.36,5,9.92,9.92,0,0,1-5.83,2.45,2.91,2.91,0,0,1-.46,0h-.42a5.35,5.35,0,0,1-2.31-.44,4.25,4.25,0,0,1-1.48-1.1,4.18,4.18,0,0,1-.8-1.48,5.72,5.72,0,0,1-.25-1.6,10.94,10.94,0,0,1,3-7.17,48.79,48.79,0,0,1,8.5-7.46,9.64,9.64,0,0,0-1.16-2.75q-.82-1.36-1.62-2.94a16.29,16.29,0,0,1-1.26-3.44,10,10,0,0,1,0-4.23,6,6,0,0,1,.82-2,7.06,7.06,0,0,1,1.46-1.62A6.58,6.58,0,0,1,343,50.43a5.6,5.6,0,0,1,2-.39,4,4,0,0,1,2,.5,3.82,3.82,0,0,1,1.51,1.59,1.73,1.73,0,0,1,.25,1,1.23,1.23,0,0,1-.41.93,1.53,1.53,0,0,1-1.15.44,1.17,1.17,0,0,1-.88-.33,2.15,2.15,0,0,0-1.49-.71,2.17,2.17,0,0,0-.88.27,3.07,3.07,0,0,0-1.43,1.71,5.16,5.16,0,0,0-.16,2.31,12.09,12.09,0,0,0,.65,2.64c.33.91.69,1.79,1.08,2.61s.74,1.57,1.07,2.23a9.37,9.37,0,0,1,.66,1.59q4.4-3,9.51-6.05T366.91,54.39ZM336.33,85.9a8,8,0,0,0,4.81-2.17,10.24,10.24,0,0,0,2.56-4.18,14.72,14.72,0,0,0,.69-5,14,14,0,0,0-.85-4.54,46.07,46.07,0,0,0-4.13,3.36,32.44,32.44,0,0,0-3.33,3.49,16.13,16.13,0,0,0-2.17,3.41,6.5,6.5,0,0,0-.66,3.16,2.81,2.81,0,0,0,.8,1.79,2.4,2.4,0,0,0,1.78.69l.25,0Z"
      />
      <path
        fill="#fbcccc"
        d="M402.33,54.33c.4.22.44.63.11,1.21-.18.26-.64,1-1.38,2.09s-1.65,2.49-2.75,4-2.34,3.2-3.74,4.93a48.73,48.73,0,0,1-4.37,4.75A27.94,27.94,0,0,1,385.58,75a8.64,8.64,0,0,1-4.48,1.43,5.36,5.36,0,0,1-2.92-.74,6,6,0,0,1-1.87-1.93,8.23,8.23,0,0,1-1-2.72,16.18,16.18,0,0,1-.24-3.11,18.48,18.48,0,0,1,.3-3.08,15.13,15.13,0,0,1,.71-2.67c-.4.63-.91,1.4-1.54,2.34s-1.31,1.93-2.06,3-1.55,2.12-2.39,3.19a31.7,31.7,0,0,1-2.56,2.86A15,15,0,0,1,365,75.59a4.65,4.65,0,0,1-2.4.8A2.68,2.68,0,0,1,361,76a3.57,3.57,0,0,1-1-1.1,4.25,4.25,0,0,1-.52-1.49,9.87,9.87,0,0,1-.14-1.59,17.76,17.76,0,0,1,.49-3.85,32.44,32.44,0,0,1,1.41-4.57,39.47,39.47,0,0,1,2.14-4.64,27.46,27.46,0,0,1,2.7-4.1,14.08,14.08,0,0,1,3.1-2.92,6,6,0,0,1,3.36-1.1,4.37,4.37,0,0,1,2.14.52,5.53,5.53,0,0,1,1.63,1.38,7.46,7.46,0,0,1,1.12,1.95,11.72,11.72,0,0,1,.66,2.2.31.31,0,0,0,.09-.11.33.33,0,0,1,.08-.11,1.09,1.09,0,0,1,1-.55,1.05,1.05,0,0,1,.55.11,1.22,1.22,0,0,1,.6.63,1.16,1.16,0,0,1,0,.86,13.85,13.85,0,0,0-.68,2.28c-.24,1-.44,2.13-.61,3.35a30.91,30.91,0,0,0-.27,3.74,15.63,15.63,0,0,0,.3,3.47,6.27,6.27,0,0,0,1.1,2.56,2.62,2.62,0,0,0,2.2,1,5.54,5.54,0,0,0,2.83-1,22.55,22.55,0,0,0,3.3-2.54,41.76,41.76,0,0,0,3.52-3.6c1.19-1.35,2.34-2.73,3.44-4.12s2.14-2.77,3.11-4.13,1.8-2.56,2.5-3.63a1.94,1.94,0,0,1,.38-.46.76.76,0,0,1,.5-.2A1,1,0,0,1,402.33,54.33Zm-29.76-.71a3.82,3.82,0,0,0-2.64,1.45,21.31,21.31,0,0,0-2.66,3.64,37.93,37.93,0,0,0-2.37,4.73,41.26,41.26,0,0,0-1.7,4.81,17,17,0,0,0-.69,3.85c0,1.06.2,1.65.71,1.76a.33.33,0,0,1,.14,0,.22.22,0,0,0,.14,0A4.31,4.31,0,0,0,366,72.59a32.53,32.53,0,0,0,3.27-3.38c1.17-1.38,2.36-2.89,3.55-4.54s2.3-3.26,3.32-4.84a13,13,0,0,0-.16-1.92,7.78,7.78,0,0,0-.58-2,4.86,4.86,0,0,0-1.1-1.59A2.34,2.34,0,0,0,372.57,53.62Z"
      />
    </svg>
  );
}
