import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deprecated_Benutzer } from '../../types';

type Props = {
  openDeleteDialog: boolean;
  setOpenDeleteDialog: (value: boolean) => void;
  userToDelete: deprecated_Benutzer;
  entferneBenutzerAusCognito: (user: deprecated_Benutzer) => void;
};

export default function BenutzerLoeschenDialog({ openDeleteDialog, setOpenDeleteDialog, userToDelete, entferneBenutzerAusCognito }: Props): JSX.Element {
  return (
    userToDelete && (
      <Dialog open={openDeleteDialog} onClose={() => setOpenDeleteDialog(false)} aria-labelledby="gutachten">
        <DialogTitle id="gutachten">Benutzer löschen</DialogTitle>
        <DialogContent>
          <DialogContentText>Soll der Benutzer {userToDelete.id} wirklich gelöscht werden?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenDeleteDialog(false);
            }}
            data-testid="dialog-terminvorbereitung"
          >
            Abbrechen
          </Button>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={() => {
              entferneBenutzerAusCognito(userToDelete);
            }}
            data-testid="dialog-besichtigung"
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    )
  );
}
