import React from 'react';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MoreVertIcon from '@mui/icons-material/MoreVert';

type MenuCellProps = {
  children: React.ReactNodeArray;
};

export function MenuCell({ children }: MenuCellProps): JSX.Element {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return React.Children.count(children) > 0 ? (
    <TableCell sx={{ textAlign: 'right' }} onClick={(event) => event.stopPropagation()}>
      <IconButton onClick={handleClick} size="large">
        <MoreVertIcon />
      </IconButton>

      <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {React.Children.map<React.ReactNode, React.ReactNode>(children, (child) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              //@ts-ignore
              onClick: () => {
                handleClose();
                if (child.props.onClick instanceof Function) {
                  child.props.onClick();
                }
              }
            });
          }
        })}
      </Menu>
    </TableCell>
  ) : (
    <TableCell />
  );
}
