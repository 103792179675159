import { Anrede, PersonenTyp, Status } from './shared/constants';
import { Person, Unternehmen } from './types';

export function getDefaultPersonMitTyp(typ: PersonenTyp): Person {
  return {
    nationalitaet: 'deutsch',
    telefon: [''],
    email: [''],
    typ: [typ],
    weitereBearbeiter: []
  };
}

export const DEFAULT_UNTERNEHMEN: Unternehmen = {
  telefon: [''],
  email: ['']
};

// aktionErlaubt
export const AKTION_DOKUMENT_HOCHLADEN = 'DOKUMENT_HOCHLADEN';
export const AKTION_DOKUMENT_KATEGORISIEREN = 'DOKUMENT_KATEGORISIEREN';
export const AKTION_DOKUMENT_LOESCHEN = 'DOKUMENT_LOESCHEN';
export const AKTION_FAHRZEUGDATEN_ERFASSEN = 'FAHRZEUGDATEN_ERFASSEN';
export const AKTION_RESTWERTANGEBOT_HINZUFUEGEN = 'RESTWERTANGEBOT_HINZUFUEGEN';
export const AKTION_RESTWERTANGEBOT_EINHOLEN = 'RESTWERTANGEBOT_EINHOLEN';
export const AKTION_RESTWERTANGEBOT_ERFASSEN = 'RESTWERTANGEBOT_ERFASSEN';
export const AKTION_STATUS_WECHSELN_VOR = 'STATUS_WECHSELN_VOR';
export const AKTION_STATUS_WECHSELN_ZURUECK = 'STATUS_WECHSELN_ZURUECK';
export const AKTION_GUTACHTENART_AENDERN = 'GUTACHTENART_AENDERN';
export const AKTION_SACHVERSTAENDIGER_AENDERN = 'SACHVERSTAENDIGER_AENDERN';
export const AKTION_SCHADENBEMERKUNG_ERFASSEN = 'SCHADENBEMERKUNG_ERFASSEN';
export const AKTION_BEWERTUNG_ERFASSEN = 'BEWERTUNG_ERFASSEN';
export const AKTION_UNTERSCHRIFT_ERFASSEN = 'UNTERSCHRIFT_ERFASSEN';
export const AKTION_KUNDENDATEN_ERFASSEN = 'KUNDENDATEN_ERFASSEN';
export const AKTION_UNFALLDATEN_ERFASSEN = 'UNFALLDATEN_ERFASSEN';
export const AKTION_FOTO_ERFASSEN = 'FOTO_ERFASSEN';

export const STATUS_TEXTE = {
  [Status.OFFEN]: 'Offen',
  [Status.TERMINFESTLEGUNG]: 'Terminfestlegung',
  [Status.BESICHTIGUNG]: 'Besichtigung',
  [Status.BEWEISSICHERUNG]: 'Beweissicherung',
  [Status.GUTACHTENAUSARBEITUNG]: 'Gutachtenausarbeitung',
  [Status.QUALITAETSSICHERUNG]: 'Qualitätssicherung',
  [Status.DRUCK_VERSAND]: 'Druck/Versand',
  [Status.MAHNWESEN]: 'Mahnwesen',
  [Status.ABGESCHLOSSEN]: 'Abgeschlossen'
};

export const FOTOTYPEN = ['image/jpg', 'image/jpeg', 'image/png'];

export const DOKUMENTTYPEN = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'video/x-flv',
  'video/mp4',
  'application/x-mpegURL',
  'video/MP2T',
  'video/3gpp',
  'video/quicktime',
  'video/x-msvideo',
  'video/x-ms-wmv',
  'video/3gpp',
  'video/ogg',
  'video/webm',
  'video/x-m4v',
  'video/ms-asf'
];

export const anredeAuswahl = [
  { label: 'Herr', value: Anrede.HERR },
  { label: 'Frau', value: Anrede.FRAU },
  { label: 'Firma', value: Anrede.FIRMA }
];
