import React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { useUser } from '../hooks/useUser';
import { isAenderbar } from '../domain/isAenderbar';
import { Achse, Fahrzeugtyp, Vorgang } from '../types';
import { Achse as AchseComponent } from './Achse';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_FAHRZEUGDATEN_ERFASSEN } from '../frontendConstants';
import { Bereifung as BereifungType, Reifenzustand } from '../shared/constants';

const EINZELBEREIFUNG = 'EINZELBEREIFUNG';
const ZWILLINGSBEREIFUNG = 'ZWILLINGSBEREIFUNG';

export const ACHSENTYPEN = [
  {
    label: 'Einzelbereifung',
    value: EINZELBEREIFUNG
  },
  {
    label: 'Zwillingsbereifung',
    value: ZWILLINGSBEREIFUNG
  }
];

const REIFENZUSTAND_TEXTE = {
  [Reifenzustand.VERKEHRSSICHER]: 'verkehrssicher',
  [Reifenzustand.NICHT_VERKEHRSSICHER]: 'nicht verkehrssicher'
};

const BEREIFUNGSART_TEXTE = {
  [BereifungType.WINTERREIFEN]: 'Winterreifen',
  [BereifungType.SOMMERREIFEN]: 'Sommerreifen',
  [BereifungType.GANZJAHRESREIFEN]: 'Ganzjahresreifen'
};

const FAHRZEUGTYP_TEXTE = {
  [Fahrzeugtyp.ZWEIRAEDRIG]: 'Zweirädrig',
  [Fahrzeugtyp.MEHRRAEDRIG]: 'Mehrrädrig'
};

const reifenHersteller = [
  'Avon',
  'Barum',
  'Bf',
  'Bridgestone',
  'Ceat',
  'Compass',
  'Continental',
  'Cooper',
  'Debica',
  'Double',
  'Dunlop',
  'Falken',
  'Fate',
  'Federal',
  'Firestone',
  'Fortuna',
  'Fulda',
  'Fullrun',
  'Fullway',
  'Gajah',
  'General',
  'Gislaved',
  'Goodride',
  'Goodyear',
  'Gremax',
  'Gt',
  'Hankook',
  'Heidenau',
  'Infinity',
  'Jinyu',
  'Kenda',
  'Kings',
  'Kingstar',
  'Kleber',
  'Kumho',
  'Landsail',
  'Linglong',
  'Marbor',
  'Maloya',
  'Marangoni',
  'Marshal',
  'Mastersteel',
  'Matador',
  'Maxxis',
  'Meteor',
  'Metzeler',
  'Michelin',
  'Milestone',
  'Minerva',
  'Nankang',
  'Nexen',
  'Nokian',
  'Pirelli',
  'Riken',
  'Roadhog',
  'Roadstone',
  'Rockstone',
  'Runway',
  'Sailun',
  'Sava',
  'Semperit',
  'Silverstone',
  'Sonar',
  'Sportiva',
  'Stunner',
  'Sumitomo',
  'Sunny',
  'Syron',
  'Toyo',
  'Tracmax',
  'Triangle',
  'Tyfoon',
  'Uniroyal',
  'Viking',
  'Vredestein',
  'Wanli',
  'Westlake',
  'Yokohama'
];

type Props = {
  readonly vorgang: Vorgang;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
  readonly isLoading: boolean;
};

export default function Bereifung({ vorgang, aktualisiereVorgang, isLoading }: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();

  const isDisabled =
    !vorgang || isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_FAHRZEUGDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const bereifungsarten = Object.entries(BEREIFUNGSART_TEXTE).map(([value, label]) => ({ label, value }));
  const fahrzeugtypen = Object.entries(FAHRZEUGTYP_TEXTE).map(([value, label]) => ({ label, value }));
  const reifenzustandAuswahl = Object.entries(REIFENZUSTAND_TEXTE).map(([value, label]) => ({ label, value }));

  const achsen: Achse[] = vorgang?.fahrzeugdaten?.achsen
    ? vorgang.fahrzeugdaten.achsen
    : [
        {
          achsenPosition: '0',
          reifengroesse: null,
          typ: EINZELBEREIFUNG,
          reifen: [
            { position: 'L', profiltiefe: '0' },
            { position: 'R', profiltiefe: '0' }
          ],
          hersteller: null
        },
        {
          achsenPosition: '1',
          reifengroesse: null,
          typ: EINZELBEREIFUNG,
          reifen: [
            { position: 'L', profiltiefe: '0' },
            { position: 'R', profiltiefe: '0' }
          ],
          hersteller: null
        }
      ];

  const achseHinzufuegen = () => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        achsen: [
          ...achsen,
          {
            achsenPosition: `${achsen.length}`,
            reifengroesse: null,
            typ: EINZELBEREIFUNG,
            reifen: [
              { position: 'L', profiltiefe: '0' },
              { position: 'R', profiltiefe: '0' }
            ],
            hersteller: null
          }
        ]
      }
    });
  };

  const achseLoeschen = (achsenPosition: string) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        achsen: achsen
          .filter((achse) => achse.achsenPosition !== achsenPosition)
          .map((achse, index) => {
            const neueAchse = {
              ...achse
            };
            neueAchse.achsenPosition = `${index}`;
            return neueAchse;
          })
      }
    });
  };

  const achsenbereifungAendern = (achsenPosition: string, typ: string) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        achsen: achsen.map((achse) => {
          if (achse.achsenPosition === achsenPosition) {
            achse.typ = typ;
            if (typ === EINZELBEREIFUNG) {
              achse.reifen = [
                { position: 'L', profiltiefe: '0' },
                { position: 'R', profiltiefe: '0' }
              ];
            } else {
              achse.reifen = [
                { position: 'LI', profiltiefe: '0' },
                { position: 'LA', profiltiefe: '0' },
                { position: 'RI', profiltiefe: '0' },
                { position: 'RA', profiltiefe: '0' }
              ];
            }
          }
          return achse;
        })
      }
    });
  };

  const fahrzeugtypAendern = (fahrzeugtyp: Fahrzeugtyp) => {
    const achsen: Achse[] =
      fahrzeugtyp === Fahrzeugtyp.ZWEIRAEDRIG
        ? [
            {
              achsenPosition: '0',
              reifengroesse: null,
              typ: EINZELBEREIFUNG,
              reifen: [{ position: 'V', profiltiefe: '0' }],
              hersteller: null
            },
            {
              achsenPosition: '1',
              reifengroesse: null,
              typ: EINZELBEREIFUNG,
              reifen: [{ position: 'H', profiltiefe: '0' }],
              hersteller: null
            }
          ]
        : [
            {
              achsenPosition: '0',
              reifengroesse: null,
              typ: EINZELBEREIFUNG,
              reifen: [
                { position: 'L', profiltiefe: '0' },
                { position: 'R', profiltiefe: '0' }
              ],
              hersteller: null
            },
            {
              achsenPosition: '1',
              reifengroesse: null,
              typ: EINZELBEREIFUNG,
              reifen: [
                { position: 'L', profiltiefe: '0' },
                { position: 'R', profiltiefe: '0' }
              ],
              hersteller: null
            }
          ];

    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        fahrzeugtyp,
        achsen
      }
    });
  };

  const profiltiefeAendern = (achsenposition: string, position: string, profiltiefe: string) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        achsen: achsen.map((a) => {
          if (a.achsenPosition === achsenposition) {
            a.reifen = (a.reifen || []).map((r) => {
              if (r.position === position) {
                r.profiltiefe = profiltiefe;
              }
              return r;
            });
          }
          return a;
        })
      }
    });
  };

  const reifengroesseAendern = (achsenposition: string, reifengroesse: string) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        achsen: achsen.map((a) => {
          if (!a.reifengroesse || a.reifengroesse === '') {
            a.reifengroesse = reifengroesse;
          }
          if (a.achsenPosition === achsenposition) {
            a.reifengroesse = reifengroesse;
          }
          return a;
        })
      }
    });
  };

  const herstellerAendern = (achsenposition: string, hersteller: string) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        achsen: achsen.map((a) => {
          if (!a.hersteller || a.hersteller === '') {
            a.hersteller = hersteller;
          }
          if (a.achsenPosition === achsenposition) {
            a.hersteller = hersteller;
          }
          return a;
        })
      }
    });
  };

  const select = {
    width: '160px'
  };

  return (
    <>
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12} sm={3}>
          <FormControl variant="standard" sx={select}>
            <InputLabel>Bereifung</InputLabel>
            <Select
              variant="standard"
              label="Bereifung"
              value={vorgang?.fahrzeugdaten?.bereifung ?? BereifungType.SOMMERREIFEN}
              disabled={isDisabled}
              onChange={(event) => {
                aktualisiereVorgang({
                  fahrzeugdaten: {
                    ...vorgang.fahrzeugdaten,
                    bereifung: event.target.value
                  }
                });
              }}
              data-testid="fahrzeugdaten-bereifung"
            >
              {bereifungsarten.map((b) => (
                <MenuItem value={b.value} key={b.value} data-testid={b.label}>
                  {b.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl variant="standard" sx={select}>
            <InputLabel>Fahrzeugtyp</InputLabel>
            <Select
              variant="standard"
              label="Fahrzeugtyp"
              value={vorgang?.fahrzeugdaten?.fahrzeugtyp ?? Fahrzeugtyp.MEHRRAEDRIG}
              disabled={isDisabled}
              onChange={(event) => {
                fahrzeugtypAendern(event.target.value as Fahrzeugtyp);
              }}
              data-testid="fahrzeugdaten-fahrzeugtyp"
            >
              {fahrzeugtypen.map((b) => (
                <MenuItem value={b.value} key={b.value} data-testid={b.label}>
                  {b.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl variant="standard" sx={select}>
            <InputLabel>Reifenzustand</InputLabel>
            <Select
              variant="standard"
              label="Reifenzustand"
              value={vorgang?.fahrzeugdaten?.reifenzustand ?? ''}
              disabled={isDisabled}
              onChange={(event) => {
                aktualisiereVorgang({
                  fahrzeugdaten: {
                    ...vorgang.fahrzeugdaten,
                    reifenzustand: event.target.value
                  }
                });
              }}
              data-testid="fahrzeugdaten-reifenzustand"
            >
              {reifenzustandAuswahl.map((b) => (
                <MenuItem value={b.value} key={b.value} data-testid={b.label}>
                  {b.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid
          item
          xs={12}
          sx={(theme) => ({
            height: theme.spacing(7),
            display: 'flex',
            alignItems: 'flex-end'
          })}
        >
          <Typography
            variant="subtitle1"
            sx={(theme) => ({
              color: theme.palette.text.secondary
            })}
          >
            Profiltiefe (mm)
          </Typography>
        </Grid>
      </Grid>
      {achsen?.map((achse, index) => (
        <AchseComponent
          key={index}
          index={index}
          achse={achse}
          achseLoeschen={achseLoeschen}
          achsenbereifungAendern={achsenbereifungAendern}
          fahrzeugtyp={vorgang?.fahrzeugdaten?.fahrzeugtyp ?? Fahrzeugtyp.MEHRRAEDRIG}
          mehrAlsZweiAchsen={achsen.length > 2}
          profiltiefeAendern={profiltiefeAendern}
          reifengroesseAendern={reifengroesseAendern}
          herstellerAendern={herstellerAendern}
          isDisabled={isDisabled}
          reifenHersteller={reifenHersteller}
        />
      ))}
      {vorgang?.fahrzeugdaten?.fahrzeugtyp !== Fahrzeugtyp.ZWEIRAEDRIG && (
        <Grid
          item
          xs={12}
          sx={(theme) => ({
            padding: theme.spacing(2)
          })}
        >
          <IconButton onClick={achseHinzufuegen} disabled={isDisabled} size="large">
            <AddCircleOutlineOutlinedIcon />
          </IconButton>
        </Grid>
      )}
    </>
  );
}
