/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from 'react';
import { Authenticator } from './Authenticator';
import { UserContext } from '../contexts/userContext';
import { AuthState } from './types';
import { CognitoUser } from '../shared/constants';

type Props = {
  children: JSX.Element;
};

type State = {
  authState: AuthState;
  authData: Partial<CognitoUser> | null;
};

export class WithAuthenticator extends React.Component<Props, State> {
  static contextType = UserContext;

  constructor(props: Props) {
    super(props);

    this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

    this.state = {
      authState: null,
      authData: null
    };
  }

  handleAuthStateChange(state: AuthState, data: any) {
    this.setState({ authState: state, authData: data });
    this.context.setUser(data);
  }

  render() {
    const { authState, authData } = this.state;

    const { children, ...props } = this.props;

    if (authState === 'signedIn') {
      return React.cloneElement(children, {
        ...props,
        authState,
        authData,
        onStateChange: this.handleAuthStateChange
      });
    }

    return <Authenticator {...props} onStateChange={this.handleAuthStateChange} />;
  }
}
