import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import InputAdornment from '@mui/material/InputAdornment';
import SyncIcon from '@mui/icons-material/Sync';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from './common/Alert';
import { FloatFormat } from './common/inputFormats';
import { useUser } from '../hooks/useUser';
import { sindFahrzeugdatenVollstaendig } from '../domain/sindFahrzeugdatenVollstaendig';
import { berechneReparaturdauer } from '../shared/frontend/berechneReparaturdauer';
import { isAenderbar } from '../domain/isAenderbar';
import { useSnackbar } from 'notistack';
import { DatResponse, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_BEWERTUNG_ERFASSEN } from '../frontendConstants';
import TextField from '@mui/material/TextField';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as mutations from '../graphql/mutations';

type Props = {
  readonly vorgang: Vorgang;
  readonly setLoading: (value: boolean) => void;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function Reparaturdauer({ vorgang, setLoading, aktualisiereVorgang }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const { gruppenVonMandant } = useUser();

  const isDisabled = !vorgang || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_BEWERTUNG_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  const handleSyncWithDat = async () => {
    try {
      setLoading(true);

      await makeGraphqlQuery<DatResponse<Vorgang>>(mutations.synchronisiereMitDat, {
        vorgangId: vorgang.id
      });

      enqueueSnackbar('Der Vorgang wurde erfolgreich mit DAT synchronisiert.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      if (error instanceof Error) {
        enqueueSnackbar(error.message, ERROR_MESSAGE);
      } else {
        enqueueSnackbar('Es ist ein Fehler aufgetreten.', ERROR_MESSAGE);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion data-textid="reparaturdauer-accordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6" gutterBottom>
          Reparaturdauer
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box pt={2}>
          <Grid container>
            <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS} alignItems="center" xs={12}>
              <Grid item xs={11}>
                <TextField
                  variant="standard"
                  label="Arbeitswerte"
                  value={vorgang?.dat?.arbeitswerte ?? '0'}
                  disabled
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Tooltip
                          title={
                            vorgang
                              ? sindFahrzeugdatenVollstaendig(vorgang)
                                ? 'Arbeitswerte von DAT laden und Vorgang synchronisieren.'
                                : 'Die Synchronisierung mit DAT kann nicht ausgeführt werden, da die Fahrzeugdaten nicht vollständig sind.'
                              : 'Der Vorgang ist nicht verfügbar.'
                          }
                        >
                          <div>
                            <IconButton
                              color="primary"
                              edge="end"
                              disabled={!(sindFahrzeugdatenVollstaendig(vorgang) ?? false) || isDisabled}
                              onClick={handleSyncWithDat}
                              size="large"
                            >
                              <SyncIcon />
                            </IconButton>
                          </div>
                        </Tooltip>
                      </InputAdornment>
                    )
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" align="center">
                  ÷
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  variant="standard"
                  data-testid="reparaturdauer-arbeitswerteProStunde"
                  label="Arbeitswerte/Std."
                  value={vorgang.reparaturdauer?.arbeitswerteProStunde ?? '10'}
                  onChange={(event) =>
                    aktualisiereVorgang({
                      reparaturdauer: {
                        ...vorgang.reparaturdauer,
                        arbeitswerteProStunde: event.target.value
                      }
                    })
                  }
                  fullWidth
                  disabled={isDisabled}
                  InputProps={{
                    inputComponent: FloatFormat
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" align="center">
                  +
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  variant="standard"
                  data-testid="reparaturdauer-materialbeschaffungsdauer"
                  label="Materialbeschaffung in Tagen"
                  value={vorgang.reparaturdauer?.materialbeschaffungsdauer ?? '1'}
                  onChange={(event) =>
                    aktualisiereVorgang({
                      reparaturdauer: {
                        ...vorgang.reparaturdauer,
                        materialbeschaffungsdauer: event.target.value
                      }
                    })
                  }
                  fullWidth
                  disabled={isDisabled}
                  InputProps={{
                    inputComponent: FloatFormat
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Typography variant="h5" align="center">
                  =
                </Typography>
              </Grid>
              <Grid item xs={11}>
                <TextField
                  variant="standard"
                  data-testid="reparaturdauer-reparaturdauer"
                  label="Reparaturdauer in Tagen"
                  value={berechneReparaturdauer(vorgang) ?? '0'}
                  disabled
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
