import { GraphQLAPI } from './GraphQLAPI';
import { GraphQLResult } from './types';
import { environment } from '../env';

const instance = new GraphQLAPI(environment.aws_appsync_graphqlEndpoint);

// TODO T = any durch T ersetzen
export async function makeGraphqlQuery<T = any>(query: string, variables: object): Promise<T> {
  return instance.graphql<any>(query, variables).then((results: GraphQLResult) => {
    if (results?.errors) {
      throw new Error(results?.errors[0].message);
    }

    const queryResults = Object.values(results.data ?? {})[0];
    return queryResults ?? null;
  });
}
