import React, { useRef, useState } from 'react';
import { SPACING_BETWEEN_BOXES, SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import Grid from '@mui/material/Grid';
import { useUser } from '../../hooks/useUser';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from '../../components/common/Alert';
import { getMessageFromError } from '../../shared/throw';
import { api } from '../../apigateway';
import Paper from '@mui/material/Paper';
import { Zahlungsimport } from '../../types';
import { Euro } from '../../components/common/Euro';
import WarningSign from '../../components/common/WarningSign';
import { ZahlungshistorieTabelle } from './ZahlungshistorieTabelle';
import { zahlungseingang } from '../../shared/url';
import Tabelle from '../../components/Tabelle/Tabelle';
import { toDateTimeString } from '../../shared/dateTime';

export function ZahlungsimportSeite(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { isAdmin, isVerwaltung } = useUser();
  const [importzeit, setImportzeit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [importAntwort, setImportAntwort] = useState<Zahlungsimport | undefined>();
  const [importDatum, setImportDatum] = useState<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const zahlungsimportErlaubt = isVerwaltung || isAdmin;

  if (!zahlungsimportErlaubt) {
    return <Alert severity="error">Zugriff ist nicht erlaubt</Alert>;
  }

  const handleClick = () => {
    setTimeout(() => {
      fileInputRef.current?.click();
    }, 0);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (!event?.target?.files) {
      return;
    }

    setIsLoading(true);
    setImportAntwort(undefined);
    setImportDatum(undefined);

    event?.target?.files[0]
      .text()
      .then((text) => api.request(zahlungseingang.importPostUrl(text)))
      .then((antwort) => {
        setImportzeit(Date.now());
        setImportAntwort(antwort);
      })
      .catch((error) => enqueueSnackbar(`Die CSV-Datei konnten nicht verarbeitet werden: ${getMessageFromError(error.data)}`, ERROR_MESSAGE))
      .finally(() => {
        if (fileInputRef.current) {
          fileInputRef.current.value = '';
        }
        setIsLoading(false);
      });
  };

  return (
    <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
      <Grid item xs={12} md={3}>
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12} md={12}>
            <Paper
              sx={(theme) => ({
                padding: theme.spacing(SPACING_BETWEEN_BOXES)
              })}
            >
              <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                <Grid item xs={12} md={12}>
                  <Typography variant="h6" gutterBottom>
                    Zahlungsimport
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="body2">Unterstützt wird eine CSV-Datei.</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" disabled={isLoading} color="primary" onClick={handleClick}>
                    CSV-Datei auswählen...
                  </Button>
                  <Box
                    sx={{
                      display: 'none'
                    }}
                    component="input"
                    ref={fileInputRef}
                    type="file"
                    accept="text/csv"
                    onChange={handleFileChange}
                    data-testid="fileInput"
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} md={12}>
            <ZahlungshistorieTabelle
              key={importzeit}
              isLoading={isLoading}
              onClickRow={(row) => {
                if (!isLoading) {
                  setImportDatum(row.importDatum);
                  setImportAntwort(row.importErgebnis);
                }
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} md={9}>
        <Tabelle
          columns={[
            { label: 'Rechnungsnummer', id: 'rechnungsnummer' },
            { label: 'Gutachtennummer', id: 'gutachtennummer' },
            { label: 'Betrag', id: 'betrag', render: (zeile) => (zeile.betrag ? <Euro value={zeile.betrag}></Euro> : null) },
            {
              render: (zeile) => (
                <WarningSign text={zeile.fehler ?? zeile.hinweis} show={!!(zeile.fehler ?? zeile.hinweis)} color={zeile.fehler ? 'error' : 'warn'} />
              )
            }
          ]}
          tableData={importAntwort?.zeilen ?? []}
          disabled={isLoading}
          title={importDatum ? `Import ${toDateTimeString(importDatum)}` : 'aktueller Import'}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
}
