import * as queries from '../graphql/queries';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import { TokenContainer } from '../shared/constants';

export async function generiereToken(mandant: string): Promise<TokenContainer> {
  const { valuateExpertToken, calculateExpertToken, fahrzeugauswahlToken, rentalPricesToken } = await makeGraphqlQuery(queries.generiereToken, { mandant });

  return {
    valuateExpertToken,
    calculateExpertToken,
    fahrzeugauswahlToken,
    rentalPricesToken
  };
}
