import React from 'react';
import Grid from '@mui/material/Grid';
import Formular from './common/Formular';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { useUser } from '../hooks/useUser';
import { isAenderbar } from '../domain/isAenderbar';
import { YesNoFormControl } from './YesNoFormControl';
import { BemerkungenZumSchaden, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_SCHADENBEMERKUNG_ERFASSEN } from '../frontendConstants';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function SchadenbedingterFahrzeugzustand({ vorgang, isLoading, aktualisiereVorgang }: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();

  const isDisabled =
    isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_SCHADENBEMERKUNG_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  const aktualisiereVerkehrssicher = (verkehrssicher: boolean) => {
    const aenderungen: Partial<BemerkungenZumSchaden> = {};
    aenderungen.verkehrssicher = verkehrssicher;
    if (verkehrssicher) {
      aenderungen.fahrbereit = null;
      aenderungen.verkehrssicherNachNotreparatur = null;
    }
    aktualisiereBemerkungenZumSchadenMehrfachAenderung(aenderungen);
  };

  const aktualisiereBemerkungenZumSchadenDaten = (key: keyof BemerkungenZumSchaden, wert: any) => {
    aktualisiereVorgang({ bemerkungenZumSchaden: { ...vorgang.bemerkungenZumSchaden, [key]: wert } });
  };

  const aktualisiereBemerkungenZumSchadenMehrfachAenderung = (aenderungen: any) => {
    aktualisiereVorgang({ bemerkungenZumSchaden: { ...vorgang.bemerkungenZumSchaden, ...aenderungen } });
  };

  const aktualisiereFahrbereit = (fahrbereit: boolean) => {
    aktualisiereBemerkungenZumSchadenDaten('fahrbereit', fahrbereit);
  };

  const aktualisiereVerkehrssicherNachNotRep = (verkehrssicherNachNotreparatur: boolean) => {
    aktualisiereBemerkungenZumSchadenDaten('verkehrssicherNachNotreparatur', verkehrssicherNachNotreparatur);
  };

  return (
    <Formular ueberschrift="Schadenbedingter Fahrzeugzustand">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="verkehrssicher"
            value={vorgang?.bemerkungenZumSchaden?.verkehrssicher}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereVerkehrssicher(jaNein);
            }}
            dataTestid="verkehrssicher"
            fullWidth
          />
        </Grid>
        {!vorgang?.bemerkungenZumSchaden?.verkehrssicher && (
          <>
            <Grid item xs={12} sm={6}>
              <YesNoFormControl
                labelText="Fahrbereit"
                value={vorgang?.bemerkungenZumSchaden?.fahrbereit}
                disabled={isDisabled}
                onChange={(jaNein) => {
                  aktualisiereFahrbereit(jaNein);
                }}
                dataTestid="fahrbereit"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <YesNoFormControl
                labelText="verkehrssicher nach Notreparatur"
                value={vorgang?.bemerkungenZumSchaden?.verkehrssicherNachNotreparatur}
                disabled={isDisabled}
                onChange={(jaNein) => {
                  aktualisiereVerkehrssicherNachNotRep(jaNein);
                }}
                dataTestid="verkehrssicherNachNotreparatur"
                fullWidth
              />
            </Grid>
          </>
        )}
      </Grid>
    </Formular>
  );
}
