import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const button = {
  marginBottom: '1rem'
};

type Props = {
  readonly open: boolean;
  readonly onAbort: () => void;
  readonly text: string;
  readonly title: string;
  readonly onChooseYes: () => void;
  readonly confirmText: string;
  readonly abortText: string;
};

export function WarningDialog({ open, onAbort, text, title, onChooseYes, confirmText, abortText }: Props): JSX.Element {
  return (
    <Dialog open={open} onClose={onAbort} aria-labelledby="gutachten">
      <DialogTitle id="gutachten">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={button}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            onAbort();
          }}
          data-testid="dialog-abort"
        >
          {abortText}
        </Button>
        <Button
          sx={button}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            onChooseYes();
          }}
          data-testid="dialog-confirm"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
