import React, { useCallback, useState } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import { useDebouncedCallback } from 'use-debounce';
import { sucheNachPersonen } from '../../domain/sucheNachPersonen';
import { Person, SuchePersonenResponse, SucheUnternehmenResponse, Unternehmen } from '../../types';
import { PersonenTyp, UnternehmenTyp } from '../../shared/constants';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import CircularProgress from '@mui/material/CircularProgress';
import { WARNING_MESSAGE } from './Alert';
import { useSnackbar } from 'notistack';

type Props = {
  readonly isDisabled: boolean;
  readonly label: string;
  readonly onSelect: (personOrUnternehmen: Person | Unternehmen) => void;
  readonly testPraefix: string;
  readonly personenUnternehmenTyp: PersonenTyp | UnternehmenTyp | string;
  readonly mandantId?: string;
};

export default function SearchPersonUnternehmen({ mandantId, isDisabled, label, onSelect, testPraefix, personenUnternehmenTyp = '' }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [filteredListe, setFilteredListe] = useState<(Person | Unternehmen)[]>([]);

  const filteredPersonen = useDebouncedCallback((value) => {
    if (value.trim() !== '') {
      suchePerson(value, mandantId);
    } else {
      setFilteredListe([]);
    }
  }, 250);

  const suchePerson = useCallback(
    async (suchbegriffe: string, mandant?: string) => {
      setLoading(true);

      try {
        let result: SuchePersonenResponse | SucheUnternehmenResponse;

        if (mandant) {
          result = await sucheNachPersonen(suchbegriffe, mandant, personenUnternehmenTyp);
        } else {
          result = await sucheNachUnternehmen(suchbegriffe, personenUnternehmenTyp as UnternehmenTyp);
        }

        if (result.fehler) {
          enqueueSnackbar(result.fehler, WARNING_MESSAGE);
        } else {
          setFilteredListe(result.items);
        }
      } catch (error) {
        enqueueSnackbar(error, WARNING_MESSAGE);
      } finally {
        setLoading(false);
      }
    },
    [enqueueSnackbar, personenUnternehmenTyp]
  );

  return (
    <Autocomplete
      filterOptions={(x) => x}
      id={`${label}-suche`}
      disabled={isDisabled}
      clearOnBlur={true}
      clearOnEscape={true}
      onClose={() => setFilteredListe([])}
      onChange={(_, person) => {
        if (person) {
          onSelect(person);
        }
      }}
      options={filteredListe}
      getOptionLabel={toLabel}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="standard"
          fullWidth
          label={label}
          onChange={(event) => {
            filteredPersonen(event.target.value);
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: <React.Fragment>{loading ? <CircularProgress color="inherit" size={20} /> : <SearchIcon />}</React.Fragment>
          }}
        />
      )}
      data-testid={`${testPraefix}-suche`}
    />
  );
}

function toLabel(person: Person | Unternehmen): string {
  return `${person?.firmenname ?? ''} ${person?.vorname ?? ''} ${person?.nachname ?? ''} ${person?.strasse ?? ''} ${person?.plz ?? ''} ${person?.ort ?? ''}`;
}

async function sucheNachUnternehmen(suchbegriffe: string, unternehmenstyp: UnternehmenTyp): Promise<SucheUnternehmenResponse> {
  return makeGraphqlQuery(queries.sucheUnternehmen, {
    suchbegriff: suchbegriffe,
    typ: unternehmenstyp
  });
}
