/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from 'react';
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from './AuthPiece';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField/TextField';
import { SPACING_BETWEEN_FORM_FIELDS } from '../components/common/spacings';
import Grid from '@mui/material/Grid/Grid';
import Link from '@mui/material/Link/Link';
import { AuthenticatorPage } from '../components/AuthenticatorPage';
import { forgotPassword, forgotPasswordSubmit } from '../shared/Auth';

export interface IForgotPasswordState extends IAuthPieceState {
  delivery: boolean;
}

export class ForgotPassword extends AuthPiece<IAuthPieceProps, IForgotPasswordState> {
  constructor(props: IAuthPieceProps) {
    super(props);

    this.send = this.send.bind(this);
    this.submit = this.submit.bind(this);

    this._validAuthStates = ['forgotPassword'];
    this.state = { delivery: false };
  }

  send() {
    const { authData = { username: '' } } = this.props;
    const username = this.getUsernameFromInput() || authData.username;

    forgotPassword(username)
      .then(() => {
        this.setState({ delivery: true });
      })
      .catch((err) => this.error(err));
  }

  submit() {
    const { authData = { username: '' } } = this.props;
    const { code, password } = this.inputs;
    const username = this.getUsernameFromInput() || authData.username;

    forgotPasswordSubmit(username, code, password)
      .then(() => {
        this.changeState('signIn');
        this.setState({ delivery: false });
      })
      .catch((err) => this.error(err));
  }

  sendView() {
    return (
      <Grid item xs={12}>
        <TextField
          variant="standard"
          fullWidth
          defaultValue={this.state.username}
          autoFocus
          label="Benutzername"
          key="username"
          name="username"
          onChange={this.handleInputChange}
          data-testid="username-input"
        />
      </Grid>
    );
  }

  submitView() {
    return (
      <>
        <Grid item xs={12}>
          <TextField variant="standard" fullWidth label="Code" key="code" name="code" autoComplete="off" onChange={this.handleInputChange} />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            fullWidth
            label="Neues Passwort"
            type="password"
            key="password"
            name="password"
            autoComplete="off"
            onChange={this.handleInputChange}
          />
        </Grid>
      </>
    );
  }

  showComponent() {
    const { hide, authData = { username: '' } } = this.props;
    if (hide?.includes(ForgotPassword)) {
      return null;
    }

    return (
      <AuthenticatorPage title="Zurücksetzen des Passworts">
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          {(this.state.delivery ?? authData.username) ? this.submitView() : this.sendView()}

          <Grid item xs={12}>
            <Grid container columns={2}>
              <Grid item xs={1}>
                {(this.state.delivery ?? authData.username) ? (
                  <Link href="#" onClick={this.send}>
                    Code erneut senden
                  </Link>
                ) : (
                  <Link href="#" onClick={() => this.changeState('signIn')}>
                    Zurück zur Anmeldung
                  </Link>
                )}
              </Grid>
              <Grid item xs={1} display="flex" justifyContent="flex-end">
                {(this.state.delivery ?? authData.username) ? (
                  <Button color="primary" variant="contained" onClick={this.submit}>
                    Abschicken
                  </Button>
                ) : (
                  <Button color="primary" variant="contained" onClick={this.send}>
                    Code senden
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AuthenticatorPage>
    );
  }
}
