import React from 'react';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_BOXES } from '../../components/common/spacings';
import { Bewertungsdaten } from '../../components/Bewertungsdaten';
import { Restwertangebote } from '../../components/Restwertangebote';
import { MerkantilerMinderwert } from '../../components/MerkantilerMinderwert';
import { Reparaturdauer } from '../../components/Reparaturdauer';
import { Schlussbemerkung } from '../../components/Schlussbemerkung';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { Gutachtenart } from '../../shared/constants';

export default function Werte(): JSX.Element {
  const { vorgang, isLoading, setLoading, speichereVorgang, hatAenderungen, aktualisiereVorgang, setzeVorgang } = useVorgangContext();

  return (
    <Grid container spacing={SPACING_BETWEEN_BOXES}>
      {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
        <>
          <Grid item xs={12} md={9}>
            <Bewertungsdaten
              vorgang={vorgang}
              isLoading={isLoading}
              setLoading={setLoading}
              setzeVorgang={setzeVorgang}
              speichereVorgang={speichereVorgang}
              hatAenderungen={hatAenderungen}
              aktualisiereVorgang={aktualisiereVorgang}
            />
          </Grid>
          {vorgang?.gutachtenart !== Gutachtenart.FAHRZEUGBEWERTUNG && (
            <>
              <Grid item xs={12}>
                <Restwertangebote
                  vorgang={vorgang}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  speichereVorgang={speichereVorgang}
                  aktualisiereVorgang={aktualisiereVorgang}
                />
              </Grid>
              <Grid item xs={12}>
                <MerkantilerMinderwert vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
              </Grid>
              <Grid item xs={12} md={6} />
            </>
          )}
        </>
      )}
      {vorgang?.gutachtenart !== Gutachtenart.FAHRZEUGBEWERTUNG && (
        <Grid item xs={12}>
          <Reparaturdauer vorgang={vorgang} setLoading={setLoading} aktualisiereVorgang={aktualisiereVorgang} />
        </Grid>
      )}
      <Grid item xs={12}>
        <Schlussbemerkung vorgang={vorgang} isLoading={isLoading} setzeVorgang={setzeVorgang} />
      </Grid>
    </Grid>
  );
}
