const prefix = 'EXPERTS24_';

function prefixed(key: string): string {
  return prefix + key;
}

function _getItem<T = unknown>(storageInstance: Storage, key: string): T | null {
  try {
    return JSON.parse(storageInstance.getItem(prefixed(key)) ?? '') || {};
  } catch {
    // ignore
  }
  return null;
}

export function getItem<T = unknown>(key: string): T | null {
  return _getItem(localStorage, key);
}

export function getSessionItem<T = unknown>(key: string): T | null {
  return _getItem(sessionStorage, key);
}

function _setItem(storageInstance: Storage, key: string, object: unknown) {
  storageInstance.setItem(prefixed(key), JSON.stringify(object));
}

export function setItem(key: string, object: unknown) {
  _setItem(localStorage, key, object);
}

export function setSessionItem(key: string, object: unknown) {
  _setItem(sessionStorage, key, object);
}

export function removeItem(key: string) {
  localStorage.removeItem(prefixed(key));
}

export function clearSessionItems() {
  sessionStorage.clear();
}
