import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import { useDebouncedCallback } from 'use-debounce';
import { MenuCell } from './MenuCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LoadingIndicator from '../common/LoadingIndicator';

export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

type Map = {
  [key: string]: unknown;
};

type Props<T extends Map> = {
  readonly columns: {
    readonly id?: string;
    readonly label?: string;
    readonly className?: string;
    readonly sortable?: boolean;
    readonly render?: (rowData: T) => React.ReactNode;
  }[];
  readonly actions?:
    | {
        readonly label: string;
        readonly onClick: (rowData: T) => void;
        readonly disabled: boolean;
      }[]
    | null;
  readonly onSuchbegriffChange?: (state: string) => void;
  readonly tableData: T[];
  readonly disabled?: boolean;
  readonly onAdd?: () => void;
  readonly typAuswahl?: JSX.Element;
  readonly title?: string;
  readonly handleSort?: (property: string) => void;
  readonly sortBy?: string;
  readonly order?: 'asc' | 'desc';
  readonly rowsPerPage?: number;
  readonly page?: number;
  readonly total?: number;
  readonly onPageChange?: (page: number) => void;
  readonly onRowsPerPageChange?: (rowPerPage: number) => void;
  readonly onClickRow?: (row: T) => void;
  readonly isLoading?: boolean;
  readonly dataTestId?: string;
};

export default function Tabelle<T extends Map>({
  columns,
  actions,
  tableData,
  onSuchbegriffChange,
  onAdd,
  typAuswahl,
  title,
  handleSort,
  sortBy,
  order,
  rowsPerPage,
  page,
  total,
  onPageChange,
  onRowsPerPageChange,
  onClickRow,
  isLoading,
  dataTestId,
  disabled = false
}: Props<T>): JSX.Element {
  const inputRef = useRef<HTMLInputElement>(null);

  const columnsWithActions = [
    ...columns,
    {
      id: 'ACTIONS',
      label: ''
    }
  ];

  const onSuchbegriffChangeDebounce = useDebouncedCallback((suchbegriff: string) => {
    onSuchbegriffChange && onSuchbegriffChange(suchbegriff);
  }, 250);

  const tableTestId = `tabelle-${dataTestId}`;

  return (
    <Box>
      <Grid container spacing={2}>
        {title && (
          <Grid item>
            <Typography variant="h6">{title}</Typography>
          </Grid>
        )}
        <Grid container item>
          <Grid item paddingBottom={2} sx={{ display: 'flex', flexGrow: '1', alignItems: 'baseline', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {typAuswahl}
            <Box display="flex" justifyContent="flex-end" alignItems="center">
              {onSuchbegriffChange && (
                <TextField
                  inputRef={inputRef}
                  variant="standard"
                  placeholder="Suche"
                  onChange={(event) => onSuchbegriffChangeDebounce(event.target.value)}
                  disabled={disabled}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <ClearIcon
                          fontSize="small"
                          onClick={() => {
                            onSuchbegriffChangeDebounce('');
                            if (inputRef.current) {
                              inputRef.current.value = '';
                            }
                          }}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              )}

              {onAdd && (
                <>
                  {' '}
                  <Button color="primary" variant="contained" onClick={onAdd} startIcon={<PersonAddIcon />} data-testid={'hinzufuegen'}>
                    Hinzufügen
                  </Button>
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <TableContainer component={Paper}>
        <Table data-busy={disabled} data-testid={tableTestId}>
          <TableHead>
            <TableRow>
              {columnsWithActions.map((column) => (
                <TableCell key={column.id ?? JSON.stringify(column)} className={column.className}>
                  {column.id && column.sortable ? (
                    <TableSortLabel
                      active={sortBy === column.id}
                      direction={sortBy === column.id ? order : 'asc'}
                      onClick={() => handleSort && handleSort(column.id!)}
                    >
                      {column.label}
                    </TableSortLabel>
                  ) : (
                    column.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {tableData.map((row: T, rowIndex: number) => (
              <TableRow
                hover
                sx={{ cursor: onClickRow ? 'pointer' : 'inherit' }}
                key={rowIndex}
                onClick={() => onClickRow && onClickRow(row)}
                data-testid={`${tableTestId}-eintrag-${rowIndex}`}
              >
                {columns.map((column, columnIndex) => (
                  <TableCell key={`${rowIndex}${columnIndex}`}>{column.render ? column.render(row) : row[column.id!]}</TableCell>
                ))}
                {actions && (
                  <MenuCell>
                    {actions.map((action, actionIndex) => (
                      <MenuItem key={`${rowIndex}${actionIndex}`} onClick={() => action.onClick(row)} data-testid={`action-${action.label}`}>
                        {action.label}
                      </MenuItem>
                    ))}
                  </MenuCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {Number.isInteger(total) && Number.isInteger(rowsPerPage) && Number.isInteger(page) && onPageChange && onRowsPerPageChange && (
        <TablePagination
          data-testid={`tabellenpaginierung-${title}`}
          data-anzahl-items={total}
          rowsPerPageOptions={ROWS_PER_PAGE_OPTIONS}
          component="div"
          count={total ?? 0}
          rowsPerPage={rowsPerPage ?? 0}
          page={page ?? 0}
          onPageChange={(_, newPage) => onPageChange(newPage)}
          onRowsPerPageChange={(event) => {
            onRowsPerPageChange(parseInt(event.target.value, 10));
          }}
        />
      )}

      <Box display="flex" justifyContent="center" flexGrow="1" paddingTop="0.5rem">
        <LoadingIndicator isLoading={isLoading ?? false} fixed={false} />
      </Box>
    </Box>
  );
}
