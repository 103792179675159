import React, { useState, useRef } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { Datum } from '../../types';
import { toDatum } from '../../shared/dateTime';

const testId = 'data-testid';

export type CustomDateTimePickerProps = {
  [testId]?: string;
  onChange: (date: Date | null) => void;
} & Omit<TextFieldProps, 'onChange'> &
  Omit<DateTimePickerProps<Date | Moment, Date | Moment>, 'renderInput' | 'onChange'>;

export function CustomDateTimePicker(props: CustomDateTimePickerProps): JSX.Element {
  // nur der DateTimePicker wird analog zur 4er Version automatisch geöffnet
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
      <DateTimePicker
        {...props}
        onChange={(date: Moment | Date | null) => {
          props.onChange(date ? moment(date).toDate() : null);
        }}
        ampm={false}
        open={open}
        onClose={() => {
          setOpen(false);
          if (props.onClose) {
            props.onClose();
          }
        }}
        renderInput={(inputProps) => {
          // onClick ist auf mobilen Geräten gesetzt und verhindert das Öffnen des Pickers
          delete inputProps?.inputProps?.onClick;

          return (
            <TextField
              {...inputProps}
              onClick={() => !props.disableOpenPicker && setOpen(true)}
              onBlur={props.onBlur}
              variant="standard"
              fullWidth={props.fullWidth}
              data-testid={props[testId]}
            />
          );
        }}
      />
    </LocalizationProvider>
  );
}

export type CustomDatePickerProps = {
  selectEndOfMonth?: boolean;
  onDateSelect?: (value: Datum) => void;
  [testId]?: string;
} & Omit<TextFieldProps, 'onChange'> &
  Omit<DatePickerProps<Datum | undefined, Datum>, 'renderInput' | 'onChange'>;

export function CustomDatePicker(props: CustomDatePickerProps): JSX.Element {
  const open = useRef(false);

  const [hasError, setHasError] = useState(false);

  function format(date: string | null) {
    return props.selectEndOfMonth ? toDatum(moment(date).endOf('month')) : toDatum(moment(date));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
      <DatePicker
        onOpen={() => {
          open.current = true;
        }}
        {...props}
        // wenn "undefined", dann muss es auf einen Leerstring gesetzt werden, sonst verwendet MUI das aktuelle Datum
        value={props.value ? props.value : ''}
        onChange={(event: Datum) => {
          setHasError(false);
          const datum = format(event);

          if (props.onDateSelect && datum && !open.current) {
            props.onDateSelect(datum);
          } else if (props.onDateSelect && !event) {
            props.onDateSelect(null);
          }
        }}
        onAccept={(event: Datum) => {
          setHasError(false);

          if (props.onDateSelect) {
            props.onDateSelect(format(event));
          }
        }}
        onClose={() => {
          open.current = false;
        }}
        closeOnSelect={true}
        {...maskWorkaround(props)}
        renderInput={(inputProps) => (
          <TextField
            onBlur={(event) => {
              setHasError(!isValidDateString(event.target.value));
            }}
            {...inputProps}
            error={hasError}
            variant="standard"
            fullWidth={props.fullWidth}
            data-testid={props[testId]}
          />
        )}
      />
    </LocalizationProvider>
  );
}

function maskWorkaround(props: CustomDatePickerProps): { [key: string]: string | boolean } {
  // TODO https://github.com/mui/mui-x/issues/4664#issuecomment-1109792746
  const maskWorkaround: { [key: string]: string | boolean } = {};

  if (props.views?.length === 2 && props.views?.includes('year') && props.views?.includes('month')) {
    maskWorkaround['inputFormat'] = 'MM.YYYY';
    maskWorkaround['disableMaskedInput'] = false;
  }
  return maskWorkaround;
}

function isValidDateString(value: string): boolean {
  if (value === '') {
    return true;
  }
  return /^(\d{2}\.)?\d{2}\.\d{4}$/.test(value);
}
