import { Vorgang } from '../types';
import * as mutations from '../graphql/mutations';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';

export async function aktualisiereVorgang(zuAktualisierenderVorgang: Partial<Vorgang>): Promise<Vorgang> {
  const copyVorgang = { ...zuAktualisierenderVorgang };
  delete copyVorgang.dat;

  return makeGraphqlQuery(mutations.aktualisiereVorgang, {
    vorgang: JSON.stringify(copyVorgang)
  });
}
