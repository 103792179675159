import { Vorgang } from '../../types';

export function ermittleKilometerstand(vorgang: Vorgang) {
  if (!vorgang) {
    return undefined;
  }
  if (vorgang.fahrzeugdaten?.kilometerleistung) {
    return Number(vorgang.fahrzeugdaten.kilometerleistung);
  } else if (vorgang.fahrzeugdaten?.kilometerleistungGeschaetzt) {
    return Number(vorgang.fahrzeugdaten?.kilometerleistungGeschaetzt);
  } else if (vorgang.fahrzeugdaten?.kilometerleistungAngegeben) {
    return Number(vorgang.fahrzeugdaten?.kilometerleistungAngegeben);
  }
  return 0;
}
