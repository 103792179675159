import React, { useEffect, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import Tabelle, { ROWS_PER_PAGE_OPTIONS } from '../../components/Tabelle/Tabelle';
import Bestaetigungsdialog from '../../components/dialog/Bestaetigungsdialog';
import { DEFAULT_UNTERNEHMEN } from '../../frontendConstants';
import UnternehmenVerwaltung from '../../components/UnternehmenVerwaltung';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import { SucheUnternehmenResponse, Unternehmen as UnternehmenType } from '../../types';
import UnternehmentypAuswahl from '../../components/common/UnternehmentypAuswahl';
import { PersonenTyp, UnternehmenTyp } from '../../shared/constants';
import { ERROR_MESSAGE, WARNING_MESSAGE } from '../../components/common/Alert';
import { useUser } from '../../hooks/useUser';
import { aktualisierePerson } from '../../domain/aktualisierePerson';

const columns = [
  { label: 'Firmenname', id: 'firmenname', sortable: true },
  {
    label: 'Anschrift',
    id: 'anschrift',
    sortable: false,
    render: (rowData: UnternehmenType) => {
      const ortPlz = [rowData?.plz, rowData?.ort].filter((v) => Boolean(v)).join(' ');
      return [rowData?.strasse, ortPlz].filter((v) => Boolean(v)).join(', ');
    }
  },
  { label: 'Bestand', id: 'userId', sortable: true },
  {
    label: 'E-Mail',
    id: 'email',
    sortable: true,
    render: (rowData: UnternehmenType) => (rowData.email ? rowData.email.join(', ') : '')
  },
  {
    label: 'Telefon',
    id: 'telefon',
    sortable: true,
    render: (rowData: UnternehmenType) => (rowData.telefon ? rowData.telefon.join(', ') : '')
  }
];

export default function Unternehmen(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const { isAdmin } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [sucheUnternehmenResponse, setSucheUnternehmenResponse] = useState<SucheUnternehmenResponse>({ items: [], total: 0 });
  const [unternehmen, setUnternehmen] = useState(DEFAULT_UNTERNEHMEN);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [typSelektion, setTypSelektion] = useState<UnternehmenTyp>(UnternehmenTyp.VERSICHERUNG);
  const [ausgewaehlterMandant, setAusgewaehlterMandant] = useState<string | null>(null);
  const [suchbegriff, setSuchbegriff] = useState<string | undefined>(undefined);

  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(25);
  const [sortBy, setSortBy] = useState('createdAt');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[1]);

  const holeUnternehmen = useCallback(
    (typ: UnternehmenTyp, suchbegriff = '', timeout = 3000): Promise<void> => {
      return new Promise((resolve) => {
        // TODO timeout wegen DynamoDB Streams => OpenSearch
        setTimeout(() => {
          makeGraphqlQuery(queries.sucheUnternehmen, {
            typ,
            suchbegriff,
            sortBy,
            order,
            limit,
            from
          })
            .then((response) => {
              if (response.fehler) {
                enqueueSnackbar(response.fehler, WARNING_MESSAGE);
                resolve();
              } else {
                setSucheUnternehmenResponse(response);
                resolve();
              }
            })
            .catch((error) => {
              console.log(error);
              enqueueSnackbar('Unternehmen konnten nicht geladen werden', ERROR_MESSAGE);
              resolve();
            });
        }, timeout);
      });
    },
    [enqueueSnackbar, from, limit, order, sortBy]
  );

  useEffect(() => {
    holeUnternehmen(typSelektion, suchbegriff, 0);
  }, [enqueueSnackbar, holeUnternehmen, typSelektion, suchbegriff]);

  useEffect(() => setPage(0), [suchbegriff, rowsPerPage]);
  useEffect(() => setFrom(page * rowsPerPage), [page, rowsPerPage]);
  useEffect(() => setLimit(rowsPerPage), [rowsPerPage]);

  const handleSort = (property: string) => {
    if (sortBy === property) {
      setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(property);
      setOrder('desc');
    }
  };

  const handleAktualisiereUnternehmen = useCallback(
    (unternehmen: UnternehmenType & { tableData?: unknown }) => {
      setIsLoading(true);
      if (unternehmen.tableData) {
        delete unternehmen.tableData;
      }

      makeGraphqlQuery(mutations.updateUnternehmen, { input: unternehmen })
        .then(() => setOpen(false))
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Unternehmen konnte nicht aktualisiert werden', ERROR_MESSAGE);
        })
        .then(() => holeUnternehmen(typSelektion, suchbegriff))
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, holeUnternehmen, typSelektion, suchbegriff]
  );

  const handleLegeUnternehmenAn = useCallback(
    (unternehmen: UnternehmenType, vermittlerAnlage: boolean) => {
      setIsLoading(true);

      makeGraphqlQuery(mutations.legeUnternehmenAn, {
        unternehmen: JSON.stringify(unternehmen)
      })
        .then(() => {
          if (vermittlerAnlage) {
            //@ts-ignore
            return aktualisierePerson({
              ...unternehmen,
              mandant: ausgewaehlterMandant,
              weitereBearbeiter: [],
              typ: [PersonenTyp.VERMITTLER]
            });
          }
        })
        .then(() => setOpen(false))
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Unternehmen konnte nicht angelegt werden', ERROR_MESSAGE);
        })
        .then(() => holeUnternehmen(typSelektion, suchbegriff))
        .finally(() => setIsLoading(false));
    },
    [ausgewaehlterMandant, enqueueSnackbar, holeUnternehmen, typSelektion, suchbegriff]
  );

  const handleLoescheUnternehmen = useCallback(
    (id: string) => {
      setIsLoading(true);

      makeGraphqlQuery(mutations.deleteUnternehmen, {
        input: { id }
      })
        .then(() => setOpenDeleteDialog(false))
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Unternehmen konnte nicht gelöscht werden', ERROR_MESSAGE);
        })
        .then(() => holeUnternehmen(typSelektion, suchbegriff))
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, holeUnternehmen, typSelektion, suchbegriff]
  );

  const bearbeiten = (rowData: UnternehmenType) => {
    setUnternehmen(rowData);
    setOpen(true);
  };

  const hinzufuegen = () => {
    setUnternehmen(DEFAULT_UNTERNEHMEN);
    setOpen(true);
  };

  const loeschen = (rowData: UnternehmenType) => {
    setUnternehmen(rowData);
    setOpenDeleteDialog(true);
  };

  const handleChangeTyp = (value: UnternehmenTyp) => {
    setTypSelektion(value);
  };

  const unternehmenAuswahl = (
    <FormControl variant="standard">
      <InputLabel>Filter</InputLabel>
      <UnternehmentypAuswahl unternehmentyp={typSelektion} setUnternehmentyp={handleChangeTyp} />
    </FormControl>
  );

  const actions = [
    {
      label: 'Bearbeiten',
      onClick: (rowData: UnternehmenType) => bearbeiten(rowData),
      disabled: isLoading
    }
  ];

  if (isAdmin) {
    actions.push({
      label: 'Löschen',
      onClick: (rowData: UnternehmenType) => loeschen(rowData),
      disabled: isLoading
    });
  }

  return (
    <>
      <Tabelle
        columns={columns}
        actions={actions}
        onSuchbegriffChange={(suchbegriff) => setSuchbegriff(suchbegriff)}
        tableData={sucheUnternehmenResponse.items}
        onAdd={hinzufuegen}
        disabled={isLoading}
        typAuswahl={unternehmenAuswahl}
        title="Unternehmen"
        handleSort={handleSort}
        sortBy={sortBy}
        order={order}
        total={sucheUnternehmenResponse.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        onRowsPerPageChange={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        dataTestId="unternehmen"
      />
      <UnternehmenVerwaltung
        unternehmenObjekt={unternehmen}
        typ={typSelektion}
        aktualisiereUnternehmen={handleAktualisiereUnternehmen}
        legeUnternehmenAn={handleLegeUnternehmenAn}
        open={open}
        onClose={() => setOpen(false)}
        isDisabled={isLoading}
        setAusgewaehlterMandant={setAusgewaehlterMandant}
        mandantenAuswahl={true}
        testPraefix="stammdaten"
      />
      <LoadingIndicator isLoading={isLoading} />
      <Bestaetigungsdialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={() => unternehmen.id && handleLoescheUnternehmen(unternehmen.id)}
        title="Löschen"
        text={`Wollen Sie das Unternehmen ${unternehmen?.firmenname} wirklich löschen?`}
      />
    </>
  );
}
