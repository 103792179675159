import React, { useCallback, useContext, useReducer, useRef } from 'react';
import { Vorgang } from '../types';
import { aktualisiereVorgang as aktualisiereVorgangDomain } from '../domain/aktualisiereVorgang';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from '../components/common/Alert';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as queries from '../graphql/queries';

type State = {
  isLoading: boolean;
  vorgang: Vorgang;
};

type Action =
  | { type: 'aktualisiere'; vorgang: Partial<Vorgang> }
  | { type: 'setze'; vorgang?: Vorgang }
  | { type: 'ladenStatus'; status: boolean }
  | { type: 'geladen'; vorgang: Vorgang };

type VorgangContextProps = {
  readonly setzeVorgang: (vorgang?: Vorgang) => void;
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly setLoading: (value: boolean) => void;
  readonly hatAenderungen: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => Promise<void>;
  readonly holeVorgang: () => void;
};

const VorgangContext = React.createContext<VorgangContextProps>({
  aktualisiereVorgang(vorgang: Partial<Vorgang>): void {
    // empty by default
  },
  setzeVorgang(vorgang?: Vorgang): void {
    // empty by default
  },
  isLoading: false,
  setLoading(value: boolean): void {
    // empty by default
  },
  hatAenderungen: false,
  vorgang: {} as Vorgang,
  speichereVorgang: async (aenderungen?: Partial<Vorgang>): Promise<void> => {
    // empty by default
  },
  holeVorgang: async () => {
    // empty by default
  }
});

export function useVorgangContext(): VorgangContextProps {
  return useContext(VorgangContext);
}

type Props = {
  children: React.ReactNode;
};

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'ladenStatus':
      return {
        ...state,
        isLoading: action.status
      };
    case 'geladen':
      return {
        ...state,
        isLoading: false,
        vorgang: action.vorgang ? { ...action.vorgang } : ({} as Vorgang)
      };
    case 'aktualisiere':
      return {
        ...state,
        vorgang: { ...state.vorgang, ...action.vorgang }
      };
    case 'setze':
      return {
        ...state,
        vorgang: action.vorgang ? { ...action.vorgang } : ({} as Vorgang)
      };
    default:
      throw new Error('Action unbekannt');
  }
}

export default function VorgangContextProvider({ children }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const id = useRef<string | undefined>(undefined);
  const aenderungen = useRef({});

  const [state, dispatch] = useReducer(reducer, {
    isLoading: false,
    vorgang: {} as Vorgang
  });

  const aktualisiereVorgang = useCallback((vorgang: Partial<Vorgang>) => {
    aenderungen.current = {
      ...aenderungen.current,
      ...vorgang
    };
    dispatch({ type: 'aktualisiere', vorgang });
  }, []);

  const setzeVorgang = useCallback((vorgang?: Vorgang) => {
    aenderungen.current = {};
    id.current = vorgang?.id;
    dispatch({ type: 'setze', vorgang });
  }, []);

  const speichereVorgang = useCallback(
    async (neueAenderungen?: Partial<Vorgang>) => {
      if (id.current) {
        let alleAenderungen = { ...aenderungen.current };
        if (neueAenderungen) {
          alleAenderungen = { ...alleAenderungen, ...neueAenderungen };
        }

        if (Object.keys(alleAenderungen).length > 0) {
          dispatch({ type: 'ladenStatus', status: true });
          await aktualisiereVorgangDomain({
            id: id.current,
            ...alleAenderungen
          })
            .then((vorgang) => {
              aenderungen.current = {};
              dispatch({ type: 'geladen', vorgang });
            })
            .catch((error) => {
              enqueueSnackbar(error.message, ERROR_MESSAGE);
              console.error({ error });
              dispatch({ type: 'ladenStatus', status: false });
            });
        }
      }
    },
    [enqueueSnackbar]
  );

  const setLoading = useCallback((status: boolean) => {
    dispatch({ type: 'ladenStatus', status });
  }, []);

  const holeVorgang = useCallback(() => {
    makeGraphqlQuery(queries.getVorgang, {
      id: state.vorgang.id
    })
      .then((geholterVorgang) => {
        if (geholterVorgang) {
          setzeVorgang(geholterVorgang);
        } else {
          //setVorgangNichtGefunden(true);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, setzeVorgang, state.vorgang.id]);

  return (
    <VorgangContext.Provider
      value={{
        setzeVorgang,
        isLoading: state.isLoading,
        setLoading,
        vorgang: state.vorgang,
        hatAenderungen: Object.keys(aenderungen.current).length > 0,
        aktualisiereVorgang,
        speichereVorgang,
        holeVorgang
      }}
    >
      {children}
    </VorgangContext.Provider>
  );
}
