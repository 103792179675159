import React from 'react';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_FORM_FIELDS } from './spacings';
import { formatDDMMYYYY } from '../../shared/dateTime';
import { Person } from '../../types';
import { anredeAuswahl } from '../../frontendConstants';

type Props = {
  readonly person: Person;
  readonly testPraefix: string;
};

export default function PersonAnzeige({ person, testPraefix }: Props): JSX.Element {
  return (
    <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS} data-testid={`${testPraefix}-anzeige`}>
      <Grid container item xs={12} sm={6}>
        {person?.anrede === 'FIRMA' && (
          <>
            <Grid item xs={6}>
              Firmenname:
            </Grid>
            <Grid item xs={6}>
              {person?.firmenname ?? ''}
            </Grid>
          </>
        )}
        <Grid item xs={12}>
          {anredeAuswahl.find((anrede) => anrede.value === person?.anrede)?.label ?? ''}
        </Grid>
        <Grid item xs={12}>
          {person?.vorname ?? ''} {person?.nachname ?? ''}
        </Grid>
        <Grid item xs={12}>
          {person?.strasse ?? ''} {person?.plz ?? ''} {person?.ort ?? ''}
        </Grid>
        <Grid item xs={12}>
          {formatDDMMYYYY(person?.geburtsdatum)}
        </Grid>
        <Grid item xs={12}>
          {person?.nationalitaet ?? ''}
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          Tel.: {person?.telefon?.join(', ') ?? ''}
        </Grid>
        <Grid item xs={12}>
          E-Mail: {person?.email?.join(', ') ?? ''}
        </Grid>
      </Grid>
    </Grid>
  );
}
