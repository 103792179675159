import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import { useSnackbar } from 'notistack';
import SyncIcon from '@mui/icons-material/Sync';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../common/Alert';
import { ermittleKilometerstand } from '../../shared/frontend/ermittleKilometerstand';
import { sindFahrzeugdatenVollstaendig } from '../../domain/sindFahrzeugdatenVollstaendig';
import { isAenderbar } from '../../domain/isAenderbar';
import { DatResponse, Vorgang } from '../../types';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly setLoading: (value: boolean) => void;
  readonly speichereVorgang: () => Promise<void>;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
};

export function DatSynchronisierungButton({ vorgang, isLoading, setLoading, speichereVorgang, setzeVorgang }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const handleSyncWithDat = async () => {
    try {
      setLoading(true);

      await speichereVorgang();

      const datAntwort = await makeGraphqlQuery<DatResponse<Vorgang>>(mutations.synchronisiereMitDat, {
        vorgangId: vorgang.id
      });

      if (datAntwort.ok) {
        if (datAntwort.data) {
          setzeVorgang(datAntwort.data);
          enqueueSnackbar('Der Vorgang wurde erfolgreich mit DAT synchronisiert.', SUCCESS_MESSAGE_AUTO_HIDE);
        } else {
          enqueueSnackbar('Vorgang in datAntwort nicht gefunden', ERROR_MESSAGE);
        }
      } else {
        enqueueSnackbar(datAntwort.error, ERROR_MESSAGE);
      }
    } catch (error) {
      console.error({ error });
      enqueueSnackbar(String(error), ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const istFahrzeugIdentifiziert = !!vorgang?.dat?.contractId;
  const badgeVisible = sindFahrzeugdatenVollstaendig(vorgang) && istSynchronisierungNotwendig(vorgang);

  return (
    <Tooltip
      title={istFahrzeugIdentifiziert ? '' : 'Die Synchronisierung mit DAT kann nicht ausgeführt werden, da das Fahrzeug noch nicht identifiziert wurde.'}
    >
      <Badge color="primary" className="vorgang-toolbar__status__dat-sync--offen" variant="dot" invisible={!badgeVisible}>
        <Button
          className="vorgang-toolbar__status__dat-sync"
          data-testid="datSynchronisierung"
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<SyncIcon />}
          disabled={isLoading || !istFahrzeugIdentifiziert || !isAenderbar(vorgang)}
          onClick={handleSyncWithDat}
        >
          DAT-Sync
        </Button>
      </Badge>
    </Tooltip>
  );
}

export function istSynchronisierungNotwendig(vorgang: Vorgang) {
  const fahrzeugdaten = vorgang.fahrzeugdaten;
  const fahrgestellnummer = fahrzeugdaten?.fahrgestellnummer;
  const kilometerleistung = ermittleKilometerstand(vorgang);
  const kennzeichen = fahrzeugdaten?.kennzeichen?.toUpperCase();

  if (!vorgang?.dat) {
    return false;
  }

  return (
    fahrgestellnummer !== vorgang.dat.fahrgestellnummer ||
    ((kilometerleistung ?? 0) > 0 && kilometerleistung !== Number(vorgang.dat.kilometerleistung)) ||
    kennzeichen !== vorgang.dat.kennzeichen
  );
}
