import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import AccordionSummary from '@mui/material/AccordionSummary';
import TableContainer from '@mui/material/TableContainer';
import Accordion from '@mui/material/Accordion';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import Formular from './common/Formular';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from './common/Alert';
import { Angebot } from './Angebot';
import { AngebotCard } from './AngebotCard';
import { AngebotHinzufuegenDialog } from './dialog/AngebotHinzufuegenDialog';
import { HaendlerangebotEinholenDialog } from './dialog/HaendlerangebotEinholenDialog';
import { useUser } from '../hooks/useUser';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_RESTWERTANGEBOT_EINHOLEN, AKTION_RESTWERTANGEBOT_ERFASSEN, AKTION_RESTWERTANGEBOT_HINZUFUEGEN } from '../frontendConstants';
import { toDateTimeString } from '../shared/dateTime';
import { Angebot as AngebotType, Bewertung, Datei, Versandinformation, Vorgang } from '../types';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { fuegeAngebotHinzu } from '../domain/dateiUpload';
import { Gutachtenart } from '../shared/constants';
import WarningSign from './common/WarningSign';
import { S3Client } from '../shared/s3Client';

type Props = {
  vorgang: Vorgang;
  isLoading: boolean;
  setLoading: (value: boolean) => void;
  speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
  aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function Restwertangebote({ vorgang, isLoading, setLoading, speichereVorgang, aktualisiereVorgang }: Readonly<Props>): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();

  const [angebotHinzufuegenDialogOpen, setAngebotHinzufuegenDialogOpen] = useState(false);
  const [haendlerangebotEinholenDialogOpen, setHaendlerangebotEinholenDialogOpen] = useState(false);

  let angebote: Array<AngebotType> = [];

  if (vorgang.bewertung?.angebote) {
    angebote = [...vorgang.bewertung.angebote].sort((left, right) => parseInt(right.wert ?? '0', 10) - parseInt(left.wert ?? '0', 10));
  }

  const disabled =
    isLoading ||
    vorgang?.gutachtenart === Gutachtenart.KURZGUTACHTEN ||
    !aktionErlaubt(AKTION_RESTWERTANGEBOT_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const restwertAnfrageNichtMoeglich =
    disabled ||
    !vorgang?.dat ||
    !!vorgang?.entsorgen ||
    !vorgang?.bewertung?.reparaturkosten ||
    !vorgang?.bewertung?.wiederbeschaffungswert ||
    !vorgang?.fahrzeugdaten?.reparierteVorschaeden?.schadenVorhandenAuswahl ||
    !vorgang?.fahrzeugdaten?.altschaden?.schadenVorhandenAuswahl ||
    (vorgang.fotos?.filter((foto) => foto.kategorien?.includes('Angebotsanfrage')).length ?? 0) < 3 ||
    !aktionErlaubt(AKTION_RESTWERTANGEBOT_EINHOLEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const darfAngebotHinzufuegen =
    isLoading || !!vorgang?.entsorgen || !aktionErlaubt(AKTION_RESTWERTANGEBOT_HINZUFUEGEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const handleAngebotHinzufuegen = async (angebot: AngebotType) => {
    try {
      setLoading(true);
      const zuAktualisierendeDaten = await fuegeAngebotHinzu(vorgang, angebot);
      speichereVorgang(zuAktualisierendeDaten);
      enqueueSnackbar('Das Angebot wurde erfolgreich hinzugefügt.', SUCCESS_MESSAGE_AUTO_HIDE);
      setAngebotHinzufuegenDialogOpen(false);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Das Angebot konnte nicht hinzugefügt werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const handleAngebotLoeschen = async (angebot: AngebotType) => {
    try {
      setLoading(true);
      const zuAktualisierendeDaten = await loescheAngebot(vorgang, angebot);
      speichereVorgang(zuAktualisierendeDaten);
      enqueueSnackbar('Das Angebot wurde erfolgreich gelöscht.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Das Angebot konnte nicht gelöscht werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const handleHaendlerangebotEinholenDialog = async (anAlleHaendler: boolean, email: string) => {
    try {
      setLoading(true);

      await makeGraphqlQuery(mutations.versendeAngebotsanfrage, {
        vorgangId: vorgang.id,
        emails: [email],
        anAlleHaendler
      });
      enqueueSnackbar(`Die Anfrage wurde erfolgreich versendet.`, SUCCESS_MESSAGE_AUTO_HIDE);
      setHaendlerangebotEinholenDialogOpen(false);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Anfrage konnte nicht versendet werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Formular ueberschrift="Händler Restwertangebote">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          {angebote.length > 0 ? (
            <>
              <Hidden mdDown>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Firmenname</TableCell>
                        <TableCell>Straße</TableCell>
                        <TableCell>Postleitzahl</TableCell>
                        <TableCell>Ort</TableCell>
                        <TableCell>Telefon</TableCell>
                        <TableCell>Wert</TableCell>
                        <TableCell>Dokumente</TableCell>
                        <TableCell
                          sx={(theme) => ({
                            width: theme.spacing(10)
                          })}
                        />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {angebote.map((angebot: AngebotType, key: number) => (
                        <Angebot vorgang={vorgang} angebot={angebot} key={key} onDelete={handleAngebotLoeschen} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Hidden>
              <Hidden mdUp>
                <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                  {angebote.map((angebot: AngebotType, key: number) => (
                    <AngebotCard key={key} vorgang={vorgang} angebot={angebot} onDelete={handleAngebotLoeschen} />
                  ))}
                </Grid>
              </Hidden>
            </>
          ) : (
            <Alert severity="info">Zu diesem Vorgang gibt es keine Angebote.</Alert>
          )}
        </Grid>
        {vorgang?.bewertung?.versandinformation && vorgang.bewertung.versandinformation.length > 0 && (
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Versandinformationen</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container>
                  {[...vorgang.bewertung.versandinformation]
                    .sort((a, b) => ((a?.versanddatum ?? '') > (b?.versanddatum ?? '') ? 1 : -1))
                    .map((event: Versandinformation | null, index: number) => (
                      <Grid item xs={12} key={index}>
                        Versand am: {toDateTimeString(event?.versanddatum)}
                        <Accordion sx={{ marginBottom: '1rem' }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography>Empfänger</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ul>{(event?.empfaenger ?? [])?.map((mail: string | null, mailIndex: number) => <li key={mailIndex}>{mail}</li>) || ''}</ul>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={restwertAnfrageNichtMoeglich}
            onClick={() => {
              speichereVorgang();
              setHaendlerangebotEinholenDialogOpen(true);
            }}
            data-testid="haendlerangebotEinholenDialogOeffnen"
          >
            Händlerangebote einholen
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <Button
            fullWidth
            variant="contained"
            color="primary"
            disabled={darfAngebotHinzufuegen}
            onClick={() => setAngebotHinzufuegenDialogOpen(true)}
            data-testid="angebotHinzufuegenDialogOeffnen"
          >
            Angebot hinzufügen
          </Button>
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={vorgang?.entsorgen ?? false}
                disabled={disabled || angebote?.length > 0}
                onChange={(event) => {
                  aktualisiereVorgang({ entsorgen: event.target.checked });
                }}
                name="checkedB"
                data-testid="entsorgen"
              />
            }
            label="Entsorgen"
          />
        </Grid>
        <WarningSign
          text="Bitte wählen Sie zunächst die Bilder aus, die bei der Angebotsanfrage versendet werden sollen."
          show={(vorgang.fotos?.filter((foto) => foto.kategorien?.includes('Angebotsanfrage')).length ?? 0) < 3}
        ></WarningSign>
      </Grid>
      {angebotHinzufuegenDialogOpen && (
        <AngebotHinzufuegenDialog
          open={angebotHinzufuegenDialogOpen}
          onClose={() => setAngebotHinzufuegenDialogOpen(false)}
          onAdd={handleAngebotHinzufuegen}
          isLoading={isLoading}
        />
      )}
      {haendlerangebotEinholenDialogOpen && (
        <HaendlerangebotEinholenDialog
          open={haendlerangebotEinholenDialogOpen}
          onClose={() => setHaendlerangebotEinholenDialogOpen(false)}
          onSend={handleHaendlerangebotEinholenDialog}
          isLoading={isLoading}
        />
      )}
    </Formular>
  );
}

export async function loescheAngebot(vorgang: Vorgang, angebot: AngebotType): Promise<{ dateien: Datei[]; bewertung: Bewertung }> {
  if (angebot.key) {
    await S3Client.remove(angebot.key);
  }

  const neueSortierteAngebote = (vorgang.bewertung?.angebote ?? [])
    .filter((a) => JSON.stringify(a) !== JSON.stringify(angebot))
    .sort((left, right) => parseInt(right.wert ?? '0', 10) - parseInt(left.wert ?? '0', 10));

  return {
    dateien: (vorgang.dateien ?? []).filter((d) => d.key !== angebot.key),
    bewertung: {
      ...vorgang.bewertung,
      angebote: neueSortierteAngebote,
      restwert: neueSortierteAngebote[0] ? neueSortierteAngebote[0].wert : '0'
    }
  };
}
