import './Dokumente.css';
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import { DateiErfolgreichHochgeladenAlert, ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import { useUser } from '../../hooks/useUser';
import { AKTION_DOKUMENT_HOCHLADEN, AKTION_DOKUMENT_KATEGORISIEREN, AKTION_DOKUMENT_LOESCHEN, DOKUMENTTYPEN } from '../../frontendConstants';
import { aktionErlaubt } from '../../domain/aktionErlaubt';
import { Datei, Foto, Vorgang } from '../../types';
import { ladeDateienHoch } from '../../domain/dateiUpload';
import { useSnackbar } from 'notistack';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { Dateityp } from '../../shared/constants';
import { S3Client } from '../../shared/s3Client';
import { FileInput } from '../../components/FileInput/FileInput';
import { Dokumententabelle } from '../../components/Dokumententabelle';

export default function Dokumente(): JSX.Element {
  const { vorgang, setLoading, isLoading, aktualisiereVorgang, speichereVorgang } = useVorgangContext();

  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();

  const darfHochladen = aktionErlaubt(AKTION_DOKUMENT_HOCHLADEN, gruppenVonMandant(vorgang.mandant), vorgang.status) && !isLoading;
  const darfKategorisieren = aktionErlaubt(AKTION_DOKUMENT_KATEGORISIEREN, gruppenVonMandant(vorgang.mandant), vorgang.status) && !isLoading;
  const darfLoeschen = aktionErlaubt(AKTION_DOKUMENT_LOESCHEN, gruppenVonMandant(vorgang.mandant), vorgang.status) && !isLoading;

  let dokumente: Array<Datei> = [];

  if (vorgang.dateien) {
    dokumente = vorgang.dateien
      .filter((datei) => datei?.typ === Dateityp.DOKUMENT)
      ?.sort((left: Datei, right: Datei) => (right?.dateiname !== left?.dateiname ? -1 : 1));
  }

  const handleDokumentLoeschen = async (dokument: Datei) => {
    try {
      setLoading(true);
      const zuAktualisierendeDateien = await loescheDateien(vorgang, [dokument]);
      speichereVorgang({
        dateien: zuAktualisierendeDateien
      });
      enqueueSnackbar('Das Dokument wurde erfolgreich gelöscht.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Datei konnte nicht gelöscht werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  const handleKategorieHinzufuegen = (dokument: Datei, kategorie: string, wert?: number | null) => {
    const zuAktualisierendeDateien = handleKategorienZuDatei(vorgang, dokument, [kategorie], wert);
    if (
      kategorie === 'Rechnung' &&
      (vorgang.rechnungsnummern ? !vorgang.rechnungsnummern?.includes((dokument?.dateiname ?? '').replace(/\.[0-9a-zA-Z]+$/i, '')) : true)
    ) {
      let rechnungsnummern = [];
      if (vorgang.rechnungsnummern) {
        vorgang.rechnungsnummern.push((dokument?.dateiname ?? '').replace(/\.[0-9a-zA-Z]+$/i, ''));
        rechnungsnummern = vorgang.rechnungsnummern;
      } else {
        rechnungsnummern.push((dokument?.dateiname ?? '').replace(/\.[0-9a-zA-Z]+$/i, ''));
      }
      aktualisiereVorgang({
        rechnungsnummern,
        dateien: zuAktualisierendeDateien
      });
    } else {
      aktualisiereVorgang({
        dateien: zuAktualisierendeDateien
      });
    }
  };

  const handleKategorieLoeschen = (foto: Foto, kategorie: string) => {
    const zuAktualisierendeDateien = handleKategorienZuDatei(vorgang, foto, [kategorie]);
    aktualisiereVorgang({
      dateien: zuAktualisierendeDateien
    });
  };

  const handleFileUpload = async (files: File[]) => {
    try {
      setLoading(true);
      const zuAktualisierendeDateien = await ladeDateienHoch(vorgang, files);
      speichereVorgang({
        dateien: zuAktualisierendeDateien
      });
      enqueueSnackbar(<DateiErfolgreichHochgeladenAlert vorgang={vorgang} multiple={files.length > 1} typ={Dateityp.DOKUMENT} />, SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Das Dokument konnte nicht hochgeladen werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <>
      <div className="dokumente__aktionen">
        <FileInput
          onChange={(acceptedFiles) => handleFileUpload(acceptedFiles)}
          accept={DOKUMENTTYPEN.join(',')}
          dataTestid="dokumentFileInput"
          disabled={!darfHochladen}
        >
          Dokumente hochladen
        </FileInput>
      </div>
      {dokumente.length > 0 ? (
        <Dokumententabelle
          dokumente={dokumente}
          spalten={['dateiname', 'uploaddatum', 'kategorien']}
          handleDokumentLoeschen={handleDokumentLoeschen}
          handleKategorieHinzufuegen={handleKategorieHinzufuegen}
          handleKategorieLoeschen={handleKategorieLoeschen}
          darfKategorisieren={darfKategorisieren}
          darfLoeschen={darfLoeschen}
          dataTestId="dokumente"
        />
      ) : (
        <Alert severity="info">Zu diesem Vorgang gibt es keine Dokumente.</Alert>
      )}
    </>
  );
}

export async function loescheDateien(vorgang: Vorgang, dateien: Datei[]): Promise<Datei[]> {
  const zuAktualisierendeDateien = (vorgang.dateien ?? []).filter((d) => !dateien.some((td) => td.key === d.key));
  await Promise.all(
    dateien.map(async (datei) => {
      if (datei.key) {
        await S3Client.remove(datei.key);
      }
    })
  );
  return zuAktualisierendeDateien;
}

export function handleKategorienZuDatei(vorgang: Vorgang, datei: Datei, kategorien: string[], wert?: number | null): Datei[] {
  return (vorgang.dateien ?? []).map((d) => {
    if (d.key === datei.key) {
      kategorien.forEach((kategorie) => {
        if ((d.kategorien ?? []).includes(kategorie)) {
          d.kategorien = (d.kategorien ?? []).filter((kat) => kat !== kategorie);
        } else {
          (d.kategorien ?? []).push(kategorie);
        }
      });
      d.wert = wert;
      return { ...d };
    }
    return d;
  });
}
