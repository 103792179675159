import React, { useEffect, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SPACING_BETWEEN_FORM_FIELDS } from './spacings';
import { toDateString } from '../../shared/dateTime';
import { useUser } from '../../hooks/useUser';
import { COGNITO_GRUPPE_ADMIN, Status, Unterzeichner } from '../../shared/constants';
import { isAenderbar } from '../../domain/isAenderbar';
import { Vorgang } from '../../types';
import { aktionErlaubt } from '../../domain/aktionErlaubt';
import { AKTION_UNTERSCHRIFT_ERFASSEN } from '../../frontendConstants';
import Box from '@mui/material/Box';
import Bestaetigungsdialog from '../dialog/Bestaetigungsdialog';

const DSGVO_UNTERZEICHNER_TEXTE = {
  [Unterzeichner.AUFTRAGGEBER]: 'Auftraggeber',
  [Unterzeichner.VERMITTLER]: 'Vermittler',
  [Unterzeichner.FAHRZEUGHALTER]: 'Fahrzeughalter'
};

const dsgvoUnterzeichner = Object.entries(DSGVO_UNTERZEICHNER_TEXTE).map(([value, label]) => ({ label, value }));

type Props = {
  readonly onSign: (signature: string, date: string, unterzeichnerAuswahl: Unterzeichner) => void;
  readonly onDeleteSignature: () => void;
  readonly signatureUrl: string | null;
  readonly date?: string;
  readonly isLoading: boolean;
  readonly vorgang: Vorgang;
  readonly mitUnterzeichner?: boolean;
  readonly unterzeichnerAuswahl: Unterzeichner;
  readonly setUnterzeichnerAuswahl?: (unterzeichner: Unterzeichner) => void;
  readonly updateUnterzeichner?: (unterzeichner: Unterzeichner) => void;
  readonly readOnly?: boolean;
  readonly mandatoryFieldsChecked?: boolean;
};

export function Signature({
  onSign,
  onDeleteSignature,
  signatureUrl,
  date: defaultDate,
  isLoading,
  vorgang,
  mitUnterzeichner = true,
  unterzeichnerAuswahl,
  setUnterzeichnerAuswahl,
  updateUnterzeichner,
  readOnly = false,
  mandatoryFieldsChecked = true
}: Props): JSX.Element {
  const signatureRef = useRef<SignatureCanvas>(null);
  const [signature, setSignature] = useState<string | undefined | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const { gruppenVonMandant, hatGruppeVonMandant } = useUser();
  const isAdmin = hatGruppeVonMandant(vorgang.mandant || '', COGNITO_GRUPPE_ADMIN);
  const isDisabled =
    isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_UNTERSCHRIFT_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);
  const [date, setDate] = useState(new Date().toISOString());

  const handleSignatureEnd = () => {
    setSignature(signatureRef?.current?.toDataURL('image/png'));
  };

  const handleSetzeSignaturZurueck = () => {
    signatureRef?.current?.clear();
    setSignature(null);
  };

  const handleBestaetigeSignatur = () => {
    if (signature) {
      onSign(signature, date, unterzeichnerAuswahl);
    }
  };

  useEffect(() => {
    if (defaultDate) {
      setDate(defaultDate);
    }
  }, [defaultDate]);

  const isUnterschriftLoeschbar = vorgang?.status === Status.OFFEN || vorgang?.status === Status.TERMINFESTLEGUNG || vorgang?.status === Status.BESICHTIGUNG;

  return (
    <>
      <Bestaetigungsdialog
        title="Unterschrift löschen"
        text="Wollen Sie die Unterschrift wirklich löschen?"
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={() => {
          onDeleteSignature();
          setSignature(null);
          setOpenDeleteDialog(false);
        }}
      />

      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          <Box
            sx={(theme) => ({
              maxWidth: '600px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              '& canvas': {
                borderStyle: 'dashed',
                borderColor: theme.palette.grey[500],
                borderWidth: '3px',
                borderRadius: theme.shape.borderRadius
              }
            })}
          >
            {signatureUrl ? (
              <Box component="img" sx={{ width: '100%' }} src={signatureUrl} alt="Signatur" />
            ) : isDisabled || readOnly ? (
              'Unterschrift nicht vorhanden'
            ) : (
              <SignatureCanvas
                ref={signatureRef}
                penColor="black"
                canvasProps={{ style: { width: '100%', aspectRatio: '3 / 1' } }}
                onEnd={handleSignatureEnd}
              />
            )}
          </Box>
        </Grid>
        {mitUnterzeichner && (
          <Grid item xs={12}>
            <Select
              variant="standard"
              label="Unterzeichner"
              data-testid="unterzeichner"
              value={unterzeichnerAuswahl}
              disabled={(Boolean(signatureUrl) && !isAdmin) || isDisabled || readOnly}
              onChange={(event) => {
                if (Boolean(signatureUrl) && isAdmin) {
                  updateUnterzeichner && updateUnterzeichner(event.target.value as Unterzeichner);
                } else {
                  setUnterzeichnerAuswahl && setUnterzeichnerAuswahl(event.target.value as Unterzeichner);
                }
              }}
            >
              {dsgvoUnterzeichner.map((u) => (
                <MenuItem key={u.value} value={u.value} data-testid={u.label}>
                  {u.label}
                </MenuItem>
              ))}
            </Select>
            <br />
            Unterschrieben am {toDateString(date)}
          </Grid>
        )}
        {!readOnly && (
          <>
            {signatureUrl ? (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenDeleteDialog(true)}
                  disabled={(isDisabled || !isUnterschriftLoeschbar) && !isAdmin}
                >
                  Entfernen
                </Button>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleBestaetigeSignatur}
                    disabled={!signature || isDisabled || !mandatoryFieldsChecked}
                    data-testid="confirm"
                  >
                    Bestätigen
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant="contained" color="secondary" onClick={handleSetzeSignaturZurueck} disabled={!signature || isDisabled} data-testid="reset">
                    Zurücksetzen
                  </Button>
                </Grid>
              </>
            )}
          </>
        )}
      </Grid>
    </>
  );
}
