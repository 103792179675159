import React from 'react';
import Button from '@mui/material/Button';
import DialogContentText from '@mui/material/DialogContentText';
import { Modal } from '../Modal';
import Grid from '@mui/material/Grid';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onChooseFotoRedirect: () => void;
};

export function FotoAbfrageDialog({ open, onClose, onChooseFotoRedirect }: Props): JSX.Element {
  return (
    <Modal
      openModal={open}
      setOpenModal={onClose}
      ariaLabelledby="gutachten"
      title="Texterkennung"
      body={<DialogContentText>Wollen Sie jetzt die Texterkennung benutzen?</DialogContentText>}
      footer={
        <>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" color="primary" onClick={onClose}>
              Nein
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button fullWidth variant="contained" color="primary" onClick={onChooseFotoRedirect}>
              Ja
            </Button>
          </Grid>
        </>
      }
      sx={{ maxWidth: '400px' }}
    />
  );
}
