import { Vorgang } from '../types';
import { ermittleKilometerstand } from '../shared/frontend/ermittleKilometerstand';

export function sindFahrzeugdatenVollstaendig(vorgang: Vorgang): boolean {
  if (vorgang.fahrzeugdaten) {
    const kannMitDatSynchronisiertWerden =
      vorgang.fahrzeugdaten &&
      Boolean(vorgang.fahrzeugdaten.fahrgestellnummer) &&
      Boolean(vorgang.fahrzeugdaten?.erstesZulassungsdatum) &&
      Boolean(ermittleKilometerstand(vorgang)) &&
      Boolean(vorgang.fahrzeugdaten.kennzeichen);

    return kannMitDatSynchronisiertWerden && istFahrzeugnummerValide(vorgang?.fahrzeugdaten?.fahrgestellnummer);
  }

  return false;
}

export function istFahrzeugnummerValide(fin: string | null | undefined): boolean {
  if (fin) {
    const finLength = fin.replace(/[^A-Z0-9]/g, '').length;
    return finLength >= 11 && finLength <= 17;
  }
  return false;
}
