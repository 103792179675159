import React, { Suspense, useEffect, useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MobileStepper from '@mui/material/MobileStepper';
import { SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';
import 'tui-image-editor/dist/tui-image-editor.css';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import './BildbearbeitenModal.css';
import { customTheme } from './whiteTheme';
import 'tui-color-picker/dist/tui-color-picker.css';
import { Foto } from '../../types';
import { ImageEditorInst } from '../../tui.image-editor';
import { S3Client } from '../../shared/s3Client';
import { Modal } from '../Modal';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly fotos: Foto[];
  readonly onSave: (file: File) => void;
  readonly fotoIndex: number;
  readonly isEditorSaving: boolean;
};

export default function BildbearbeitenModal({ open, onClose, fotos, onSave, fotoIndex, isEditorSaving }: Props): JSX.Element {
  const [activeStep, setActiveStep] = useState(fotoIndex || 0);
  const [activeFoto, setActiveFoto] = useState(fotos.length > 0 ? fotos[fotoIndex] : {});
  const [editorRef, setEditorRef] = useState<React.MutableRefObject<ImageEditorInst | null> | null>(null);

  useEffect(() => {
    setActiveFoto(fotos[activeStep]);
  }, [fotos, activeStep]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function dataURLtoFile(dataurl: string | undefined, filename: string) {
    const arr = dataurl?.split(',') ?? [];

    const match = arr[0].match(/:(.*?);/);
    const mime = match ? match[1] : undefined;
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  return (
    <Modal
      sx={{ width: '98%' }}
      openModal={open}
      setOpenModal={onClose}
      ariaLabelledby="texterkennungModalUeberschrift"
      body={
        <Grid item container xs={12} spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <FotoEditor foto={activeFoto} setEditorRef={setEditorRef} />

          <Grid item xs={12}>
            <MobileStepper
              variant="dots"
              steps={fotos.length || 0}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button size="small" onClick={handleNext} disabled={activeStep === fotos.length - 1}>
                  Weiter
                  <KeyboardArrowRight />
                </Button>
              }
              backButton={
                <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                  <KeyboardArrowLeft />
                  Zurück
                </Button>
              }
            />
          </Grid>
        </Grid>
      }
      footer={
        <>
          <Grid item xs={5} sm={3} lg={2}>
            <Button fullWidth autoFocus onClick={onClose} variant="contained" color="secondary">
              Schließen
            </Button>
          </Grid>
          <Grid item xs={5} sm={3} lg={2}>
            <Button
              fullWidth
              onClick={() => {
                onSave(dataURLtoFile(editorRef?.current?.imageEditorInst.toDataURL(), 'bearbeitet_' + activeFoto.dateiname));
              }}
              variant="contained"
              color="primary"
              disabled={isEditorSaving}
              endIcon={isEditorSaving ? <CircularProgress size={24} /> : <></>}
            >
              Speichern
            </Button>
          </Grid>
        </>
      }
    ></Modal>
  );
}

// @ts-ignore see https://github.com/microsoft/TypeScript/issues/30712
const ImageEditor = React.lazy(() => import('../../tui.image-editor'));

type FotoEditorProps = {
  readonly foto: Foto;
  readonly setEditorRef: (editorRef: React.MutableRefObject<ImageEditorInst | null>) => void;
};

function FotoEditor(props: FotoEditorProps) {
  return (
    <Suspense fallback={'Lade...'}>
      <LazyFotoEditor {...props} />
    </Suspense>
  );
}

function LazyFotoEditor({ foto, setEditorRef }: FotoEditorProps): JSX.Element {
  const editorRef = useRef<ImageEditorInst | null>(null);
  const [url, setUrl] = useState('');

  const isTablet = useMedia('(max-width: 1024px)');
  const isMobile = useMedia('(max-width: 500px)');

  useEffect(() => {
    setEditorRef(editorRef);
  }, [editorRef, setEditorRef]);

  useEffect(() => {
    async function loadUrl(key: string) {
      const storageUrl = await S3Client.get(key.replace(/^public\//, ''));
      setUrl(storageUrl);
    }

    if (foto.key) {
      loadUrl(foto.key);
    }
  }, [foto.key]);

  useEffect(() => {
    function loadImage() {
      createFileFromUrl(url).then((data) => {
        if (data.type.includes('image')) {
          editorRef?.current?.imageEditorInst.loadImageFromFile(data).then(() => {
            if (editorRef?.current?.imageEditorInst?.resizeCanvasDimension) {
              editorRef.current.imageEditorInst.resizeCanvasDimension({
                width: document.documentElement.clientWidth - (isMobile ? 70 : isTablet ? 350 : 250),
                height: document.documentElement.clientHeight - (isMobile ? 70 : isTablet ? 350 : 250)
              });
            }
          });
        }
      });
    }

    if (url !== '') {
      loadImage();
    }
  }, [isMobile, isTablet, url]);

  async function createFileFromUrl(url: string) {
    const response = await fetch(url, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        Origin: window.location.origin
      }
    });
    const data = await response.blob();
    const metaData = {
      type: response.headers.get('content-type') ?? undefined
    };
    return new File([data], 'image', metaData);
  }

  return (
    <ImageEditor
      //@ts-ignore
      ref={editorRef}
      includeUI={{
        loadImage: {
          path: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
          name: 'Blank'
        },
        menu: ['crop', 'flip', 'rotate', 'draw', 'shape', 'icon', 'text', 'filter'],
        initMenu: 'crop',
        uiSize: {
          width: document.documentElement.clientWidth - 65 + 'px',
          height: '80vh'
        },
        menuBarPosition: !(isTablet || isMobile) ? 'left' : 'bottom',
        theme: customTheme
      }}
      cssMaxHeight={1024}
      cssMaxWidth={document.documentElement.clientWidth - 125}
      selectionStyle={{
        cornerSize: isTablet || isMobile ? 50 : 20,
        rotatingPointOffset: 70,
        cornerColor: 'green',
        transparentCorners: false
      }}
      usageStatistics={false}
    />
  );
}

function useMedia(mediaQuery: string) {
  const match = () => {
    if (!window.matchMedia) {
      return false;
    }
    return window.matchMedia(mediaQuery).matches;
  };

  const [isMatch, setIsMatch] = useState(match);

  useEffect(() => {
    // Update state on window `resize` event.
    // Usage of `match` function defined outside of `useEffect`
    // ensures that it has current values of arguments.
    const handler = () => setIsMatch(match);
    window.addEventListener('resize', handler);
    // Remove event listener on cleanup.
    return () => window.removeEventListener('resize', handler);
  });

  return isMatch;
}
