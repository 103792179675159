import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { UnternehmenTyp } from '../../shared/constants';

const UNTERNEHMENTYP_TEXTE: Record<string, string> = {
  [UnternehmenTyp.VERSICHERUNG]: 'Versicherung',
  [UnternehmenTyp.RECHTSANWALT]: 'Rechtsanwalt',
  [UnternehmenTyp.WERKSTATT]: 'Werkstatt',
  [UnternehmenTyp.HAENDLER]: 'Restwerthändler'
};

type Props = {
  readonly unternehmentyp: UnternehmenTyp | string;
  readonly setUnternehmentyp: (unternehmentyp: UnternehmenTyp) => void;
  readonly disabled?: boolean;
  readonly dataTestid?: string;
};

export default function UnternehmentypAuswahl({ unternehmentyp, setUnternehmentyp, disabled = false, dataTestid = '' }: Props) {
  const unternehmenTypen = Object.entries(UNTERNEHMENTYP_TEXTE).map(([key, value]) => {
    // z.B. "UNTERNEHMENTYP_VERSICHERUNG": 'Versicherung'
    return { label: value, value: key };
  });

  return (
    <Select
      variant="standard"
      disabled={disabled}
      value={unternehmentyp}
      onChange={(event) => setUnternehmentyp(event.target.value as UnternehmenTyp)}
      data-testid={dataTestid}
    >
      {unternehmenTypen.map((typ, index) => {
        return (
          <MenuItem key={index} value={typ.value}>
            {typ.label}
          </MenuItem>
        );
      })}
    </Select>
  );
}
