import { environment } from '../env';
import { PutObjectCommand, DeleteObjectCommand, GetObjectCommand, S3Client as S3 } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { getCredentials } from './Auth';

async function getClient() {
  return new S3({
    region: environment.aws_project_region,
    credentials: await getCredentials()
  });
}

/*
 *  TODO public/ ist ein Artefakt aus der Amplify Storage API. Wenn public entfernt wird, muss die Datenbasis migriert werden.
 */
function prefixKey(key: string) {
  return key.startsWith('public/') ? key : `public/${key}`;
}

export class S3Client {
  static async get(key: string): Promise<string> {
    const s3 = await getClient();
    const command = new GetObjectCommand({
      Bucket: environment.aws_user_files_s3_bucket,
      Key: prefixKey(key)
    });
    return await getSignedUrl(s3, command, { expiresIn: 604800 });
  }

  static async put(key: string, body: Blob, contentType?: string): Promise<void> {
    const s3 = await getClient();
    await s3.send(
      new PutObjectCommand({
        ContentType: contentType,
        Bucket: environment.aws_user_files_s3_bucket,
        Key: prefixKey(key),
        Body: body
      })
    );
  }

  static async remove(key: string): Promise<void> {
    const s3 = await getClient();
    await s3.send(
      new DeleteObjectCommand({
        Bucket: environment.aws_user_files_s3_bucket,
        Key: prefixKey(key)
      })
    );
  }

  static async getLastModifiedISOString(key: string): Promise<string> {
    const s3 = await getClient();
    const response = await s3.send(
      new GetObjectCommand({
        Bucket: environment.aws_user_files_s3_bucket,
        Key: prefixKey(key)
      })
    );
    return response?.LastModified?.toISOString() ?? new Date().toISOString();
  }
}
