export const COGNITO_GRUPPE_SACHVERSTAENGIGER = 'Sachverstaendiger';
export const COGNITO_GRUPPE_QUALITAETSSICHERUNG = 'Qualitaetssicherung';
export const COGNITO_GRUPPE_VERWALTUNG = 'Verwaltung';
export const COGNITO_GRUPPE_ADMIN = 'Admin';
export const COGNITO_GRUPPE_EXPERTS24ADMIN = 'Experts24Admin';

export const EVENT_TYPE_ZAHLUNG_IMPORTIERT = 'ZAHLUNG_IMPORTIERT';
export const EVENT_TYPE_ZAHLUNG_GELOESCHT = 'ZAHLUNG_GELOESCHT';

// Reihenfolge ist richtig und wichtig
export enum Status {
  OFFEN = 'OFFEN',
  TERMINFESTLEGUNG = 'TERMINFESTLEGUNG',
  BESICHTIGUNG = 'BESICHTIGUNG',
  BEWEISSICHERUNG = 'BEWEISSICHERUNG',
  GUTACHTENAUSARBEITUNG = 'GUTACHTENAUSARBEITUNG',
  QUALITAETSSICHERUNG = 'QUALITAETSSICHERUNG',
  DRUCK_VERSAND = 'DRUCK_VERSAND',
  MAHNWESEN = 'MAHNWESEN',
  ABGESCHLOSSEN = 'ABGESCHLOSSEN'
}

export const AKTIVE_VORGAENGE = [Status.OFFEN, Status.BESICHTIGUNG, Status.TERMINFESTLEGUNG, Status.GUTACHTENAUSARBEITUNG, Status.DRUCK_VERSAND] as const;

export enum Gutachtenart {
  SCHADENSGUTACHTEN_HAFTPFLICHT = 'SCHADENSGUTACHTEN_HAFTPFLICHT',
  SCHADENSGUTACHTEN_KASKO = 'SCHADENSGUTACHTEN_KASKO',
  KURZGUTACHTEN = 'KURZGUTACHTEN',
  REPARATURNACHWEIS = 'REPARATURNACHWEIS',
  FAHRZEUGBEWERTUNG = 'FAHRZEUGBEWERTUNG'
}

export enum Bereifung {
  WINTERREIFEN = 'WINTERREIFEN',
  SOMMERREIFEN = 'SOMMERREIFEN',
  GANZJAHRESREIFEN = 'GANZJAHRESREIFEN'
}

export enum Allgemeinzustand {
  DURCHSCHNITTLICHER_ZUSTAND = 'DURCHSCHNITTLICHER_ZUSTAND',
  UEBERDURCHSCHNITTLICHER_ZUSTAND = 'UEBERDURCHSCHNITTLICHER_ZUSTAND',
  UNTERDURCHSCHNITTLICHER_ZUSTAND = 'UNTERDURCHSCHNITTLICHER_ZUSTAND'
}

export enum AltschadenEnum {
  KEINE_ALTSCHAEDEN_AEUSSERLICH_ERKENNBAR = 'KEINE_ALTSCHAEDEN_AEUSSERLICH_ERKENNBAR',
  ALTSCHAEDEN_VORHANDEN = 'ALTSCHAEDEN_VORHANDEN'
}

export enum Zulassungsbescheinigung {
  ZULASSUNGSBESCHEINIGUNG_I = 'ZULASSUNGSBESCHEINIGUNG_I',
  ZULASSUNGSBESCHEINIGUNG_II = 'ZULASSUNGSBESCHEINIGUNG_II'
}

export enum Reifenzustand {
  VERKEHRSSICHER = 'VERKEHRSSICHER',
  NICHT_VERKEHRSSICHER = 'NICHT_VERKEHRSSICHER'
}

export enum Fahrzeugart {
  PKW = 'PKW',
  LKW = 'LKW',
  KRAFTRAD = 'KRAFTRAD',
  KRAFTOMNIBUS = 'KRAFTOMNIBUS',
  FAHRRAD = 'FAHRRAD',
  SONSTIGES = 'SONSTIGES'
}

export enum Unterzeichner {
  VERMITTLER = 'VERMITTLER',
  AUFTRAGGEBER = 'AUFTRAGGEBER',
  FAHRZEUGHALTER = 'FAHRZEUGHALTER',
  SACHVERSTAENDIGER = 'SACHVERSTAENDIGER'
}

export enum Dateityp {
  FOTO = 'FOTO',
  DOKUMENT = 'DOKUMENT'
}

export enum Generierungsstatus {
  ANGEFRAGT = 'ANGEFRAGT',
  IN_ERSTELLUNG = 'IN_ERSTELLUNG',
  ERSTELLT = 'ERSTELLT',
  FEHLGESCHLAGEN = 'FEHLGESCHLAGEN'
}

export enum EmailEmpfaengerTyp {
  VERSICHERUNG = 'VERSICHERUNG',
  RECHTSANWALT = 'RECHTSANWALT',
  VERMITTLER = 'VERMITTLER',
  AUFTRAGGEBER = 'AUFTRAGGEBER',
  FAHRZEUGHALTER = 'FAHRZEUGHALTER',
  SACHVERSTAENDIGER = 'SACHVERSTAENDIGER',
  SONSTIGE = 'SONSTIGE'
}

export enum PersonenTyp {
  PERSON = 'PERSON',
  VERMITTLER = 'VERMITTLER',
  UNFALLGEGNER = 'UNFALLGEGNER'
}

export enum Anrede {
  HERR = 'HERR',
  FRAU = 'FRAU',
  FIRMA = 'FIRMA'
}

export enum UnternehmenTyp {
  VERSICHERUNG = 'VERSICHERUNG',
  RECHTSANWALT = 'RECHTSANWALT',
  WERKSTATT = 'WERKSTATT',
  HAENDLER = 'HAENDLER'
}

export type TokenContainer = {
  valuateExpertToken?: string;
  calculateExpertToken?: string;
  fahrzeugauswahlToken?: string;
  rentalPricesToken?: string;
};

export type DatZugangsdaten = {
  customerNumber: string | null;
  customerLogin: string | null;
  customerPassword: string | null;
  interfacePartnerNumber: string | null;
  interfacePartnerSignature: string | null;
};

export type Lackart = {
  description: string;
  key: string;
};

export type Land = {
  nationalitaet: string;
  land: string;
};

export type ChallengeName = 'CUSTOM_CHALLENGE' | 'MFA_SETUP' | 'NEW_PASSWORD_REQUIRED' | 'SELECT_MFA_TYPE' | 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA';

export interface CognitoUserCustomAttributes {
  'custom:kuerzel': string;
  'cognito:groups': string[];
}

export interface CognitoUser {
  username: string;
  attributes: {
    email: string;
    family_name: string;
    given_name: string;
    'custom:mandant': string;
    'custom:kuerzel': string;
  };
  signInUserSession?: {
    idToken: {
      payload: CognitoUserCustomAttributes;
      jwtToken: string;
    };
  };
  unverified: {
    email: string;
  };
  challengeName?: ChallengeName;
}

type BoundingBox = {
  width: number;
  height: number;
  left: number;
  top: number;
};

export type Word = {
  text: string;
  boundingBox: BoundingBox;
};

export type IdentifyTextOutputText = {
  fullText: string;
  lines: string[];
  linesDetailed: Word[];
  words: Word[];
};

export type Feld = {
  objekt: string;
  feld: string;
  label: string;
  value: string;
  word: Word;
  type: string;
};

export type TexterkennungKategorie = {
  kategorie: string;
  word: Word;
};

export type SelectOption = {
  label: string;
  value: string;
};

export type DownloadImage = {
  type: string;
  data: string;
};
