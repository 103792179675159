import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { Schadenbereiche } from '../types';
import { SelectOption } from '../shared/constants';
import Box from '@mui/material/Box';

type Props = {
  readonly label: string;
  readonly schadenVorhandenAuswahl: string | undefined | null;
  readonly selectOptions: SelectOption[];
  readonly schadenbereiche: Schadenbereiche | undefined | null;
  readonly isDisabled: boolean;
  readonly speichereAenderung: (aenderung: any) => void;
  readonly autoAnzeigen: boolean | undefined | null;
  readonly testid: string;
};

const itemLeft = {
  justifySelf: 'start'
};

const itemHCenter = {
  justifySelf: 'center'
};

const itemVCenter = {
  alignSelf: 'center'
};

const itemRight = {
  justifySelf: 'end'
};

const itemEnd = {
  alignSelf: 'end'
};

export default function Schadenanzeige({
  label,
  schadenVorhandenAuswahl,
  selectOptions,
  schadenbereiche,
  isDisabled,
  speichereAenderung,
  autoAnzeigen,
  testid
}: Props): JSX.Element {
  return (
    <>
      <Typography variant="h6">{label}</Typography>
      <FormControl variant="standard" fullWidth sx={{ marginBottom: '1rem' }}>
        <InputLabel>{label}</InputLabel>
        <Select
          variant="standard"
          value={schadenVorhandenAuswahl ?? ''}
          disabled={isDisabled}
          onChange={(event) => {
            speichereAenderung({
              schadenVorhandenAuswahl: event.target.value,
              schadenbereiche
            });
          }}
          data-testid={`${testid}-fahrzeugdaten`}
        >
          {selectOptions.map((b: SelectOption) => (
            <MenuItem value={b.value} key={b.value} data-testid={b.label}>
              {b.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {autoAnzeigen && (
        <Box
          component="div"
          sx={{
            margin: 'auto',
            width: '350px',
            height: '640px',
            display: 'grid',
            backgroundSize: 'cover',
            backgroundImage: `url("/${label === 'Altschaden' ? 'redcar' : 'greencar'}.png")`,
            gridTemplateColumns: 'auto auto auto',
            gridTemplateRows: '25% 25% 25% 25%'
          }}
        >
          <Box component="div" sx={{ ...itemLeft }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.vorneLinks ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    vorneLinks: event.target.checked
                  }
                });
              }}
              data-testid={`${testid}-vorne-links`}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemHCenter, ...itemVCenter }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.vorneMittig ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    vorneMittig: event.target.checked
                  }
                });
              }}
              data-testid={`${testid}-vorne-mittig`}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemRight }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.vorneRechts ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    vorneRechts: event.target.checked
                  }
                });
              }}
              data-testid={`${testid}-vorne-rechts`}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemLeft, ...itemVCenter }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.tuerVorneLinks ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    tuerVorneLinks: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
          <Box
            component="div"
            sx={{
              gridColumnStart: 2,
              gridRowStart: 2,
              gridRowEnd: 4,
              justifySelf: 'center',
              alignSelf: 'center'
            }}
          >
            <Checkbox
              color="primary"
              checked={schadenbereiche?.dach ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    dach: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemRight, ...itemVCenter }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.tuerVorneRechts ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    tuerVorneRechts: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemVCenter }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.tuerHintenLinks ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    tuerHintenLinks: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemRight, ...itemVCenter }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.tuerHintenRechts ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    tuerHintenRechts: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemLeft, ...itemEnd }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.hintenLinks ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    hintenLinks: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemHCenter, ...itemEnd }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.hintenMittig ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    hintenMittig: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
          <Box component="div" sx={{ ...itemRight, ...itemEnd }}>
            <Checkbox
              color="primary"
              checked={schadenbereiche?.hintenRechts ?? false}
              disabled={isDisabled}
              onChange={(event) => {
                speichereAenderung({
                  schadenVorhandenAuswahl,
                  schadenbereiche: {
                    ...schadenbereiche,
                    hintenRechts: event.target.checked
                  }
                });
              }}
            ></Checkbox>
          </Box>
        </Box>
      )}
    </>
  );
}
