import './BottomNavigation.css';
import React from 'react';
import MaterialBottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { useHistory, useLocation } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GavelIcon from '@mui/icons-material/Gavel';
import GestureIcon from '@mui/icons-material/Gesture';
import PeopleIcon from '@mui/icons-material/People';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import WarningIcon from '@mui/icons-material/Warning';
import { holeGeschaedigten } from '../../shared/frontend/holeGeschaedigten';
import { Vorgang } from '../../types';
import { Gutachtenart } from '../../shared/constants';

type Props = { readonly vorgang: Vorgang };

const bottomNavigationRedButtonClass = 'bottom-navigation__button--red';
const bottomNavigationYellowButtonClass = 'bottom-navigation__button--yellow';

export default function BottomNavigation({ vorgang }: Props) {
  const history = useHistory();
  const location = useLocation();

  let activePageIndex = undefined;

  function switchPage(value: number) {
    switch (value) {
      case 0:
        history.push(`/vorgang/${vorgang?.id}/kunde`);
        break;
      case 1:
        history.push(`/vorgang/${vorgang?.id}/unfalldaten`);
        break;
      case 2:
        history.push(`/vorgang/${vorgang?.id}/unterschriften`);
        break;
      case 3:
        history.push(`/vorgang/${vorgang?.id}/fahrzeug`);
        break;
      case 4:
        history.push(`/vorgang/${vorgang?.id}/fotos`);
        break;
      case 5:
        history.push(`/vorgang/${vorgang?.id}/schadenbemerkungen`);
        break;
      case 6:
        history.push(`/vorgang/${vorgang?.id}/bewertung`);
        break;
      case 7:
        history.push(`/vorgang/${vorgang?.id}/gutachten`);
        break;

      default:
        break;
    }
  }

  if (location.pathname === `/vorgang/${vorgang?.id}/kunde`) {
    activePageIndex = 0;
  } else if (location.pathname.includes('/unfalldaten')) {
    activePageIndex = 1;
  } else if (location.pathname.includes('/unterschriften')) {
    activePageIndex = 2;
  } else if (location.pathname.includes('/fahrzeug')) {
    activePageIndex = 3;
  } else if (location.pathname.includes('/fotos')) {
    activePageIndex = 4;
  } else if (location.pathname.includes('/schadenbemerkungen')) {
    activePageIndex = 5;
  } else if (location.pathname.includes('/bewertung')) {
    activePageIndex = 6;
  } else if (location.pathname.includes('/gutachten')) {
    activePageIndex = 7;
  }

  const geschaedigter = holeGeschaedigten(vorgang);
  const hatEmail = geschaedigter?.email && geschaedigter?.email?.length > 0 && geschaedigter?.email[0] !== '';
  const hatGeburtsdatum = geschaedigter?.geburtsdatum;
  const kundendatenVollstaendig = hatEmail && hatGeburtsdatum;

  return (
    <MaterialBottomNavigation
      value={activePageIndex}
      onChange={(event, newValue) => {
        switchPage(newValue);
      }}
      showLabels
      className="bottom-navigation"
    >
      <BottomNavigationAction
        label="Kundendaten"
        value={0}
        icon={<PeopleIcon />}
        className={kundendatenVollstaendig ? '' : bottomNavigationYellowButtonClass}
      />
      <BottomNavigationAction value={1} label="Unfalldaten" icon={<WarningIcon />} disabled={vorgang?.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG} />
      <BottomNavigationAction
        value={2}
        label="Unterschriften"
        icon={<GestureIcon />}
        className={vorgang?.unterschriften?.abtretungserklaerung?.key ? '' : bottomNavigationRedButtonClass}
      />
      <BottomNavigationAction value={3} label="Fahrzeugdaten" icon={<DriveEtaIcon />} />
      <BottomNavigationAction value={4} label="Fotos" icon={<PhotoLibraryIcon />} />
      <BottomNavigationAction value={5} label="Bemerkungen" icon={<ChatIcon />} disabled={vorgang?.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG} />

      <BottomNavigationAction value={6} label="Werte" icon={<EqualizerIcon />} />
      <BottomNavigationAction value={7} label="Gutachten" icon={<GavelIcon />} />
    </MaterialBottomNavigation>
  );
}
