import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_BOXES } from '../../../components/common/spacings';
import { Besichtigungsdaten } from './Besichtigungsdaten';
import { Auftraggeber } from './Auftraggeber';
import { Fahrzeughalter } from './Fahrzeughalter';
import { Besichtigung, BesichtigungenConnection } from '../../../types';
import { useVorgangContext } from '../../../contexts/vorgangContext';
import { Besichtigungdialog } from './Besichtigungsdialog';
import { ERROR_MESSAGE } from '../../../components/common/Alert';
import { useSnackbar } from 'notistack';
import { api } from '../../../apigateway';
import { besichtigung as besichtigungApi } from '../../../shared/url';
import { getMessageFromError } from '../../../shared/throw';

export default function Kunde(): JSX.Element {
  const { vorgang, isLoading, setLoading, aktualisiereVorgang, speichereVorgang, setzeVorgang } = useVorgangContext();
  const { enqueueSnackbar } = useSnackbar();
  const [besichtigung, setBesichtigung] = useState<Partial<Besichtigung> | null>(null);

  const aktualisiereBesichtigungsdaten = useCallback(
    (besichtigung: Partial<Besichtigung>) => {
      setLoading(true);

      api
        .request(
          besichtigungApi.post(besichtigung.id, {
            ...besichtigung,
            vorgangId: vorgang.id
          })
        )
        .then((aktualisierteBesichtigung) => {
          let besichtigungen: Besichtigung[];

          if (!besichtigung.id) {
            besichtigungen = [...vorgang.besichtigungen.items, aktualisierteBesichtigung];
          } else {
            besichtigungen = vorgang.besichtigungen.items.map((it) => (it.id === aktualisierteBesichtigung.id ? aktualisierteBesichtigung : it));
          }

          setzeVorgang({ ...vorgang, besichtigungen: { items: besichtigungen } });
          setBesichtigung(null);
        })
        .catch((error) => enqueueSnackbar(`Die Besichtigung konnten nicht gespeichert werden: ${getMessageFromError(error)}`, ERROR_MESSAGE))
        .finally(() => setLoading(false));
    },
    [enqueueSnackbar, setLoading, setzeVorgang, vorgang]
  );

  const loescheBesichtigungsdaten = useCallback(
    (besichtigung: Besichtigung) => {
      setLoading(true);

      api
        .request(besichtigungApi.delete(besichtigung.id!))
        .then(() => {
          const besichtigungsdaten = vorgang.besichtigungen.items.filter((it) => it.id !== besichtigung.id);

          setzeVorgang({ ...vorgang, besichtigungen: { items: besichtigungsdaten } });
        })
        .catch((error) => enqueueSnackbar(`Die Besichtigung konnten nicht gelöscht werden: ${getMessageFromError(error)}`, ERROR_MESSAGE))
        .finally(() => setLoading(false));
    },
    [enqueueSnackbar, setLoading, setzeVorgang, vorgang]
  );

  return (
    <Grid container spacing={SPACING_BETWEEN_BOXES}>
      <Grid item xs={12} md={6}>
        <Auftraggeber
          vorgang={vorgang}
          aktualisiereVorgang={aktualisiereVorgang}
          speichereVorgang={speichereVorgang}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Fahrzeughalter
          vorgang={vorgang}
          aktualisiereVorgang={aktualisiereVorgang}
          speichereVorgang={speichereVorgang}
          setzeVorgang={setzeVorgang}
          isLoading={isLoading}
          setLoading={setLoading}
        />
      </Grid>
      {vorgang.besichtigungen.items.map((besichtigung: Besichtigung, index: number) => {
        return (
          <Grid item xs={12} md={6} key={index}>
            <Besichtigungsdaten
              vorgang={vorgang}
              isLoading={isLoading}
              title={`Daten zur ${index + 1}. Besichtigung`}
              besichtigung={besichtigung}
              removeBesichtigung={() => loescheBesichtigungsdaten(besichtigung)}
              editBesichtigung={() => {
                setBesichtigung(besichtigung);
              }}
              createBesichtigung={() => {
                setBesichtigung({});
              }}
              dataTestId={`besichtigung-${index}`}
            />
          </Grid>
        );
      })}
      {vorgang.besichtigungen.items.length === 0 && (
        <Grid item xs={12} md={6}>
          <Besichtigungsdaten
            vorgang={vorgang}
            isLoading={isLoading}
            title={'Daten zur 1. Besichtigung'}
            besichtigung={{}}
            editBesichtigung={() => {
              setBesichtigung({});
            }}
            createBesichtigung={() => {
              setBesichtigung({});
            }}
          />
        </Grid>
      )}
      {besichtigung && (
        <Besichtigungdialog
          title={
            findIndex(besichtigung, vorgang.besichtigungen) !== -1
              ? `Daten zur ${findIndex(besichtigung, vorgang.besichtigungen) + 1}. Besichtigung`
              : 'Daten zur neuen Besichtigung'
          }
          vorgang={vorgang}
          isLoading={isLoading}
          setLoading={setLoading}
          aktualisiereBesichtigungsdaten={aktualisiereBesichtigungsdaten}
          besichtigung={besichtigung}
          onClose={() => setBesichtigung(null)}
        />
      )}
    </Grid>
  );
}

export function findIndex(besichtigung: Partial<Besichtigung>, besichtigungen: BesichtigungenConnection) {
  return besichtigungen.items.findIndex(({ id }) => id === besichtigung.id);
}
