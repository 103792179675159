import React, { useEffect, useState } from 'react';
import TextField, { TextFieldProps } from '@mui/material/TextField';

function sanitizeEmail(input: string): string {
  return input.replace(/[^\w.@+\-~]+/g, '');
}

export function EmailTextField(props: Omit<TextFieldProps, 'type' | 'value'>): JSX.Element {
  const { defaultValue, onChange, ...otherProps } = props;
  const [email, setEmail] = useState<unknown>(props.defaultValue || '');

  useEffect(() => {
    setEmail(props.defaultValue);
  }, [props.defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const cleanEmail = sanitizeEmail(event.target.value);
    setEmail(cleanEmail);

    if (onChange) {
      onChange({ ...event, target: { ...event.target, value: cleanEmail } });
    }
  };

  return <TextField variant="standard" {...otherProps} value={email} onChange={handleChange} type="email" />;
}
