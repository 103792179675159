/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from 'react';
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from './AuthPiece';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField/TextField';
import Grid from '@mui/material/Grid/Grid';
import Link from '@mui/material/Link/Link';
import { SPACING_BETWEEN_FORM_FIELDS } from '../components/common/spacings';
import { AuthenticatorPage } from '../components/AuthenticatorPage';
import { signIn } from '../shared/Auth';
import { CognitoUser } from '../shared/constants';

export interface ISignInState extends IAuthPieceState {
  loading?: boolean;
}

export class SignIn extends AuthPiece<IAuthPieceProps, ISignInState> {
  constructor(props: IAuthPieceProps) {
    super(props);

    this.checkContact = this.checkContact.bind(this);
    this.signIn = this.signIn.bind(this);

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp'];
    this.state = {};
  }

  checkContact(user: CognitoUser) {
    this.changeState('signedIn', user);
  }

  signIn(event: React.FormEvent) {
    if (event) {
      event.preventDefault();
    }

    const username = this.getUsernameFromInput();
    const password = this.inputs.password;

    if (!username || !password) {
      return;
    }

    this.setState({ loading: true });

    signIn(username, password)
      .then((user) => {
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.changeState('requireNewPassword', user);
        } else {
          this.checkContact(user);
        }
      })
      .catch((error) => {
        if (error.code === 'UserNotConfirmedException') {
          this.changeState('confirmSignUp', { username });
        } else if (error.code === 'PasswordResetRequiredException') {
          this.changeState('forgotPassword', { username });
        } else {
          this.error(error);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  showComponent() {
    const { hide = [] } = this.props;
    if (hide.includes(SignIn)) {
      return null;
    }

    return (
      <AuthenticatorPage title="Melden Sie sich mit Ihrem Account an">
        <form onSubmit={this.signIn}>
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                defaultValue={this.state.username}
                autoFocus
                label="Benutzername"
                key="username"
                name="username"
                onChange={this.handleInputChange}
                data-testid="username-input"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                fullWidth
                label="Passwort"
                key="password"
                type="password"
                name="password"
                data-testid="sign-in-password-input"
                onChange={this.handleInputChange}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid container columns={2}>
                <Grid item xs={1}>
                  <Link href="#" onClick={() => this.changeState('forgotPassword')}>
                    Passwort zurücksetzen
                  </Link>
                </Grid>
                <Grid item xs={1} display="flex" justifyContent="flex-end">
                  <Button type="submit" color="primary" variant="contained" disabled={this.state.loading} data-testid="sign-in-sign-in-button">
                    Anmelden
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </AuthenticatorPage>
    );
  }
}
