import React, { useEffect, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import { S3Client } from '../../shared/s3Client';
import Box from '@mui/material/Box';

type Props = {
  readonly thumbnailkey: string;
  readonly height: number;
};

export function Thumbnail({ thumbnailkey, height }: Props): JSX.Element {
  const [url, setUrl] = useState<string>('');

  useEffect(() => {
    let didCancel = false;
    const loadUrl = async (key: string) => {
      const cleanedUrl = await S3Client.get(key.replace(/^public\//, ''));
      if (!didCancel) {
        setUrl(cleanedUrl);
      }
    };
    if (thumbnailkey) {
      loadUrl(thumbnailkey);
    }
    return () => {
      didCancel = true;
    };
  }, [thumbnailkey]);

  return (
    <Tooltip title={<img src={url} alt="Kein Bild vorhanden" />}>
      <Box component="img" sx={{ height }} src={url} alt="Kein Bild vorhanden" />
    </Tooltip>
  );
}
