import '../screens/Vorgang/Unterschriften.css';
import React, { ChangeEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { Signature } from './common/Signature';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { Vorgang } from '../types';
import { Unterzeichner } from '../shared/constants';
import { Modal } from './Modal';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { UnterschriftenCheckboxState } from '../screens/Vorgang/Unterschriften';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly updateCheckboxState: (newState: Partial<UnterschriftenCheckboxState>) => void;
  readonly checkboxState: UnterschriftenCheckboxState;
};

export function DSGVO({ vorgang, isLoading, updateCheckboxState, checkboxState }: Props): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxState.dsgvo}
              onChange={(event: ChangeEvent<HTMLInputElement>) => updateCheckboxState({ dsgvo: event.target.checked })}
              name="DSGVO"
              color="primary"
              data-testid="dsgvoCheckbox"
            />
          }
          label={
            <Link
              onClick={(event) => {
                event.preventDefault();
                setIsModalOpen(true);
              }}
              data-testid="dsgvoLink"
            >
              Erklärung zur DSGVO (Datenschutzverordnung)*
            </Link>
          }
        />
      </Grid>
      <DSGVOModal vorgang={vorgang} isLoading={isLoading} isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}

type ModalProps = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly isModalOpen: boolean;
  readonly onClose: () => void;
};

function DSGVOModal({ vorgang, isLoading, isModalOpen, onClose }: ModalProps): JSX.Element {
  const dsgvoUrl = useDateiUrl(vorgang?.unterschriften?.dsgvo?.key);

  const signDate = vorgang?.unterschriften?.dsgvo?.datum ?? new Date().toISOString();

  return (
    <Modal
      openModal={isModalOpen}
      setOpenModal={onClose}
      title="Erklärung zur DSGVO (Datenschutzverordnung)"
      body={
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS} data-testid="dsgvoModal">
          <Grid item xs={12}>
            <Typography variant="body2">
              Ich stimme ausdrücklich zu, dass alle Daten, die mit meiner Person (Auftraggeber/Fahrzeughalter) und dem Auftrag zur Gutachten- und
              Reparaturnachweiserstellung im Zusammenhang stehen, wie z.B. Kontaktdaten, Daten des zu begutachtenden Objektes und Daten zur Ermittlung des
              Versicherers, verwendet werden dürfen.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Ich wurde darauf hingewiesen, dass meine Daten von der Firma Experts24 Tassone GmbH entsprechend der EU-DSGVO geschützt werden. Außerdem werden
              meine Daten an Dritte weitergegeben, wenn dies zur Leistungserstellung notwendig ist. Dritte sind die Firma DAT (Datenlieferant für Fahrzeuge),
              die ADELTA.FINANZ AG (Factoringgesellschaft), der GDV (Gesamtverband der Deutschen Versicherer), Händler/Autohäuser/Reparaturbetriebe zur
              Ermittlung von Restwert/Wiederbeschaffungswert, Versicherer, die im Schadensregulierungsprozess involviert sind, und Steuerberater/Rechtsanwälte
              der Firma Experts24 Tassone GmbH im Rahmen der Buchführung/Rechtsberatung.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Weitere Informationen können der Internetseite{' '}
              <Link href="https://www.experts24.de/datenschutz" underline="always" target="_blank" color="primary">
                https://www.experts24.de/datenschutz
              </Link>
              &nbsp;entnommen werden. Den Bedingungen der Datenschutzerklärung kann jederzeit widersprochen werden.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Signature
              onSign={() => {}}
              onDeleteSignature={() => {}}
              signatureUrl={dsgvoUrl}
              isLoading={isLoading}
              unterzeichnerAuswahl={vorgang?.unterschriften?.dsgvo?.unterzeichner ?? Unterzeichner.AUFTRAGGEBER}
              vorgang={vorgang}
              date={signDate}
              readOnly={true}
            />
          </Grid>
        </Grid>
      }
      footer={
        <div className="unterschriften__modal-button">
          <Button color="primary" variant="contained" onClick={onClose}>
            Schließen
          </Button>
        </div>
      }
    />
  );
}
