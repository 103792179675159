import * as React from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles/createTheme';

const innerWrapperCss = (theme: Theme) => ({
  position: 'relative',
  marginBottom: '20px',
  padding: '35px 40px',
  textAlign: 'left',
  display: 'inline-block',
  maxWidth: '460px',
  borderRadius: '6px',
  boxShadow: '1px 1px 4px 0 rgba(0,0,0,0.15)',
  boxSizing: 'border-box',
  [theme.breakpoints.down('md')]: {
    boxShadow: 'none'
  }
});

type Props = {
  readonly title: string;
  readonly children: React.ReactNode;
};

export function AuthenticatorPage(props: Props): JSX.Element {
  return (
    <Box component="div" sx={{ textAlign: 'center', margin: '5% auto 50px' }}>
      <Box component="div" sx={innerWrapperCss}>
        <Box component="h1" sx={{ marginBottom: '24px', fontSize: '18px', fontWeight: '500' }}>
          {props.title}
        </Box>
        {props.children}
      </Box>
    </Box>
  );
}
