import React, { useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Modal } from '../Modal';

type Props = {
  readonly openModal: boolean;
  readonly setOpenModal: (value: boolean) => void;
  readonly mandanten: string[];
  readonly legeVorgangAn: (mandant: string) => void;
  readonly isLoading: boolean;
};

export default function VorgangZuMandantAnlegenModal({ openModal, setOpenModal, mandanten, legeVorgangAn, isLoading }: Props): JSX.Element {
  const [selectedMandant, setSelectedMandant] = useState(mandanten[0]);
  return (
    <Modal
      openModal={openModal}
      setOpenModal={setOpenModal}
      ariaLabelledby="benutzerAnlegenModal"
      title="Vorgang anlegen"
      body={
        <FormControl variant="standard" fullWidth>
          <InputLabel>Mandantenauswahl</InputLabel>
          <Select
            variant="standard"
            label="Mandantenauswahl"
            value={selectedMandant}
            onChange={(event) => {
              setSelectedMandant(event.target.value);
            }}
            data-testid={'Mandantenauswahl'}
          >
            {mandanten.map((g) => (
              <MenuItem key={g} value={g}>
                {g.toUpperCase()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      }
      footer={
        <>
          <Grid item>
            <Button onClick={() => setOpenModal(false)} variant="contained" color="secondary">
              Abbrechen
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => legeVorgangAn(selectedMandant)} variant="contained" color="primary" disabled={isLoading}>
              Vorgang anlegen
            </Button>
          </Grid>
        </>
      }
    ></Modal>
  );
}
