import './Bearbeiterauswahl.css';
import React, { useState, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { deprecated_Benutzer } from '../../types';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import { ERROR_MESSAGE } from '../common/Alert';
import { enqueueSnackbar } from 'notistack';
import { getMessageFromError } from '../../shared/throw';
import { getSessionItem, setSessionItem } from '../../shared/storage';

type Props = {
  label?: string;
  mandant: string;
  userId: string | null | undefined;
  gruppen?: string[];
  className?: string;
  disabled: boolean;
  onChange: (bearbeiter: deprecated_Benutzer) => void;
  dataTestId?: string;
};

export function Bearbeiterauswahl({ label, mandant, userId, gruppen, className, disabled, onChange, dataTestId }: Props): JSX.Element {
  const [bearbeiterliste, setBearbeiterliste] = useState<deprecated_Benutzer[]>([]);

  useEffect(() => {
    let didCancel = false;

    if (mandant) {
      const cachedBenutzerliste = holeAusCache(mandant, gruppen);

      if (cachedBenutzerliste) {
        setBearbeiterliste(cachedBenutzerliste);
      } else {
        makeGraphqlQuery<deprecated_Benutzer[]>(queries.holeBenutzerliste, { mandant, gruppen })
          .then((bl) => {
            if (!didCancel) {
              speichereImCache(mandant, gruppen, bl);
              setBearbeiterliste(bl);
            }
          })
          .catch((error) => {
            enqueueSnackbar(getMessageFromError(error) ?? 'Bearbeiterliste konnte nicht geladen werden', ERROR_MESSAGE);
          });
      }
    }

    return () => {
      didCancel = true;
    };
  }, [gruppen, mandant]);

  return (
    <FormControl variant="standard" className={`bearbeiter-auswahl ${className ?? ''}`}>
      <InputLabel>{label}</InputLabel>
      <Select
        variant="standard"
        label="Bearbeiter"
        role={`bearbeiter-auswahl ${className ?? ''}`}
        value={bearbeiterliste.length > 0 && !!userId ? userId : ''}
        disabled={disabled}
        onChange={(event: SelectChangeEvent<string>) => onChange(bearbeiterliste.find((it) => it.id === event.target.value)!)}
        {...{ 'data-testid': dataTestId ?? 'bearbeiter-auswahl' }}
      >
        {bearbeiterliste.length > 0
          ? bearbeiterliste.map((b) => (
              <MenuItem key={b.id} value={b.id ?? undefined} data-testid={b.id}>
                <Tooltip title={zuAnzeigeWert(b)}>
                  <span>{zuAnzeigeWert(b)}</span>
                </Tooltip>
              </MenuItem>
            ))
          : null}
      </Select>
    </FormControl>
  );
}

function zuAnzeigeWert(b: deprecated_Benutzer): string {
  return b.given_name && b.family_name ? `${b.given_name} ${b.family_name}` : (b.id ?? '');
}

function holeAusCache(mandant: string, gruppen: string[] | undefined): deprecated_Benutzer[] | null {
  return getSessionItem(zuKey(mandant, gruppen));
}

function speichereImCache(mandant: string, gruppen: string[] | undefined, benutzerliste: deprecated_Benutzer[]) {
  setSessionItem(zuKey(mandant, gruppen), benutzerliste);
}

function zuKey(mandant: string, gruppen: string[] | undefined): string {
  const key = `bearbeiterauswahl_${mandant}`;
  if (Array.isArray(gruppen) && gruppen.length > 0) {
    return key + '_' + gruppen.join(',');
  }
  return key;
}
