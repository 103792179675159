import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Formular from './common/Formular';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from './common/Alert';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { Signature } from './common/Signature';
import { Vorgang } from '../types';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as mutations from '../graphql/mutations';
import { useSnackbar } from 'notistack';
import { Unterzeichner } from '../shared/constants';
import { S3Client } from '../shared/s3Client';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
};

export function Schlussbemerkung({ vorgang, isLoading, setzeVorgang }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const schlussbemerkungUrl = useDateiUrl(vorgang?.unterschriften?.schlussbemerkung?.key);

  const signDate = vorgang?.unterschriften?.schlussbemerkung?.datum;

  const handleSign = async (signature: string, datum: string, unterzeichnerAuswahl: Unterzeichner) => {
    try {
      const response = await fetch(signature);
      const blob = await response.blob();
      const contentType = blob.type;

      const key = `${vorgang.id}/unterschriften/schlussbemerkung.png`;

      await S3Client.put(key, blob, contentType);
      const aktualisierterVorgang = await makeGraphqlQuery(mutations.leisteUnterschrift, {
        vorgangId: vorgang.id,
        typ: 'schlussbemerkung',
        key,
        datum,
        unterzeichner: unterzeichnerAuswahl
      });
      setzeVorgang(aktualisierterVorgang);
      enqueueSnackbar('Die Unterschrift wurde erfolgreich gespeichert.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Unterschrift konnte nicht gespeichert werden.', ERROR_MESSAGE);
    }
  };

  const handleDeleteSignature = async () => {
    try {
      await S3Client.remove(`${vorgang.id}/unterschriften/schlussbemerkung.png`);
      const aktualisierterVorgang = await makeGraphqlQuery(mutations.entferneUnterschrift, {
        vorgangId: vorgang.id,
        typ: 'schlussbemerkung'
      });
      setzeVorgang(aktualisierterVorgang);
      enqueueSnackbar('Die Unterschrift wurde erfolgreich entfernt.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Unterschrift konnte nicht entfernt werden.', ERROR_MESSAGE);
    }
  };

  return (
    <Formular ueberschrift="Schlussbemerkung">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          <Typography variant="body2">
            Diese Expertise wurde nach bestem Wissen und Gewissen erstellt. Die Erstellung dieser Expertise erfolgte unter zur Hilfenahme moderner
            Datenverarbeitungssysteme. Die in diesem Zusammenhang gespeicherten Daten unterliegen dem Datenschutz gemäß EU-DSGVO.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Signature
            onSign={handleSign}
            onDeleteSignature={handleDeleteSignature}
            signatureUrl={schlussbemerkungUrl}
            isLoading={isLoading}
            vorgang={vorgang}
            mitUnterzeichner={false}
            unterzeichnerAuswahl={Unterzeichner.SACHVERSTAENDIGER}
            date={signDate}
          />
        </Grid>
      </Grid>
    </Formular>
  );
}
