import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import Alert from '@mui/material/Alert';
import DeleteIcon from '@mui/icons-material/Delete';
import { useSnackbar } from 'notistack';
import { SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { dialogActions } from '../../components/dialog/Dialog';
import Formular from '../../components/common/Formular';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { Regel, Textbaustein } from '../../types';
import { Gutachtenart } from '../../shared/constants';
import { useUser } from '../../hooks/useUser';

type CsvRowError = {
  row?: number;
  index: number;
  code?: string;
  message: string;
};

export const GUTACHTENARTEN: string[] = [
  Gutachtenart.SCHADENSGUTACHTEN_HAFTPFLICHT,
  Gutachtenart.SCHADENSGUTACHTEN_KASKO,
  Gutachtenart.KURZGUTACHTEN,
  Gutachtenart.REPARATURNACHWEIS,
  Gutachtenart.FAHRZEUGBEWERTUNG
];

const TEXTBAUSTEINREGELN: string[] = [
  Regel.IMMER,
  Regel.NACHBESICHTIGUNG,
  Regel.REPARATURKOSTEN_GROESSER_WIEDERBESCHAFFUNGSWERT,
  Regel.REPARATURKOSTEN_KLEINER_WIEDERBESCHAFFUNGSWERT,
  Regel.REPARATURWUERDIG,
  Regel.REPARATURGRAENZE,
  Regel.AELTER_ALS_3_JAHRE_UND_SCHECKHEFT,
  Regel.AELTER_ALS_3_JAHRE_UND_NICHT_SCHECKHEFT,
  Regel.FAHRZEUG_JUENGER_ALS_3_JAHRE,
  Regel.REPARATURKOSTEN_KLEINER_50PROZENT_WIEDERBESCHAFFUNGSWERT,
  Regel.REPARATURWERKSTATT_ANGEGEBEN,
  Regel.REPARATURKOSTEN_GROESSER_50PROZENT_WIEDERBESCHAFFUNGSWERT,
  Regel.FAHRZEUGALTER_GROESSER_120_MONATE,
  Regel.FAHRZEUGALTER_KLEINER_120_MONATE,
  Regel.MARKIERUNG_FAHRZEUGGRAFIK_IN_PROEXPERT,
  Regel.MERKANTILER_MINDERWERT_VORHANDEN,
  Regel.MERKANTILER_MINDERWERT_KLEINER_100_EURO,
  Regel.MERKANTILER_MINDERWERT_NICHT_VORHANDEN_FAHRZEUG_AELTER_10_JAHRE,
  Regel.RICHTBANK_ERFORDERLICH,
  Regel.VERMESSUNG_VORDERACHSE,
  Regel.PRUEFARBEITEN_ERFORDERLICH,
  Regel.FAHRBEREIT,
  Regel.NICHT_FAHRBEREIT,
  Regel.VERKEHRSSICHERHEIT,
  Regel.VERKEHRSSICHER_NACH_NOTREPARATUR,
  Regel.ZULASSUNGSBESCHEINIGUNG_TEIL_1,
  Regel.ZULASSUNGSBESCHEINIGUNG_TEIL_2,
  Regel.DURCHGEFUEHRTE_ACHSVERMESSUNG_MIT_SCHADENSFESTSTELLUNG,
  Regel.DURCHGEFUEHRTE_ACHSVERMESSUNG_OHNE_SCHADENSFESTSTELLUNG,
  Regel.BEILACKIERUNG_ERFORDERLICH,
  Regel.ACHSWEISE_ERNEUERUNG_DER_REIFEN,
  Regel.LENKGETRIEBE_ERNEUERN,
  Regel.REPARATURKOSTEN_GROESSER_200_PROZENT_WIEDERBESCHAFFUNGSWERT_KEIN_RESTWERT,
  Regel.WERTVERBESSERUNG,
  Regel.LACKSCHICHTENDICKENMESSUNG,
  Regel.KAROSSERIEVERMESSUNG,
  Regel.ANHAENGERKUPPLUNG,
  Regel.FAHRZEUGZUSTAND,
  Regel.ACHSWEISE_ERNEUERUNG_DER_STOSSDAEMPFER,
  Regel.MIETWAGENKLASSE_VORHANDEN,
  Regel.NUTZUNGSAUSFALLKLASSE_VORHANDEN,
  Regel.PROBEFAHRT_ERFORDERLICH,
  Regel.FEHLERSPEICHER_AUSLESEN,
  Regel.ALTSCHAEDEN_ODER_VORSCHAEDEN_VORHANDEN,
  Regel.KORREKTUR_DURCH_VORSCHAEDEN_GROESSER_0,
  Regel.REGELBESTEUERUNG_JA
];

export default function Mandanten(): JSX.Element | null {
  const history = useHistory();
  const { user, isAdmin, isExperts24Admin } = useUser();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [mandantHinzufuegenDialogOpen, setMandantHinzufuegenDialogOpen] = useState(false);
  const [hinzugefuegteMandanten, setHinzugefuegteMandanten] = useState(0);
  const [seletedMandantToDelete, setSeletedMandantToDelete] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [mandanten, setMandanten] = useState<string[]>([]);

  useEffect(() => {
    if (user && !(isAdmin || isExperts24Admin)) {
      history.push('/');
    }
  }, [user, isAdmin, isExperts24Admin, history]);

  const handleMandantHinzufuegen = async (mandant: string, textbausteine: Textbaustein[]) => {
    try {
      setIsLoading(true);
      await makeGraphqlQuery(mutations.legeMandantAn, {
        mandant,
        textbausteine: textbausteine.map((textbaustein) => JSON.stringify(textbaustein))
      });
      enqueueSnackbar('Der Mandant wurde erfolgreich hinzugefügt.', SUCCESS_MESSAGE_AUTO_HIDE);
      setMandantHinzufuegenDialogOpen(false);
      setHinzugefuegteMandanten(hinzugefuegteMandanten + 1);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Der Mandant konnte nicht hinzugefügt werden.', ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMandantLoeschen = async (mandant: string) => {
    try {
      setIsLoading(true);
      await makeGraphqlQuery(mutations.loescheMandant, { mandant });
      enqueueSnackbar('Der Mandant wurde erfolgreich gelöscht.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Der Mandant konnte nicht gelöscht werden.', ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
      window.location.reload();
    }
  };

  useEffect(() => {
    makeGraphqlQuery(queries.holeMandanten, {}).then((it) => setMandanten(it ?? []));
  }, [hinzugefuegteMandanten]);

  return (
    user && (
      <>
        <Toolbar>
          <Typography variant="h6">Admin</Typography>
        </Toolbar>
        <Formular ueberschrift="Mandanten">
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <List>
                {mandanten.map((mandant) => (
                  <ListItem key={mandant} data-testid={mandant}>
                    <ListItemText primary={mandant.toUpperCase()} />
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        setSeletedMandantToDelete(mandant);
                        setDeleteDialogOpen(true);
                      }}
                      data-testid="delete"
                      size="large"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button variant="contained" color="primary" data-testid="mandantHinzufuegenDialogOeffnen" onClick={() => setMandantHinzufuegenDialogOpen(true)}>
                Mandant anlegen
              </Button>
            </Grid>
          </Grid>
        </Formular>
        <MandantHinzufuegenDialog
          open={mandantHinzufuegenDialogOpen}
          onClose={() => setMandantHinzufuegenDialogOpen(false)}
          onMandantHinzufuegen={handleMandantHinzufuegen}
          isLoading={isLoading}
        />
        <DeleteMandant
          open={deleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          mandant={seletedMandantToDelete ?? ''}
          onDeleteMandant={handleMandantLoeschen}
        />
        <LoadingIndicator isLoading={isLoading} />
      </>
    )
  );
}

type DeleteMandantProps = {
  readonly open: boolean;
  readonly mandant: string;
  readonly onClose: () => void;
  readonly onDeleteMandant: (mandant: string) => Promise<void>;
};

function DeleteMandant({ open, mandant, onClose, onDeleteMandant }: DeleteMandantProps) {
  return (
    <Dialog open={open} onClose={onClose} data-testid="deleteMandantDialog">
      <DialogTitle id="deleteMandantDialogTitle">Mandant löschen</DialogTitle>
      <DialogContent>
        <Grid container>Wollen Sie den Mandanten {mandant?.toUpperCase()} wirklich löschen?</Grid>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Abbrechen
        </Button>
        <Button
          onClick={async () => {
            await onDeleteMandant(mandant);
            onClose();
          }}
          variant="contained"
          color="primary"
          data-testid="deleteMandant"
        >
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

type MandantHinzufuegenDialogProps = {
  readonly open: boolean;
  readonly onMandantHinzufuegen: (mandant: string, textbausteine: Textbaustein[]) => void;
  readonly onClose: () => void;
  readonly isLoading: boolean;
};

function MandantHinzufuegenDialog({ open, onMandantHinzufuegen, onClose, isLoading }: MandantHinzufuegenDialogProps) {
  const { enqueueSnackbar } = useSnackbar();

  const [mandant, setMandant] = useState('');
  const [mandantExistiert, setMandantExistiert] = useState(false);
  const [isCheckingMandant, setCheckingMandant] = useState(false);
  const [textbausteine, setTextbausteine] = useState<Textbaustein[]>([]);
  const textbausteineFileInputRef = useRef<HTMLInputElement>(null);
  const [errors, setErrors] = useState<CsvRowError[] | null>(null);
  const [fehlerberichtDialogOpen, setFehlerberichtDialogOpen] = useState(false);

  const istMandantLeer = mandant === '';
  const istMandantValide = /^[a-z]{2}$/.test(mandant);

  useEffect(() => {
    let didCancel = false;

    async function existiertMandant() {
      if (!didCancel) {
        setCheckingMandant(true);
      }
      try {
        const mandantExistiertBereits = await makeGraphqlQuery(queries.existiertMandant, { mandant });
        if (!didCancel) {
          setMandantExistiert(mandantExistiertBereits);
          setCheckingMandant(false);
        }
      } catch (errors) {
        setMandantExistiert(true);
        enqueueSnackbar('Das Kürzel für den Mandanten konnte nicht überprüft werden.', ERROR_MESSAGE);
      } finally {
        if (!didCancel) {
          setCheckingMandant(false);
        }
      }
    }

    if (istMandantValide && !istMandantLeer) {
      existiertMandant();
    }

    return () => {
      didCancel = true;
    };
  }, [enqueueSnackbar, istMandantLeer, istMandantValide, mandant]);

  const handleTextbausteineHochladenClick = () => {
    setTimeout(() => {
      textbausteineFileInputRef.current?.click();
    }, 0);
  };

  const handleTextbausteineFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.persist();

    if (!event?.target?.files) {
      return;
    }

    try {
      const rawCsvData = await parseCSV(event.target.files[0]);
      const csvData = parseCsvDataToTextbausteine(rawCsvData);
      const errors = validiereTextbausteine(csvData);

      if (errors.length > 0) {
        setErrors(errors);
      } else {
        setErrors(null);
      }
      setTextbausteine(csvData.data);
    } catch (error) {
      console.log({ error });
      enqueueSnackbar('Die Textbausteine konnten nicht verarbeitet werden.', ERROR_MESSAGE);
    } finally {
      if (textbausteineFileInputRef.current) {
        textbausteineFileInputRef.current.value = '';
      }
    }
  };

  return (
    <>
      <Dialog
        open={open}
        keepMounted
        onClose={onClose}
        aria-labelledby="mandantHinzufuegenDialogTitle"
        aria-describedby="mandantHinzufuegenDialogBeschreibung"
        data-testid="mandantHinzufuegenDialog"
      >
        <DialogTitle id="mandantHinzufuegenDialogTitle">Mandant hinzufügen</DialogTitle>
        <DialogContent>
          <DialogContentText id="mandantHinzufuegenDialogBeschreibung">Bitte geben Sie das Kürzel des neuen Mandanten ein.</DialogContentText>
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                data-testid="mandantenkuerzel"
                label="Mandantenkürzel"
                helperText={
                  !istMandantValide || istMandantLeer
                    ? 'Das Kürzel muss aus zwei Buchstaben bestehen.'
                    : isCheckingMandant
                      ? 'Es wird geprüft, ob das Kürzel bereits verwendet wird...'
                      : mandantExistiert
                        ? 'Ein Mandant mit diesem Kürzel existiert bereits.'
                        : 'Das Kürzel kann für den Mandanten verwendet werden.'
                }
                disabled={isLoading}
                error={mandantExistiert || (!istMandantValide && !istMandantLeer)}
                fullWidth
                value={mandant.toUpperCase()}
                onChange={(event) => setMandant(event.target.value.trim().toLowerCase().substring(0, 2))}
                {...(isCheckingMandant
                  ? {
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            <CircularProgress size={24} />
                          </InputAdornment>
                        )
                      }
                    }
                  : {})}
              />
            </Grid>
            <Grid item xs={6}>
              <Button variant="contained" disabled={isLoading || isCheckingMandant} color="primary" onClick={handleTextbausteineHochladenClick}>
                Textbausteine&nbsp;auswählen...
              </Button>
              <Box
                sx={{
                  display: 'none'
                }}
                component="input"
                ref={textbausteineFileInputRef}
                type="file"
                accept="text/csv"
                onChange={handleTextbausteineFileChange}
                data-testid="fileInput"
              />
            </Grid>
            <Grid item xs={errors ? 12 : 6} data-testid="importBericht">
              {errors ? (
                <Alert severity="error">
                  <Box pb={SPACING_BETWEEN_FORM_FIELDS}>Die Textbausteine konnte nicht erkannt werden werden. Bitte überprüfen Sie die CSV-Datei.</Box>
                  <Button variant="contained" color="secondary" size="small" onClick={() => setFehlerberichtDialogOpen(true)}>
                    Fehlerbericht anzeigen
                  </Button>
                </Alert>
              ) : (
                textbausteine && textbausteine.length > 0 && `${textbausteine.length} Textbausteine erkannt`
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={dialogActions}>
          <Button autoFocus onClick={onClose} variant="contained" color="secondary">
            Abbrechen
          </Button>
          <Button
            onClick={() => onMandantHinzufuegen(mandant, textbausteine)}
            disabled={istMandantLeer || !istMandantValide || mandantExistiert || isLoading || isCheckingMandant || textbausteine.length === 0}
            variant="contained"
            color="primary"
            data-testid="mandantHinzufuegen"
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
      <FehlerberichtDialog open={fehlerberichtDialogOpen} onClose={() => setFehlerberichtDialogOpen(false)} errors={errors} />
    </>
  );
}

type FehlerberichtDialogProps = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly errors: CsvRowError[] | null;
};

function FehlerberichtDialog({ open, onClose, errors }: FehlerberichtDialogProps) {
  return (
    <Dialog
      open={open}
      keepMounted
      onClose={onClose}
      aria-labelledby="fehlerberichtDialogTitle"
      aria-describedby="fehlerberichtDialogBeschreibung"
      data-testid="fehlerberichtDialog"
    >
      <DialogTitle id="fehlerberichtDialogTitle">Fehlerbericht</DialogTitle>
      <DialogContent>
        <DialogContentText id="fehlerberichtDialogBeschreibung">Folgende Fehler wurden erkannt:</DialogContentText>
        <TableContainer
          sx={{
            maxHeight: 440
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Zeile</TableCell>
                <TableCell>Fehler</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {errors?.map((error, key) =>
                typeof error.row !== 'undefined' ? (
                  <TableRow key={key}>
                    <TableCell>{error.row + 2}</TableCell>
                    <TableCell>{error.message}</TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={key}>
                    <TableCell>{error.index + 2}</TableCell>
                    <TableCell>{error.message}</TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export function validiereTextbausteine(csvData: { data: Textbaustein[]; errors: CsvRowError[] }): CsvRowError[] {
  const errors = csvData.data.reduce((errors: CsvRowError[], textbaustein, index) => {
    if (!GUTACHTENARTEN.includes(textbaustein.gutachtenart || '')) {
      errors.push({
        index,
        message: `Die Gutachtenart ${textbaustein.gutachtenart} ist unbekannt.`
      });
    }

    if (!textbaustein.text && !textbaustein.ueberschrift) {
      errors.push({
        index,
        message: `Mindestens Überschrift oder Text müssen gesetzt werden.`
      });
    }

    if (textbaustein.reihenfolge === null || textbaustein.reihenfolge === undefined || Number.isNaN(textbaustein.reihenfolge)) {
      errors.push({
        index,
        message: `Die Reihenfolge muss gesetzt werden.`
      });
    }

    if (!TEXTBAUSTEINREGELN.includes(textbaustein.regel || '')) {
      errors.push({
        index,
        message: `Die Textbausteinregel ${textbaustein.regel} ist unbekannt.`
      });
    }

    return errors;
  }, []);

  return [...csvData.errors, ...errors];
}

function countChar(str: string, char: string) {
  let count = 0;
  for (const element of str) {
    if (element === char) {
      count++;
    }
  }
  return count;
}

async function parseCSV(file: File) {
  const text = await file.text();
  const csvData = text.split(/\r\n|\n/);
  const rows: string[] = [];

  csvData.shift();
  let line = '';

  for (const row of csvData) {
    const count = countChar(row, '"');

    if (count % 2 === 0 && count > 0) {
      if (line.length > 0) {
        rows.push(line);
        line = '';
      }

      rows.push(row);
    } else if (count % 2 !== 0 || (count % 2 === 0 && count === 0)) {
      line += row + '\n';
    }
  }

  if (line.length > 0) {
    rows.push(line);
  }

  return rows.filter((it) => it.trim() !== '').map((it) => it.replaceAll('"', ''));
}

function parseCsvDataToTextbausteine(csvData: string[]) {
  const data: Textbaustein[] = [];
  const errors: CsvRowError[] = [];

  csvData.forEach((row, index) => {
    const attributes = row.split(';');
    if (attributes.length !== 5) {
      errors.push({ index, message: 'Die Zeile hat nicht die vorgegebene Anzahl an Feldern.' });
    }
    data.push({
      gutachtenart: attributes[0],
      regel: attributes[1],
      reihenfolge: Number.parseInt(attributes[2]),
      ueberschrift: attributes[3],
      text: attributes[4]
    } as Textbaustein);
  });

  return {
    data,
    errors
  };
}
