import React from 'react';
import Fab from '@mui/material/Fab';
import SaveIcon from '@mui/icons-material/Save';
import { SPACING_TO_FAB } from './common/spacings';

type Props = {
  readonly isLoading: boolean;
  readonly onClick: () => void;
};

export function FloatingSaveButton({ isLoading, onClick }: Props): JSX.Element {
  return (
    <Fab
      aria-label="Menu"
      color="primary"
      sx={(theme) => ({
        position: 'fixed',
        bottom: theme.spacing(SPACING_TO_FAB * 2),
        right: theme.spacing(SPACING_TO_FAB),
        zIndex: theme.zIndex.drawer + 2
      })}
      onClick={() => onClick()}
      hidden={isLoading}
      data-testid="vorgangSpeichern"
    >
      <SaveIcon />
    </Fab>
  );
}
