import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { SPACING_BETWEEN_COLUMNS } from '../../components/common/spacings';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { HisConfig } from '../../components/admin/HisConfig';
import { Dat } from '../../components/admin/Dat';
import { COGNITO_GRUPPE_ADMIN } from '../../shared/constants';
import MandantenAuswahl from '../../components/common/MandantenAuswahl';
import { HonorarTabelle } from '../../components/admin/HonorarTabelle';
import { useUser } from '../../hooks/useUser';
import { Mandant } from '../../types';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';

export default function MandantenConfig(): JSX.Element | null {
  const { user, isAdmin } = useUser();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [aktuellerMandantId, setAktuellerMandantId] = useState('');
  const [mandant, setMandant] = useState<Mandant | null>(null);

  useEffect(() => {
    if (aktuellerMandantId !== '') {
      makeGraphqlQuery(queries.holeMandant, {
        mandant: aktuellerMandantId
      }).then((mandant) => {
        setMandant(mandant);
      });
    }
  }, [aktuellerMandantId]);

  useEffect(() => {
    if (user && !isAdmin) {
      history.push('/');
    }
  }, [user, isAdmin, history]);

  return (
    user && (
      <>
        <Toolbar>
          <Typography
            variant="h6"
            sx={{
              display: 'flex',
              flexGrow: 1,
              flexWrap: 'nowrap'
            }}
          >
            Konfiguration
          </Typography>
          <MandantenAuswahl
            onSelectMandant={(mandantId: string) => {
              setAktuellerMandantId(mandantId);
            }}
            gruppe={COGNITO_GRUPPE_ADMIN}
          />
        </Toolbar>
        {mandant && (
          <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
            <Grid item xs={12}>
              <HisConfig setLoading={setIsLoading} isLoading={isLoading} mandant={mandant} />
            </Grid>
            <Grid item xs={12}>
              <Dat setLoading={setIsLoading} isLoading={isLoading} mandant={mandant} />
            </Grid>
            <Grid item xs={12}>
              <HonorarTabelle setLoading={setIsLoading} isLoading={isLoading} mandant={mandant} />
            </Grid>
          </Grid>
        )}

        <LoadingIndicator isLoading={isLoading} />
      </>
    )
  );
}
