/* eslint sonarjs/no-duplicate-string: 0 */
export const config = {
  aws_project_region: 'eu-central-1',
  aws_appsync_graphqlEndpoint: 'https://cqi3i6jybvbz3hecxobasnekjy.appsync-api.eu-central-1.amazonaws.com/graphql',
  aws_appsync_region: 'eu-central-1',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_cognito_identity_pool_id: 'eu-central-1:708d0428-4050-447c-b3c5-ee1dce457d84',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_ftjeiZwIZ',
  aws_user_pools_web_client_id: '1vsmebfh8c37k1h8oanusuha8n',
  oauth: {},
  aws_cognito_username_attributes: [],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
  aws_user_files_s3_bucket: 'dateien152541-develop',
  aws_user_files_s3_bucket_region: 'eu-central-1',
  aws_dynamodb_all_tables_region: 'eu-central-1',
  aws_dynamodb_table_schemas: [
    {
      tableName: 'laufnummer-develop',
      region: 'eu-central-1'
    }
  ],
  predictions: {
    identify: {
      identifyText: {
        proxy: false,
        region: 'eu-central-1',
        defaults: {
          format: 'ALL'
        }
      }
    }
  },
  aws_cognito_login_mechanisms: ['PREFERRED_USERNAME'],
  apiGateway: 'https://u17krc9s7f.execute-api.eu-central-1.amazonaws.com/develop/'
};
