import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import InputAdornment from '@mui/material/InputAdornment';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { ALLGEMEINZUSTAND_TEXTE, REPARATURART_TEXTE } from '../shared/domain/vorgang';
import Schadenanzeige from './Schadenanzeige';
import { useUser } from '../hooks/useUser';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_FAHRZEUGDATEN_ERFASSEN } from '../frontendConstants';
import { Altschaden, Fahrzeugdaten, ReparierteVorschaden, ReparierteVorschadenEnum, Schadenbereiche, Vorgang } from '../types';
import { Allgemeinzustand, AltschadenEnum, Gutachtenart } from '../shared/constants';

const ALTSCHAEDEN_VORHANDEN = 'ALTSCHAEDEN_VORHANDEN';
const ANLEGEN_TEXT = 'Anlegen';
const SPEICHERN_TEXT = 'Übernehmen';

const beschaedigungen = [
  'Kratzer',
  'Schramme',
  'Riss',
  'Bruch',
  'Delle',
  'Stauchung',
  'Korrosion',
  'Fehlt',
  'Lackschaden',
  'Farbtonabweichung',
  'Unlackiert',
  'Hagelschaden'
];

const Bauteile = [
  {
    bedingung: 'vorneLinks',
    bauteile: ['Stoßfängerverkleidung vorne links', 'Scheinwerfer links', 'Kotflügel links']
  },
  {
    bedingung: 'vorneMittig',
    bauteile: ['Stoßfängerverkleidung vorne mittig', 'Motorhaube', 'Frontscheibe']
  },
  {
    bedingung: 'vorneRechts',
    bauteile: ['Stoßfängerverkleidung vorne rechts', 'Scheinwerfer rechts', 'Kotflügel rechts']
  },
  {
    bedingung: 'tuerVorneLinks',
    bauteile: ['Außenspiegel links', 'Tür vorne links', 'Türschweller vorne links']
  },
  {
    bedingung: 'tuerVorneRechts',
    bauteile: ['Außenspiegel rechts', 'Tür vorne rechts', 'Türschweller vorne rechts']
  },
  {
    bedingung: 'tuerHintenLinks',
    bauteile: ['Tür hinten links', 'Türschweller hinten links', 'Seitenwand hinten links']
  },
  {
    bedingung: 'tuerHintenRechts',
    bauteile: ['Tür hinten rechts', 'Türschweller hinten rechts', 'Seitenwand hinten rechts']
  },
  { bedingung: 'dach', bauteile: ['Dach'] },
  {
    bedingung: 'hintenLinks',
    bauteile: ['Stoßfängerverkleidung hinten links', 'Rückleuchte links', 'Seitenwand hinten links']
  },
  {
    bedingung: 'hintenMittig',
    bauteile: ['Stoßfängerverkleidung hinten mittig', 'Heckklappe', 'Heckscheibe', 'Heckabschlussblech']
  },
  {
    bedingung: 'hintenRechts',
    bauteile: ['Seitenwand hinten rechts', 'Stoßfängerverkleidung hinten rechts', 'Rückleuchte rechts']
  }
];

const REPARIERTE_VORSCHADEN_TEXTE = {
  [ReparierteVorschadenEnum.KEINE_REPARIERTEN_VORSCHAEDEN_MITGETEILT_UND_VORGEFUNDEN]: 'keine reparierten Vorschäden mitgeteilt und vorgefunden',
  [ReparierteVorschadenEnum.REPARIERTE_VORSCHAEDEN_VORHANDEN]: 'reparierte Vorschäden vorhanden',
  [ReparierteVorschadenEnum.LACKSCHICHTENDICKENMESSUNG_DURCHGEFUEHRT_UND_KEINE_REPARIERTEN_VORSCHAEDEN]:
    'Lackschichtendickenmessung durchgeführt und keine reparierten Vorschäden mitgeteilt und vorgefunden'
};

const ALTSCHAEDEN_TEXTE = {
  [AltschadenEnum.KEINE_ALTSCHAEDEN_AEUSSERLICH_ERKENNBAR]: 'keine Altschäden äußerlich erkennbar',
  [AltschadenEnum.ALTSCHAEDEN_VORHANDEN]: 'Altschäden vorhanden'
};

const filterOptions = createFilterOptions({ matchFrom: 'start', trim: true });

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export default function Fahrzeugschaeden({ vorgang, aktualisiereVorgang, isLoading }: Props) {
  const { gruppenVonMandant } = useUser();

  const [altschadenbauteileAuswahl, setAltschadenbauteileAuswahl] = useState<string[]>([]);
  const [vorschadenbauteileAuswahl, setVorschadenbauteileAuswahl] = useState<string[]>([]);

  useEffect(() => {
    if (vorgang?.fahrzeugdaten?.altschaden?.schadenbereiche) {
      const tempbauteile = ermittleSchadenbereiche(vorgang.fahrzeugdaten.altschaden.schadenbereiche);
      setAltschadenbauteileAuswahl(tempbauteile);
    }
  }, [vorgang]);

  useEffect(() => {
    if (vorgang?.fahrzeugdaten?.reparierteVorschaeden?.schadenbereiche) {
      const tempbauteile = ermittleSchadenbereiche(vorgang?.fahrzeugdaten?.reparierteVorschaeden?.schadenbereiche);
      setVorschadenbauteileAuswahl(tempbauteile);
    }
  }, [vorgang]);

  const aktualisiereFahrzeugdaten = (attribut: keyof Fahrzeugdaten, wert: any) => {
    aktualisiereVorgang({
      fahrzeugdaten: {
        ...vorgang.fahrzeugdaten,
        [attribut]: wert
      }
    });
  };

  const algemeinzustaende = Object.entries(ALLGEMEINZUSTAND_TEXTE).map(([value, label]) => ({ label, value }));

  const altschaeden = Object.entries(ALTSCHAEDEN_TEXTE).map(([value, label]) => ({ label, value }));

  const reparierteVorschaeden = Object.entries(REPARIERTE_VORSCHADEN_TEXTE).map(([value, label]) => ({ label, value }));

  const reparaturArt = Object.entries(REPARATURART_TEXTE).map(([value, label]) => ({ label, value }));

  const ermittleSchadenbereiche = (schadenbereiche: Schadenbereiche): string[] => {
    return Object.entries(schadenbereiche).reduce((bauteile, [key, value]) => {
      if (value) {
        bauteile.push(...(Bauteile.find((bauteil) => bauteil.bedingung === key)?.bauteile ?? []));
      }
      return bauteile;
    }, [] as string[]);
  };

  const speichereAltschaden = (value: Altschaden) => {
    let newValue = {
      ...(vorgang.fahrzeugdaten?.altschaden ? vorgang.fahrzeugdaten.altschaden : {}),
      ...value
    };
    if (newValue.schadenVorhandenAuswahl && newValue.schadenVorhandenAuswahl !== ALTSCHAEDEN_VORHANDEN) {
      newValue = {
        schadenbereiche: {
          vorneLinks: false,
          vorneMittig: false,
          vorneRechts: false,
          tuerVorneLinks: false,
          tuerVorneRechts: false,
          tuerHintenLinks: false,
          tuerHintenRechts: false,
          dach: false,
          hintenLinks: false,
          hintenMittig: false,
          hintenRechts: false
        },
        schadenVorhandenAuswahl: value.schadenVorhandenAuswahl,
        schaeden: []
      };
    }

    aktualisiereFahrzeugdaten('altschaden', newValue);
  };

  const speichereRepVorschaden = (value: ReparierteVorschaden) => {
    let newValue = {
      ...(vorgang.fahrzeugdaten?.reparierteVorschaeden ? vorgang.fahrzeugdaten.reparierteVorschaeden : {}),
      ...value
    };

    if (value?.schadenVorhandenAuswahl && value.schadenVorhandenAuswahl !== ReparierteVorschadenEnum.REPARIERTE_VORSCHAEDEN_VORHANDEN) {
      newValue = {
        schadenVorhandenAuswahl: newValue.schadenVorhandenAuswahl,
        schadenbereiche: {
          vorneLinks: false,
          vorneMittig: false,
          vorneRechts: false,
          tuerVorneLinks: false,
          tuerVorneRechts: false,
          tuerHintenLinks: false,
          tuerHintenRechts: false,
          dach: false,
          hintenLinks: false,
          hintenMittig: false,
          hintenRechts: false
        },
        vorschaeden: []
      };
    }
    aktualisiereFahrzeugdaten('reparierteVorschaeden', newValue);
  };

  const handleAltschadenHizufuegen = () => {
    speichereAltschaden({
      schaeden: [
        ...(vorgang.fahrzeugdaten?.altschaden?.schaeden ? vorgang.fahrzeugdaten.altschaden.schaeden : []),
        { bauteil: bauteilAuswahl, beschaedigung: beschaedigungenAuswahl }
      ]
    });
    setBauteilAuswahl('');
    setBeschaedigungenAuswahl('');
  };

  const handleVorschadenHizufuegen = () => {
    speichereRepVorschaden({
      vorschaeden: [
        ...(vorgang.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden ? vorgang.fahrzeugdaten.reparierteVorschaeden.vorschaeden : []),
        {
          bauteil: vorschadenbauteilAuswahl,
          reparaturart: reparaturArtAuswahl,
          sachFachgerecht: sachUndFachgerechtAuswahl,
          lackschichtendickenmessung
        }
      ]
    });
    setVorschadenbauteilAuswahl('');
    setSachUndFachgerechtAuswahl('');
    setReparaturArtAuswahl('');
    setLackschichtendickenmessung('');
  };

  const handleDeleteAltschaden = (index: number) => {
    const neuSchaedenliste = [...(vorgang.fahrzeugdaten?.altschaden?.schaeden ? vorgang.fahrzeugdaten.altschaden.schaeden : [])];
    if (neuSchaedenliste && neuSchaedenliste.length > index) {
      neuSchaedenliste.splice(index, 1);
    }

    speichereAltschaden({
      schaeden: neuSchaedenliste
    });
  };

  const handleEditAltschaden = (index: number) => {
    if (!vorgang?.fahrzeugdaten?.altschaden?.schaeden) {
      return;
    }
    setAltschadenButtonText(SPEICHERN_TEXT);
    setAltschadenEditIndex(index);
    const schaden = vorgang.fahrzeugdaten.altschaden.schaeden[index];
    setBauteilAuswahl(schaden?.bauteil ?? '');
    setBeschaedigungenAuswahl(schaden?.beschaedigung ?? '');
  };

  const speichereAltschadenAenderungen = () => {
    if (!vorgang?.fahrzeugdaten?.altschaden?.schaeden || altschadenEditIndex === null || altschadenEditIndex === undefined) {
      return;
    }
    vorgang.fahrzeugdaten.altschaden.schaeden[altschadenEditIndex] = {
      bauteil: bauteilAuswahl,
      beschaedigung: beschaedigungenAuswahl
    };
    speichereAltschaden({
      schaeden: [...vorgang.fahrzeugdaten.altschaden.schaeden]
    });
    setzeEingabeAltschadenZurueck();
  };

  const setzeEingabeAltschadenZurueck = () => {
    setAltschadenButtonText(ANLEGEN_TEXT);
    setAltschadenEditIndex(null);
    setBauteilAuswahl('');
    setBeschaedigungenAuswahl('');
  };

  const handleEditVorschaden = (index: number) => {
    if (!vorgang?.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden) {
      return;
    }
    setVorschadenButtonText(SPEICHERN_TEXT);
    setVorschadenEditIndex(index);
    const schaden = vorgang.fahrzeugdaten.reparierteVorschaeden.vorschaeden[index];
    setVorschadenbauteilAuswahl(schaden?.bauteil ?? '');
    setSachUndFachgerechtAuswahl(schaden?.sachFachgerecht ?? '');
    setReparaturArtAuswahl(schaden?.reparaturart ?? '');
    setLackschichtendickenmessung(schaden?.lackschichtendickenmessung ?? '');
  };

  const speichereVorschadenAenderungen = () => {
    if (!vorgang?.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden || vorschadenEditIndex === null || vorschadenEditIndex === undefined) {
      return;
    }
    vorgang.fahrzeugdaten.reparierteVorschaeden.vorschaeden[vorschadenEditIndex] = {
      bauteil: vorschadenbauteilAuswahl,
      reparaturart: reparaturArtAuswahl,
      sachFachgerecht: sachUndFachgerechtAuswahl,
      lackschichtendickenmessung
    };
    speichereRepVorschaden({
      vorschaeden: [...vorgang.fahrzeugdaten.reparierteVorschaeden.vorschaeden]
    });
    setzeEingabeVorschadenZurueck();
  };

  const setzeEingabeVorschadenZurueck = () => {
    setVorschadenButtonText(ANLEGEN_TEXT);
    setVorschadenEditIndex(null);
    setVorschadenbauteilAuswahl('');
    setSachUndFachgerechtAuswahl('');
    setReparaturArtAuswahl('');
    setLackschichtendickenmessung('');
  };

  const handleDeleteVorschaden = (index: number) => {
    const neuSchaedenliste = [...(vorgang.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden ? vorgang.fahrzeugdaten.reparierteVorschaeden.vorschaeden : [])];
    if (neuSchaedenliste && neuSchaedenliste.length > index) {
      neuSchaedenliste.splice(index, 1);
    }

    speichereRepVorschaden({
      vorschaeden: neuSchaedenliste
    });
  };

  const [bauteilAuswahl, setBauteilAuswahl] = useState('');
  const [beschaedigungenAuswahl, setBeschaedigungenAuswahl] = useState('');

  const [vorschadenbauteilAuswahl, setVorschadenbauteilAuswahl] = useState('');
  const [sachUndFachgerechtAuswahl, setSachUndFachgerechtAuswahl] = useState('');
  const [reparaturArtAuswahl, setReparaturArtAuswahl] = useState<string>('');
  const [lackschichtendickenmessung, setLackschichtendickenmessung] = useState('');
  const [altschadenButtonText, setAltschadenButtonText] = useState(ANLEGEN_TEXT);
  const [altschadenEditIndex, setAltschadenEditIndex] = useState<number | null>(null);
  const [vorschadenButtonText, setVorschadenButtonText] = useState(ANLEGEN_TEXT);
  const [vorschadenEditIndex, setVorschadenEditIndex] = useState<number | null>(null);

  const altschaedenVorhanden =
    !!vorgang?.fahrzeugdaten?.altschaden?.schadenVorhandenAuswahl && vorgang.fahrzeugdaten.altschaden.schadenVorhandenAuswahl === ALTSCHAEDEN_VORHANDEN;

  const vorschaedenVorhanden =
    !!vorgang?.fahrzeugdaten?.reparierteVorschaeden?.schadenVorhandenAuswahl &&
    vorgang.fahrzeugdaten.reparierteVorschaeden.schadenVorhandenAuswahl === ReparierteVorschadenEnum.REPARIERTE_VORSCHAEDEN_VORHANDEN;

  const darfAltschadenErfassen = aktionErlaubt(AKTION_FAHRZEUGDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const isDisabled = isLoading || !!vorgang.wurdeGutachtenVersandt || !darfAltschadenErfassen;

  return (
    <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
      <Grid item xs={12}>
        <FormControl variant="standard">
          <InputLabel>Allgemeinzustand</InputLabel>
          <Select
            variant="standard"
            label="Allgemeinzustand"
            value={vorgang?.fahrzeugdaten?.allgemeinzustand ?? Allgemeinzustand.DURCHSCHNITTLICHER_ZUSTAND}
            disabled={isDisabled}
            onChange={(event) => {
              aktualisiereFahrzeugdaten('allgemeinzustand', event.target.value);
            }}
            data-testid="fahrzeugdaten-allgemeinzustand"
          >
            {algemeinzustaende.map((b) => (
              <MenuItem value={b.value} key={b.value} data-testid={b.label}>
                {b.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS} alignItems="flex-start">
        {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
          <Grid item container xs={12} md={6} alignItems="center" spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <Schadenanzeige
                label="Altschaden"
                testid="altschaden"
                schadenVorhandenAuswahl={vorgang?.fahrzeugdaten?.altschaden?.schadenVorhandenAuswahl}
                selectOptions={altschaeden}
                schadenbereiche={vorgang?.fahrzeugdaten?.altschaden?.schadenbereiche}
                isDisabled={isDisabled}
                speichereAenderung={speichereAltschaden}
                autoAnzeigen={altschaedenVorhanden}
              />
            </Grid>
            {altschaedenVorhanden && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={isDisabled}
                    filterOptions={filterOptions}
                    freeSolo
                    options={altschadenbauteileAuswahl}
                    value={bauteilAuswahl}
                    onInputChange={(_, value: string) => setBauteilAuswahl(value)}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} label="Bauteil" disabled={isDisabled} fullWidth data-testid="altschaden-bauteil" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    disabled={isDisabled}
                    filterOptions={filterOptions}
                    freeSolo
                    options={beschaedigungen}
                    value={beschaedigungenAuswahl}
                    onInputChange={(_, value: string) => setBeschaedigungenAuswahl(value)}
                    renderInput={(params) => (
                      <TextField variant="standard" {...params} label="Beschädigung" disabled={isDisabled} fullWidth data-testid="altschaden-beschaedigung" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={!bauteilAuswahl || !beschaedigungenAuswahl || isDisabled}
                    onClick={altschadenEditIndex !== null ? speichereAltschadenAenderungen : handleAltschadenHizufuegen}
                    data-testid="altschaden-anlegen"
                  >
                    {altschadenButtonText}
                  </Button>
                </Grid>
                {altschadenEditIndex !== null && (
                  <Grid item xs={12}>
                    <Button fullWidth variant="contained" color="primary" onClick={setzeEingabeAltschadenZurueck} data-testid="altschaden-edit-abbrechen">
                      Abbrechen
                    </Button>
                  </Grid>
                )}
              </>
            )}
            {altschaedenVorhanden && vorgang?.fahrzeugdaten?.altschaden?.schaeden && vorgang?.fahrzeugdaten?.altschaden?.schaeden.length > 0 && (
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Bauteil</TableCell>
                        <TableCell>Beschaedigung</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vorgang?.fahrzeugdaten?.altschaden?.schaeden?.map((schaden, index: number) => (
                        <TableRow key={index}>
                          <TableCell data-testid={`altschaden-${index}-bauteil`}>{schaden?.bauteil}</TableCell>
                          <TableCell data-testid={`altschaden-${index}-beschaedigung`}>{schaden?.beschaedigung}</TableCell>
                          <TableCell align="right">
                            <IconButton data-testid={`altschaden-${index}-edit`} aria-label="edit" onClick={() => handleEditAltschaden(index)} size="large">
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              data-testid={`altschaden-${index}-delete`}
                              aria-label="delete"
                              onClick={() => handleDeleteAltschaden(index)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        )}

        <Grid item container xs={12} md={6} spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12}>
            <Schadenanzeige
              label="Reparierte Vorschäden"
              testid="vorschaden"
              schadenVorhandenAuswahl={vorgang?.fahrzeugdaten?.reparierteVorschaeden?.schadenVorhandenAuswahl}
              selectOptions={reparierteVorschaeden}
              schadenbereiche={vorgang?.fahrzeugdaten?.reparierteVorschaeden?.schadenbereiche}
              isDisabled={isDisabled}
              speichereAenderung={speichereRepVorschaden}
              autoAnzeigen={vorschaedenVorhanden}
            />
          </Grid>
          {vorschaedenVorhanden && (
            <>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={isDisabled}
                  filterOptions={filterOptions}
                  freeSolo
                  options={vorschadenbauteileAuswahl}
                  value={vorschadenbauteilAuswahl}
                  onInputChange={(_, value: string) => setVorschadenbauteilAuswahl(value)}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} label="Bauteil" disabled={isDisabled} fullWidth data-testid="vorschaden-bauteil" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl variant="standard" fullWidth>
                  <InputLabel>Reparaturart</InputLabel>
                  <Select
                    variant="standard"
                    value={reparaturArtAuswahl}
                    onChange={(event) => {
                      setReparaturArtAuswahl(event.target.value);
                    }}
                    disabled={isDisabled}
                    data-testid={`vorschaden-reparaturart`}
                  >
                    {reparaturArt.map((g) => (
                      <MenuItem key={g.value} value={g.value} data-testid={`${g.label}`}>
                        {g.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Autocomplete
                  disabled={isDisabled}
                  freeSolo
                  options={['sach- und fachgerecht repariert', 'Nicht sach- und fachgerecht repariert']}
                  value={sachUndFachgerechtAuswahl}
                  onInputChange={(_, value: string) => setSachUndFachgerechtAuswahl(value)}
                  renderInput={(params) => (
                    <TextField variant="standard" {...params} label="Reparatur" disabled={isDisabled} fullWidth data-testid="vorschaden-reparatur" />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="standard"
                  label="Lackschichtendickenmessung"
                  value={lackschichtendickenmessung}
                  disabled={isDisabled}
                  onChange={(event) => setLackschichtendickenmessung(event.target.value.trim())}
                  fullWidth
                  data-testid="vorschaden-lackschichtendickenmessung"
                  InputProps={{
                    endAdornment: <InputAdornment position="start">&micro;m</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  disabled={!vorschadenbauteilAuswahl || !sachUndFachgerechtAuswahl || !reparaturArtAuswahl || isDisabled}
                  onClick={vorschadenEditIndex !== null ? speichereVorschadenAenderungen : handleVorschadenHizufuegen}
                  data-testid="vorschaden-anlegen"
                >
                  {vorschadenButtonText}
                </Button>
              </Grid>
              {vorschadenEditIndex !== null && (
                <Grid item xs={12}>
                  <Button fullWidth variant="contained" color="primary" onClick={setzeEingabeVorschadenZurueck} data-testid="vorschaden-edit-abbrechen">
                    Abbrechen
                  </Button>
                </Grid>
              )}
            </>
          )}
          {vorschaedenVorhanden &&
            vorgang?.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden &&
            vorgang?.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden.length > 0 && (
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table size="small" aria-label="a dense table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Bauteil</TableCell>
                        <TableCell>Reparaturart</TableCell>
                        <TableCell>Reparatur</TableCell>
                        <TableCell>Lackschichtendickenmessung</TableCell>
                        <TableCell />
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {vorgang?.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden?.map((schaden, index: number) => (
                        <TableRow key={index}>
                          <TableCell data-testid={`vorschaden-${index}-bauteil`}>{schaden?.bauteil}</TableCell>
                          <TableCell data-testid={`vorschaden-${index}-reparaturart`}>
                            {schaden?.reparaturart && REPARATURART_TEXTE[schaden.reparaturart]}
                          </TableCell>
                          <TableCell data-testid={`vorschaden-${index}-fachgerecht`}>{schaden?.sachFachgerecht}</TableCell>
                          <TableCell data-testid={`vorschaden-${index}-lackschichtendickenmessung`}>
                            {schaden?.lackschichtendickenmessung && <>{schaden.lackschichtendickenmessung} &micro;m</>}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton data-testid={`vorschaden-${index}-edit`} aria-label="edit" onClick={() => handleEditVorschaden(index)} size="large">
                              <EditIcon />
                            </IconButton>
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              data-testid={`vorschaden-${index}-delete`}
                              aria-label="delete"
                              onClick={() => handleDeleteVorschaden(index)}
                              size="large"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          variant="standard"
          label="Bemerkungen zum Fahrzeugzustand"
          multiline
          defaultValue={vorgang?.fahrzeugdaten?.bemerkungen ?? ''}
          disabled={isDisabled}
          onChange={(event) => {
            aktualisiereFahrzeugdaten('bemerkungen', event.target.value);
          }}
          fullWidth
          inputProps={{
            'data-testid': 'vorschaden-bemerkung'
          }}
        />
      </Grid>
    </Grid>
  );
}
