import React from 'react';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import DeleteIcon from '@mui/icons-material/Delete';

type Props = {
  readonly anchorEl: (EventTarget & HTMLButtonElement) | null;
  readonly onClose: () => void;
  readonly onOpenAngebotLoeschenBestaetigungsdialog: () => void;
};

export function AngebotMenu({ anchorEl, onClose, onOpenAngebotLoeschenBestaetigungsdialog }: Props): JSX.Element {
  return (
    <Menu
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={onClose}
    >
      <MenuItem onClick={onOpenAngebotLoeschenBestaetigungsdialog}>
        <ListItemIcon>
          <DeleteIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Löschen" />
      </MenuItem>
    </Menu>
  );
}
