/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const legeVorgangAn = /* GraphQL */ `
  mutation LegeVorgangAn($mandant: String!) {
    legeVorgangAn(mandant: $mandant) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const erzeugeReparaturnachweis = /* GraphQL */ `
  mutation ErzeugeReparaturnachweis(
    $mandant: String!
    $vorgangsnummer: String
  ) {
    erzeugeReparaturnachweis(
      mandant: $mandant
      vorgangsnummer: $vorgangsnummer
    ) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const aktualisiereVorgang = /* GraphQL */ `
  mutation AktualisiereVorgang($vorgang: AWSJSON!) {
    aktualisiereVorgang(vorgang: $vorgang) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const aktualisiereDATDaten = /* GraphQL */ `
  mutation AktualisiereDATDaten(
    $vorgangId: String
    $contractId: String
    $datECode: String
  ) {
    aktualisiereDATDaten(
      vorgangId: $vorgangId
      contractId: $contractId
      datECode: $datECode
    ) {
      ok
      error
    }
  }
`;
export const weiseBearbeiterZu = /* GraphQL */ `
  mutation WeiseBearbeiterZu($vorgangId: ID!, $neuerBearbeiter: ID!) {
    weiseBearbeiterZu(
      vorgangId: $vorgangId
      neuerBearbeiter: $neuerBearbeiter
    ) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const setzeStatus = /* GraphQL */ `
  mutation SetzeStatus($vorgangId: ID!, $status: String!, $bearbeiter: String) {
    setzeStatus(
      vorgangId: $vorgangId
      status: $status
      bearbeiter: $bearbeiter
    ) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const leisteUnterschrift = /* GraphQL */ `
  mutation LeisteUnterschrift(
    $vorgangId: ID!
    $typ: String!
    $key: String!
    $datum: String!
    $unterzeichner: Unterzeichner!
  ) {
    leisteUnterschrift(
      vorgangId: $vorgangId
      typ: $typ
      key: $key
      datum: $datum
      unterzeichner: $unterzeichner
    ) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const entferneUnterschrift = /* GraphQL */ `
  mutation EntferneUnterschrift($vorgangId: ID!, $typ: String!) {
    entferneUnterschrift(vorgangId: $vorgangId, typ: $typ) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const generiereHisDokument = /* GraphQL */ `
  mutation GeneriereHisDokument($vorgangId: ID!) {
    generiereHisDokument(vorgangId: $vorgangId) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const generiereGutachten = /* GraphQL */ `
  mutation GeneriereGutachten($vorgangId: ID!, $textbausteine: [AWSJSON!]!) {
    generiereGutachten(vorgangId: $vorgangId, textbausteine: $textbausteine)
  }
`;
export const generiereFahrzeugbewertung = /* GraphQL */ `
  mutation GeneriereFahrzeugbewertung($vorgangId: ID!) {
    generiereFahrzeugbewertung(vorgangId: $vorgangId) {
      gutachtennummer
      key
      dateiname
      keyDruckversion
      dateinameDruckversion
      erstellungsdatum
      generierungsstatus
      generierungsfehler
    }
  }
`;
export const generiereReparaturnachweis = /* GraphQL */ `
  mutation GeneriereReparaturnachweis($vorgangId: ID!) {
    generiereReparaturnachweis(vorgangId: $vorgangId)
  }
`;
export const versendeGutachten = /* GraphQL */ `
  mutation VersendeGutachten(
    $vorgangId: ID!
    $gutachtennummer: String!
    $empfaenger: [EmailEmpfaenger]!
  ) {
    versendeGutachten(
      vorgangId: $vorgangId
      gutachtennummer: $gutachtennummer
      empfaenger: $empfaenger
    )
  }
`;
export const versendeHis = /* GraphQL */ `
  mutation VersendeHis(
    $vorgangId: ID!
    $emailAdressen: [String]!
    $vorname: String
    $nachname: String
  ) {
    versendeHis(
      vorgangId: $vorgangId
      emailAdressen: $emailAdressen
      vorname: $vorname
      nachname: $nachname
    ) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const aktualisiereVehicle = /* GraphQL */ `
  mutation AktualisiereVehicle($vorgangId: ID!, $vehicle: String) {
    aktualisiereVehicle(vorgangId: $vorgangId, vehicle: $vehicle) {
      ok
      data
      error
    }
  }
`;
export const identifiziereFahrzeug = /* GraphQL */ `
  mutation IdentifiziereFahrzeug(
    $vorgangsnummer: String
    $fahrgestellnummer: String
    $erstesZulassungsdatum: AWSDate
    $mandant: String
  ) {
    identifiziereFahrzeug(
      vorgangsnummer: $vorgangsnummer
      fahrgestellnummer: $fahrgestellnummer
      erstesZulassungsdatum: $erstesZulassungsdatum
      mandant: $mandant
    ) {
      ok
      data
      error
    }
  }
`;
export const synchronisiereMitDat = /* GraphQL */ `
  mutation SynchronisiereMitDat($vorgangId: ID!) {
    synchronisiereMitDat(vorgangId: $vorgangId) {
      ok
      data {
        id
        vorgangsnummer
        mandant
        status
        statusLetzteAenderung
        aufgaben {
          finaleDatenerfassung
          bildbearbeitung
          kalkulationserstellung
          wiederbeschaffungswertUndSchadensumfang
          merkantilerMinderwert
          restwert
          wertverbesserung
          textbearbeitung
        }
        userId
        bearbeiter {
          id
          sub
          kuerzel
          mandant
          given_name
          family_name
          email
        }
        gruppen
        ehemaligeBearbeiter
        gutachtenart
        besichtigungen {
          items {
            id
            vorgangId
            strasse
            plz
            ort
            vermittlerId
            vermittler {
              id
              userId
              gruppen
              mandant
              weitereBearbeiter
              typ
              firmenname
              anrede
              nachname
              vorname
              strasse
              plz
              ort
              geburtsdatum
              nationalitaet
              telefon
              email
              updatedAt
              createdAt
            }
            besichtigungstermin
            demontierterZustand
            besichtigtVonUserId
            besichtigtVonVorname
            besichtigtVonNachname
            besichtigtVonStrasse
            besichtigtVonPlz
            besichtigtVonOrt
            besichtigtVonLand
            entfernungInKm
            userId
            ehemaligeBearbeiter
            gruppen
            updatedAt
            createdAt
          }
          nextToken
        }
        auftraggeberId
        auftraggeber {
          id
          userId
          gruppen
          mandant
          weitereBearbeiter
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          geburtsdatum
          nationalitaet
          telefon
          email
          updatedAt
          createdAt
        }
        auftraggeberRechtsschutzVorhanden
        auftraggeberVorsteuerabzugsberechtigt
        auftraggeberPostversand
        auftraggeberVip
        fahrzeughalterId
        fahrzeughalter {
          id
          userId
          gruppen
          mandant
          weitereBearbeiter
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          geburtsdatum
          nationalitaet
          telefon
          email
          updatedAt
          createdAt
        }
        fahrzeughalterIstAuftraggeber
        fahrzeughalterRechtsschutzVorhanden
        fahrzeugdaten {
          fahrgestellnummer
          kilometerleistung
          kilometerleistungGeschaetzt
          kilometerleistungAngegeben
          land
          kennzeichen
          erstesZulassungsdatum
          letztesZulassungsdatum
          naechsteHauptuntersuchung
          anzahlVorhalter
          scheckheftgepflegt
          regelbesteuert
          bereifung
          fahrzeugtyp
          achsen {
            achsenPosition
            reifengroesse
            typ
            hersteller
            reifen {
              position
              profiltiefe
            }
          }
          allgemeinzustand
          altschaden {
            schadenVorhandenAuswahl
            schadenbereiche {
              vorneLinks
              vorneMittig
              vorneRechts
              tuerVorneLinks
              tuerVorneRechts
              tuerHintenLinks
              tuerHintenRechts
              dach
              hintenLinks
              hintenMittig
              hintenRechts
            }
            schaeden {
              bauteil
              beschaedigung
            }
          }
          reparierteVorschaeden {
            schadenVorhandenAuswahl
            schadenbereiche {
              vorneLinks
              vorneMittig
              vorneRechts
              tuerVorneLinks
              tuerVorneRechts
              tuerHintenLinks
              tuerHintenRechts
              dach
              hintenLinks
              hintenMittig
              hintenRechts
            }
            vorschaeden {
              bauteil
              sachFachgerecht
              lackschichtendickenmessung
              reparaturart
            }
          }
          bemerkungen
          zulassungsbescheinigung
          reifenzustand
          mietwagenklasse
          nutzungsausfallKlasse
          nutzungsausfallKlasseOhneFahrzeugAlter
          nutzungsausfallOhneAltersbezug
          phantomkalkulation
          anzahlSitze
          anzahlTueren
          antriebsart
          aufbauart
          hubraum
          leistungKw
          farbe
          container
          containerName
          herstellername
          haupttypname
          untertypname
          fahrzeugartname
          serienausstattung
          sonderausstattung
          zusatzausstattung
          reparierteSchadenbereiche {
            baugruppe
            beschreibung
            reparaturart
          }
          lackPositionen {
            beschreibung
            lackstufe
          }
          arbeitsPositionen {
            beschreibung
            reparaturart
          }
          materialPositionen {
            beschreibung
            preisProEinheit
          }
        }
        bewertung {
          wertverbesserung
          restwert
          angebote {
            firmenname
            strasse
            plz
            ort
            telefon
            wert
            key
          }
          merkantilerMinderwert
          neupreis
          wiederbeschaffungswert
          reparaturkosten
          veraeusserungswert
          fahrzeugalter
          schadenumfang
          faktorMarktgaengigkeit
          faktorVorschaeden
          lohnkosten
          materialkosten
          bemerkung
          wiederbeschaffungsdauer
          reparaturdauer
          korrekturWiederbeschaffungswert
          versandinformation {
            versanddatum
            empfaenger
          }
        }
        stundenverrechnungssaetze {
          typ
          mechanik
          karosserie
          lackierung
          elektrik
          aufschlagLackmaterial
          aufschlagErsatzteil
          verbringungszeit
          lackart
          inklusiveMaterial
          lacquerMethod
        }
        unfallgegnerId
        unfallgegner {
          id
          userId
          gruppen
          mandant
          weitereBearbeiter
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          geburtsdatum
          nationalitaet
          telefon
          email
          updatedAt
          createdAt
        }
        unfalldaten {
          land
          unfallort
          datum
          kennzeichen
          fahrzeughersteller
          fahrzeugart
          sonstigeFahrzeugart
          versicherungId
          versicherungsnummer
          schadennummer
          selbstbeteiligung
        }
        dat {
          dossierId
          contractId
          datECode
          letzteAktualisierung
          calculationDocument {
            key
            dateiname
            uploaddatum
          }
          vehicleEvaluationDocument {
            key
            dateiname
            uploaddatum
          }
          arbeitswerte
          wertverbesserung
          reparaturkostenBrutto
          lohnkosten
          materialkosten
          anzahlSitze
          anzahlTueren
          antriebsart
          aufbauart
          hubraum
          leistungKw
          farbe
          kennzeichen
          kilometerleistung
          fahrgestellnummer
          wiederbeschaffungswertBrutto
          listenneupreisBrutto
          listenausstattungspreisBrutto
          neupreisBrutto
          vehicleType
          manufacturer
          baseModel
        }
        reparaturdauer {
          arbeitswerteProStunde
          materialbeschaffungsdauer
        }
        unterschriften {
          dsgvo {
            key
            datum
            unterzeichner
          }
          abtretungserklaerung {
            key
            datum
            unterzeichner
          }
          his {
            key
            datum
            unterzeichner
          }
          schlussbemerkung {
            key
            datum
            unterzeichner
          }
          auftragsbestaetigung {
            key
            datum
            unterzeichner
          }
        }
        fotos {
          key
          dateiname
          kategorien
          typ
          thumbnailname
          thumbnailkey
          uploaddatum
          beschreibung
        }
        dateien {
          key
          dateiname
          kategorien
          typ
          uploaddatum
          restwertangebot
          wert
        }
        bemerkungenZumSchaden {
          fahrbereit
          verkehrssicherNachNotreparatur
          verkehrssicher
          pruefarbeitenErforderlich
          achsvermessungErforderlich
          achsvermessungDurchgefuehrt
          achsvermessungMitSchadenfeststellung
          lenkgetriebeErneuern
          achsweiseErneuerungDerReifen
          achsweiseErneuerungDerStossdaempfer
          karosserievermessungErforderlich
          richtbankErforderlich
          beilackierungNotwendig
          anhaengerkupplungAusSicherheitsgruendenErneuern
          schadenhergang
          plausibilitaet
          factoring
          finanzierungMoeglich
          reparaturnachweisAuswahl
          probefahrtErforderlich
          fehlerspeicherAuslesen
          bemerkungen
        }
        gutachten {
          gutachtennummer
          key
          dateiname
          keyDruckversion
          dateinameDruckversion
          erstellungsdatum
          generierungsstatus
          generierungsfehler
        }
        rechtsanwaltId
        rechtsanwalt {
          id
          userId
          gruppen
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          telefon
          email
          updatedAt
          createdAt
        }
        werkstattId
        werkstatt {
          id
          userId
          gruppen
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          telefon
          email
          updatedAt
          createdAt
        }
        versicherungId
        versicherung {
          id
          userId
          gruppen
          typ
          firmenname
          anrede
          nachname
          vorname
          strasse
          plz
          ort
          telefon
          email
          updatedAt
          createdAt
        }
        entsorgen
        vorgangTextbausteine {
          ueberschrift
          text
          regel
          reihenfolge
          disabled
          id
        }
        wurdeGutachtenVersandt
        his {
          datei {
            key
            dateiname
            uploaddatum
          }
          wurdeHisVersand
          versandDatum
        }
        zugehoerigeGutachtenId
        zugehoerigeGutachtenNummer
        rechnungsnummern
        versandinformation {
          versanddatum
          empfaenger
        }
        zahlungseingang {
          id
          jahr
          importDatum
          rechnungsnummer
          betrag
          bezahldatum
          status
          name
          beschreibung
          gutachtennummer
        }
        verwaltungscheckbox
        geloescht
        updatedAt
        createdAt
      }
      error
    }
  }
`;
export const aktualisiereDatZugangsdaten = /* GraphQL */ `
  mutation AktualisiereDatZugangsdaten(
    $datZugangsdaten: AWSJSON!
    $mandant: String!
  ) {
    aktualisiereDatZugangsdaten(
      datZugangsdaten: $datZugangsdaten
      mandant: $mandant
    ) {
      ok
      data
      error
    }
  }
`;
export const aktualisiereTextbausteineZuGutachtenart = /* GraphQL */ `
  mutation AktualisiereTextbausteineZuGutachtenart(
    $gutachtenart: String!
    $textbausteine: [AWSJSON!]!
    $mandant: String!
  ) {
    aktualisiereTextbausteineZuGutachtenart(
      gutachtenart: $gutachtenart
      textbausteine: $textbausteine
      mandant: $mandant
    )
  }
`;
export const legeMandantAn = /* GraphQL */ `
  mutation LegeMandantAn($mandant: String!, $textbausteine: [AWSJSON]) {
    legeMandantAn(mandant: $mandant, textbausteine: $textbausteine)
  }
`;
export const loescheMandant = /* GraphQL */ `
  mutation LoescheMandant($mandant: String!) {
    loescheMandant(mandant: $mandant)
  }
`;
export const aktualisiereMandant = /* GraphQL */ `
  mutation AktualisiereMandant(
    $mandantId: String!
    $hisEmail: String
    $honorartabelle: [HonorarEintragInput]
  ) {
    aktualisiereMandant(
      mandantId: $mandantId
      hisEmail: $hisEmail
      honorartabelle: $honorartabelle
    )
  }
`;
export const versendeAngebotsanfrage = /* GraphQL */ `
  mutation VersendeAngebotsanfrage(
    $vorgangId: ID!
    $emails: [String]!
    $anAlleHaendler: Boolean
  ) {
    versendeAngebotsanfrage(
      vorgangId: $vorgangId
      emails: $emails
      anAlleHaendler: $anAlleHaendler
    )
  }
`;
export const aktualisierePerson = /* GraphQL */ `
  mutation AktualisierePerson($person: AWSJSON!) {
    aktualisierePerson(person: $person) {
      id
      userId
      gruppen
      mandant
      weitereBearbeiter
      typ
      firmenname
      anrede
      nachname
      vorname
      strasse
      plz
      ort
      geburtsdatum
      nationalitaet
      telefon
      email
      updatedAt
      createdAt
    }
  }
`;
export const legeUnternehmenAn = /* GraphQL */ `
  mutation LegeUnternehmenAn($unternehmen: AWSJSON!) {
    legeUnternehmenAn(unternehmen: $unternehmen) {
      id
      userId
      gruppen
      typ
      firmenname
      anrede
      nachname
      vorname
      strasse
      plz
      ort
      telefon
      email
      updatedAt
      createdAt
    }
  }
`;
export const createMandant = /* GraphQL */ `
  mutation CreateMandant(
    $input: CreateMandantInput!
    $condition: ModelMandantConditionInput
  ) {
    createMandant(input: $input, condition: $condition) {
      id
      hisEmail
      honorartabelle {
        schadenhoehe
        grundhonorar
      }
      updatedAt
      createdAt
    }
  }
`;
export const updateMandant = /* GraphQL */ `
  mutation UpdateMandant(
    $input: UpdateMandantInput!
    $condition: ModelMandantConditionInput
  ) {
    updateMandant(input: $input, condition: $condition) {
      id
      hisEmail
      honorartabelle {
        schadenhoehe
        grundhonorar
      }
      updatedAt
      createdAt
    }
  }
`;
export const deleteMandant = /* GraphQL */ `
  mutation DeleteMandant(
    $input: DeleteMandantInput!
    $condition: ModelMandantConditionInput
  ) {
    deleteMandant(input: $input, condition: $condition) {
      id
      hisEmail
      honorartabelle {
        schadenhoehe
        grundhonorar
      }
      updatedAt
      createdAt
    }
  }
`;
export const createUserRechte = /* GraphQL */ `
  mutation CreateUserRechte(
    $input: CreateUserRechteInput!
    $condition: ModelUserRechteConditionInput
  ) {
    createUserRechte(input: $input, condition: $condition) {
      id
      mandantenrechte {
        mandantenKuerzel
        rechte
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRechte = /* GraphQL */ `
  mutation UpdateUserRechte(
    $input: UpdateUserRechteInput!
    $condition: ModelUserRechteConditionInput
  ) {
    updateUserRechte(input: $input, condition: $condition) {
      id
      mandantenrechte {
        mandantenKuerzel
        rechte
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTextbaustein = /* GraphQL */ `
  mutation CreateTextbaustein(
    $input: CreateTextbausteinInput!
    $condition: ModelTextbausteinConditionInput
  ) {
    createTextbaustein(input: $input, condition: $condition) {
      id
      mandant
      gutachtenart
      ueberschrift
      text
      regel
      reihenfolge
      createdAt
      updatedAt
    }
  }
`;
export const updateTextbaustein = /* GraphQL */ `
  mutation UpdateTextbaustein(
    $input: UpdateTextbausteinInput!
    $condition: ModelTextbausteinConditionInput
  ) {
    updateTextbaustein(input: $input, condition: $condition) {
      id
      mandant
      gutachtenart
      ueberschrift
      text
      regel
      reihenfolge
      createdAt
      updatedAt
    }
  }
`;
export const deleteTextbaustein = /* GraphQL */ `
  mutation DeleteTextbaustein(
    $input: DeleteTextbausteinInput!
    $condition: ModelTextbausteinConditionInput
  ) {
    deleteTextbaustein(input: $input, condition: $condition) {
      id
      mandant
      gutachtenart
      ueberschrift
      text
      regel
      reihenfolge
      createdAt
      updatedAt
    }
  }
`;
export const deletePerson = /* GraphQL */ `
  mutation DeletePerson(
    $input: DeletePersonInput!
    $condition: ModelPersonConditionInput
  ) {
    deletePerson(input: $input, condition: $condition) {
      id
      userId
      gruppen
      mandant
      weitereBearbeiter
      typ
      firmenname
      anrede
      nachname
      vorname
      strasse
      plz
      ort
      geburtsdatum
      nationalitaet
      telefon
      email
      updatedAt
      createdAt
    }
  }
`;
export const createUnternehmen = /* GraphQL */ `
  mutation CreateUnternehmen(
    $input: CreateUnternehmenInput!
    $condition: ModelUnternehmenConditionInput
  ) {
    createUnternehmen(input: $input, condition: $condition) {
      id
      userId
      gruppen
      typ
      firmenname
      anrede
      nachname
      vorname
      strasse
      plz
      ort
      telefon
      email
      updatedAt
      createdAt
    }
  }
`;
export const updateUnternehmen = /* GraphQL */ `
  mutation UpdateUnternehmen(
    $input: UpdateUnternehmenInput!
    $condition: ModelUnternehmenConditionInput
  ) {
    updateUnternehmen(input: $input, condition: $condition) {
      id
      userId
      gruppen
      typ
      firmenname
      anrede
      nachname
      vorname
      strasse
      plz
      ort
      telefon
      email
      updatedAt
      createdAt
    }
  }
`;
export const deleteUnternehmen = /* GraphQL */ `
  mutation DeleteUnternehmen(
    $input: DeleteUnternehmenInput!
    $condition: ModelUnternehmenConditionInput
  ) {
    deleteUnternehmen(input: $input, condition: $condition) {
      id
      userId
      gruppen
      typ
      firmenname
      anrede
      nachname
      vorname
      strasse
      plz
      ort
      telefon
      email
      updatedAt
      createdAt
    }
  }
`;
export const createVorgang = /* GraphQL */ `
  mutation CreateVorgang(
    $input: CreateVorgangInput!
    $condition: ModelVorgangConditionInput
  ) {
    createVorgang(input: $input, condition: $condition) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
export const updateVorgang = /* GraphQL */ `
  mutation UpdateVorgang(
    $input: UpdateVorgangInput!
    $condition: ModelVorgangConditionInput
  ) {
    updateVorgang(input: $input, condition: $condition) {
      id
      vorgangsnummer
      mandant
      status
      statusLetzteAenderung
      aufgaben {
        finaleDatenerfassung
        bildbearbeitung
        kalkulationserstellung
        wiederbeschaffungswertUndSchadensumfang
        merkantilerMinderwert
        restwert
        wertverbesserung
        textbearbeitung
      }
      userId
      bearbeiter {
        id
        sub
        kuerzel
        mandant
        given_name
        family_name
        email
      }
      gruppen
      ehemaligeBearbeiter
      gutachtenart
      besichtigungen {
        items {
          id
          vorgangId
          strasse
          plz
          ort
          vermittlerId
          vermittler {
            id
            userId
            gruppen
            mandant
            weitereBearbeiter
            typ
            firmenname
            anrede
            nachname
            vorname
            strasse
            plz
            ort
            geburtsdatum
            nationalitaet
            telefon
            email
            updatedAt
            createdAt
          }
          besichtigungstermin
          demontierterZustand
          besichtigtVonUserId
          besichtigtVonVorname
          besichtigtVonNachname
          besichtigtVonStrasse
          besichtigtVonPlz
          besichtigtVonOrt
          besichtigtVonLand
          entfernungInKm
          userId
          ehemaligeBearbeiter
          gruppen
          updatedAt
          createdAt
        }
        nextToken
      }
      auftraggeberId
      auftraggeber {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      auftraggeberRechtsschutzVorhanden
      auftraggeberVorsteuerabzugsberechtigt
      auftraggeberPostversand
      auftraggeberVip
      fahrzeughalterId
      fahrzeughalter {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      fahrzeughalterIstAuftraggeber
      fahrzeughalterRechtsschutzVorhanden
      fahrzeugdaten {
        fahrgestellnummer
        kilometerleistung
        kilometerleistungGeschaetzt
        kilometerleistungAngegeben
        land
        kennzeichen
        erstesZulassungsdatum
        letztesZulassungsdatum
        naechsteHauptuntersuchung
        anzahlVorhalter
        scheckheftgepflegt
        regelbesteuert
        bereifung
        fahrzeugtyp
        achsen {
          achsenPosition
          reifengroesse
          typ
          hersteller
          reifen {
            position
            profiltiefe
          }
        }
        allgemeinzustand
        altschaden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          schaeden {
            bauteil
            beschaedigung
          }
        }
        reparierteVorschaeden {
          schadenVorhandenAuswahl
          schadenbereiche {
            vorneLinks
            vorneMittig
            vorneRechts
            tuerVorneLinks
            tuerVorneRechts
            tuerHintenLinks
            tuerHintenRechts
            dach
            hintenLinks
            hintenMittig
            hintenRechts
          }
          vorschaeden {
            bauteil
            sachFachgerecht
            lackschichtendickenmessung
            reparaturart
          }
        }
        bemerkungen
        zulassungsbescheinigung
        reifenzustand
        mietwagenklasse
        nutzungsausfallKlasse
        nutzungsausfallKlasseOhneFahrzeugAlter
        nutzungsausfallOhneAltersbezug
        phantomkalkulation
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        container
        containerName
        herstellername
        haupttypname
        untertypname
        fahrzeugartname
        serienausstattung
        sonderausstattung
        zusatzausstattung
        reparierteSchadenbereiche {
          baugruppe
          beschreibung
          reparaturart
        }
        lackPositionen {
          beschreibung
          lackstufe
        }
        arbeitsPositionen {
          beschreibung
          reparaturart
        }
        materialPositionen {
          beschreibung
          preisProEinheit
        }
      }
      bewertung {
        wertverbesserung
        restwert
        angebote {
          firmenname
          strasse
          plz
          ort
          telefon
          wert
          key
        }
        merkantilerMinderwert
        neupreis
        wiederbeschaffungswert
        reparaturkosten
        veraeusserungswert
        fahrzeugalter
        schadenumfang
        faktorMarktgaengigkeit
        faktorVorschaeden
        lohnkosten
        materialkosten
        bemerkung
        wiederbeschaffungsdauer
        reparaturdauer
        korrekturWiederbeschaffungswert
        versandinformation {
          versanddatum
          empfaenger
        }
      }
      stundenverrechnungssaetze {
        typ
        mechanik
        karosserie
        lackierung
        elektrik
        aufschlagLackmaterial
        aufschlagErsatzteil
        verbringungszeit
        lackart
        inklusiveMaterial
        lacquerMethod
      }
      unfallgegnerId
      unfallgegner {
        id
        userId
        gruppen
        mandant
        weitereBearbeiter
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        geburtsdatum
        nationalitaet
        telefon
        email
        updatedAt
        createdAt
      }
      unfalldaten {
        land
        unfallort
        datum
        kennzeichen
        fahrzeughersteller
        fahrzeugart
        sonstigeFahrzeugart
        versicherungId
        versicherungsnummer
        schadennummer
        selbstbeteiligung
      }
      dat {
        dossierId
        contractId
        datECode
        letzteAktualisierung
        calculationDocument {
          key
          dateiname
          uploaddatum
        }
        vehicleEvaluationDocument {
          key
          dateiname
          uploaddatum
        }
        arbeitswerte
        wertverbesserung
        reparaturkostenBrutto
        lohnkosten
        materialkosten
        anzahlSitze
        anzahlTueren
        antriebsart
        aufbauart
        hubraum
        leistungKw
        farbe
        kennzeichen
        kilometerleistung
        fahrgestellnummer
        wiederbeschaffungswertBrutto
        listenneupreisBrutto
        listenausstattungspreisBrutto
        neupreisBrutto
        vehicleType
        manufacturer
        baseModel
      }
      reparaturdauer {
        arbeitswerteProStunde
        materialbeschaffungsdauer
      }
      unterschriften {
        dsgvo {
          key
          datum
          unterzeichner
        }
        abtretungserklaerung {
          key
          datum
          unterzeichner
        }
        his {
          key
          datum
          unterzeichner
        }
        schlussbemerkung {
          key
          datum
          unterzeichner
        }
        auftragsbestaetigung {
          key
          datum
          unterzeichner
        }
      }
      fotos {
        key
        dateiname
        kategorien
        typ
        thumbnailname
        thumbnailkey
        uploaddatum
        beschreibung
      }
      dateien {
        key
        dateiname
        kategorien
        typ
        uploaddatum
        restwertangebot
        wert
      }
      bemerkungenZumSchaden {
        fahrbereit
        verkehrssicherNachNotreparatur
        verkehrssicher
        pruefarbeitenErforderlich
        achsvermessungErforderlich
        achsvermessungDurchgefuehrt
        achsvermessungMitSchadenfeststellung
        lenkgetriebeErneuern
        achsweiseErneuerungDerReifen
        achsweiseErneuerungDerStossdaempfer
        karosserievermessungErforderlich
        richtbankErforderlich
        beilackierungNotwendig
        anhaengerkupplungAusSicherheitsgruendenErneuern
        schadenhergang
        plausibilitaet
        factoring
        finanzierungMoeglich
        reparaturnachweisAuswahl
        probefahrtErforderlich
        fehlerspeicherAuslesen
        bemerkungen
      }
      gutachten {
        gutachtennummer
        key
        dateiname
        keyDruckversion
        dateinameDruckversion
        erstellungsdatum
        generierungsstatus
        generierungsfehler
      }
      rechtsanwaltId
      rechtsanwalt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      werkstattId
      werkstatt {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      versicherungId
      versicherung {
        id
        userId
        gruppen
        typ
        firmenname
        anrede
        nachname
        vorname
        strasse
        plz
        ort
        telefon
        email
        updatedAt
        createdAt
      }
      entsorgen
      vorgangTextbausteine {
        ueberschrift
        text
        regel
        reihenfolge
        disabled
        id
      }
      wurdeGutachtenVersandt
      his {
        datei {
          key
          dateiname
          uploaddatum
        }
        wurdeHisVersand
        versandDatum
      }
      zugehoerigeGutachtenId
      zugehoerigeGutachtenNummer
      rechnungsnummern
      versandinformation {
        versanddatum
        empfaenger
      }
      zahlungseingang {
        id
        jahr
        importDatum
        rechnungsnummer
        betrag
        bezahldatum
        status
        name
        beschreibung
        gutachtennummer
      }
      verwaltungscheckbox
      geloescht
      updatedAt
      createdAt
    }
  }
`;
