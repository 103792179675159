import React from 'react';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_BOXES } from '../../components/common/spacings';
import { Unfallgegner } from '../../components/Unfallgegner';
import { Rechtsanwalt } from '../../components/Rechtsanwalt';
import { Werkstatt } from '../../components/Werkstatt';
import { Unfalldaten } from '../../components/Unfalldaten';
import { Schadenhergang } from '../../components/Schadenhergang';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { Gutachtenart } from '../../shared/constants';

export default function UnfalldatenView(): JSX.Element {
  const { vorgang, setLoading, isLoading, speichereVorgang, aktualisiereVorgang, setzeVorgang } = useVorgangContext();

  return (
    <Grid container spacing={SPACING_BETWEEN_BOXES}>
      <Grid item xs={12} md={6}>
        <Unfalldaten
          vorgang={vorgang}
          setLoading={setLoading}
          isLoading={isLoading}
          speichereVorgang={speichereVorgang}
          aktualisiereVorgang={aktualisiereVorgang}
          setzeVorgang={setzeVorgang}
        />
      </Grid>
      {vorgang?.gutachtenart !== Gutachtenart.SCHADENSGUTACHTEN_KASKO && (
        <Grid item xs={12} md={6}>
          <Unfallgegner vorgang={vorgang} speichereVorgang={speichereVorgang} setzeVorgang={setzeVorgang} setLoading={setLoading} />
        </Grid>
      )}

      {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
        <Grid item xs={12} md={6}>
          <Schadenhergang vorgang={vorgang} isLoading={isLoading} aktualisiereVorgang={aktualisiereVorgang} />
        </Grid>
      )}

      <Grid item xs={12} md={6}>
        <Rechtsanwalt vorgang={vorgang} setzeVorgang={setzeVorgang} speichereVorgang={speichereVorgang} setLoading={setLoading} />
      </Grid>

      {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
        <Grid item xs={12} md={6}>
          <Werkstatt vorgang={vorgang} speichereVorgang={speichereVorgang} setzeVorgang={setzeVorgang} setLoading={setLoading} />
        </Grid>
      )}
    </Grid>
  );
}
