import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

interface Props {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const IntegerNumericFormat = React.forwardRef<NumericFormatProps, Props>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator=""
      allowLeadingZeros={false}
      decimalScale={0}
      fixedDecimalScale
      decimalSeparator=","
      valueIsNumericString
      allowNegative={false}
      allowedDecimalSeparators={[',']}
      onFocus={(event) => {
        event.target.select();
      }}
    />
  );
});

const FloatNumericFormat = React.forwardRef<NumericFormatProps, Props>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      fixedDecimalScale
      decimalSeparator=","
      valueIsNumericString
      allowLeadingZeros={false}
      allowNegative={false}
      allowedDecimalSeparators={[',']}
      onFocus={(event) => {
        event.target.select();
      }}
    />
  );
});

const EuroNumericFormat = React.forwardRef<NumericFormatProps, Props>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value
          }
        });
      }}
      thousandSeparator="."
      allowLeadingZeros={false}
      decimalScale={2}
      fixedDecimalScale
      decimalSeparator=","
      valueIsNumericString
      allowNegative={false}
      allowedDecimalSeparators={[',']}
      onFocus={(event) => {
        event.target.select();
      }}
    />
  );
});

export const EuroFormat = EuroNumericFormat as any;
export const FloatFormat = FloatNumericFormat as any;
export const IntegerFormat = IntegerNumericFormat as any;
