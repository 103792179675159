import React from 'react';
import Link from '@mui/material/Link';
import { Vorgang } from '../../types';
import { OptionsObject } from 'notistack';
import { Dateityp } from '../../shared/constants';

type Props = {
  readonly vorgang: Vorgang;
  readonly multiple: boolean;
  readonly typ: Dateityp;
};

export function DateiErfolgreichHochgeladenAlert({ vorgang, multiple, typ }: Props): JSX.Element {
  return (
    <span>
      {multiple ? 'Die' : 'Das'}&nbsp;
      <Link href={`/vorgang/${vorgang.id}/fotos`} underline="always" color="inherit">
        {typ === Dateityp.FOTO ? (multiple ? 'Fotos' : 'Foto') : multiple ? 'Dokumente' : 'Dokument'}
      </Link>
      &nbsp;{multiple ? 'wurden' : 'wurde'} erfolgreich hochgeladen.
    </span>
  );
}

export const SUCCESS_MESSAGE_AUTO_HIDE: OptionsObject = { variant: 'success' };

export const ERROR_MESSAGE: OptionsObject = {
  variant: 'error',
  autoHideDuration: null
};

export const WARNING_MESSAGE: OptionsObject = {
  variant: 'warning',
  autoHideDuration: null
};
