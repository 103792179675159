const Berechtigungen = `
,,Offen,Terminfestlegung,Besichtigung,Beweissicherung,Gutachtenausarbeitung,Qualitätssicherung,Druck/Versand,Mahnwesen,Abgeschlossen
Sachverstaendiger,DOKUMENT_HOCHLADEN,1,1,1,1,1,0,0,0,0
Qualitaetssicherung,DOKUMENT_HOCHLADEN,0,0,0,0,0,1,0,0,0
Verwaltung,DOKUMENT_HOCHLADEN,1,1,1,1,1,1,1,1,1
Admin,DOKUMENT_HOCHLADEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,DOKUMENT_KATEGORISIEREN,1,1,1,1,1,0,0,0,0
Qualitaetssicherung,DOKUMENT_KATEGORISIEREN,0,0,0,0,0,1,0,0,0
Verwaltung,DOKUMENT_KATEGORISIEREN,1,1,1,1,1,1,1,1,1
Admin,DOKUMENT_KATEGORISIEREN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,DOKUMENT_LOESCHEN,1,1,1,1,1,0,0,0,0
Qualitaetssicherung,DOKUMENT_LOESCHEN,0,0,0,0,0,1,0,0,0
Verwaltung,DOKUMENT_LOESCHEN,1,1,1,1,1,1,0,0,0
Admin,DOKUMENT_LOESCHEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,FAHRZEUGDATEN_ERFASSEN,1,1,1,1,1,0,0,0,0
Qualitaetssicherung,FAHRZEUGDATEN_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,FAHRZEUGDATEN_ERFASSEN,0,0,0,0,0,0,0,0,0
Admin,FAHRZEUGDATEN_ERFASSEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,RESTWERTANGEBOT_ERFASSEN,1,1,1,1,1,0,0,0,0
Qualitaetssicherung,RESTWERTANGEBOT_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,RESTWERTANGEBOT_ERFASSEN,1,1,1,1,1,0,1,1,1
Admin,RESTWERTANGEBOT_ERFASSEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,RESTWERTANGEBOT_HINZUFUEGEN,1,1,1,1,1,0,0,0,0
Qualitaetssicherung,RESTWERTANGEBOT_HINZUFUEGEN,0,0,0,0,0,1,0,0,0
Verwaltung,RESTWERTANGEBOT_HINZUFUEGEN,1,1,1,1,1,0,1,1,1
Admin,RESTWERTANGEBOT_HINZUFUEGEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,RESTWERTANGEBOT_EINHOLEN,0,0,0,0,0,1,1,1,1
Qualitaetssicherung,RESTWERTANGEBOT_EINHOLEN,0,0,0,0,0,1,1,1,1
Verwaltung,RESTWERTANGEBOT_EINHOLEN,0,0,0,0,0,1,1,1,1
Admin,RESTWERTANGEBOT_EINHOLEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,STATUS_WECHSELN_VOR,1,1,1,1,1,0,0,0,0
Qualitaetssicherung,STATUS_WECHSELN_VOR,0,0,0,0,0,1,0,0,0
Verwaltung,STATUS_WECHSELN_VOR,1,1,1,1,1,0,1,1,0
Admin,STATUS_WECHSELN_VOR,1,1,1,1,1,1,1,1,0
Sachverstaendiger,STATUS_WECHSELN_ZURUECK,0,1,1,1,1,0,0,0,0
Qualitaetssicherung,STATUS_WECHSELN_ZURUECK,0,0,0,0,0,1,0,0,0
Verwaltung,STATUS_WECHSELN_ZURUECK,0,1,1,1,1,0,1,1,1
Admin,STATUS_WECHSELN_ZURUECK,0,1,1,1,1,1,1,1,1
Sachverstaendiger,GUTACHTENART_AENDERN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,GUTACHTENART_AENDERN,0,0,0,0,0,1,0,0,0
Verwaltung,GUTACHTENART_AENDERN,1,1,1,0,1,1,0,0,0
Admin,GUTACHTENART_AENDERN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,SACHVERSTAENDIGER_AENDERN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,SACHVERSTAENDIGER_AENDERN,0,0,0,0,0,1,0,0,0
Verwaltung,SACHVERSTAENDIGER_AENDERN,1,1,1,0,1,1,0,0,0
Admin,SACHVERSTAENDIGER_AENDERN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,SCHADENBEMERKUNG_ERFASSEN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,SCHADENBEMERKUNG_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,SCHADENBEMERKUNG_ERFASSEN,1,1,1,0,1,0,0,0,0
Admin,SCHADENBEMERKUNG_ERFASSEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,BEWERTUNG_ERFASSEN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,BEWERTUNG_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,BEWERTUNG_ERFASSEN,1,1,1,0,1,0,0,0,0
Admin,BEWERTUNG_ERFASSEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,UNTERSCHRIFT_ERFASSEN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,UNTERSCHRIFT_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,UNTERSCHRIFT_ERFASSEN,1,1,1,0,1,0,0,0,0
Admin,UNTERSCHRIFT_ERFASSEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,KUNDENDATEN_ERFASSEN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,KUNDENDATEN_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,KUNDENDATEN_ERFASSEN,1,1,1,0,1,0,0,0,0
Admin,KUNDENDATEN_ERFASSEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,UNFALLDATEN_ERFASSEN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,UNFALLDATEN_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,UNFALLDATEN_ERFASSEN,1,1,1,0,1,0,0,0,0
Admin,UNFALLDATEN_ERFASSEN,1,1,1,1,1,1,1,1,1
Sachverstaendiger,FOTO_ERFASSEN,1,1,1,0,1,0,0,0,0
Qualitaetssicherung,FOTO_ERFASSEN,0,0,0,0,0,1,0,0,0
Verwaltung,FOTO_ERFASSEN,1,1,1,0,1,0,0,0,0
Admin,FOTO_ERFASSEN,1,1,1,1,1,1,1,1,1

`;

export default Berechtigungen;
