import { Allgemeinzustand, Gutachtenart } from '../constants';

export const GUTACHTENARTEN_TEXTE = {
  [Gutachtenart.SCHADENSGUTACHTEN_HAFTPFLICHT]: 'Haftpflicht',
  [Gutachtenart.SCHADENSGUTACHTEN_KASKO]: 'Kasko',
  [Gutachtenart.KURZGUTACHTEN]: 'Kurzgutachten',
  [Gutachtenart.REPARATURNACHWEIS]: 'Reparaturnachweis',
  [Gutachtenart.FAHRZEUGBEWERTUNG]: 'Fahrzeugbewertung'
};

export const ALLGEMEINZUSTAND_TEXTE: Record<string, string> = {
  [Allgemeinzustand.DURCHSCHNITTLICHER_ZUSTAND]: 'durchschnittlicher Zustand',
  [Allgemeinzustand.UEBERDURCHSCHNITTLICHER_ZUSTAND]: 'überdurchschnittlicher Zustand',
  [Allgemeinzustand.UNTERDURCHSCHNITTLICHER_ZUSTAND]: 'unterdurchschnittlicher Zustand'
};

export const REPARATURART_TEXTE: Record<string, string> = {
  REPARATURART_ERNEUERT: 'erneuert',
  REPARATURART_INSTANDGESETZT: 'instandgesetzt',
  REPARATURART_LACKIERT: 'lackiert'
};

// Wichtig!!! Die Reihenfolge nicht vertauschen
export const FOTOKATEGORIEN = [
  'Gutachtenrelevant',
  'Angebotsanfrage',
  'Fahrzeugschein',
  'Fahrzeugbrief',
  'Fahrgestellnummer',
  'Schaden',
  'Kilometerstand',
  'Reifengröße',
  'TÜV-Plakette',
  'Unfallmitteilung',
  'Altschäden',
  'Reparierte Vorschäden',
  'Scheckheft'
];

export const NUTZUNGSAUSFALL_KLASSE_ZU_WERT = {
  A: 23,
  B: 29,
  C: 35,
  D: 38,
  E: 43,
  F: 50,
  G: 59,
  H: 65,
  J: 79,
  K: 119,
  L: 175
};

export const MEHRWERTSTEUER = 19;
