import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, { useEffect, useState } from 'react';
import { EuroFormat } from '../common/inputFormats';
import { dialogActions } from './Dialog';
import InputAdornment from '@mui/material/InputAdornment';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onAdd: (kategorie: string, wert?: number | null) => void;
  readonly kategorien: string[];
  readonly text: string;
};

export function KategorieHinzufuegenDialog({ open, onClose, onAdd, kategorien, text }: Props): JSX.Element {
  const [kategorie, setKategorie] = useState(kategorien[0]);
  const [wert, setWert] = useState(0);

  useEffect(() => {
    setWert(0);
  }, [kategorie]);

  useEffect(() => {
    if (kategorien.length > 0) {
      setKategorie(kategorien[0]);
    }
  }, [kategorien]);
  const istFremdrechnung = kategorie === 'Fremdrechnung';
  const istWertBeiFremdrechnungVorhanden = (istFremdrechnung && wert > 0) || !istFremdrechnung;

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="kategorieHinzufuegen">
      <DialogTitle id="kategorieHinzufuegen">Kategorie hinzufügen</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>Kategorie</InputLabel>
              <Select
                variant="standard"
                label="Kategorie"
                value={kategorie ?? kategorien[0]}
                onChange={(event) => {
                  setKategorie(event.target.value);
                }}
                data-testid="dokument-kategorie-auswahl"
              >
                {kategorien.map((k) => (
                  <MenuItem key={k} value={k} data-testid={k}>
                    {k}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {istFremdrechnung && (
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="Nettowert"
                value={wert}
                onChange={(event) => setWert(Number(event.target.value))}
                fullWidth
                data-testid="fremdrechnung-nettowert"
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Abbrechen
        </Button>
        <Button onClick={() => onAdd(kategorie, wert > 0 ? wert : null)} variant="contained" color="primary" disabled={!istWertBeiFremdrechnungVorhanden}>
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
