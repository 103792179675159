import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { PersonenTyp } from '../../shared/constants';

const PERSONENTYP_TEXTE: Record<string, string> = {
  [PersonenTyp.PERSON]: 'Person',
  [PersonenTyp.UNFALLGEGNER]: 'Unfallgegner',
  [PersonenTyp.VERMITTLER]: 'Vermittler'
};

type Props = {
  readonly personentyp: PersonenTyp;
  readonly setPersonentyp: (personentyp: PersonenTyp) => void;
  readonly disabled?: boolean;
  readonly dataTestid?: string;
};

export default function PersonentypAuswahl({ personentyp, setPersonentyp, disabled = false, dataTestid = '' }: Props) {
  const personentypen = Object.entries(PERSONENTYP_TEXTE).map(([key, value]) => {
    return { label: value, value: key };
  });

  return (
    <Select
      variant="standard"
      disabled={disabled}
      value={personentyp}
      onChange={(event) => setPersonentyp(event.target.value as PersonenTyp)}
      data-testid={dataTestid}
    >
      {personentypen.map((typ, index) => {
        return (
          <MenuItem key={index} value={typ.value}>
            {typ.label}
          </MenuItem>
        );
      })}
    </Select>
  );
}
