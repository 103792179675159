import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

type Props = {
  readonly labelText: string | React.ReactNode;
  readonly value: boolean | null | undefined;
  readonly disabled: boolean;
  readonly fullWidth?: boolean;
  readonly onChange: (value: boolean) => void;
  readonly dataTestid: string;
};

export function YesNoFormControl({ labelText, value, disabled, fullWidth, onChange, dataTestid }: Props): JSX.Element {
  let selectedValue = '';

  if (value === true) {
    selectedValue = 'Ja';
  } else if (value === false) {
    selectedValue = 'Nein';
  }

  return (
    <FormControl variant="standard" fullWidth={fullWidth}>
      <InputLabel>{labelText}</InputLabel>
      <Select variant="standard" value={selectedValue} onChange={(event) => onChange(event.target.value === 'Ja')} data-testid={dataTestid} disabled={disabled}>
        <MenuItem value="Ja" data-testid={`${dataTestid}-Ja`}>
          Ja
        </MenuItem>
        <MenuItem value="Nein" data-testid={`${dataTestid}-Nein`}>
          Nein
        </MenuItem>
      </Select>
    </FormControl>
  );
}
