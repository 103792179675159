import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { AngebotLoeschenBestaetigungsdialog } from './dialog/AngebotLoeschenBestaetigungsdialog';
import { AngebotMenu } from './menu/AngebotMenu';
import { useUser } from '../hooks/useUser';
import { dokumente } from '../domain/dokumente';
import { isAenderbar } from '../domain/isAenderbar';
import { Angebot as AngebotTyp, Vorgang } from '../types';
import { Euro } from './common/Euro';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_RESTWERTANGEBOT_ERFASSEN } from '../frontendConstants';

type Props = {
  readonly vorgang: Vorgang;
  readonly angebot: AngebotTyp;
  readonly onDelete: (angebot: AngebotTyp) => Promise<void>;
};

export function Angebot({ vorgang, angebot, onDelete }: Props): JSX.Element {
  const url = useDateiUrl(angebot.key);
  const { gruppenVonMandant } = useUser();

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [angebotLoeschenBestaetigungsdialogOpen, setAngebotLoeschenBestaetigungsdialogOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAngebotLoeschenBestaetigungsdialog = () => {
    setAngebotLoeschenBestaetigungsdialogOpen(true);
    setAnchorEl(null);
  };

  const handleCloseAngebotLoeschenBestaetigungsdialog = () => {
    setAngebotLoeschenBestaetigungsdialogOpen(false);
  };

  const handleDelete = async () => {
    setAngebotLoeschenBestaetigungsdialogOpen(false);
    await onDelete(angebot);
  };

  const datei = dokumente(vorgang).find((dokument) => dokument.key === angebot.key);

  return (
    <>
      <TableRow data-testid="angebot">
        <TableCell>{angebot.firmenname}</TableCell>
        <TableCell>{angebot.strasse}</TableCell>
        <TableCell>{angebot.plz}</TableCell>
        <TableCell>{angebot.ort}</TableCell>
        <TableCell>{angebot.telefon}</TableCell>
        <TableCell>
          <Euro value={angebot.wert} />
        </TableCell>
        <TableCell>
          {url && (
            <Link href={url} underline="always" target="_blank" color="primary">
              <span>{datei?.dateiname}</span>
            </Link>
          )}
        </TableCell>
        <TableCell>
          <IconButton
            onClick={handleClick}
            disabled={!isAenderbar(vorgang) || !aktionErlaubt(AKTION_RESTWERTANGEBOT_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status)}
            size="large"
          >
            <MoreHorizIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <AngebotMenu anchorEl={anchorEl} onClose={handleMenuClose} onOpenAngebotLoeschenBestaetigungsdialog={handleOpenAngebotLoeschenBestaetigungsdialog} />
      <AngebotLoeschenBestaetigungsdialog
        open={angebotLoeschenBestaetigungsdialogOpen}
        onClose={handleCloseAngebotLoeschenBestaetigungsdialog}
        onDelete={handleDelete}
      />
    </>
  );
}
