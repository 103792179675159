import React, { useEffect, useState, useCallback } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import PersonenVerwaltung from '../../components/common/PersonenVerwaltung';
import Bestaetigungsdialog from '../../components/dialog/Bestaetigungsdialog';
import { useUser } from '../../hooks/useUser';
import MandantenAuswahl from '../../components/common/MandantenAuswahl';
import * as mutations from '../../graphql/mutations';
import * as queries from '../../graphql/queries';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import { Person, SuchePersonenArgs, SuchePersonenResponse } from '../../types';
import { COGNITO_GRUPPE_ADMIN, PersonenTyp } from '../../shared/constants';
import PersonentypAuswahl from '../../components/common/PersonentypAuswahl';
import { ERROR_MESSAGE, WARNING_MESSAGE } from '../../components/common/Alert';
import { useSnackbar } from 'notistack';
import Tabelle, { ROWS_PER_PAGE_OPTIONS } from '../../components/Tabelle/Tabelle';
import { getDefaultPersonMitTyp } from '../../frontendConstants';
import { getMessageFromError } from '../../shared/throw';
import { aktualisierePerson } from '../../domain/aktualisierePerson';

const columns = [
  { label: 'ID', id: 'id', sortable: true },
  { label: 'Firmenname', id: 'firmenname', sortable: true },
  {
    label: 'Vorname',
    id: 'vorname',
    sortable: true
  },
  { label: 'Nachname', id: 'nachname', sortable: true },
  {
    label: 'Adresse',
    id: 'strasse',
    render: (rowData: Person) => {
      return (
        <>
          {rowData.strasse}
          {rowData.strasse && (rowData.plz ?? rowData.ort) && ', '}
          {rowData.plz} {rowData.ort}
        </>
      );
    }
  },
  {
    label: 'Bestand',
    id: 'userId',
    sortable: true,
    render: (rowData: Person) => rowData.userId
  }
];

async function suchePersonen(variables: SuchePersonenArgs): Promise<SuchePersonenResponse> {
  if (variables.mandant) {
    return makeGraphqlQuery(queries.suchePersonen, variables);
  }
  return {
    items: [],
    total: 0
  };
}

export default function Personen(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const { mandanten, hatGruppeVonMandant } = useUser();

  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [suchePersonenResponse, setSuchePersonenResponse] = useState<SuchePersonenResponse>({ items: [], total: 0 });
  const [person, setPerson] = useState<Person>(getDefaultPersonMitTyp(PersonenTyp.PERSON));
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [typSelektion, setTypSelektion] = useState(PersonenTyp.PERSON);
  const [ausgewaehlterMandant, setAusgewaehlterMandant] = useState<string | null>(null);
  const [suchbegriff, setSuchbegriff] = useState('');

  const [from, setFrom] = useState(0);
  const [limit, setLimit] = useState(25);
  const [sortBy, setSortBy] = useState('createdAt');
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_OPTIONS[1]);

  const isAdmin = hatGruppeVonMandant(ausgewaehlterMandant ?? '', COGNITO_GRUPPE_ADMIN);

  const holePersonen = useCallback(
    (suchbegriff, ausgewaehlterMandant, typSelektion, timeout = 0): Promise<void> => {
      return new Promise((resolve) => {
        // TODO timeout wegen DynamoDB Streams => OpenSearch
        setTimeout(() => {
          suchePersonen({
            suchbegriff,
            from,
            limit,
            sortBy,
            order,
            mandant: ausgewaehlterMandant || '',
            typ: typSelektion
          })
            .then((response) => {
              if (response.fehler) {
                enqueueSnackbar(response.fehler, WARNING_MESSAGE);
                resolve();
              } else {
                setSuchePersonenResponse(response);
                resolve();
              }
            })
            .catch((error) => {
              console.error(error);
              enqueueSnackbar('Personen konnten nicht geladen werden', ERROR_MESSAGE);
              resolve();
            });
        }, timeout);
      });
    },
    [enqueueSnackbar, from, limit, order, sortBy]
  );

  useEffect(() => {
    setIsLoading(true);

    holePersonen(suchbegriff, ausgewaehlterMandant, typSelektion).finally(() => setIsLoading(false));
  }, [suchbegriff, typSelektion, ausgewaehlterMandant, holePersonen]);

  useEffect(() => {
    if (mandanten.length > 0 && ausgewaehlterMandant === null) {
      setAusgewaehlterMandant(mandanten[0]);
    }
  }, [ausgewaehlterMandant, mandanten]);

  useEffect(() => setPage(0), [suchbegriff, rowsPerPage]);
  useEffect(() => setFrom(page * rowsPerPage), [page, rowsPerPage]);
  useEffect(() => setLimit(rowsPerPage), [rowsPerPage]);

  const handleSort = (property: string) => {
    if (sortBy === property) {
      setOrder((current) => (current === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortBy(property);
      setOrder('desc');
    }
  };

  const handleAktualisierePerson = useCallback(
    (person: Person & { tableData?: unknown }) => {
      setIsLoading(true);

      if (person.tableData) {
        delete person.tableData;
      }

      aktualisierePerson(person)
        .then(() => setOpen(false))
        .then(() => holePersonen(suchbegriff, ausgewaehlterMandant, typSelektion, 1500))
        .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Person konnte nicht gespeichert werden', ERROR_MESSAGE))
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, holePersonen, suchbegriff, ausgewaehlterMandant, typSelektion]
  );

  const handleLegePersonAn = useCallback(
    (person: Person) => {
      setIsLoading(true);

      aktualisierePerson({
        ...person,
        mandant: ausgewaehlterMandant
      })
        .then(() => setOpen(false))
        .then(() => holePersonen(suchbegriff, ausgewaehlterMandant, typSelektion, 1500))
        .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Person konnte nicht gespeichert werden', ERROR_MESSAGE))
        .finally(() => setIsLoading(false));
    },
    [ausgewaehlterMandant, enqueueSnackbar, holePersonen, suchbegriff, typSelektion]
  );

  const handleLoeschePerson = useCallback(
    (id: string) => {
      setIsLoading(true);

      makeGraphqlQuery(mutations.deletePerson, {
        input: { id }
      })
        .then(() => setOpenDeleteDialog(false))
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Person konnte nicht gelöscht werden', ERROR_MESSAGE);
        })
        .then(() => holePersonen(suchbegriff, ausgewaehlterMandant, typSelektion, 1500))
        .finally(() => setIsLoading(false));
    },
    [enqueueSnackbar, holePersonen, suchbegriff, ausgewaehlterMandant, typSelektion]
  );

  const bearbeiten = (rowData: Person) => {
    setPerson(rowData);
    setOpen(true);
  };

  const hinzufuegen = () => {
    setPerson(getDefaultPersonMitTyp(PersonenTyp.PERSON));
    setOpen(true);
  };

  const loeschen = (rowData: Person) => {
    setPerson(rowData);
    setOpenDeleteDialog(true);
  };

  const personenAuswahl = (
    <>
      <MandantenAuswahl
        onSelectMandant={(mandant) => {
          setAusgewaehlterMandant(mandant);
        }}
      />
      <FormControl variant="standard">
        <InputLabel>Filter</InputLabel>
        <PersonentypAuswahl personentyp={typSelektion} setPersonentyp={setTypSelektion} />
      </FormControl>
    </>
  );

  const actions = [
    {
      label: 'Bearbeiten',
      onClick: (rowData: Person) => bearbeiten(rowData),
      disabled: isLoading
    }
  ];

  if (isAdmin) {
    actions.push({
      label: 'Löschen',
      onClick: (rowData: Person) => loeschen(rowData),
      disabled: isLoading
    });
  }

  return (
    <>
      <Tabelle
        columns={columns}
        actions={actions}
        onSuchbegriffChange={(suchbegriff) => setSuchbegriff(suchbegriff)}
        tableData={suchePersonenResponse.items}
        onAdd={hinzufuegen}
        disabled={isLoading}
        typAuswahl={personenAuswahl}
        title="Personen"
        handleSort={handleSort}
        sortBy={sortBy}
        order={order}
        total={suchePersonenResponse.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(newPage) => setPage(newPage)}
        onRowsPerPageChange={(rowsPerPage) => setRowsPerPage(rowsPerPage)}
        dataTestId="personen"
      />
      {open ? (
        <PersonenVerwaltung
          personObjekt={person}
          typ={PersonenTyp.PERSON}
          aktualisierePerson={handleAktualisierePerson}
          legePersonAn={handleLegePersonAn}
          onClose={() => setOpen(false)}
          isDisabled={isLoading}
          testPraefix={'stammdaten'}
        />
      ) : null}
      <LoadingIndicator isLoading={isLoading} />
      <Bestaetigungsdialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={() => person?.id && handleLoeschePerson(person.id)}
        title="Löschen"
        text={`Wollen Sie die Person ${person?.vorname} ${person?.nachname} wirklich löschen?`}
      />
    </>
  );
}
