import React from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useUser } from '../hooks/useUser';
import Formular from './common/Formular';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { YesNoFormControl } from './YesNoFormControl';
import { BemerkungenZumSchaden, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_UNFALLDATEN_ERFASSEN } from '../frontendConstants';

const PLAUSIBILITAET_JA = 'JA';
const PLAUSIBILITAET_KEINE_ANGABE = 'KEINE_ANGABE';

const PLAUSIBILITAET_TEXTE = {
  [PLAUSIBILITAET_JA]: 'Ja',
  [PLAUSIBILITAET_KEINE_ANGABE]: 'Keine Angabe'
};

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function Schadenhergang({ vorgang, isLoading, aktualisiereVorgang }: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();
  const plausibilitaet_texte = Object.entries(PLAUSIBILITAET_TEXTE).map(([value, label]) => ({ label, value }));

  const isDisabled = isLoading || !aktionErlaubt(AKTION_UNFALLDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  const aktualisiereBemerkungenZumSchadenDaten = (key: keyof BemerkungenZumSchaden, wert: any) => {
    aktualisiereVorgang({ bemerkungenZumSchaden: { ...vorgang.bemerkungenZumSchaden, [key]: wert } });
  };

  return (
    <Formular ueberschrift="Schadenhergang">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            multiline
            defaultValue={vorgang?.bemerkungenZumSchaden?.schadenhergang ?? ''}
            disabled={isDisabled}
            onChange={(event) => aktualisiereBemerkungenZumSchadenDaten('schadenhergang', event.target.value)}
            fullWidth
            inputProps={{
              'data-testid': 'schadenbedingterFahrzeugzustand-schadenhergang'
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Plausibilität</InputLabel>
            <Select
              variant="standard"
              value={vorgang?.bemerkungenZumSchaden?.plausibilitaet ?? PLAUSIBILITAET_JA}
              onChange={(event) => {
                aktualisiereBemerkungenZumSchadenDaten('plausibilitaet', event.target.value);
              }}
              data-testid="plausibilitaet"
              disabled={isDisabled}
            >
              {plausibilitaet_texte.map((auswahl, index) => (
                <MenuItem key={index} value={auswahl.value} data-testid={`plausibilitaet-${auswahl.label}`}>
                  {auswahl.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} />

        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Factoring"
            value={vorgang?.bemerkungenZumSchaden?.factoring}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('factoring', jaNein);
            }}
            dataTestid="schadenhergang-factoring"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Finanzierung möglich (Büro)"
            value={vorgang?.bemerkungenZumSchaden?.finanzierungMoeglich}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('finanzierungMoeglich', jaNein);
            }}
            dataTestid="schadenhergang-finanzierungMoeglich"
            fullWidth
          />
        </Grid>
      </Grid>
    </Formular>
  );
}
