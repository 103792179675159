import React, { ChangeEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_COLUMNS, SPACING_BETWEEN_FORM_FIELDS } from '../../../components/common/spacings';
import Formular from '../../../components/common/Formular';
import { AKTION_KUNDENDATEN_ERFASSEN } from '../../../frontendConstants';
import WarningSign from '../../../components/common/WarningSign';
import PersonAnzeige from '../../../components/common/PersonAnzeige';
import { useUser } from '../../../hooks/useUser';
import { isAenderbar } from '../../../domain/isAenderbar';
import { Person, Vorgang } from '../../../types';
import { YesNoFormControl } from '../../../components/YesNoFormControl';
import { aktionErlaubt } from '../../../domain/aktionErlaubt';
import SearchPersonUnternehmen from '../../../components/common/SearchPersonUnternehmen';
import { PersonenTyp } from '../../../shared/constants';
import { getMessageFromError } from '../../../shared/throw';
import { ERROR_MESSAGE } from '../../../components/common/Alert';
import { useSnackbar } from 'notistack';
import { aktualisierePerson } from '../../../domain/aktualisierePerson';
import { PersonEingabeMaske } from '../../../components/PersonEingabeMaske/PersonEingabeMaske';

type Props = {
  readonly vorgang: Vorgang;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
  readonly isLoading: boolean;
  readonly setLoading: (value: boolean) => void;
};

export function Auftraggeber({ vorgang, aktualisiereVorgang, speichereVorgang, isLoading, setLoading }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();
  const [dialog, setDialog] = useState({ anlegen: false, aendern: false });

  const handleAuftraggeberAusgeaehlt = (person: Person) => {
    speichereVorgang({ auftraggeberId: person.id });
  };

  const handleAktualisierePerson = (person: Person) => {
    setLoading(true);

    aktualisierePerson({
      ...person,
      mandant: vorgang.mandant,
      weitereBearbeiter: Array.from(new Set([...(person.weitereBearbeiter ?? []), ...vorgang.ehemaligeBearbeiter, vorgang.userId]))
    })
      .then((gespeichertePerson) => {
        speichereVorgang({ auftraggeberId: gespeichertePerson.id });
        setDialog({ anlegen: false, aendern: false });
      })
      .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Auftraggeber konnte nicht gespeichert werden', ERROR_MESSAGE))
      .finally(() => setLoading(false));
  };

  const handleAuftraggeberAendernDialog = () => {
    setDialog((current) => ({ ...current, aendern: true }));
  };

  const handleAuftraggeberAnlegenDialog = () => {
    setDialog((current) => ({ ...current, anlegen: true }));
  };

  const handleAuftraggeberLoeschen = () => {
    speichereVorgang({
      auftraggeberId: null,
      auftraggeberVorsteuerabzugsberechtigt: null,
      auftraggeberRechtsschutzVorhanden: null,
      auftraggeberPostversand: null
    });
  };

  const isDisabled =
    !vorgang || isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_KUNDENDATEN_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  return (
    <Formular ueberschrift="Auftraggeber">
      <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
        <Grid
          item
          container
          xs={12}
          sx={(theme) => ({
            [theme.breakpoints.down('md')]: {
              marginBottom: -theme.spacing(SPACING_BETWEEN_FORM_FIELDS)
            }
          })}
        >
          <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <SearchPersonUnternehmen
                mandantId={vorgang?.mandant}
                isDisabled={isDisabled}
                label="Auftraggeber suchen"
                onSelect={(person) => handleAuftraggeberAusgeaehlt(person as Person)}
                testPraefix="auftraggeber"
                personenUnternehmenTyp={PersonenTyp.PERSON}
              />
            </Grid>
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                disabled={isDisabled || !vorgang?.auftraggeberId}
                onClick={() => handleAuftraggeberAendernDialog()}
                data-testid="auftraggeber-bearbeiten"
                size="large"
              >
                <Tooltip title="Ändern">
                  <EditIcon />
                </Tooltip>
              </IconButton>
              <IconButton disabled={isDisabled} onClick={() => handleAuftraggeberAnlegenDialog()} data-testid="auftraggeber-anlegen" size="large">
                <Tooltip title="Anlegen">
                  <AddBoxIcon />
                </Tooltip>
              </IconButton>
              <IconButton
                disabled={isDisabled || !vorgang?.auftraggeberId}
                onClick={() => handleAuftraggeberLoeschen()}
                data-testid="auftraggeber-loeschen"
                size="large"
              >
                <Tooltip title="Zuordnung löschen">
                  <DeleteIcon />
                </Tooltip>
              </IconButton>
            </Grid>
            <Grid item xs={12}>
              <WarningSign
                show={vorgang?.auftraggeber === null && vorgang?.auftraggeberId !== null}
                text="Der Auftraggeber wurde gelöscht oder Sie haben nicht die erforderlichen Rechte, diesen zu sehen."
              />
              {vorgang?.auftraggeber && <PersonAnzeige person={vorgang.auftraggeber} testPraefix="auftraggeber" />}
            </Grid>

            {vorgang?.auftraggeber && (
              <>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vorgang.auftraggeberPostversand ?? false}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          aktualisiereVorgang({ auftraggeberPostversand: event.target.checked });
                        }}
                        name="Angebotsanfrage"
                        color="primary"
                      />
                    }
                    label="Postversand"
                  />

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={vorgang.auftraggeberVip ?? false}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => {
                          aktualisiereVorgang({ auftraggeberVip: event.target.checked });
                        }}
                        name="VIP"
                        color="primary"
                      />
                    }
                    label="VIP"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <YesNoFormControl
                    labelText="Rechtsschutz"
                    value={vorgang.auftraggeberRechtsschutzVorhanden}
                    disabled={isDisabled}
                    onChange={(jaNein) => {
                      aktualisiereVorgang({ auftraggeberRechtsschutzVorhanden: jaNein });
                    }}
                    dataTestid="rechtsschutzVorhanden"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <YesNoFormControl
                    labelText={
                      <>
                        Fahrzeug im Betriebsvermögen <Typography variant="caption">(vorsteuerabzugsberechtigt)</Typography>{' '}
                      </>
                    }
                    value={vorgang?.auftraggeberVorsteuerabzugsberechtigt}
                    disabled={isDisabled}
                    onChange={(jaNein) => {
                      aktualisiereVorgang({ auftraggeberVorsteuerabzugsberechtigt: jaNein });
                    }}
                    dataTestid="vorsteuerabzugsberechtigt"
                    fullWidth
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>

      {(dialog.anlegen || dialog.aendern) && (
        <PersonEingabeMaske
          personDatensatz={dialog.aendern ? vorgang?.auftraggeber : null}
          typ={PersonenTyp.PERSON}
          onClose={() => {
            setDialog({ anlegen: false, aendern: false });
          }}
          aktualisierePerson={handleAktualisierePerson}
          isDisabled={isDisabled}
          mandant={vorgang.mandant}
          testPraefix="auftraggeber"
        />
      )}
    </Formular>
  );
}
