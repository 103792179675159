import { Theme } from '@mui/material/styles/createTheme';
import { SPACING_BETWEEN_BOXES } from '../common/spacings';

export function dialogActions(theme: Theme) {
  return {
    dialogActions: {
      paddingRight: theme.spacing(SPACING_BETWEEN_BOXES),
      paddingBottom: theme.spacing(SPACING_BETWEEN_BOXES)
    }
  };
}
