import '../screens/Vorgang/Unterschriften.css';
import React, { ChangeEvent, Fragment, useState } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import HonorarTabelleModal from './modal/HonorartabelleModal';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { Signature } from './common/Signature';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { formatDDMMYYYY } from '../shared/dateTime';
import { holeGeschaedigten } from '../shared/frontend/holeGeschaedigten';
import { name } from '../shared/frontend/adresse';
import { Vorgang } from '../types';
import getVersicherungAnzeigeText from '../domain/getVersicherungAnzeigeText';
import { Unterzeichner } from '../shared/constants';
import Button from '@mui/material/Button';
import { Modal } from './Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { UnterschriftenCheckboxState } from '../screens/Vorgang/Unterschriften';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly updateCheckboxState: (newState: Partial<UnterschriftenCheckboxState>) => void;
  readonly checkboxState: UnterschriftenCheckboxState;
};

export function Abtretungserklaerung({ vorgang, isLoading, updateCheckboxState, checkboxState }: Props): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxState.abtretungserklaerung}
            onChange={(event: ChangeEvent<HTMLInputElement>) => updateCheckboxState({ abtretungserklaerung: event.target.checked })}
            name="abtretungserklaerung"
            color="primary"
            data-testid="abtretungserklaerungCheckbox"
          />
        }
        label={
          <Link
            onClick={(event) => {
              event.preventDefault();
              setIsModalOpen(true);
            }}
            data-testid="abtretungserklaerungLink"
          >
            Abtretungserklärung*
          </Link>
        }
      />
      <AbtretungserklaerungModal vorgang={vorgang} isLoading={isLoading} isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}

type ModalProps = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly isModalOpen: boolean;
  readonly onClose: () => void;
};

function AbtretungserklaerungModal({ vorgang, isLoading, isModalOpen, onClose }: ModalProps): JSX.Element {
  const [openHonorartabelle, setOpenHonorartabelle] = useState(false);

  const abtretungserklaerungUrl = useDateiUrl(vorgang?.unterschriften?.abtretungserklaerung?.key);

  const geschaedigter = holeGeschaedigten(vorgang);

  const signDate = vorgang?.unterschriften?.abtretungserklaerung?.datum ?? new Date().toISOString();

  return (
    <Modal
      openModal={isModalOpen}
      setOpenModal={onClose}
      title="Abtretungserklärung"
      body={
        <Fragment>
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS} data-testid="abtretungserklaerungModal">
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={4}>
                  Zum Gutachten
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="vorgangsnummer">
                  {vorgang.vorgangsnummer}
                </Grid>
                <Grid item xs={4}>
                  Schaden vom
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="unfalldatum">
                  {formatDDMMYYYY(vorgang?.unfalldaten?.datum)}
                </Grid>
                <Grid item xs={4}>
                  Versicherung
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="versicherung">
                  {getVersicherungAnzeigeText(vorgang?.versicherung ?? null)}
                </Grid>
                <Grid item xs={4}>
                  Schädiger
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="schaediger">
                  {`${vorgang?.unfallgegner?.vorname ?? ''} ${vorgang?.unfallgegner?.nachname ?? ''}`}
                </Grid>
                <Grid item xs={4}>
                  amtl. Kennz.
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="kennzeichen">
                  {vorgang?.unfalldaten?.kennzeichen}
                </Grid>
                <Grid item xs={4}>
                  Versicherungs-Nr.
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="versicherungsnummer">
                  {vorgang?.unfalldaten?.versicherungsnummer}
                </Grid>
                <Grid item xs={4}>
                  Schaden-Nr.
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="schadennummer">
                  {vorgang?.unfalldaten?.schadennummer}
                </Grid>
                <Grid item xs={4}>
                  Geschädigter/Zedent
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="geschaedigter">
                  {name(geschaedigter)}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                Aus Anlass des oben beschriebenen Schadenfalles beauftrage ich die Firma Experts24 Tassone GmbH, ein Gutachten zur Schadenhöhe sowie im Falle
                der Reparatur einen Reparaturnachweis zu erstellen. Experts24 Tassone GmbH berechnet das Honorar in Anlehnung an die Schadenhöhe gemäß{' '}
                <Link onClick={() => setOpenHonorartabelle(true)} underline="hover">
                  Honorartabelle*
                </Link>{' '}
                zzgl. erforderlicher Nebenkosten (Sachverständigenkosten).
              </Typography>
              <br />
              <Typography variant="body2">
                Ich trete hiermit meine Ansprüche auf Erstattung der Sachverständigenkosten in Höhe des Bruttobetrages der Rechnung der Firma Experts24 Tassone
                GmbH, bzw. des Nettoendbetrages bei Vorsteuerabzugsberechtigung, unwiderruflich, erstrangig, erfüllungshalber gegen den Fahrer, den Halter und
                den Versicherer des am Unfall beteiligten Fahrzeuges sowie im Fall des Kaskoschadens gegen die Versicherung des Geschädigten an Experts24
                Tassone GmbH ab.
              </Typography>
              <br />
              <Typography variant="body2">
                Hiermit weise ich den regulierungspflichtigen Versicherer an, die Sachverständigenkosten unmittelbar an Experts24 Tassone GmbH zu zahlen.
              </Typography>
              <br />
              <Typography variant="body2">
                Die Firma Experts24 Tassone GmbH ist berechtigt diese Abtretung den Anspruchsgegnern offen zu legen und die erfüllungshalber abgetretenen
                Ansprüche auf Erstattung der Sachverständigenkosten gegenüber den Anspruchsgegnern geltend zu machen.
              </Typography>
              <br />
              <Typography variant="body2">
                Durch diese Abtretung werden die Ansprüche der Firma Experts24 Tassone GmbH aus dem Sachverständigenvertrag gegen mich nicht berührt, jedoch
                werden Zahlungen des Fahrers, des Halters und des Versicherers an Experts24 Tassone GmbH auf den abgetretenen Anspruch auf Erstattung von
                Sachverständigenkosten an Experts24 Tassone GmbH mit Erfüllungswirkung auf die Ansprüche der Experts24 gegen mich angerechnet. Folglich kann die
                Experts24 Tassone GmbH Sachverständigenkosten gegen mich geltend machen, wenn und soweit die regulierungspflichtigen Personen keine Zahlung oder
                lediglich eine teilweise Zahlung leisten.
              </Typography>
              <br />
              <Typography variant="body2">
                Eine Inanspruchnahme auf Zahlung der Sachverständigenkosten meinerseits durch Experts24 Tassone GmbH erfolgt jedoch nur Zug-um-Zug gegen
                Rückabtretung des Anspruchs auf Erstattung der Sachverständigenkosten gegen den Fahrer, Halter und die Versicherung in der jeweils dann noch
                nicht durch den Fahrer, Halter oder der Versicherung gezahlten Höhe. Diese Rückabtretung wird mir in diesem Fall von Experts24 Tassone GmbH mit
                einer detaillierten Forderungsaufstellung in einer an mich adressierten Zahlungsaufforderung bestätigt. Mit erfolgter Rückabtretung steht es mir
                frei, die Sachverständigenkosten in der noch nicht gezahlten Höhe gegenüber dem Fahrer, Halter und der Versicherung selbst geltend zu machen.
              </Typography>
              <br />
              <Typography variant="body2">
                Die Experts24 Tassone GmbH ist berechtigt, im Rahmen des offenen Factorings ihren Anspruch auf Zahlung der Sachverständigenkosten mitsamt den
                erfüllungshalber abgetretenen Ansprüchen an die <strong>ADELTA.FINANZ AG</strong> zu verkaufen und weiter abzutreten, so dass die{' '}
                <strong>ADELTA.FINANZ AG</strong> diese Ansprüche als Gläubigerin geltend machen kann. In diesem Fall weise ich den regulierungspflichtigen
                Versicherer an, die Sachverständigenkosten unmittelbar an die <strong>ADELTA.FINANZ AG</strong> zu zahlen. Ich bin damit einverstanden, dass
                meine Versicherungsforderung auf diese Weise im Rahmen des Factorings verwertet wird, soweit die betreffende KFZ-Haftpflicht- oder
                Kaskoversicherung die Versicherungsforderung anerkennt. Diese Abtretungsvereinbarung dient zugleich als Nachweis der Abtretung gemäß §§ 407, 409
                BGB. Die Experts24 Tassone GmbH und – im Fall der Weiterveräußerung der Ansprüche im Rahmen des Factorings – auch die{' '}
                <strong>ADELTA.FINANZ AG</strong> sind ermächtigt, die entsprechende Abtretungsanzeige gemäß § 409 BGB gegenüber den betreffenden
                Anspruchsgegnern vorzunehmen.
              </Typography>
              <br />
              <Typography variant="body2">
                Ich bin damit einverstanden, dass das Gutachten und der Reparaturnachweis sowie die Rechnungen über die Sachverständigenkosten an die
                Anspruchsgegner sowie – im Fall des Factorings – an die <strong>ADELTA.FINANZ AG</strong> übersandt werden. In Bezug auf diese Abtretung
                verzichte ich gemäß § 151 S. 1 BGB auf den Zugang der Annahmeerklärung der Experts24 Tassone GmbH.
              </Typography>
              <br />
              <br />
              <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                <Grid item xs={12}>
                  Der Zedent oder Bevollmächtigter des Zedenten:
                </Grid>
                <Grid item xs={4}>
                  Vor- und Nachname
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="geschaedigterName">
                  {name(geschaedigter)}
                </Grid>
                <Grid item xs={4}>
                  Straße und Nr.
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="geschaedigterStrasse">
                  {geschaedigter?.strasse}
                </Grid>
                <Grid item xs={4}>
                  PLZ, Ort
                </Grid>
                <Grid item xs={1}>
                  :
                </Grid>
                <Grid item xs={7} data-testid="geschaedigterPlzOrt">
                  {geschaedigter?.plz} {geschaedigter?.ort}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Signature
                onSign={() => {}}
                onDeleteSignature={() => {}}
                signatureUrl={abtretungserklaerungUrl}
                isLoading={isLoading}
                vorgang={vorgang}
                unterzeichnerAuswahl={vorgang?.unterschriften?.abtretungserklaerung?.unterzeichner ?? Unterzeichner.AUFTRAGGEBER}
                date={signDate}
                readOnly={true}
              />
            </Grid>
            <Grid item xs={12}>
              Fahrzeug steht im Betriebsvermögen: {vorgang?.auftraggeberVorsteuerabzugsberechtigt ? 'Ja' : 'Nein'}
              <br />
              Vorsteuer bereits erhalten: Nein
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                {'('}
                <strong>
                  <u>Hinweis der Tassone Experts24 GmbH:</u>
                </strong>{' '}
                Sofern die <strong>ADELTA.FINANZ AG</strong> diese von dem Zedenten oder dessen Bevollmächtigtenunterschriebene Abtretung / Zahlungsanweisung
                dem betreffenden Anspruchsgegner im Original oder in Kopie vorlegt, gilt diese zugleichals Nachweis der Abtretung der hier abgetretenen
                Forderungen und des Zahlungsanspruchs aus dem Gutachtensauftrag durch die Experts24Tassone GmbH an die <strong>ADELTA.FINANZ AG</strong> im
                Rahmen des Factorings; § 151 S. 1 BGB findet Anwendung.{')'}
              </Typography>
            </Grid>
          </Grid>
          {openHonorartabelle && <HonorarTabelleModal onClose={() => setOpenHonorartabelle(false)} aktuelleMandantId={vorgang.mandant} />}
        </Fragment>
      }
      footer={
        <div className="unterschriften__modal-button">
          <Button color="primary" variant="contained" onClick={onClose}>
            Schließen
          </Button>
        </div>
      }
    />
  );
}
