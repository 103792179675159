import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { ACHSENTYPEN } from './Bereifung';
import { Achse as AchseTyp, Fahrzeugtyp } from '../types';
import { FloatFormat } from './common/inputFormats';

type Props = {
  readonly achse: AchseTyp;
  readonly index: number;
  readonly achseLoeschen: (position: string) => void;
  readonly achsenbereifungAendern: (achsenPosition: string, typ: string) => void;
  readonly fahrzeugtyp: Fahrzeugtyp;
  readonly mehrAlsZweiAchsen: boolean;
  readonly profiltiefeAendern: (achsenposition: string, position: string, profiltiefe: string) => void;
  readonly reifengroesseAendern: (achsenposition: string, reifengroesse: string) => void;
  readonly herstellerAendern: (achsenposition: string, hersteller: string) => void;
  readonly isDisabled: boolean;
  readonly reifenHersteller: string[];
};

export function Achse({
  achse,
  index,
  achseLoeschen,
  achsenbereifungAendern,
  fahrzeugtyp,
  mehrAlsZweiAchsen,
  profiltiefeAendern,
  reifengroesseAendern,
  herstellerAendern,
  isDisabled,
  reifenHersteller
}: Props): JSX.Element {
  const filterOptions = createFilterOptions({ matchFrom: 'start', trim: true });

  const [reifengroesse, setReifengroesse] = useState<string>(achse.reifengroesse ?? '');

  const reifen = achse.reifen ?? [];

  let achseLabel = '';
  if (mehrAlsZweiAchsen) {
    achseLabel = `${index + 1}. Achse`;
  } else {
    achseLabel = index === 1 ? 'Hinten' : 'Vorn';
  }

  useEffect(() => {
    setReifengroesse(achse.reifengroesse ?? '');
  }, [achse.reifengroesse]);

  return (
    <Grid
      container
      spacing={SPACING_BETWEEN_FORM_FIELDS}
      sx={{
        paddingBottom: '1rem'
      }}
    >
      <Grid item xs={12} sm={1}>
        <Typography
          variant="subtitle1"
          sx={(theme) => ({
            color: theme.palette.text.secondary
          })}
        >
          {achseLabel}
        </Typography>
      </Grid>
      {fahrzeugtyp !== Fahrzeugtyp.ZWEIRAEDRIG && (
        <Grid item xs={12} sm={3}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Achsenbereifung</InputLabel>
            <Select
              variant="standard"
              label="Achsenbereifung"
              value={achse.typ}
              disabled={isDisabled}
              onChange={(event: SelectChangeEvent<string>) => {
                achsenbereifungAendern(achse.achsenPosition, event.target.value);
              }}
              data-testid={`fahrzeugdaten-achsenbereifung-${index}`}
            >
              {ACHSENTYPEN.map((g) => (
                <MenuItem key={g.value} value={g.value} data-testid={`${g.label}-${index}`}>
                  {g.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid item xs={12} sm={2}>
        <Autocomplete
          disabled={isDisabled}
          filterOptions={filterOptions}
          freeSolo
          options={reifenHersteller}
          value={achse.hersteller ?? ''}
          onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
            if (event.target.value !== achse.hersteller) {
              herstellerAendern(achse.achsenPosition, event.target.value);
            }
          }}
          renderInput={(params) => (
            <TextField variant="standard" {...params} label="Hersteller" disabled={isDisabled} fullWidth data-testid={`fahrzeugdaten-hersteller-${index}`} />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <TextField
          variant="standard"
          label="Reifengröße"
          value={reifengroesse}
          disabled={isDisabled}
          onChange={(event) => setReifengroesse(event.target.value)}
          onBlur={(event) => {
            if (event.target.value !== (achse.reifengroesse ?? '')) {
              reifengroesseAendern(achse.achsenPosition, event.target.value);
            }
          }}
          fullWidth
          data-testid={`fahrzeugdaten-reifengroesse-${index}`}
        />
      </Grid>
      {reifen?.map((einzelReifen, i) => (
        <Grid item key={i} xs={12} sm={1}>
          <Tooltip
            title={
              profiltiefeUngueltig(einzelReifen?.profiltiefe ?? '')
                ? 'Das Hauptprofil muss am ganzen Umfang eine Profiltiefe von mindestens 1,6 Millimeter aufweisen; als Hauptprofil gelten dabei die breiten Profilrillen im mittleren Bereich der Lauffläche, der etwa 3/4 der Laufflächenbreite einnimmt. Jedoch genügt bei Fahrrädern mit Hilfsmotor, Kleinkrafträdern und Leichtkrafträdern eine Profiltiefe von mindestens 1 mm.'
                : ''
            }
          >
            <div>
              <TextField
                variant="standard"
                label={einzelReifen.position}
                error={profiltiefeUngueltig(einzelReifen?.profiltiefe ?? '')}
                value={einzelReifen.profiltiefe ?? 0}
                disabled={isDisabled}
                onChange={(event) => {
                  if (event.target.value !== einzelReifen.profiltiefe) {
                    profiltiefeAendern(achse.achsenPosition, einzelReifen.position, event.target.value);
                  }
                }}
                fullWidth
                data-testid={`fahrzeugdaten-profiltiefe-${index}-${i}-${einzelReifen.position}`}
                InputProps={{
                  inputComponent: FloatFormat
                }}
              />
            </div>
          </Tooltip>
        </Grid>
      ))}
      {index > 1 && (
        <Grid item xs={12} sx={{ textAlign: 'right' }} {...((achse.reifen || []).length === 2 ? { sm: 2 } : { sm: 4 })}>
          <IconButton
            onClick={() => {
              achseLoeschen(achse.achsenPosition);
            }}
            disabled={isDisabled}
            size="large"
          >
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
}

export function profiltiefeUngueltig(profiltiefe: string): boolean {
  if (!profiltiefe) {
    return false;
  }

  const value = profiltiefe.replace(',', '.');

  /*
   * Einschränkung erfolgte vor der Umstellung auf v5 in react-number-format über die Prop decimalScale={1}.
   * Da jetzt das Format als InputProp an das Textfield übergeben wird, wurde es vorübergehend hier zu einer Validierung.
   */
  if ((value.split('.')[1] || '0').length > 1) {
    return true;
  }

  const p = Number.parseFloat(value);
  return (p !== 0 && p < 1.6) || p > 22;
}
