import './Navigation.css';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import DashboardIcon from '@mui/icons-material/Dashboard';
import BuildIcon from '@mui/icons-material/Build';
import GavelIcon from '@mui/icons-material/Gavel';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SettingsIcon from '@mui/icons-material/Settings';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import EuroSymbol from '@mui/icons-material/EuroSymbol';
import ApartmentIcon from '@mui/icons-material/Apartment';
import GroupIcon from '@mui/icons-material/Group';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AccountBox from '@mui/icons-material/AccountBox';
import LogoutIcon from '@mui/icons-material/Logout';
import Button from '@mui/material/Button';
import { VorgangNavigationListe } from './VorgangNavigationListe';
import { Logo } from '../common/Logo';
import { useUser } from '../../hooks/useUser';

export const navigationItemClass = 'navigation__item';
const dashboardPath = '/dashboard';

type Props = {
  readonly drawerOpen: boolean;
  readonly setDrawerOpen: (value: boolean) => void;
};

export function Navigation({ drawerOpen, setDrawerOpen }: Props): JSX.Element {
  const location = useLocation();
  const history = useHistory();

  const { user, kuerzel, isAdmin, isExperts24Admin, isVerwaltung } = useUser();

  useEffect(() => {
    setDrawerOpen(false);
  }, [location.pathname, setDrawerOpen]);

  const navigiereZu = (pathname: string) => {
    if (pathname !== location.pathname) {
      history.push(pathname);
    }
    setDrawerOpen(false);
  };

  return (
    <nav>
      <Drawer
        variant="temporary"
        anchor="left"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        classes={{ paper: 'navigation__drawer-paper' }}
        ModalProps={{ keepMounted: true }}
      >
        <div
          className="navigation__toolbar"
          onClick={() => {
            navigiereZu(dashboardPath);
          }}
        >
          <Logo />
        </div>

        <Divider />
        <List>
          {/* Dashboard */}
          <ListItemButton onClick={() => navigiereZu(dashboardPath)} selected={location.pathname === dashboardPath} data-testid="Dashboard">
            <ListItemIcon>
              <DashboardIcon />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>

          <VorgangNavigationListe onClick={() => setDrawerOpen(false)} />

          {/* Stammdaten */}
          <ListItem>
            <ListItemIcon>
              <PermContactCalendarIcon />
            </ListItemIcon>
            <ListItemText primary="Stammdaten" />
          </ListItem>

          {/* Stammdaten Sub-Liste */}
          <List disablePadding>
            <ListItemButton
              onClick={() => navigiereZu('/stammdaten/person')}
              selected={location.pathname === '/stammdaten/person'}
              className={navigationItemClass}
              data-testid="Personen"
            >
              <ListItemIcon>
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Personen" />
            </ListItemButton>
            <ListItemButton
              onClick={() => navigiereZu('/stammdaten/unternehmen')}
              selected={location.pathname === '/stammdaten/unternehmen'}
              className={navigationItemClass}
              data-testid="Unternehmen"
            >
              <ListItemIcon>
                <ApartmentIcon />
              </ListItemIcon>
              <ListItemText primary="Unternehmen" />
            </ListItemButton>
          </List>

          {/* Administration */}
          {isAdmin && (
            <>
              <ListItem>
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Administration" />
              </ListItem>
              {/* Administration Sub-Liste */}
              <List disablePadding>
                <ListItemButton
                  onClick={() => navigiereZu('/admin/mandantenconfig')}
                  selected={location.pathname === '/admin/mandantenconfig'}
                  className={navigationItemClass}
                  data-testid="Konfiguration"
                >
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary="Konfiguration" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigiereZu('/admin/textbausteine')}
                  selected={location.pathname === '/admin/textbausteine'}
                  className={navigationItemClass}
                  data-testid="Textbausteine"
                >
                  <ListItemIcon>
                    <GavelIcon />
                  </ListItemIcon>
                  <ListItemText primary="Textbausteine" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigiereZu('/admin/mandantenbenutzerverwaltung')}
                  selected={location.pathname === '/admin/mandantenbenutzerverwaltung'}
                  className={navigationItemClass}
                  data-testid="Benutzerverwaltung"
                >
                  <ListItemIcon>
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Benutzerverwaltung" />
                </ListItemButton>
              </List>
            </>
          )}

          {/* Franchisegeber Administration */}
          {isExperts24Admin && (
            <>
              <ListItem>
                <ListItemIcon>
                  <BusinessCenterIcon />
                </ListItemIcon>
                <ListItemText primary="Franchisegeber Administration" />
              </ListItem>
              {/* Franchisegeber Administration Sub-Liste */}
              <List disablePadding>
                <ListItemButton
                  onClick={() => navigiereZu('/admin/mandanten')}
                  selected={location.pathname === '/admin/mandanten'}
                  className={navigationItemClass}
                  data-testid="Mandanten"
                >
                  <ListItemIcon>
                    <GroupAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Mandanten" />
                </ListItemButton>
                <ListItemButton
                  onClick={() => navigiereZu('/admin/userverwaltung')}
                  selected={location.pathname === '/admin/userverwaltung'}
                  className={navigationItemClass}
                  data-testid="Benutzerverwaltung"
                >
                  <ListItemIcon>
                    <PersonAddIcon />
                  </ListItemIcon>
                  <ListItemText primary="Benutzerverwaltung" />
                </ListItemButton>
              </List>
            </>
          )}

          {/* Sonstiges */}

          <List disablePadding>
            {isVerwaltung && (
              <ListItemButton onClick={() => navigiereZu('/zahlungsimport')} selected={location.pathname === '/zahlungsimport'} data-testid="zahlungsimport">
                <ListItemIcon>
                  <EuroSymbol />
                </ListItemIcon>
                <ListItemText primary="Zahlungsimport" />
              </ListItemButton>
            )}
            <ListItemButton onClick={() => navigiereZu(`/profil/${user?.username}`)} selected={location.pathname === '/profil'} data-testid="profil">
              <ListItemIcon>
                <AccountBox />
              </ListItemIcon>
              <ListItemText primary="Profileinstellungen" />
            </ListItemButton>

            <div id="portal-verwaltungscheckbox" />
            <div id="portal-aufgabenliste" />

            <ListItem>
              <Button
                fullWidth
                startIcon={<LogoutIcon />}
                variant="contained"
                color="error"
                size="large"
                onClick={() => history.push(`${location.pathname}/logout`)}
                data-testid="abmeldenButton"
              >
                {kuerzel} Abmelden
              </Button>
            </ListItem>
          </List>
        </List>
      </Drawer>
    </nav>
  );
}
