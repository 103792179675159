import React, { useMemo } from 'react';

type Props = {
  readonly value: number | string | undefined;
};

function toNumber(value: number | string | undefined): number {
  if (typeof value === 'string') {
    return parseFloat(value);
  }
  return value ?? 0;
}

export function Euro({ value }: Props): React.ReactElement {
  const euroValue = useMemo(() => {
    return new Intl.NumberFormat('de-DE', {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(toNumber(value));
  }, [value]);

  return <>{euroValue}</>;
}
