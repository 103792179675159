/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from 'react';
import { AuthState } from './types';
import { setItem } from '../shared/storage';
import { CognitoUser } from '../shared/constants';

export interface IAuthPieceProps {
  authData?: Partial<CognitoUser>;
  authState?: string;
  hide?: any;
  onAuthEvent?: any;
  onStateChange?: (state: AuthState, data?: any) => void;
}

export interface IAuthPieceState {
  username?: string | null;
  requestPending?: boolean;
}

export class AuthPiece<Props extends IAuthPieceProps, State extends IAuthPieceState> extends React.Component<Props, State> {
  public _validAuthStates: string[];
  public _isHidden: boolean;
  public inputs: any;

  constructor(props: any) {
    super(props);

    this.inputs = {};

    this._isHidden = true;
    this._validAuthStates = [];
    this.changeState = this.changeState.bind(this);
    this.error = this.error.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
  }

  componentDidMount() {
    if (!this.props.authData?.username) {
      const searchParams = new URLSearchParams(window.location.search);
      const username = searchParams ? searchParams.get('username') : undefined;
      this.setState({ username });
    }
  }

  getUsernameFromInput() {
    return this.inputs.username || this.state.username;
  }

  errorMessage(err: any) {
    if (typeof err === 'string') {
      return err;
    }
    return err.message ? err.message : JSON.stringify(err);
  }

  triggerAuthEvent(event: any) {
    if (this.props.onAuthEvent) {
      this.props.onAuthEvent(event);
    }
  }

  changeState(state: AuthState, data?: Partial<CognitoUser>) {
    if (this.props.onStateChange) {
      this.props.onStateChange(state, data);
    }
    setItem('accessToken', data?.signInUserSession?.idToken?.jwtToken);
    this.triggerAuthEvent({
      type: 'stateChange',
      data: state
    });
  }

  error(err: any) {
    this.triggerAuthEvent({
      type: 'error',
      data: this.errorMessage(err)
    });
  }

  handleInputChange(evt: any) {
    this.inputs = this.inputs || {};
    const { name, value, type, checked } = evt.target;
    const check_type = ['radio', 'checkbox'].includes(type);
    this.inputs[name] = check_type ? `${checked}` : value;
    this.inputs['checkedValue'] = check_type ? value : null;
  }

  render() {
    //@ts-ignores
    if (!this._validAuthStates.includes(this.props.authState)) {
      this._isHidden = true;
      this.inputs = {};
      return null;
    }

    if (this._isHidden) {
      this.inputs = {};
    }
    this._isHidden = false;

    return this.showComponent();
  }

  showComponent(): React.ReactNode {
    throw new Error("You must implement showComponent() and don't forget to set this._validAuthStates.");
  }
}
