import { ApiRequest, HttpMethods } from '../types';

const MIME_APPLICATION_JSON = 'application/json';
const CONTENT_TYPE = 'content-type';
const credentials = { credentials: 'same-origin' as RequestCredentials };

const bodylessMethods = new Set<HttpMethods>();
bodylessMethods.add('GET');
bodylessMethods.add('HEAD');
bodylessMethods.add('DELETE');

function sanitizeHeaders(headers: Record<string, string> = {}): Record<string, string> {
  return Object.entries(headers).reduce(
    (acc, [key, value]) => {
      acc[key.toLowerCase()] = value;
      return acc;
    },
    {} as Record<string, string>
  );
}

function constructHeaders(headers: Record<string, string> = {}): Headers {
  return new Headers({
    'content-type': headers['content-type'] ?? MIME_APPLICATION_JSON,
    ...sanitizeHeaders(headers)
  });
}

function isJSON(headers: Headers) {
  const contentType = headers.get(CONTENT_TYPE);
  return contentType ? contentType.indexOf(MIME_APPLICATION_JSON) === 0 : false;
}

function transformBody(headers: Headers, body: BodyInit | object = ''): BodyInit {
  return isJSON(headers) ? JSON.stringify(body) : (body as BodyInit);
}

function constructBody(method: HttpMethods, headers: Headers, body?: BodyInit | object): BodyInit | undefined {
  return bodylessMethods.has(method) ? undefined : transformBody(headers, body);
}

function toRequest({ url, method, headers, body }: ApiRequest) {
  const _headers = constructHeaders(headers);
  const _body = constructBody(method, _headers, body);
  return new Request(url, { method, headers: _headers, body: _body, ...credentials });
}

function collectHeaders(response: Response) {
  const headers: Record<string, string> = {};
  for (const [key, value] of response.headers.entries()) {
    headers[key] = value;
  }
  return headers;
}

async function parseResponseBody(response: Response) {
  try {
    if (isJSON(response.headers)) {
      return await response.json();
    }
    return await response.text();
  } catch (_) {
    try {
      return await response.text();
    } catch (_) {
      //empty
    }
  }
  return null;
}

async function handleResponse<T>(response: Response): Promise<T> {
  const ok = response.status >= 200 && response.status < 400;
  const data = await parseResponseBody(response);

  return ok
    ? data
    : Promise.reject({
        status: response.status,
        data,
        headers: collectHeaders(response)
      });
}

function handleError(error: any) {
  return Promise.reject(Object.keys(error).length !== 0 ? error : { status: -1, data: error.toString(), headers: {} });
}

function doExchange<T>(params: ApiRequest): Promise<T> {
  return fetch(toRequest(params))
    .then((response) => handleResponse<T>(response))
    .catch((error) => handleError(error));
}

function retryExchange<T>(params: ApiRequest): Promise<T> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      doExchange<T>(params)
        .then((response) => resolve(response))
        .catch((error) => reject(error));
    }, 1000);
  });
}

export function exchange<T>(params: ApiRequest): Promise<T> {
  return doExchange<T>(params).catch((error) => {
    return error.status === -1 ? retryExchange(params) : Promise.reject(error);
  });
}
