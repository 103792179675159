import React, { createContext, useCallback, useState } from 'react';
import { CognitoUser, CognitoUserCustomAttributes } from '../shared/constants';

export type UserContextProps = {
  readonly user: CognitoUser | null;
  readonly kuerzel: string | null;
  readonly isAdmin: boolean;
  readonly isVerwaltung: boolean;
  readonly isExperts24Admin: boolean;
  readonly mandanten: string[];
  readonly gruppen: string[];
  readonly mandantenZuGruppen: Record<string, string[]>;
  readonly hatGruppeVonMandant: (mandant: string, gruppe: string) => boolean;
  readonly gruppenVonMandant: (mandant: string) => string[];
  readonly resetUser: () => void;
  readonly setUser: (user: CognitoUser) => void;
};

type Props = {
  children: React.ReactNode;
};

export const UserContext = createContext<UserContextProps>({
  user: null,
  mandanten: [],
  gruppen: [],
  kuerzel: null,
  isAdmin: false,
  isVerwaltung: false,
  isExperts24Admin: false,
  mandantenZuGruppen: {},
  hatGruppeVonMandant: () => false,
  gruppenVonMandant: () => [],
  resetUser: () => null,
  setUser: () => null
});

export default function DomainContextProvider({ children }: Props): JSX.Element {
  const [state, setState] = useState<{
    user: CognitoUser | null;
    kuerzel: string | null;
    isAdmin: boolean;
    isVerwaltung: boolean;
    isExperts24Admin: boolean;
    mandanten: string[];
    gruppen: string[];
    mandantenZuGruppen: Record<string, string[]>;
  }>({
    user: null,
    mandanten: [],
    gruppen: [],
    kuerzel: null,
    isAdmin: false,
    isVerwaltung: false,
    isExperts24Admin: false,
    mandantenZuGruppen: {}
  });

  const hatGruppeVonMandant = useCallback((mandant: string, gruppe: string) => state.gruppen.includes(`${mandant}_${gruppe}`), [state.gruppen]);

  const gruppenVonMandant = useCallback(
    (mandant: string) => {
      return state.gruppen.filter((it) => it.startsWith(mandant)).map((it) => it.substr(3));
    },
    [state.gruppen]
  );

  const resetUser = useCallback(() => {
    setState({
      user: null,
      mandanten: [],
      gruppen: [],
      kuerzel: null,
      isAdmin: false,
      isVerwaltung: false,
      isExperts24Admin: false,
      mandantenZuGruppen: {}
    });
  }, []);

  const setUser = useCallback((user: CognitoUser) => {
    const payload = user?.signInUserSession?.idToken?.payload ?? ({} as CognitoUserCustomAttributes);
    const gruppen = payload['cognito:groups']?.filter((group: string) => new RegExp('^[A-Za-z]{2}["_]', 'g').test(group)) ?? [];

    setState({
      user,
      mandanten: ermittleMandanten(gruppen),
      gruppen,
      kuerzel: payload['custom:kuerzel']?.toUpperCase() ?? '',
      isAdmin: hatGruppe(gruppen, 'Admin'),
      isVerwaltung: hatGruppe(gruppen, 'Verwaltung'),
      isExperts24Admin: hatGruppe(gruppen, 'Experts24Admin'),
      mandantenZuGruppen: ermittleMandantenZuGruppen(gruppen)
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: state.user,
        mandanten: state.mandanten,
        gruppen: state.gruppen,
        kuerzel: state.kuerzel,
        isAdmin: state.isAdmin,
        isVerwaltung: state.isVerwaltung,
        isExperts24Admin: state.isExperts24Admin,
        mandantenZuGruppen: state.mandantenZuGruppen,
        hatGruppeVonMandant,
        gruppenVonMandant,
        resetUser,
        setUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

function hatGruppe(gruppen: string[], zuSuchendeGruppe: string): boolean {
  return gruppen.includes(zuSuchendeGruppe) || gruppen.some((gruppe) => gruppe.includes(`_${zuSuchendeGruppe}`));
}

function ermittleMandanten(groups: string[]): string[] {
  const tempMandanten: string[] = [];
  groups.forEach((gruppe) => {
    const mandant = gruppe.split('_')[0];
    if (!tempMandanten.includes(mandant)) {
      tempMandanten.push(mandant);
    }
  });
  return tempMandanten;
}

function ermittleMandantenZuGruppen(groups: string[]): Record<string, string[]> {
  const mandantenZuGruppen: Record<string, string[]> = {};

  for (const group of groups) {
    const [mandant, gruppe] = group.split('_');

    if (gruppe in mandantenZuGruppen) {
      const mandanten = mandantenZuGruppen[gruppe];

      if (!mandanten.includes(mandant)) {
        mandantenZuGruppen[gruppe] = [...mandanten, mandant];
      }
    } else {
      mandantenZuGruppen[gruppe] = [mandant];
    }
  }
  return mandantenZuGruppen;
}
