import React, { useRef, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';
import { EuroFormat } from '../common/inputFormats';
import WarningSign from '../common/WarningSign';
import { Angebot, Unternehmen } from '../../types';
import SearchPersonUnternehmen from '../common/SearchPersonUnternehmen';
import { UnternehmenTyp } from '../../shared/constants';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import { dialogActions } from './Dialog';

const ANGEBOTTYPEN = ['application/pdf'];

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onAdd: (angebot: Partial<Angebot>) => Promise<void>;
  readonly isLoading: boolean;
};

export function AngebotHinzufuegenDialog({ open, onClose, onAdd, isLoading }: Props): JSX.Element {
  const [firmenname, setFirmenname] = useState('');
  const [strasse, setStrasse] = useState('');
  const [plz, setPlz] = useState('');
  const [ort, setOrt] = useState('');
  const [telefon, setTelefon] = useState('');
  const [wert, setWert] = useState('0');
  const [dokument, setDokument] = useState<File | null>(null);
  const dokumentFileInputRef = useRef<HTMLInputElement | null>(null);
  const kannHinzugefuegtWerden = firmenname && strasse && plz && ort && telefon && parseFloat(wert) > 0;

  const angebot = {
    firmenname,
    strasse,
    plz,
    ort,
    wert,
    telefon,
    dokument
  };

  const handleDokumenteHochladenClick = () => {
    setTimeout(() => {
      dokumentFileInputRef?.current?.click();
    }, 0);
  };

  const handleHaendlerSelected = (haendler: Unternehmen) => {
    setFirmenname(haendler.firmenname ?? '');
    setStrasse(haendler.strasse ?? '');
    setPlz(haendler.plz ?? '');
    setOrt(haendler.ort ?? '');
    setTelefon(haendler.telefon && haendler.telefon.length > 0 ? haendler.telefon.join(', ') : '');
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="angebotHinzufuegen">
      <DialogTitle id="angebotHinzufuegen">Angebot hinzufügen</DialogTitle>
      <DialogContent>
        <DialogContentText>Bitte geben Sie die Daten zu einem vorliegenden Angebot ein.</DialogContentText>
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12}>
            <SearchPersonUnternehmen
              personenUnternehmenTyp={UnternehmenTyp.HAENDLER}
              isDisabled={false}
              label="Restwerthändler"
              onSelect={(haendler) => {
                handleHaendlerSelected(haendler as Unternehmen);
              }}
              testPraefix="restwerthaendler"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Firmenname"
              value={firmenname}
              onChange={(event) => setFirmenname(event.target.value)}
              fullWidth
              data-testid="angebot-firmenname"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Straße"
              disabled={isLoading}
              value={strasse}
              onChange={(event) => setStrasse(event.target.value)}
              fullWidth
              data-testid="angebot-strasse"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="PLZ"
              disabled={isLoading}
              value={plz}
              onChange={(event) => setPlz(event.target.value)}
              fullWidth
              data-testid="angebot-plz"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Ort"
              disabled={isLoading}
              value={ort}
              onChange={(event) => setOrt(event.target.value)}
              fullWidth
              data-testid="angebot-ort"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Telefon"
              disabled={isLoading}
              value={telefon}
              onChange={(event) => setTelefon(event.target.value)}
              fullWidth
              data-testid="angebot-telefon"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              label="Wert"
              disabled={isLoading}
              value={wert}
              onChange={(event) => setWert(event.target.value)}
              fullWidth
              data-testid="angebot-wert"
              InputProps={{
                inputComponent: EuroFormat,
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <Button variant="contained" disabled={isLoading} color="primary" onClick={handleDokumenteHochladenClick}>
              Dokument auswählen...
            </Button>
            <Box
              sx={{ display: 'none' }}
              component="input"
              ref={dokumentFileInputRef}
              type="file"
              accept={ANGEBOTTYPEN.join(',')}
              onChange={(event) => {
                event.persist();
                if (event?.target?.files) {
                  setDokument(event?.target?.files[0]);
                }
              }}
              data-testid="angebot-fileInput"
            />
          </Grid>
          <Grid item xs={6}>
            <WarningSign text="Es wurde kein Dokument ausgewählt." show={!dokument} />
            {dokument && dokument.name}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Abbrechen
        </Button>
        <Button
          onClick={() => onAdd(angebot)}
          disabled={!kannHinzugefuegtWerden || isLoading}
          variant="contained"
          color="primary"
          data-testid="angebotHinzufuegen"
        >
          Hinzufügen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
