import React, { useCallback, useEffect, useState } from 'react';
import { Dateityp, Gutachtenart } from '../../shared/constants';
import { Euro } from '../../components/common/Euro';
import Grid from '@mui/material/Grid';
import { Mandant, Vorgang, Zahlungseingang } from '../../types';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import { SPACING_BETWEEN_BOXES } from '../../components/common/spacings';
import Formular from '../../components/common/Formular';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { berechneGesamtzahlungsbetrag } from '../../shared/util';
import Tabelle from '../../components/Tabelle/Tabelle';
import { api } from '../../apigateway';
import { zahlungseingang } from '../../shared/url';
import { getMessageFromError } from '../../shared/throw';
import { ERROR_MESSAGE } from '../../components/common/Alert';
import { useSnackbar } from 'notistack';
import Bestaetigungsdialog from '../../components/dialog/Bestaetigungsdialog';
import { useUser } from '../../hooks/useUser';
import { Dokumententabelle } from '../../components/Dokumententabelle';

export function RechnungPage(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { vorgang, isLoading, setLoading, holeVorgang } = useVorgangContext();
  const { isAdmin, isVerwaltung } = useUser();
  const [mandant, setMandant] = useState<Mandant | null>(null);
  const [schadenhoehe, setSchadenhoehe] = useState(0);
  const [anzahlLichtbilder, setAnzahlLichtbilder] = useState(0);
  const [grundhonorar, setGrundhonorar] = useState(0);
  const [zahlungseingangZumLoeschen, setZahlungseingangZumLoeschen] = useState<Zahlungseingang | null>();

  useEffect(() => {
    const holeMandantDaten = async () => {
      const mandant = await makeGraphqlQuery<Mandant>(queries.holeMandant, {
        mandant: vorgang?.mandant
      });
      setMandant(mandant);
    };

    if (vorgang?.id) {
      holeMandantDaten();

      setSchadenhoehe(ermittleSchadenhoehe(vorgang));

      setAnzahlLichtbilder(vorgang?.fotos?.filter((foto) => foto?.kategorien?.includes('Gutachtenrelevant')).length ?? 0);
    }
  }, [vorgang]);

  useEffect(() => {
    let grundhonorar = 0;

    if (schadenhoehe > 0) {
      const eintrag = mandant?.honorartabelle?.find((honorarEintrag, index: number) => {
        if ((honorarEintrag?.schadenhoehe ?? 0) / 100 >= schadenhoehe) {
          return mandant?.honorartabelle && mandant.honorartabelle[index !== 0 ? index - 1 : index];
        }
        return false;
      });

      if (eintrag && eintrag.grundhonorar) {
        grundhonorar = eintrag.grundhonorar / 100;
      }
    }

    setGrundhonorar(grundhonorar);
  }, [schadenhoehe, mandant]);

  const zahlungseingangLoeschen = useCallback(
    (zeile: Zahlungseingang) => {
      setLoading(true);
      api
        .request(zahlungseingang.loeschePostUrl(zeile.id, zeile.jahr, zeile.importDatum))
        .then(() => enqueueSnackbar('Zahlungseingang gelöscht'))
        .then(() => holeVorgang())
        .catch((error) => enqueueSnackbar(`Die Zahlungshistorie konnte nicht zurückgesetzt werden: ${getMessageFromError(error.data)}`, ERROR_MESSAGE))
        .finally(() => setLoading(false));
    },
    [setLoading, enqueueSnackbar, holeVorgang]
  );

  const zahlungsimportErlaubt = isVerwaltung || isAdmin;

  return (
    <>
      <Bestaetigungsdialog
        title="Eingang löschen"
        text="Möchten Sie den Zahlungseingang wirklich löschen?"
        open={!!zahlungseingangZumLoeschen}
        onClose={() => setZahlungseingangZumLoeschen(null)}
        onDelete={() => {
          zahlungseingangLoeschen(zahlungseingangZumLoeschen!);
          setZahlungseingangZumLoeschen(null);
        }}
      />

      <Grid container spacing={SPACING_BETWEEN_BOXES}>
        <Grid item xs={12} lg={6}>
          <Formular ueberschrift="Rechnungsparameter">
            <Grid container>
              {vorgang?.gutachtenart !== Gutachtenart.FAHRZEUGBEWERTUNG && (
                <>
                  <Grid item xs={6}>
                    Schadenhöhe:
                  </Grid>
                  <Grid item xs={6}>
                    <Euro value={schadenhoehe} />
                  </Grid>
                  <Grid item xs={6}>
                    Grundhonorar:
                  </Grid>
                  <Grid item xs={6}>
                    {grundhonorar > 0 ? <Euro value={grundhonorar} /> : 'gesondert ermitteln'}
                  </Grid>
                </>
              )}
              <Grid item xs={6}>
                Anzahl Lichtbilder:
              </Grid>
              <Grid item xs={6}>
                {anzahlLichtbilder}
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                1. Fotosatz: <Euro value={anzahlLichtbilder * 2 || 0} />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                2. Fotosatz: <Euro value={anzahlLichtbilder * 0.5 || 0} />
              </Grid>
              <Grid item xs={6}>
                Anzahl Besichtigung:
              </Grid>
              <Grid item xs={6}>
                {vorgang?.besichtigungen?.items?.length}
              </Grid>
            </Grid>
          </Formular>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Formular ueberschrift="Rechnungsdokumente">
            <Dokumententabelle
              spalten={['dateiname', 'uploaddatum', 'kategorien', 'wert']}
              dokumente={nurRechnungsdokumente(vorgang)}
              dataTestId="rechnungen"
            />
          </Formular>
        </Grid>
        {vorgang?.zahlungseingang && (
          <Grid item xs={12} lg={12}>
            <Formular ueberschrift="Zahlungseingänge">
              <Tabelle
                actions={
                  zahlungsimportErlaubt
                    ? [
                        {
                          label: 'Löschen',
                          onClick: setZahlungseingangZumLoeschen,
                          disabled: isLoading
                        }
                      ]
                    : null
                }
                columns={[
                  { label: 'Rechnungsnummer', id: 'rechnungsnummer' },
                  { label: 'Status', id: 'status' },
                  { label: 'Datum', id: 'bezahldatum' },
                  { label: 'Betrag', id: 'betrag', render: (zeile) => <Euro value={zeile.betrag}></Euro> },
                  { label: 'Name', id: 'name' },
                  { label: 'Beschreibung', id: 'beschreibung' }
                ]}
                tableData={vorgang.zahlungseingang}
                disabled={isLoading}
                isLoading={isLoading}
              />
              <b>
                Gesamtbetrag: <Euro value={berechneGesamtzahlungsbetrag(vorgang.zahlungseingang)}></Euro>
              </b>
            </Formular>
          </Grid>
        )}
      </Grid>
    </>
  );
}

function nurRechnungsdokumente(vorgang: Vorgang) {
  return (vorgang?.dateien ?? [])
    .filter((datei) => datei?.typ === Dateityp.DOKUMENT)
    .filter((datei) => datei?.kategorien?.some((it) => it === 'Rechnung' || it === 'Fremdrechnung'));
}

export function ermittleSchadenhoehe(vorgang: Vorgang): number {
  const summeReparaturkostenMerkantilerMinderwert =
    parseFloat(vorgang.bewertung?.reparaturkosten ?? '0') / 1.19 + parseFloat(vorgang.bewertung?.merkantilerMinderwert ?? '0');

  return summeReparaturkostenMerkantilerMinderwert < parseFloat(vorgang?.bewertung?.wiederbeschaffungswert ?? '0')
    ? summeReparaturkostenMerkantilerMinderwert
    : parseFloat(vorgang.bewertung?.wiederbeschaffungswert ?? '0');
}
