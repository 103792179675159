import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';
import { dialogActions } from './Dialog';
import { EmailTextField } from '../common/EmailTextField';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSend: (anAlleHaendler: boolean, haendlerEmailadresse: string) => void;
  readonly isLoading: boolean;
};

export function HaendlerangebotEinholenDialog({ open, onClose, onSend, isLoading }: Props): JSX.Element {
  const [haendlerEmailadresse, setHaendlerEmailadresse] = useState('');
  const [anAlleHaendler, setAnAlleHaendler] = useState(true);

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="angebotHinzufuegen">
      <DialogTitle id="angebotHinzufuegen">Händlerangebot einholen</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Bitte geben Sie die Emailadresse des Händlers ein. Es werden alle Bilder mit der Kategorie &quot;Angebotsanfrage&quot; mit versendet.
        </DialogContentText>
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox color="primary" checked={anAlleHaendler} onChange={(event) => setAnAlleHaendler(event.target.checked)} name="restwerthaendler" />
              }
              label="An alle Restwerthändler senden"
            />
          </Grid>
          <Grid item xs={12}>
            <EmailTextField
              label="Händler Email"
              onChange={(event) => setHaendlerEmailadresse(event.target.value)}
              fullWidth
              data-testid="haendler-firmenname"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Abbrechen
        </Button>
        <Button
          onClick={() => onSend(anAlleHaendler, haendlerEmailadresse)}
          disabled={isLoading || (haendlerEmailadresse.trim().length === 0 && !anAlleHaendler)}
          variant="contained"
          color="primary"
          data-testid="haendler-versenden"
        >
          Versenden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
