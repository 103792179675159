import React, { memo, useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import SpeedDial from '@mui/material/SpeedDial';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { SPACING_BETWEEN_BOXES, SPACING_TO_FAB } from '../../components/common/spacings';
import Formular from '../../components/common/Formular';
import { GUTACHTENARTEN_TEXTE } from '../../shared/domain/vorgang';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import { arrayMove, SortableContainer, SortableElement, SortableHandle, SortEndHandler } from 'react-sortable-hoc';
import { COGNITO_GRUPPE_ADMIN, Gutachtenart } from '../../shared/constants';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import { useSnackbar } from 'notistack';
import MandantenAuswahl from '../../components/common/MandantenAuswahl';
import { Regel, Textbaustein as TextbausteinType } from '../../types';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { useUser } from '../../hooks/useUser';
import Box from '@mui/material/Box';

export const TEXTBAUSTEINREGELTEXTE: Record<string, string> = {
  [Regel.IMMER]: 'immer',
  [Regel.NACHBESICHTIGUNG]: 'Besichtigungen > 1',
  [Regel.REPARATURKOSTEN_GROESSER_WIEDERBESCHAFFUNGSWERT]: 'Reparaturkosten > Wiederbeschaffungswert',
  [Regel.REPARATURKOSTEN_KLEINER_WIEDERBESCHAFFUNGSWERT]: 'Reparaturkosten < Wiederbeschaffungswert',
  [Regel.REPARATURWUERDIG]: '(Reparaturkosten : Wiederbeschaffungswert) < 1',
  [Regel.REPARATURGRAENZE]: '(Reparaturkosten : Wiederbeschaffungswert) > 1 < 1,3',
  [Regel.AELTER_ALS_3_JAHRE_UND_SCHECKHEFT]: 'Fahrzeug älter als 3 Jahre und Scheckheftgepflegt',
  [Regel.AELTER_ALS_3_JAHRE_UND_NICHT_SCHECKHEFT]: 'Fahrzeug älter als 3 Jahre und nicht Scheckheftgepflegt',
  [Regel.FAHRZEUG_JUENGER_ALS_3_JAHRE]: 'Fahrzeug jünger als drei Jahre',
  [Regel.REPARATURKOSTEN_KLEINER_50PROZENT_WIEDERBESCHAFFUNGSWERT]: 'Reparaturkosten < 50% vom Wiederbeschaffungswert',
  [Regel.REPARATURKOSTEN_GROESSER_50PROZENT_WIEDERBESCHAFFUNGSWERT]: 'Reparaturkosten > 50% vom Wiederbeschaffungswert',
  [Regel.REPARATURWERKSTATT_ANGEGEBEN]: 'Konkrete Reparaturwerkstatt wurde angegeben',
  [Regel.FAHRZEUGALTER_GROESSER_120_MONATE]: 'Fahrzeugalter > 120 Monate',
  [Regel.FAHRZEUGALTER_KLEINER_120_MONATE]: 'Fahrzeugalter < 120 Monate',
  [Regel.MARKIERUNG_FAHRZEUGGRAFIK_IN_PROEXPERT]: 'Markierung an Fahrzeuggrafik in ProExpert',
  [Regel.MERKANTILER_MINDERWERT_VORHANDEN]: 'Merkantiler Minderwert gegeben, gemäß der Bedigungen der Berechnungsmethoden',
  [Regel.MERKANTILER_MINDERWERT_KLEINER_100_EURO]: 'Merkantiler Minderwert < 100,00 EUR',
  [Regel.MERKANTILER_MINDERWERT_NICHT_VORHANDEN_FAHRZEUG_AELTER_10_JAHRE]: 'Merkantiler Minderwert nicht gegeben, Alter > 10 Jahre',
  [Regel.RICHTBANK_ERFORDERLICH]: 'Richtbank erforderlich',
  [Regel.VERMESSUNG_VORDERACHSE]: 'Vermessung Achsen',
  [Regel.PRUEFARBEITEN_ERFORDERLICH]: 'Prüfarbeiten erforderlich',
  [Regel.FAHRBEREIT]: 'Fahrbereit',
  [Regel.NICHT_FAHRBEREIT]: 'nicht Fahrbereit',
  [Regel.VERKEHRSSICHERHEIT]: 'Verkehrssicherheit',
  [Regel.VERKEHRSSICHER_NACH_NOTREPARATUR]: 'Verkehrssicher nach Notreparatur',
  [Regel.ZULASSUNGSBESCHEINIGUNG_TEIL_1]: 'Zulassungsbescheinigung Teil I',
  [Regel.ZULASSUNGSBESCHEINIGUNG_TEIL_2]: 'Zulassungsbescheinigung Teil II',
  [Regel.DURCHGEFUEHRTE_ACHSVERMESSUNG_MIT_SCHADENSFESTSTELLUNG]: 'Durchgeführte Achsvermessung mit Schadensfeststellung',
  [Regel.DURCHGEFUEHRTE_ACHSVERMESSUNG_OHNE_SCHADENSFESTSTELLUNG]: 'Durchgeführte Achsvermessung ohne Schadensfeststellung',
  [Regel.BEILACKIERUNG_ERFORDERLICH]: 'Beilackierung erforderlich',
  [Regel.ACHSWEISE_ERNEUERUNG_DER_REIFEN]: 'Achsweise Erneuerung der Reifen',
  [Regel.LENKGETRIEBE_ERNEUERN]: 'Lenkgetriebe erneuern',
  [Regel.REPARATURKOSTEN_GROESSER_200_PROZENT_WIEDERBESCHAFFUNGSWERT_KEIN_RESTWERT]:
    'Reparaturkosten > 200% vom Wiederbeschaffungswert. Kein Restwert erzielbar',
  [Regel.WERTVERBESSERUNG]: 'Wertverbesserung',
  [Regel.LACKSCHICHTENDICKENMESSUNG]: 'Lackschichtendickenmessung',
  [Regel.KAROSSERIEVERMESSUNG]: 'Karosserievermessung',
  [Regel.ANHAENGERKUPPLUNG]: 'Anhängerkupplung',
  [Regel.FAHRZEUGZUSTAND]: 'Fahrzeugzustand',
  [Regel.ACHSWEISE_ERNEUERUNG_DER_STOSSDAEMPFER]: 'Achsweise erneuerung der Stoßdämpfer',
  [Regel.MIETWAGENKLASSE_VORHANDEN]: 'Mietwagenklasse vorhanden',
  [Regel.NUTZUNGSAUSFALLKLASSE_VORHANDEN]: 'Nutzungsausfallklasse vorhanden',
  [Regel.PROBEFAHRT_ERFORDERLICH]: 'Probefahrt erforderlich',
  [Regel.FEHLERSPEICHER_AUSLESEN]: 'Fehlerspeicher auslesen',
  [Regel.ALTSCHAEDEN_ODER_VORSCHAEDEN_VORHANDEN]: 'Wenn Altschäden und/oder reparierte Vorschäden vorhanden',
  [Regel.KORREKTUR_DURCH_VORSCHAEDEN_GROESSER_0]: 'Wenn Korrektur durch Vorschäden > 0',
  [Regel.REGELBESTEUERUNG_JA]: 'Regelbesteuerung ausgewählt'
};

type SortableTextbausteinProps = {
  readonly textbaustein: TextbausteinType;
  readonly textbausteinIndex: number;
  readonly isLoading: boolean;
  readonly aktualisiereTextbaustein: (reihenfolge: number | null | undefined, attribut: keyof TextbausteinType, wert: never) => void;
  readonly handleTextbausteinLoeschen: (position: number) => void;
};

type SortableTextbausteineContainerProps = {
  readonly items: TextbausteinType[];
  readonly isLoading: boolean;
  readonly aktualisiereTextbaustein: (reihenfolge: number | null | undefined, attribut: keyof TextbausteinType, wert: never) => void;
  readonly handleTextbausteinLoeschen: (position: number) => void;
};

const SortItem = memo(
  SortableElement<SortableTextbausteinProps>(
    ({ textbaustein, textbausteinIndex, isLoading, aktualisiereTextbaustein, handleTextbausteinLoeschen }: SortableTextbausteinProps) => {
      return (
        <Grid item xs={12} lg={6} key={textbaustein.id}>
          <Textbaustein
            key={textbaustein.id}
            index={textbausteinIndex}
            textbaustein={textbaustein}
            aktualisiereTextbaustein={aktualisiereTextbaustein}
            isLoading={isLoading}
            handleTextbausteinLoeschen={handleTextbausteinLoeschen}
          />
        </Grid>
      );
    }
  )
);

const SortableList = SortableContainer<SortableTextbausteineContainerProps>(
  ({ items: textbausteine, isLoading, aktualisiereTextbaustein, handleTextbausteinLoeschen }: SortableTextbausteineContainerProps) => {
    return (
      <Grid container spacing={3}>
        {textbausteine.map((textbaustein, index) => (
          <SortItem
            key={textbaustein.id}
            index={index}
            textbaustein={textbaustein}
            textbausteinIndex={index}
            isLoading={isLoading}
            aktualisiereTextbaustein={aktualisiereTextbaustein}
            handleTextbausteinLoeschen={handleTextbausteinLoeschen}
          />
        ))}
      </Grid>
    );
  }
);

export default function TextbausteineAdmin(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { isAdmin, isExperts24Admin } = useUser();

  const [gutachtenart, setGutachtenart] = useState<Gutachtenart>(Gutachtenart.SCHADENSGUTACHTEN_HAFTPFLICHT);
  const [isLoading, setIsLoading] = useState(false);
  const [aktuellerMandant, setAktuellerMandant] = useState('');
  const [textbausteine, setTextbausteine] = useState<TextbausteinType[]>([]);

  useEffect(() => {
    let didCancel = false;

    async function holeTextbausteine() {
      try {
        if (aktuellerMandant === '') {
          setTextbausteine([]);
        } else {
          setIsLoading(true);
          const tb = await holeTextbausteineZuGutachtenart(gutachtenart, aktuellerMandant);
          if (!didCancel) {
            setTextbausteine(
              sortByReihenfolge(tb).map((textbaustein, reihenfolge) => ({
                ...textbaustein,
                reihenfolge
              }))
            );
          }
        }
      } catch (error) {
        console.log({ error });
        enqueueSnackbar('Die Textbausteine konnten nicht geholt werden', ERROR_MESSAGE);
      } finally {
        setIsLoading(false);
      }
    }

    if (gutachtenart) {
      holeTextbausteine();
    }
    return () => {
      didCancel = true;
    };
  }, [gutachtenart, setIsLoading, aktuellerMandant, enqueueSnackbar]);

  const gutachtenarten = Object.entries(GUTACHTENARTEN_TEXTE).map(([value, label]) => ({ label, value }));

  const aktualisiereTextbaustein = useCallback((reihenfolge: number | null | undefined, attribut: keyof TextbausteinType, wert: never) => {
    setTextbausteine((current) => {
      return current.map((textbaustein): TextbausteinType => {
        if (textbaustein.reihenfolge === reihenfolge) {
          return {
            ...textbaustein,
            [attribut]: wert
          };
        }
        return textbaustein;
      });
    });
  }, []);

  const handleTextbausteineSpeichernClick = async () => {
    try {
      setIsLoading(true);
      await aktualisiereTextbausteineZuGutachtenart(gutachtenart, textbausteine, aktuellerMandant);

      enqueueSnackbar('Die Änderungen wurden erfolgreich gespeichert', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });

      enqueueSnackbar('Die Änderungen konnten nicht gespeichert werden.', ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTextbausteinHinzufuegen = () => {
    setTextbausteine((current: TextbausteinType[]) => [
      ...current,
      {
        mandant: aktuellerMandant,
        gutachtenart,
        ueberschrift: '',
        text: '',
        regel: Regel.IMMER,
        reihenfolge: textbausteine.length
      }
    ]);
  };

  const handleTextbausteinLoeschen = useCallback((position: number) => {
    setTextbausteine((current) => {
      const textbausteineCopy = [...current];
      if (position > -1) {
        textbausteineCopy.splice(position, 1);
      }

      return sortByReihenfolge(textbausteineCopy).map((tb, reihenfolge) => ({
        ...tb,
        reihenfolge
      }));
    });
  }, []);

  const onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    setTextbausteine(
      arrayMove(textbausteine, oldIndex, newIndex).map((tbs, index) => {
        return { ...tbs, reihenfolge: index };
      })
    );
  };

  const handleTextbausteineExportieren = async () => {
    const csvData = ['Gutachtenart;Regel;Reihenfolge;Überschrift;Text'];
    const tb = await holeTextbausteineZuMandant(aktuellerMandant);
    const tbSortiert = [];
    tbSortiert.push(
      ...getTextbausteineFromGutachtenart(Gutachtenart.SCHADENSGUTACHTEN_HAFTPFLICHT, tb),
      ...getTextbausteineFromGutachtenart(Gutachtenart.KURZGUTACHTEN, tb),
      ...getTextbausteineFromGutachtenart(Gutachtenart.SCHADENSGUTACHTEN_KASKO, tb)
    );
    tbSortiert.forEach((textbaustein) => {
      csvData.push(getCsvDataFromTextbaustein(textbaustein));
    });
    const url = window.URL.createObjectURL(new Blob([csvData.join('')]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'textbausteine.csv');
    document.body.appendChild(link);
    link.click();
  };

  const getCsvDataFromTextbaustein = (textbaustein: TextbausteinType) => {
    // Zeilenumbrüche ersetzen, da es sonst zu einem Fehler in der CSV kommt
    return `\n${textbaustein.gutachtenart};${textbaustein.regel};${textbaustein.reihenfolge};${textbaustein.ueberschrift};${textbaustein.text?.replace(
      /\n/g,
      ''
    )}`;
  };

  const getTextbausteineFromGutachtenart = (gutachtenart: Gutachtenart, textbausteine: TextbausteinType[]) => {
    return textbausteine
      .filter((t) => t.gutachtenart === gutachtenart)
      .sort((a, b) => ((a.reihenfolge ?? 0) > (b.reihenfolge ?? 0) ? 1 : (a.reihenfolge ?? 0) < (b.reihenfolge ?? 0) ? -1 : 0));
  };

  return (
    <>
      <Toolbar>
        <Typography
          variant="h6"
          sx={{
            display: 'flex',
            flexGrow: 1,
            flexWrap: 'nowrap',
            alignItems: 'center'
          }}
        >
          Textbausteine
        </Typography>
        {(isAdmin || isExperts24Admin) && (
          <Button onClick={handleTextbausteineExportieren} disabled={isLoading} data-testid="textbausteine_exportieren">
            <CloudDownloadIcon />
          </Button>
        )}
        <MandantenAuswahl onSelectMandant={setAktuellerMandant} gruppe={COGNITO_GRUPPE_ADMIN} />
        <SpeedDial
          ariaLabel="Menü"
          sx={(theme) => ({
            position: 'fixed',
            bottom: theme.spacing(SPACING_TO_FAB * 2),
            right: theme.spacing(SPACING_TO_FAB),
            zIndex: theme.zIndex.drawer + 2
          })}
          icon={<SaveIcon data-testid="speichern" />}
          onClick={handleTextbausteineSpeichernClick}
          hidden={
            isLoading ||
            textbausteine.some(
              (textbaustein) => !textbaustein.ueberschrift || textbaustein.ueberschrift === '' || !textbaustein.text || textbaustein.text === ''
            )
          }
          open={false}
        />
      </Toolbar>
      <Grid container spacing={SPACING_BETWEEN_BOXES}>
        <Grid item xs={12}>
          <Formular ueberschrift="Gutachtenart">
            <Grid container spacing={SPACING_BETWEEN_BOXES}>
              <Grid item xs={3}>
                <Select
                  variant="standard"
                  label="Gutachtenart"
                  value={gutachtenart}
                  disabled={isLoading}
                  onChange={(event) => {
                    setGutachtenart(event.target.value as Gutachtenart);
                  }}
                  data-testid="gutachtenart"
                >
                  {gutachtenarten.map((g) => (
                    <MenuItem key={g.value} value={g.value} data-testid={g.label}>
                      {g.label}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
          </Formular>
        </Grid>

        <Grid item xs={12}>
          <Formular ueberschrift="Textbausteine" {...(isLoading ? {} : { 'data-anzahl-textbausteine': textbausteine.length })}>
            <Grid container spacing={SPACING_BETWEEN_BOXES}>
              {isLoading ? (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  <CircularProgress />
                </Grid>
              ) : (
                <SortableList
                  axis="xy"
                  items={textbausteine}
                  onSortEnd={onSortEnd}
                  useDragHandle
                  useWindowAsScrollContainer={true}
                  isLoading={isLoading}
                  aktualisiereTextbaustein={aktualisiereTextbaustein}
                  handleTextbausteinLoeschen={handleTextbausteinLoeschen}
                />
              )}
              <Grid item xs={12}>
                <Card raised>
                  <CardHeader
                    avatar={
                      <Tooltip title="Textbaustein hinzufügen">
                        <div>
                          <Button onClick={handleTextbausteinHinzufuegen} disabled={isLoading} data-testid="textbaustein_hinzufuegen">
                            <AddIcon />
                          </Button>
                        </div>
                      </Tooltip>
                    }
                  />
                </Card>
              </Grid>
            </Grid>
          </Formular>
        </Grid>
      </Grid>
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
}

type TextbausteinProps = {
  textbaustein: TextbausteinType;
  aktualisiereTextbaustein: (reihenfolge: number | null | undefined, attribut: keyof TextbausteinType, wert: never) => void;
  handleTextbausteinLoeschen: (position: number) => void;
  isLoading: boolean;
  index: number;
};

function Textbaustein({ textbaustein, aktualisiereTextbaustein, handleTextbausteinLoeschen, isLoading, index }: TextbausteinProps) {
  const DragHandle = SortableHandle(() => <DragIndicatorIcon sx={{ marginLeft: 'auto' }} />);

  const regeln = Object.entries(TEXTBAUSTEINREGELTEXTE).map(([value, label]) => ({
    label,
    value
  }));
  return (
    <Grid item xs={12}>
      <Card raised>
        <CardHeader
          title={
            <Box component="div" sx={{ display: 'flex' }}>
              <TextField
                variant="standard"
                sx={{ width: '80%' }}
                defaultValue={textbaustein.ueberschrift}
                data-testid={`ueberschrift-${textbaustein.reihenfolge}`}
                onChange={(event) => aktualisiereTextbaustein(textbaustein.reihenfolge, 'ueberschrift', event.target.value as never)}
              />
              <DragHandle />
            </Box>
          }
          avatar={
            <>
              {index + 1}
              <Tooltip title="Textbaustein löschen">
                <div>
                  <IconButton
                    color="primary"
                    onClick={() => {
                      if (textbaustein.reihenfolge || textbaustein.reihenfolge === 0) {
                        handleTextbausteinLoeschen(textbaustein.reihenfolge);
                      }
                    }}
                    disabled={isLoading}
                    data-testid={`loeschen-${textbaustein.reihenfolge}`}
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Tooltip>
            </>
          }
        />
        <CardContent>
          <TextField
            variant="standard"
            fullWidth
            multiline
            defaultValue={textbaustein.text}
            data-testid={`text-${textbaustein.reihenfolge}`}
            onChange={(event) => aktualisiereTextbaustein(textbaustein.reihenfolge, 'text', event.target.value as never)}
          />
        </CardContent>
        <CardActions disableSpacing>
          <Hidden smDown>
            <FormControl variant="standard" sx={(theme) => ({ marginLeft: theme.spacing(1) })}>
              <InputLabel>Regel</InputLabel>
              <Select
                variant="standard"
                label="Regel"
                value={textbaustein.regel}
                tabIndex={2}
                data-testid={`regel-${textbaustein.reihenfolge}`}
                onChange={(event) => aktualisiereTextbaustein(textbaustein.reihenfolge, 'regel', event.target.value as never)}
              >
                {regeln.map((r) => (
                  <MenuItem key={r.value} value={r.value} data-testid={`regel-${r.label}-${textbaustein.reihenfolge}`}>
                    {r.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Hidden>
        </CardActions>
      </Card>
    </Grid>
  );
}

async function holeTextbausteineZuGutachtenart(gutachtenart: Gutachtenart, mandant: string): Promise<TextbausteinType[]> {
  const textbausteine = await makeGraphqlQuery<string>(queries.holeTextbausteineZuGutachtenart, {
    gutachtenart,
    mandant
  });

  return JSON.parse(textbausteine) as TextbausteinType[];
}

async function aktualisiereTextbausteineZuGutachtenart(gutachtenart: Gutachtenart, textbausteine: TextbausteinType[], mandant: string): Promise<void> {
  await makeGraphqlQuery(mutations.aktualisiereTextbausteineZuGutachtenart, {
    gutachtenart,
    // Zeilenumbrüche ersetzen, da es sonst zu einem Fehler in der CSV kommt
    textbausteine: textbausteine.map((textbaustein) => JSON.stringify({ ...textbaustein, text: textbaustein.text?.replace(/\n/g, '') })),
    mandant
  });
}

async function holeTextbausteineZuMandant(mandant: string): Promise<TextbausteinType[]> {
  const textbausteine = await makeGraphqlQuery<string>(queries.holeTextbausteineZuMandant, {
    mandant
  });

  return JSON.parse(textbausteine) as TextbausteinType[];
}

function sortByReihenfolge(tb: TextbausteinType[]): TextbausteinType[] {
  return tb.sort((left, right) => (left.reihenfolge <= right.reihenfolge ? -1 : 1));
}
