import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import Formular from '../components/common/Formular';
import { SPACING_BETWEEN_BOXES, SPACING_BETWEEN_FORM_FIELDS } from '../components/common/spacings';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../components/common/Alert';
import LoadingIndicator from '../components/common/LoadingIndicator';
import { EmailTextField } from '../components/common/EmailTextField';
import { benutzerprofil } from '../shared/url';
import { api } from '../apigateway';
import { getMessageFromError } from '../shared/throw';
import { Benutzer, Benutzerprofil } from '../types';
import { useParams } from 'react-router';
import { LandAutocomplete } from '../components/LandTextField';

type FormularDaten = {
  vorname?: string;
  nachname?: string;
  email?: string;
  strasse?: string | null;
  plz?: string | null;
  ort?: string | null;
  land?: string | null;
};

export default function Profil(): JSX.Element | null {
  const { username } = useParams<{ username: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<(Benutzer & Benutzerprofil) | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [formulardaten, setFormulardaten] = useState<FormularDaten>();

  useEffect(() => {
    setIsLoading(true);

    api
      .request(benutzerprofil.get(username))
      .then(setUser)
      .catch((error) => enqueueSnackbar(`Benutzerprofil konnte nicht geladen werden: ${getMessageFromError(error)}`, ERROR_MESSAGE))
      .finally(() => setIsLoading(false));
  }, [enqueueSnackbar, username]);

  useEffect(() => {
    if (user) {
      setFormulardaten({
        vorname: user.vorname,
        nachname: user?.nachname,
        email: user?.email,
        strasse: user.strasse,
        plz: user.plz,
        ort: user.ort,
        land: user.land ?? 'Deutschland'
      });
    }
  }, [user]);

  const handleProfilSpeichern = () => {
    setIsLoading(true);

    api
      .request(
        benutzerprofil.patch(username, {
          username,
          ...formulardaten
        })
      )
      .then((antwort) => {
        enqueueSnackbar('Das Profil wurde erfolgreich aktualisiert.', SUCCESS_MESSAGE_AUTO_HIDE);
        setUser(antwort);
      })
      .catch((error) => enqueueSnackbar(`Benutzerprofil konnte nicht aktualisiert werden: ${getMessageFromError(error)}`, ERROR_MESSAGE))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      {user && (
        <Grid container spacing={SPACING_BETWEEN_BOXES}>
          <Grid item xs={12} md={6}>
            <Formular ueberschrift="Profileinstellungen">
              <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    label="Vorname"
                    value={formulardaten?.vorname}
                    disabled={isLoading}
                    onChange={(event) => setFormulardaten((state) => ({ ...state, vorname: event.target.value }))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    variant="standard"
                    label="Nachname"
                    value={formulardaten?.nachname}
                    disabled={isLoading}
                    onChange={(event) => setFormulardaten((state) => ({ ...state, nachname: event.target.value }))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <EmailTextField
                    label="E-Mail-Adresse"
                    defaultValue={formulardaten?.email}
                    disabled={isLoading}
                    onChange={(event) => setFormulardaten((state) => ({ ...state, email: event.target.value }))}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sx={{ minHeight: '64px' }}>
                  <Typography
                    variant="caption"
                    sx={(theme) => ({
                      color: theme.palette.text.disabled
                    })}
                  >
                    Gruppen
                  </Typography>
                  <br />
                  {(user?.gruppen ?? [])
                    .filter((gruppe: string) => !gruppe.startsWith(`${user?.mandant}_`))
                    .map((gruppe: string) => (
                      <Chip
                        key={gruppe}
                        label={gruppe}
                        size="small"
                        color="default"
                        variant="outlined"
                        sx={{ margin: '0.1rem', height: '20px', userSelect: 'none' }}
                      />
                    ))}
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField variant="standard" label="Kürzel" value={user?.kuerzel?.toUpperCase() ?? ''} disabled={true} fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField variant="standard" label="Mandant" value={user?.mandant?.toUpperCase() ?? ''} disabled={true} fullWidth />
                </Grid>
              </Grid>
            </Formular>
          </Grid>
          <Grid item xs={12} md={6}>
            <Formular ueberschrift="Adresse">
              <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    label="Straße"
                    value={formulardaten?.strasse}
                    disabled={isLoading}
                    onChange={(event) => setFormulardaten((state) => ({ ...state, strasse: event.target.value }))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    label="PLZ"
                    value={formulardaten?.plz}
                    disabled={isLoading}
                    onChange={(event) => setFormulardaten((state) => ({ ...state, plz: event.target.value }))}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="standard"
                    label="Ort"
                    value={formulardaten?.ort}
                    disabled={isLoading}
                    onChange={(event) => setFormulardaten((state) => ({ ...state, ort: event.target.value }))}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12}>
                  <LandAutocomplete
                    value={formulardaten?.land}
                    disabled={isLoading}
                    onChange={(value) => setFormulardaten((state) => ({ ...state, land: value }))}
                    dataTestId="land"
                  />
                </Grid>
              </Grid>
            </Formular>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button variant="contained" color="primary" onClick={handleProfilSpeichern} disabled={isLoading}>
              Speichern
            </Button>
          </Grid>
        </Grid>
      )}
      <LoadingIndicator isLoading={isLoading} />
    </>
  );
}
