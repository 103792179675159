import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { AngebotLoeschenBestaetigungsdialog } from './dialog/AngebotLoeschenBestaetigungsdialog';
import { AngebotMenu } from './menu/AngebotMenu';
import { Angebot, Vorgang } from '../types';
import { dokumente } from '../domain/dokumente';
import { Euro } from './common/Euro';

type Props = {
  readonly vorgang: Vorgang;
  readonly angebot: Angebot;
  readonly onDelete: (angebot: Angebot) => Promise<void>;
};

export function AngebotCard({ vorgang, angebot, onDelete }: Props): JSX.Element {
  const url = useDateiUrl(angebot.key);

  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [angebotLoeschenBestaetigungsdialogOpen, setAngebotLoeschenBestaetigungsdialogOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAngebotLoeschenBestaetigungsdialog = () => {
    setAngebotLoeschenBestaetigungsdialogOpen(true);
    setAnchorEl(null);
  };

  const handleCloseAngebotLoeschenBestaetigungsdialog = () => {
    setAngebotLoeschenBestaetigungsdialogOpen(false);
  };

  const handleDelete = async () => {
    setAngebotLoeschenBestaetigungsdialogOpen(false);
    await onDelete(angebot);
  };

  const datei = dokumente(vorgang).find((dokument) => dokument.key === angebot.key);

  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          title={angebot.firmenname}
          subheader={<Euro value={angebot.wert} />}
          action={
            <IconButton aria-label="settings" onClick={handleClick} size="large">
              <MoreVertIcon />
            </IconButton>
          }
        />
        <CardContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                {angebot.strasse}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                {angebot.plz}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                {angebot.ort}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1" component="p">
                {angebot.telefon}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {url && (
                <Link href={url} underline="always" target="_blank" color="primary">
                  {datei?.dateiname}
                </Link>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <AngebotMenu anchorEl={anchorEl} onClose={handleMenuClose} onOpenAngebotLoeschenBestaetigungsdialog={handleOpenAngebotLoeschenBestaetigungsdialog} />
      <AngebotLoeschenBestaetigungsdialog
        open={angebotLoeschenBestaetigungsdialogOpen}
        onClose={handleCloseAngebotLoeschenBestaetigungsdialog}
        onDelete={handleDelete}
      />
    </Grid>
  );
}
