import crypto from 'crypto';

export function uuid(): string {
  const randomBytes = new Uint8Array(16);
  crypto.getRandomValues(randomBytes);

  randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40; // Set version 4
  randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80; // Set variant

  const chars = Array.from(randomBytes, (byte) => byte.toString(16).padStart(2, '0')).join('');
  return `${chars.slice(0, 8)}-${chars.slice(8, 12)}-${chars.slice(12, 16)}-${chars.slice(16, 20)}-${chars.slice(20)}`;
}
