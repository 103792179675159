import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import ReactDOM from 'react-dom';
import { SPACING_BETWEEN_BOXES, SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';
import { Vorgang } from '../../types';

type Props = {
  vorgang: Vorgang;
  speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
};

export function Verwaltungscheckbox({ vorgang, speichereVorgang }: Props): JSX.Element | null {
  const el = document.querySelector('#portal-verwaltungscheckbox');

  return el
    ? ReactDOM.createPortal(
        <Box p={SPACING_BETWEEN_BOXES}>
          <Paper elevation={5}>
            <Box pt={SPACING_BETWEEN_FORM_FIELDS}>
              <Typography align="center" variant="h6">
                Verwaltung
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <Checkbox
                data-testid={'verwaltungscheckbox'}
                edge="start"
                color="primary"
                checked={Boolean(vorgang?.verwaltungscheckbox)}
                onChange={(event) => {
                  speichereVorgang({
                    verwaltungscheckbox: event.target.checked
                  });
                }}
              />
            </Box>
          </Paper>
        </Box>,
        el
      )
    : null;
}
