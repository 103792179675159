import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import PersonenVerwaltung from './common/PersonenVerwaltung';
import Formular from './common/Formular';
import { AKTION_UNFALLDATEN_ERFASSEN, getDefaultPersonMitTyp } from '../frontendConstants';
import WarningSign from './common/WarningSign';
import PersonAnzeige from './common/PersonAnzeige';
import { useUser } from '../hooks/useUser';
import { Person, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import SearchPersonUnternehmen from './common/SearchPersonUnternehmen';
import { PersonenTyp } from '../shared/constants';
import { getMessageFromError } from '../shared/throw';
import { ERROR_MESSAGE } from './common/Alert';
import { useSnackbar } from 'notistack';
import { aktualisierePerson } from '../domain/aktualisierePerson';

type Props = {
  readonly vorgang: Vorgang;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly setLoading: (value: boolean) => void;
};

export function Unfallgegner({ setLoading, vorgang, speichereVorgang, setzeVorgang }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();
  const [dialog, setDialog] = useState({ anlegen: false, aendern: false });

  const handleUnfallgegnerAusgeaehlt = (person: Person) => {
    speichereVorgang({ unfallgegnerId: person.id });
  };

  const handleAktualisiereUnfallgegner = (person: Person) => {
    setLoading(true);

    aktualisierePerson({
      ...person,
      weitereBearbeiter: Array.from(new Set([...(person.weitereBearbeiter ?? []), ...vorgang.ehemaligeBearbeiter, vorgang.userId]))
    })
      .then((aktualisiertePerson) => {
        setzeVorgang({ ...vorgang, unfallgegner: aktualisiertePerson });
        setDialog({ anlegen: false, aendern: false });
      })
      .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Unfallgegner konnte nicht gespeichert werden', ERROR_MESSAGE))
      .finally(() => setLoading(false));
  };

  const handleLegeUnfallgegnerAn = (person: Person) => {
    setLoading(true);

    aktualisierePerson({
      ...person,
      mandant: vorgang.mandant,
      weitereBearbeiter: Array.from(new Set([...(person.weitereBearbeiter ?? []), ...vorgang.ehemaligeBearbeiter, vorgang.userId]))
    })
      .then((angelegtePerson) => {
        speichereVorgang({ unfallgegnerId: angelegtePerson.id });
        setDialog({ anlegen: false, aendern: false });
      })
      .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Unfallgegner konnte nicht gespeichert werden', ERROR_MESSAGE))
      .finally(() => setLoading(false));
  };

  const handleUnfallgegnerAendernDialog = () => {
    setDialog((current) => ({ ...current, aendern: true }));
  };

  const handleUnfallgegnerAnlegenDialog = () => {
    setDialog((current) => ({ ...current, anlegen: true }));
  };

  const handleUnfallgegnerLoeschen = () => {
    speichereVorgang({ unfallgegnerId: null });
  };

  const isDisabled = !vorgang || !aktionErlaubt(AKTION_UNFALLDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  return (
    <Formular ueberschrift="Unfallgegner">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          <SearchPersonUnternehmen
            mandantId={vorgang?.mandant || ''}
            isDisabled={isDisabled}
            label="Unfallgegner suchen"
            onSelect={(person) => handleUnfallgegnerAusgeaehlt(person as Person)}
            testPraefix="unfallgegner"
            personenUnternehmenTyp={PersonenTyp.UNFALLGEGNER}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            disabled={isDisabled || !vorgang?.unfallgegnerId}
            onClick={() => handleUnfallgegnerAendernDialog()}
            data-testid="unfallgegner-bearbeiten"
            size="large"
          >
            <Tooltip title="Ändern">
              <EditIcon />
            </Tooltip>
          </IconButton>
          <IconButton disabled={isDisabled} onClick={() => handleUnfallgegnerAnlegenDialog()} data-testid="unfallgegner-anlegen" size="large">
            <Tooltip title="Anlegen">
              <AddBoxIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            disabled={isDisabled || !vorgang?.unfallgegnerId}
            onClick={() => handleUnfallgegnerLoeschen()}
            data-testid="unfallgegner-loeschen"
            size="large"
          >
            <Tooltip title="Zuorgnung löschen">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <WarningSign
            show={vorgang?.unfallgegner === null && vorgang?.unfallgegnerId !== null}
            text="Der Unfallgegner wurde gelöscht oder Sie haben nicht die erforderlichen Rechte, diesen zu sehen."
          />
          {vorgang?.unfallgegner && vorgang?.unfallgegner !== getDefaultPersonMitTyp(PersonenTyp.UNFALLGEGNER) && (
            <PersonAnzeige person={vorgang.unfallgegner} testPraefix="unfallgegner" />
          )}
        </Grid>
      </Grid>
      {(dialog.anlegen || dialog.aendern) && (
        <PersonenVerwaltung
          personObjekt={dialog.aendern ? vorgang?.unfallgegner : getDefaultPersonMitTyp(PersonenTyp.UNFALLGEGNER)}
          typ={PersonenTyp.UNFALLGEGNER}
          onClose={() => {
            setDialog({ anlegen: false, aendern: false });
          }}
          aktualisierePerson={handleAktualisiereUnfallgegner}
          legePersonAn={handleLegeUnfallgegnerAn}
          isDisabled={isDisabled}
          testPraefix="unfallgegner"
          withoutRequiredFields={true}
        />
      )}
    </Formular>
  );
}
