import React from 'react';
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

type Props = {
  text: string | null;
  show: boolean;
  color?: 'error' | 'warn';
};

export default function WarningSign({ text, show, color = 'error' }: Props): JSX.Element {
  return show ? (
    <Typography
      color={color === 'warn' ? 'orange' : 'error'}
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <WarningIcon
        sx={{
          marginRight: '0.5rem'
        }}
      />{' '}
      {text}
    </Typography>
  ) : (
    <></>
  );
}
