import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { Datei, Foto } from '../types';
import { toDateTimeString } from '../shared/dateTime';
import { KategorieHinzufuegenDialog } from './dialog/KategorieHinzufuegenDialog';
import { DokumentLoeschenBestaetigungsdialog } from './dialog/DokumentLoeschenBestaetigungsdialog';
import { Euro } from './common/Euro';
import Tabelle from './Tabelle/Tabelle';
import { S3Link } from './common/S3Link';

const PDF_DOKUMENTKATEGORIEN = ['Rechnung', 'Unterschriften', 'WebScan'];

const DOKUMENTKATEGORIEN = ['Angebot', 'Rechnung', 'HIS Antwort', 'Fremdrechnung', 'Unterschriften', 'WebScan'];

type Props = {
  readonly dokumente: Datei[];
  readonly spalten: Array<keyof Datei>;
  readonly handleDokumentLoeschen?: (dokument: Datei) => void;
  readonly handleKategorieHinzufuegen?: (dokument: Datei, kategorie: string, wert?: number | null) => void;
  readonly handleKategorieLoeschen?: (foto: Foto, kategorie: string) => void;
  readonly darfKategorisieren?: boolean;
  readonly darfLoeschen?: boolean;
  readonly dataTestId?: string;
};

export function Dokumententabelle({
  dokumente,
  spalten,
  handleDokumentLoeschen,
  handleKategorieHinzufuegen,
  handleKategorieLoeschen,
  dataTestId,
  darfKategorisieren = false,
  darfLoeschen = false
}: Props): JSX.Element {
  const [dokument, setDokument] = useState<Datei | null>(null);
  const [kategorien, setKategorien] = useState<string[]>([]);
  const [dokumentLoeschenBestaetigungsdialogOpen, setDokumentLoeschenBestaetigungsdialogOpen] = useState(false);
  const [kategorieHinzufuegenDialogOpen, setKategorieHinzufuegenDialogOpen] = useState(false);

  const actions = [];

  if (darfKategorisieren) {
    actions.push({
      label: 'Kategorie hinzufügen',
      onClick: (zeile: Datei) => {
        setKategorien(
          DOKUMENTKATEGORIEN.filter((kategorie) => {
            const hatKategorie = zeile?.kategorien?.includes(kategorie);
            const istPdfTyp = PDF_DOKUMENTKATEGORIEN.includes(kategorie);
            const endetNichtMitPdf = !zeile?.dateiname?.toLowerCase().endsWith('.pdf');

            return !(hatKategorie ?? (istPdfTyp && endetNichtMitPdf));
          })
        );

        setDokument(zeile);
        setKategorieHinzufuegenDialogOpen(true);
      },
      disabled: false
    });
  }
  if (darfLoeschen) {
    actions.push({
      label: 'Löschen',
      onClick: (zeile: Datei) => {
        setDokument(zeile);
        setDokumentLoeschenBestaetigungsdialogOpen(true);
      },
      disabled: false
    });
  }

  const columns = [];

  if (spalten.includes('dateiname')) {
    columns.push({
      label: 'Dateiname',
      render: (zeile: Datei) => {
        return (
          <S3Link s3key={zeile.key ?? ''} underline="always" target="_blank" color="primary">
            {zeile.dateiname}
          </S3Link>
        );
      }
    });
  }
  if (spalten.includes('uploaddatum')) {
    columns.push({ label: 'Datum', render: (zeile: Datei) => toDateTimeString(zeile.uploaddatum) });
  }
  if (spalten.includes('kategorien')) {
    columns.push({
      label: 'Kategorie',
      render: (zeile: Datei) => (
        <>
          {zeile.kategorien?.map((kategorie) => (
            <Chip
              key={kategorie}
              label={kategorie}
              color="primary"
              size="small"
              onDelete={darfLoeschen && !zeile.restwertangebot ? () => handleKategorieLoeschen && handleKategorieLoeschen(zeile, kategorie) : undefined}
            />
          ))}
          {zeile.restwertangebot ? <Box color="error.main">Restwertangebote sind nicht löschbar</Box> : null}
        </>
      )
    });
  }
  if (spalten.includes('wert')) {
    columns.push({ label: 'Nettowert', id: 'betrag', render: (zeile: Datei) => zeile.wert && <Euro value={Number(zeile.wert)}></Euro> });
  }

  return (
    <>
      <Tabelle actions={actions} columns={columns} tableData={dokumente} dataTestId={dataTestId} />

      <DokumentLoeschenBestaetigungsdialog
        open={dokumentLoeschenBestaetigungsdialogOpen}
        onClose={() => setDokumentLoeschenBestaetigungsdialogOpen(false)}
        onDelete={() => handleDokumentLoeschen && dokument && handleDokumentLoeschen(dokument)}
      />

      <KategorieHinzufuegenDialog
        open={kategorieHinzufuegenDialogOpen}
        onClose={() => setKategorieHinzufuegenDialogOpen(false)}
        onAdd={(kategorie: string, wert?: number | null) => {
          setKategorieHinzufuegenDialogOpen(false);
          handleKategorieHinzufuegen && dokument && handleKategorieHinzufuegen(dokument, kategorie, wert);
        }}
        kategorien={kategorien}
        text={'Bitte wählen Sie eine Kategorie aus, die dem Dokument hinzugefügt werden soll.'}
      />
    </>
  );
}
