import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './spacings';
import { LAENDER } from '../../countries';
import { anredeAuswahl, getDefaultPersonMitTyp } from '../../frontendConstants';
import WarningSign from './WarningSign';
import { WarningDialog } from '../dialog/WarningDialog';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as queries from '../../graphql/queries';
import { EmailTextField } from './EmailTextField';
import { deprecated_Benutzer, Person } from '../../types';
import PersonentypAuswahl from './PersonentypAuswahl';
import { PersonenTyp } from '../../shared/constants';
import { CustomDatePicker } from './customDatePickers';
import { Modal } from '../Modal';
import { getCurrentUser } from '../../shared/Auth';

const LAENDER_BY_NATIONALITAET: string[] = LAENDER.map((it) => it.nationalitaet);

const filterOptions = createFilterOptions<string>({
  matchFrom: 'start',
  trim: true
});

type Props = {
  readonly personObjekt: Person | null | undefined;
  readonly typ: PersonenTyp;
  readonly aktualisierePerson: (person: Person) => void;
  readonly legePersonAn: (person: Person) => void;
  readonly onClose: () => void;
  readonly isDisabled: boolean;
  readonly testPraefix: string;
  readonly withoutRequiredFields?: boolean;
};

export default function PersonenVerwaltung({
  personObjekt,
  typ,
  aktualisierePerson,
  legePersonAn,
  onClose,
  isDisabled,
  testPraefix,
  withoutRequiredFields = false
}: Props): JSX.Element {
  const [person, setPerson] = useState(personObjekt ?? getDefaultPersonMitTyp(PersonenTyp.PERSON));
  const [personentyp, setPersonentyp] = useState(typ);
  const [bearbeiter, setBearbeiter] = useState([]);
  const [showWarning, setShowWarning] = useState(true);
  const [showWarningDialog, setShowWarningDialog] = useState(false);

  useEffect(() => {
    const emails = person.email ?? [];
    const telefonnummern = person.telefon ?? [];
    setShowWarning(
      emails.length === 0 || (emails.length > 0 && emails[0] === '') || telefonnummern.length <= 0 || (telefonnummern.length > 0 && telefonnummern[0] === '')
    );
  }, [person]);

  useEffect(() => {
    if (personObjekt) {
      if (!personObjekt.id) {
        personObjekt.typ = [typ];
      }
      setPerson({ ...getDefaultPersonMitTyp(PersonenTyp.PERSON), ...personObjekt });
    }
  }, [personObjekt, typ]);

  useEffect(() => {
    const holeBearbeiter = async () => {
      const b = await makeGraphqlQuery(queries.holeBenutzerliste, {
        mandant: ''
      });
      const authenticatedUser = await getCurrentUser();
      setBearbeiter(b.filter((it: deprecated_Benutzer) => it.id !== authenticatedUser.username));
    };
    holeBearbeiter();
  }, []);

  const aktualisierePersonenAttribute = (attribute: string, wert: string | null) => {
    setPerson({ ...person, [attribute]: wert });
  };

  const aktualisiereTelefonnummern = (index: number, wert: string) => {
    const nummern = [...(person.telefon ?? [])];
    nummern[index] = wert;
    setPerson({ ...person, telefon: nummern });
  };

  const aktualisiereEmailadressen = (index: number, wert: string) => {
    const emailadressen = [...(person.email ?? [])];
    emailadressen[index] = wert;
    setPerson({ ...person, email: emailadressen });
  };

  const emailHinzufuegen = () => {
    const vorhandeneEmailadressen = person?.email ? person.email : [];
    setPerson({ ...person, email: [...vorhandeneEmailadressen, ''] });
  };

  const telefonnummerHinzufuegen = () => {
    const vorhandeneTelefonnr = person?.telefon ? person.telefon : [];
    setPerson({ ...person, telefon: [...vorhandeneTelefonnr, ''] });
  };

  const loescheTelefonnummer = (index: number) => {
    const telefonnummer = [...(person?.telefon ?? [])];
    telefonnummer.splice(index, 1);
    setPerson({ ...person, telefon: [...telefonnummer] });
  };

  const loescheEmail = (index: number) => {
    const emailadressen = [...(person?.email ?? [])];
    emailadressen.splice(index, 1);
    setPerson({ ...person, email: [...emailadressen] });
  };

  const speichern = () => {
    if (showWarning) {
      setShowWarningDialog(true);
    } else {
      speicherePersonendaten();
    }
  };

  const speicherePersonendaten = () => {
    if (person.id) {
      aktualisierePerson(person);
    } else {
      legePersonAn(person);
    }
  };

  const handleAddTyp = () => {
    if (!person?.typ?.includes(personentyp)) {
      const personentypen = person?.typ ? [...person.typ] : [];
      personentypen.push(personentyp);
      setPerson({ ...person, typ: personentypen });
    }
  };

  const handleDeleteTyp = (t: PersonenTyp) => {
    const personentypen = person?.typ ? [...person.typ] : [];
    if (t !== typ) {
      personentypen.splice(personentypen.indexOf(t), 1);
      setPerson({ ...person, typ: personentypen });
    }
  };

  const handleDeleteBearbeiter = (b: string) => {
    const tempBearbeiter = person.weitereBearbeiter ? [...person.weitereBearbeiter] : [];
    tempBearbeiter.splice((person.weitereBearbeiter ?? []).indexOf(b), 1);
    setPerson({ ...person, weitereBearbeiter: [...tempBearbeiter] });
  };

  const pflichtFelderAusgefuellt = () => {
    if (withoutRequiredFields) {
      return true;
    }
    return person?.email?.[0] !== '' || person?.telefon?.[0] !== '';
  };

  return (
    <>
      <Modal
        openModal={true}
        setOpenModal={onClose}
        ariaLabelledby="texterkennungModalUeberschrift"
        title={`Personenverwaltung ${person.id}`}
        body={
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard">
                <PersonentypAuswahl
                  disabled={isDisabled}
                  personentyp={personentyp}
                  setPersonentyp={setPersonentyp}
                  dataTestid={`${testPraefix}-personenverwaltung-typ`}
                />
              </FormControl>

              <IconButton onClick={handleAddTyp} size="large">
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={12} sm={6}>
              {person?.typ?.map((t, index) => {
                return <Chip key={index} label={t} onDelete={() => handleDeleteTyp(t)} disabled={t === typ} />;
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>weitere berechtigte Personen</InputLabel>
                <Select
                  variant="standard"
                  multiple
                  disabled={isDisabled}
                  value={person?.weitereBearbeiter ?? []}
                  onChange={(event) => {
                    setPerson({
                      ...person,
                      weitereBearbeiter: event.target.value as string[]
                    });
                  }}
                  data-testid={`${testPraefix}-personenverwaltung-weitereBearbeiter`}
                >
                  {bearbeiter.map((b: deprecated_Benutzer) => (
                    <MenuItem value={b.id ?? undefined} key={b.id} data-testid={`${testPraefix}-${b.id}`}>
                      {b.id}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              {person?.weitereBearbeiter?.map((b, index) => {
                return <Chip key={index} label={b} onDelete={() => handleDeleteBearbeiter(b)} />;
              })}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Anrede</InputLabel>
                <Select
                  variant="standard"
                  disabled={isDisabled}
                  value={person?.anrede ?? ''}
                  onChange={(event) => {
                    aktualisierePersonenAttribute('anrede', event.target.value);
                  }}
                  data-testid={`${testPraefix}-personenverwaltung-anrede`}
                >
                  {anredeAuswahl.map((a) => (
                    <MenuItem value={a.value} key={a.value} data-testid={a.label}>
                      {a.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                disabled={isDisabled}
                label="Firma"
                defaultValue={person?.firmenname ?? ''}
                onChange={(event) => aktualisierePersonenAttribute('firmenname', event.target.value)}
                fullWidth
                data-testid={`${testPraefix}-personenverwaltung-firmenname`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                disabled={isDisabled}
                label="Vorname"
                defaultValue={person?.vorname ?? ''}
                onChange={(event) => aktualisierePersonenAttribute('vorname', event.target.value)}
                fullWidth
                data-testid={`${testPraefix}-personenverwaltung-vorname`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                disabled={isDisabled}
                label="Nachname"
                defaultValue={person?.nachname ?? ''}
                onChange={(event) => aktualisierePersonenAttribute('nachname', event.target.value)}
                fullWidth
                data-testid={`${testPraefix}-personenverwaltung-nachname`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                disabled={isDisabled}
                label="Straße"
                defaultValue={person?.strasse ?? ''}
                onChange={(event) => aktualisierePersonenAttribute('strasse', event.target.value)}
                fullWidth
                data-testid={`${testPraefix}-personenverwaltung-strasse`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                disabled={isDisabled}
                label="PLZ"
                defaultValue={person?.plz ?? ''}
                onChange={(event) => aktualisierePersonenAttribute('plz', event.target.value)}
                fullWidth
                data-testid={`${testPraefix}-personenverwaltung-plz`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                disabled={isDisabled}
                label="Ort"
                defaultValue={person?.ort ?? ''}
                onChange={(event) => aktualisierePersonenAttribute('ort', event.target.value)}
                fullWidth
                data-testid={`${testPraefix}-personenverwaltung-ort`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomDatePicker
                disableOpenPicker
                disabled={isDisabled}
                openTo="year"
                views={['year', 'month', 'day']}
                disableFuture
                label="Geburtsdatum"
                value={person.geburtsdatum}
                onDateSelect={(date) => aktualisierePersonenAttribute('geburtsdatum', date)}
                fullWidth
                data-testid={`${testPraefix}-personenverwaltung-geburtsdatum`}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                disabled={isDisabled}
                filterOptions={filterOptions}
                freeSolo
                options={LAENDER_BY_NATIONALITAET}
                value={person?.nationalitaet ?? ''}
                onChange={(_, value: string | null) => aktualisierePersonenAttribute('nationalitaet', value)}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    {...params}
                    label="Nationalität"
                    fullWidth
                    data-testid={`${testPraefix}-personenverwaltung-nationalitaet`}
                    InputProps={{
                      ...params.InputProps
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6} />
            <Grid item container xs={12} sm={6} spacing={SPACING_BETWEEN_FORM_FIELDS}>
              <Grid item xs={12}>
                <Typography>Telefonnummern</Typography>
              </Grid>
              {person?.telefon?.map((telefonnummer, index) => (
                <Grid item xs={12} key={index}>
                  <TextField
                    variant="standard"
                    disabled={isDisabled}
                    defaultValue={telefonnummer}
                    onChange={(event) => aktualisiereTelefonnummern(index, event.target.value)}
                    fullWidth
                    data-testid={`${testPraefix}-personenverwaltung-telefon-${index}`}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => loescheTelefonnummer(index)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <IconButton
                  onClick={telefonnummerHinzufuegen}
                  disabled={isDisabled}
                  data-testid={`${testPraefix}-personenverwaltung-telefon-hinzufuegen`}
                  size="large"
                >
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>

            <Grid item container xs={12} sm={6} spacing={SPACING_BETWEEN_FORM_FIELDS}>
              <Grid item xs={12}>
                <Typography>Email</Typography>
              </Grid>
              {person?.email?.map((email, index) => (
                <Grid item xs={12} key={index}>
                  <EmailTextField
                    disabled={isDisabled}
                    defaultValue={email}
                    onChange={(event) => aktualisiereEmailadressen(index, event.target.value)}
                    fullWidth
                    data-testid={`${testPraefix}-personenverwaltung-email-${index}`}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={() => loescheEmail(index)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      )
                    }}
                  />
                </Grid>
              ))}
              <Grid item xs={12}>
                <IconButton onClick={emailHinzufuegen} disabled={isDisabled} data-testid={`${testPraefix}-personenverwaltung-email-hinzufuegen`} size="large">
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <WarningSign text="Bitte hinterlegen Sie mindestens eine E-Mail Adresse und/oder Telefonnummer" show={showWarning} />
            </Grid>
          </Grid>
        }
        footer={
          <>
            <Grid item>
              <Button color="secondary" variant="contained" onClick={() => onClose()} data-testid={`${testPraefix}-personenverwaltung-abbrechen`}>
                Abbrechen
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={isDisabled || !pflichtFelderAusgefuellt()}
                onClick={() => speichern()}
                data-testid={`${testPraefix}-personenverwaltung-speichern`}
              >
                Speichern
              </Button>
            </Grid>
          </>
        }
      ></Modal>
      {showWarningDialog && (
        <WarningDialog
          open={showWarningDialog}
          onAbort={() => setShowWarningDialog(false)}
          text="Bitte hinterlegen Sie mindestens eine E-Mail Adresse und/oder Telefonnummer."
          title="Fehlende Daten"
          onChooseYes={() => {
            speicherePersonendaten();
            setShowWarningDialog(false);
          }}
          confirmText="Speichern"
          abortText="Abbrechen"
        />
      )}
    </>
  );
}
