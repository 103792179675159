import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import AccordionSummary from '@mui/material/AccordionSummary';
import TableContainer from '@mui/material/TableContainer';
import Accordion from '@mui/material/Accordion';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { EuroFormat } from '../common/inputFormats';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../common/Alert';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';
import { HonorarEintrag, Mandant } from '../../types';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const addButton = {
  display: 'flex',
  marginTop: '1rem',
  justifyContent: 'center'
};

type Props = {
  readonly setLoading: (value: boolean) => void;
  readonly isLoading: boolean;
  readonly mandant: Mandant;
};

export function HonorarTabelle({ setLoading, isLoading, mandant }: Props): JSX.Element {
  const [honorartabelle, setHonorartabelle] = useState<Array<HonorarEintrag | null> | null | undefined>(mandant.honorartabelle ?? []);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (mandant.id) {
      setHonorartabelle(mandant.honorartabelle);
    }
  }, [mandant]);

  const deleteEntry = (index: number) => {
    honorartabelle?.splice(index, 1);
    setHonorartabelle([...(honorartabelle ?? [])]);
  };

  const addEntry = () => {
    honorartabelle?.push({ schadenhoehe: 0, grundhonorar: 0 });
    setHonorartabelle([...(honorartabelle ?? [])]);
  };

  const speichereHonorarTabelle = async () => {
    try {
      setLoading(true);
      await makeGraphqlQuery(mutations.aktualisiereMandant, {
        mandantId: mandant.id,
        hisEmail: mandant.hisEmail,
        honorartabelle: honorartabelle
      });
      enqueueSnackbar('Die Daten wurden erfolgreich gespeichert.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      enqueueSnackbar('Die Daten konnten nicht gespeichert werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion data-testid={'honorartabelleAccordion'}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" gutterBottom>
          Honorartabelle
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Schadenhöhe netto bis</TableCell>
                    <TableCell>Grundhonorar netto</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {honorartabelle?.map((row, index) => (
                    <TableRow key={index} data-testid={`entry-${index}`}>
                      <TableCell>
                        <TextField
                          variant="standard"
                          value={row?.schadenhoehe ? row.schadenhoehe / 100 : undefined}
                          onChange={(event) => {
                            if (row) {
                              row.schadenhoehe = (parseFloat(event.target.value) || 0) * 100;
                            }
                          }}
                          fullWidth
                          data-testid="schadenhoehe"
                          InputProps={{
                            inputComponent: EuroFormat,
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                          }}
                        />
                      </TableCell>
                      <TableCell>
                        <TextField
                          variant="standard"
                          value={row?.grundhonorar ? row.grundhonorar / 100 : undefined}
                          onChange={(event) => {
                            if (row) {
                              row.grundhonorar = (parseFloat(event.target.value) || 0) * 100;
                            }
                          }}
                          fullWidth
                          data-testid="grundhonorar"
                          InputProps={{
                            inputComponent: EuroFormat,
                            endAdornment: <InputAdornment position="end">€</InputAdornment>
                          }}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <IconButton onClick={() => deleteEntry(index)} size="large">
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sx={addButton}>
            <IconButton color="primary" onClick={addEntry} data-testid="hinzufuegen" size="large">
              <AddIcon />
            </IconButton>
          </Grid>
          <Grid item xs={12} sx={addButton}>
            <Button color="primary" variant="contained" disabled={isLoading} onClick={speichereHonorarTabelle} data-testid="submit">
              Speichern
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
