import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import Switch from '@mui/material/Switch';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_BOXES, SPACING_BETWEEN_COLUMNS, SPACING_BETWEEN_FORM_FIELDS } from '../../../components/common/spacings';
import PersonenVerwaltung from '../../../components/common/PersonenVerwaltung';
import { AKTION_KUNDENDATEN_ERFASSEN, getDefaultPersonMitTyp } from '../../../frontendConstants';
import WarningSign from '../../../components/common/WarningSign';
import PersonAnzeige from '../../../components/common/PersonAnzeige';
import { useUser } from '../../../hooks/useUser';
import { isAenderbar } from '../../../domain/isAenderbar';
import { Person, Vorgang } from '../../../types';
import { YesNoFormControl } from '../../../components/YesNoFormControl';
import { aktionErlaubt } from '../../../domain/aktionErlaubt';
import SearchPersonUnternehmen from '../../../components/common/SearchPersonUnternehmen';
import { PersonenTyp } from '../../../shared/constants';
import { useSnackbar } from 'notistack';
import { getMessageFromError } from '../../../shared/throw';
import { ERROR_MESSAGE } from '../../../components/common/Alert';
import { aktualisierePerson } from '../../../domain/aktualisierePerson';
import { PersonEingabeMaske } from '../../../components/PersonEingabeMaske/PersonEingabeMaske';

type Props = {
  readonly vorgang: Vorgang;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly setLoading: (value: boolean) => void;
  readonly isLoading: boolean;
};

export function Fahrzeughalter({ vorgang, aktualisiereVorgang, speichereVorgang, setzeVorgang, setLoading, isLoading }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();
  const [dialog, setDialog] = useState({ anlegen: false, aendern: false });

  const handleFahrzeughalterAusgewaehlt = (person: Person) => {
    speichereVorgang({ fahrzeughalterId: person.id });
  };

  const handleAktualisiereFahrzeughalter = (person: Person) => {
    setLoading(true);

    aktualisierePerson({
      ...person,
      mandant: vorgang.mandant,
      weitereBearbeiter: Array.from(new Set([...(person.weitereBearbeiter ?? []), ...vorgang.ehemaligeBearbeiter, vorgang.userId]))
    })
      .then((aktualisiertePerson) => {
        speichereVorgang({ fahrzeughalterId: aktualisiertePerson.id });
        setDialog({ anlegen: false, aendern: false });
      })
      .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Fahrzeughalter konnte nicht gespeichert werden', ERROR_MESSAGE))
      .finally(() => setLoading(false));
  };

  const handleFahrzeughalterAendernDialog = () => {
    setDialog((current) => ({ ...current, aendern: true }));
  };

  const handleFahrzeughalterAnlegenDialog = () => {
    setDialog((current) => ({ ...current, anlegen: true }));
  };

  const handleFahrzeughalterLoeschen = () => {
    speichereVorgang({
      fahrzeughalterId: null,
      fahrzeughalterIstAuftraggeber: true,
      fahrzeughalterRechtsschutzVorhanden: null
    });
  };

  const isDisabled =
    !vorgang || isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_KUNDENDATEN_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  return (
    <Paper
      sx={(theme) => ({
        padding: theme.spacing(SPACING_BETWEEN_BOXES)
      })}
    >
      <Grid container>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Fahrzeughalter
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <FormControlLabel
            control={
              <Switch
                color="primary"
                checked={vorgang?.fahrzeughalterIstAuftraggeber ?? true}
                disabled={isDisabled}
                onChange={(event) => {
                  aktualisiereVorgang({
                    fahrzeughalterIstAuftraggeber: event.target.checked
                  });
                }}
                data-testid="fahrzeughalter-istAuftraggeber"
              />
            }
            label="wie Auftraggeber"
          />
        </Grid>
      </Grid>
      <Collapse
        component={Box}
        sx={(theme) => ({
          paddingTop: theme.spacing(2)
        })}
        in={!(vorgang?.fahrzeughalterIstAuftraggeber ?? true)}
      >
        <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
          <Grid
            item
            container
            xs={12}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                marginBottom: -theme.spacing(SPACING_BETWEEN_FORM_FIELDS)
              }
            })}
          >
            <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS}>
              <Grid item xs={12}>
                <SearchPersonUnternehmen
                  mandantId={vorgang?.mandant}
                  isDisabled={isDisabled}
                  label="Fahrzeughalter suchen"
                  onSelect={(person) => handleFahrzeughalterAusgewaehlt(person as Person)}
                  testPraefix="fahrzeughalter"
                  personenUnternehmenTyp={PersonenTyp.PERSON}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end'
                }}
              >
                <IconButton
                  disabled={isDisabled || !vorgang?.fahrzeughalterId}
                  onClick={handleFahrzeughalterAendernDialog}
                  data-testid="fahrzeughalter-bearbeiten"
                  size="large"
                >
                  <Tooltip title="Ändern">
                    <EditIcon />
                  </Tooltip>
                </IconButton>
                <IconButton disabled={isDisabled} onClick={handleFahrzeughalterAnlegenDialog} data-testid="fahrzeughalter-anlegen" size="large">
                  <Tooltip title="Anlegen">
                    <AddBoxIcon />
                  </Tooltip>
                </IconButton>
                <IconButton
                  disabled={isDisabled || !vorgang?.fahrzeughalterId}
                  onClick={handleFahrzeughalterLoeschen}
                  data-testid="fahrzeughalter-loeschen"
                  size="large"
                >
                  <Tooltip title="Zuorgnung löschen">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <WarningSign
                  show={vorgang?.fahrzeughalter === null && vorgang?.fahrzeughalterId !== null}
                  text="Der Fahrzeughalter wurde gelöscht oder Sie haben nicht die erforderlichen Rechte, diesen zu sehen."
                />
                {vorgang?.fahrzeughalter && <PersonAnzeige person={vorgang.fahrzeughalter} testPraefix="fahrzeughalter" />}
              </Grid>
              {vorgang?.fahrzeughalter && (
                <Grid item xs={12}>
                  <YesNoFormControl
                    labelText="Rechtsschutz"
                    value={vorgang.fahrzeughalterRechtsschutzVorhanden}
                    disabled={isDisabled}
                    onChange={(jaNein) => {
                      aktualisiereVorgang({
                        fahrzeughalterRechtsschutzVorhanden: jaNein
                      });
                    }}
                    dataTestid="fahrzeughalter-rechtsschutzVorhanden"
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Collapse>
      {(dialog.anlegen || dialog.aendern) && (
        <PersonEingabeMaske
          personDatensatz={dialog.aendern ? vorgang?.fahrzeughalter : null}
          typ={PersonenTyp.PERSON}
          onClose={() => {
            setDialog({ anlegen: false, aendern: false });
          }}
          aktualisierePerson={handleAktualisiereFahrzeughalter}
          isDisabled={isDisabled}
          mandant={vorgang.mandant}
          testPraefix="fahrzeughalter"
        />
      )}
    </Paper>
  );
}
