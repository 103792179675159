import { Person } from '../../types';

type PersonType = Person | null | undefined;

export function name(person: PersonType): string {
  if (!person) {
    return '';
  }
  const result = [];
  if (person.vorname) {
    result.push(person.vorname);
  }
  if (person.nachname) {
    result.push(person.nachname);
  }

  return result.join(' ');
}

export function strasse(person: PersonType): string {
  return person && person?.strasse ? person.strasse : '';
}

export function ort(person: PersonType): string {
  return person && person?.ort ? `${person?.ort}` : '';
}

export function plz(person: PersonType): string {
  return person && person?.plz ? `${person?.plz}` : '';
}
