import moment, { DurationInputArg2 } from 'moment';
import { Datum } from '../types';

const rtf = new Intl.RelativeTimeFormat('de', { numeric: 'auto' });
const dtf = new Intl.DateTimeFormat('de-DE', { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' });

type NullOrUndefinedOrString = string | null | undefined;

// TODO Ersetzung durch separater Felder für Datum und Uhrzeit im Datenmodell.
function convertDateToTimezone(date: Date) {
  // Zeitzone des lokalen Rechners ermitteln
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return localTimezone === 'Europe/Berlin' ? date : new Date(date.toLocaleString('en-US', { timeZone: 'Europe/Berlin' }));
}

class EnhancedDate {
  private date: Date;
  private parts;

  constructor(date: Date) {
    this.date = convertDateToTimezone(date);
    this.parts = dtf.formatToParts(this.date);
  }

  _findPart(type: string): string {
    return this.parts.find((it) => it.type === type)?.value ?? '';
  }

  isToday() {
    const today = new Date();
    return this.date.getDate() === today.getDate() && this.date.getMonth() === today.getMonth() && this.date.getFullYear() === today.getFullYear();
  }

  isYesterday() {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return this.date.getDate() === yesterday.getDate() && this.date.getMonth() === yesterday.getMonth() && this.date.getFullYear() === yesterday.getFullYear();
  }

  year = () => this._findPart('year');
  month = () => this._findPart('month');
  day = () => this._findPart('day');
  hour = () => this._findPart('hour');
  minute = () => this._findPart('minute');
}

function capitalizeFirstChar(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function toDateTimeString(isoString: NullOrUndefinedOrString): string {
  if (!isoString) {
    return '';
  }

  const date = new EnhancedDate(new Date(isoString));

  if (date.isToday()) {
    const today = rtf.formatToParts(0, 'day')[0].value;
    return `${capitalizeFirstChar(today)}, ${date.hour()}:${date.minute()} Uhr`;
  } else if (date.isYesterday()) {
    const yesterday = rtf.formatToParts(-1, 'day')[0].value;
    return `${capitalizeFirstChar(yesterday)}, ${date.hour()}:${date.minute()} Uhr`;
  }

  return `${date.day()}.${date.month()}.${date.year()}, ${date.hour()}:${date.minute()} Uhr`;
}

export function toDateTimeString_plain(isoString: NullOrUndefinedOrString): string {
  if (isoString) {
    const date = new EnhancedDate(new Date(isoString));
    return `${date.day()}.${date.month()}.${date.year()} ${date.hour()}:${date.minute()}`;
  }
  return '';
}

export function toDateString(isoString: NullOrUndefinedOrString): string {
  if (isoString) {
    const date = new EnhancedDate(new Date(isoString));
    return `${date.day()}.${date.month()}.${date.year()}`;
  }
  return '';
}

export function toTimeString(isoString: NullOrUndefinedOrString): string {
  if (isoString) {
    const date = new EnhancedDate(new Date(isoString));
    return `${date.hour()}:${date.minute()}`;
  }
  return '';
}

export function getLastDayOfMonth(year: number, month: number): number {
  return new Date(year, month, 0).getDate();
}

export function isOlderThan(isoString: Datum, duration: number, unit: DurationInputArg2) {
  return moment().subtract(duration, unit).isAfter(isoString);
}

export function toDatum(momentDate: moment.Moment): Datum | null {
  return momentDate.isValid() ? (momentDate.format('YYYY-MM-DD') as Datum) : null;
}

export function toTime(datum?: Datum): number | null {
  return datum ? new Date(datum).getTime() : null;
}

export function formatDDMMYYYY(datum?: Datum | Date): string {
  return datum
    ? new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      }).format(new Date(datum))
    : '';
}

export function formatMMYYYY(datum?: Datum): string {
  return datum
    ? new Intl.DateTimeFormat('de-DE', {
        year: 'numeric',
        month: '2-digit'
      }).format(new Date(datum))
    : '';
}

function extractValue(parts: Intl.DateTimeFormatPart[], type: string): string {
  return parts.find((part) => part.type === type)?.value ?? '';
}

export function formatYYMM(datumUhrzeit: Date): string {
  const parts = new Intl.DateTimeFormat('de-DE', {
    year: '2-digit',
    month: '2-digit'
  }).formatToParts(datumUhrzeit);

  const month = extractValue(parts, 'month');
  const year = extractValue(parts, 'year');

  return `${year}${month}`;
}

export function formatYYYY(date: Date): string {
  const parts = new Intl.DateTimeFormat('de-DE', {
    year: 'numeric'
  }).formatToParts(date);

  return extractValue(parts, 'year');
}

export function aktuellesJahr(): number {
  return Number(formatYYYY(new Date()));
}
