import React, { useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { Modal } from '../Modal';

type Props = {
  readonly openModal: boolean;
  readonly onClose: () => void;
  readonly erzeugeReparaturnachweis: (selectedMandant: string, vorgangsnummer: string) => void;
  readonly mandanten: string[];
};

export default function ReparaturnachweisZuVorgangModal({ openModal, onClose, erzeugeReparaturnachweis, mandanten }: Props): JSX.Element {
  const [vorgangsnummer, setVorgangsnummer] = useState('');
  const [selectedMandant, setSelectedMandant] = useState(mandanten[0]);

  return (
    <Modal
      openModal={openModal}
      setOpenModal={onClose}
      ariaLabelledby="reparaturnachweisAnlegenModal"
      title="Reparaturnachweis anlegen"
      body={
        <>
          {mandanten.length > 1 && (
            <FormControl variant="standard" fullWidth>
              <InputLabel>Mandantenauswahl</InputLabel>
              <Select
                variant="standard"
                label="Mandantenauswahl"
                value={selectedMandant}
                onChange={(event) => {
                  setSelectedMandant(event.target.value);
                }}
                data-testid={'Mandantenauswahl'}
              >
                {mandanten.map((g) => (
                  <MenuItem key={g} value={g}>
                    {g.toUpperCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            variant="standard"
            autoFocus
            label="Vorgangsnummer"
            value={vorgangsnummer}
            onChange={(event) => {
              setVorgangsnummer(event.target.value);
            }}
            fullWidth
            data-testid="vorgangsnummer"
          />
        </>
      }
      footer={
        <>
          <Grid item>
            <Button onClick={() => onClose()} variant="contained" color="secondary">
              Abbrechen
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={() => erzeugeReparaturnachweis(selectedMandant, vorgangsnummer)} variant="contained" color="primary">
              Reparaturnachweis anlegen
            </Button>
          </Grid>
        </>
      }
    ></Modal>
  );
}
