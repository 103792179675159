import './Layout.css';
import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import { Navigation } from '../Navigation/Navigation';
import { LogoWhite } from '../common/LogoWhite';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router';

type Props = {
  readonly children: React.ReactNode;
};

export function Layout({ children }: Props): JSX.Element {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const isInVorgang = location.pathname.includes('vorgang');

  const navigiereZuDashboard = () => {
    if (location.pathname !== '/dashboard') {
      history.push('/dashboard');
    }
  };

  return (
    <div className="layout">
      <CssBaseline />
      <AppBar position="fixed">
        <Toolbar sx={{ display: 'flex' }}>
          <IconButton color="inherit" edge="start" onClick={() => setDrawerOpen(true)} data-testid="menu-button" size="large">
            <MenuIcon />
          </IconButton>

          {!isInVorgang && (
            <Box
              onClick={navigiereZuDashboard}
              sx={(theme) => ({
                height: theme.mixins.toolbar.minHeight,
                cursor: 'pointer'
              })}
            >
              <LogoWhite />
            </Box>
          )}

          <div id="layout__app-toolbar" />
        </Toolbar>
      </AppBar>
      <Navigation setDrawerOpen={setDrawerOpen} drawerOpen={drawerOpen} />

      <main className="layout__content">{children}</main>
    </div>
  );
}
