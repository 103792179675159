import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { dialogActions } from './Dialog';
import { EmailTextField } from '../common/EmailTextField';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSend: (emailAdressen: string[]) => void;
  readonly isLoading: boolean;
  readonly hisEmail: string;
};

export function HISVersendenDialog({ open, onClose, onSend, isLoading, hisEmail }: Props): JSX.Element {
  const [email, setEmail] = useState('');

  const sendEmail = () => {
    const emailAdressen = [hisEmail];
    if (email !== '') {
      emailAdressen.push(email);
    }
    onSend(emailAdressen);
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="versendenTitle" data-testid="hisVersendenDialog">
      <DialogTitle id="versendenTitle">HIS versenden</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Das PDF wird an die eingetragene Kontakt-E-Mailadresse {hisEmail} gesendet.
          <br />
          Hier können Sie einen zusätzlichen Empfänger eintragen.
        </DialogContentText>
        <EmailTextField autoFocus id="name" label="E-Mail-Addresse" onChange={(event) => setEmail(event.target.value)} fullWidth />
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button onClick={onClose} color="secondary" variant="contained">
          Abbrechen
        </Button>
        <Button onClick={sendEmail} color="primary" variant="contained" disabled={isLoading} data-testid="confirm">
          Senden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
