import React, { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Tab from '@mui/material/Tab';
import TableBody from '@mui/material/TableBody';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import Checkbox from '@mui/material/Checkbox';
import Tabs from '@mui/material/Tabs';
import { SPACING_BETWEEN_FORM_FIELDS } from '../../components/common/spacings';
import LoadingIndicator from '../../components/common/LoadingIndicator';
import Formular from '../../components/common/Formular';
import { useUser } from '../../hooks/useUser';
import BenutzerAnlegenModal from '../../components/modal/BenutzerAnlegenModal';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import { useSnackbar } from 'notistack';
import { COGNITO_GRUPPE_ADMIN, COGNITO_GRUPPE_QUALITAETSSICHERUNG, COGNITO_GRUPPE_SACHVERSTAENGIGER, COGNITO_GRUPPE_VERWALTUNG } from '../../shared/constants';
import * as queries from '../../graphql/queries';
import * as mutations from '../../graphql/mutations';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import { deprecated_Benutzer, Mandantenrechte, UserRechte } from '../../types';
import { Link } from 'react-router-dom';

type UserMitRechten = deprecated_Benutzer & Omit<UserRechte, 'id'>;

export default function FranchiseUserVerwaltung(): JSX.Element | null {
  const { user, isAdmin, isExperts24Admin } = useUser();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [isLoading, setIsLoading] = useState(false);
  const [benutzerRechte, setBenutzerRechte] = useState<UserRechte[]>([]);
  const [users, setUsers] = useState<deprecated_Benutzer[]>([]);
  const [userMitRechten, setUserMitRechten] = useState<UserMitRechten[]>([]);
  const [mandanten, setMandanten] = useState<string[]>([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [openBenutzerAnlegenDialog, setOpenBenutzerAnlegenDialog] = useState(false);
  const [neuerBenutzer, setNeuerBenutzer] = useState<deprecated_Benutzer>({
    id: '',
    email: '',
    family_name: '',
    given_name: '',
    kuerzel: '',
    mandant: ''
  });
  const [currentMandant, setCurrentMandant] = useState('');

  useEffect(() => {
    const holeAlleBenutzerUndMandanten = async () => {
      setIsLoading(true);
      const users = await makeGraphqlQuery(queries.holeBenutzerliste, {
        mandant: ''
      });
      setUsers(users);
      const benutzerRechte: UserRechte[] = (await makeGraphqlQuery(queries.listUserRechte, {})).items ?? [];
      setBenutzerRechte(benutzerRechte);
      const mandanten = await makeGraphqlQuery(queries.holeMandanten, {});
      setMandanten(mandanten);
      setIsLoading(false);
    };
    if (user && !(isAdmin || isExperts24Admin)) {
      history.push('/');
    } else {
      holeAlleBenutzerUndMandanten();
    }
  }, [user, isAdmin, isExperts24Admin, history]);

  useEffect(() => {
    if (users.length > 0) {
      const userMitRechten = users.map((user) => {
        const userrechte = benutzerRechte.find((rechte) => rechte.id === user.sub);
        return { ...userrechte, ...user };
      });
      setUserMitRechten(userMitRechten);
    }
  }, [users, benutzerRechte]);

  const hatRecht = (zuPruefendesRecht: string, user: UserMitRechten, mandantKuerzel: string) => {
    if (hatUserIrgendwelcheMandantenRechte(user)) {
      const mandantenrechte = hatUserIrgendwelcheMandantenRechte(user)?.find((mandantRechte) => mandantRechte?.mandantenKuerzel === mandantKuerzel);
      // hat der User aktuell Rechte fuer diesen einen Mandanten

      return hatUserSpezifischesRechtFuerMandant(mandantenrechte?.rechte ?? [], zuPruefendesRecht);
    }
    return false;
  };

  const handleChangeRecht = (zuVeraenderndeRecht: string, user: UserMitRechten, mandantKuerzel: string) => {
    user.mandantenrechte = setzeMandantenRecht(user.mandantenrechte, mandantKuerzel, zuVeraenderndeRecht);
    setUserMitRechten([...userMitRechten.map((umr) => (umr.id !== user.id ? umr : user))]);
  };

  const speichereUserRechte = async () => {
    setIsLoading(true);
    try {
      const updatedUserRechteAusDB = await Promise.all(
        userMitRechten
          .filter((user) => benutzerRechte.some((rechte) => rechte.id === user.sub))
          .map(async (user) => {
            return makeGraphqlQuery(mutations.updateUserRechte, {
              input: {
                id: user.sub,
                mandantenrechte: user.mandantenrechte
              }
            });
          })
      );

      const createdUserRechteAusDB = await Promise.all(
        userMitRechten
          .filter((user) => benutzerRechte.every((rechte) => rechte.id !== user.sub))
          .map(async (user) => {
            return makeGraphqlQuery(mutations.createUserRechte, {
              input: {
                id: user.sub,
                mandantenrechte: user.mandantenrechte
              }
            });
          })
      );
      setBenutzerRechte([...updatedUserRechteAusDB, ...createdUserRechteAusDB]);
      enqueueSnackbar(`Die Einstellungen wurden erfolgreich gespeichert.`, SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.log(error);
      enqueueSnackbar(`Die Einstellungen konnten nicht gespeichert werden.`, ERROR_MESSAGE);
    } finally {
      setIsLoading(false);
    }
  };

  const fuegeBenutzerZuCognitoHinzu = async (benutzer: deprecated_Benutzer) => {
    setIsLoading(true);
    const erfolgreich = await makeGraphqlQuery(queries.fuegeBenutzerZuCognitoHinzu, {
      id: benutzer.id,
      family_name: benutzer.family_name,
      given_name: benutzer.given_name,
      kuerzel: benutzer.kuerzel,
      mandant: benutzer.mandant,
      email: benutzer.email
    });
    setIsLoading(false);
    setOpenBenutzerAnlegenDialog(false);
    erfolgreich
      ? enqueueSnackbar(`Der Benutzer ${benutzer.id} wurde erfolgreich für den Mandanten ${currentMandant.toUpperCase()} angelegt.`, SUCCESS_MESSAGE_AUTO_HIDE)
      : enqueueSnackbar(`Der Benutzer ${benutzer.id} konnte für den Mandanten ${currentMandant.toUpperCase()} nicht angelegt werden.`, ERROR_MESSAGE);
  };

  return (
    user && (
      <>
        <Toolbar>
          <Typography variant="h6">Admin</Typography>
        </Toolbar>
        <Formular ueberschrift="Benutzerverwaltung">
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Tabs
              value={tabIndex}
              onChange={(event, index) => setTabIndex(index)}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Übersicht" data-testid="uebersicht" />
              {mandanten.map((mandant) => (
                <Tab key={mandant} label={mandant} data-testid={`mandant-tab-${mandant}`} />
              ))}
            </Tabs>
            <Grid item xs={12}>
              {tabIndex === 0 && (
                <div>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ width: '300px' }}>Benutzer</TableCell>
                        <TableCell>Rechten</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userMitRechten.map((user) => (
                        <TableRow key={user.id}>
                          <TableCell>
                            <Link to={`/profil/${user.id}`}>{user.id}</Link>
                          </TableCell>
                          <TableCell data-testid={`${user.id}-rechte`}>
                            {user.mandantenrechte?.map((mandantRecht) =>
                              mandantRecht?.rechte?.map((recht) => {
                                const label = mandantRecht.mandantenKuerzel + '_' + recht;
                                return <Chip key={label} label={label} />;
                              })
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              )}
              {mandanten.map((mandant, index) => {
                if (index + 1 === tabIndex) {
                  return (
                    <Fragment key={mandant}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '300px' }}>Benutzer</TableCell>
                            <TableCell>Sachverständiger</TableCell>
                            <TableCell>Qualitätssicherung</TableCell>
                            <TableCell>Verwaltung</TableCell>
                            <TableCell>Admin</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userMitRechten.map((user) => (
                            <TableRow key={user.id} data-testid={`${mandant}-${user.id}`}>
                              <TableCell data-testid="userId">
                                <Link to={`/profil/${user.id}`}>{user.id}</Link>
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={hatRecht(COGNITO_GRUPPE_SACHVERSTAENGIGER, user, mandant)}
                                  onChange={() => handleChangeRecht(COGNITO_GRUPPE_SACHVERSTAENGIGER, user, mandant)}
                                  color="primary"
                                  data-testid="sachverstaendiger"
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={hatRecht(COGNITO_GRUPPE_QUALITAETSSICHERUNG, user, mandant)}
                                  onChange={() => handleChangeRecht(COGNITO_GRUPPE_QUALITAETSSICHERUNG, user, mandant)}
                                  color="primary"
                                  data-testid="qualitaetssicherung"
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={hatRecht(COGNITO_GRUPPE_VERWALTUNG, user, mandant)}
                                  onChange={() => {
                                    handleChangeRecht(COGNITO_GRUPPE_VERWALTUNG, user, mandant);
                                  }}
                                  color="primary"
                                  data-testid="verwaltung"
                                />
                              </TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={hatRecht(COGNITO_GRUPPE_ADMIN, user, mandant)}
                                  onChange={() => {
                                    handleChangeRecht(COGNITO_GRUPPE_ADMIN, user, mandant);
                                  }}
                                  color="primary"
                                  data-testid="admin"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                      <Button
                        variant="contained"
                        color="primary"
                        data-testid="legeUserAn"
                        onClick={() => {
                          setCurrentMandant(mandant);
                          setNeuerBenutzer((benutzer) => ({
                            ...benutzer,
                            mandant: mandant
                          }));
                          setOpenBenutzerAnlegenDialog(true);
                        }}
                        sx={{ marginTop: '1rem' }}
                      >
                        Benutzerkonto für {mandant} anlegen
                      </Button>
                      <Grid item xs={12} sm={6}>
                        <Button
                          variant="contained"
                          color="primary"
                          data-testid="speichereUserRechte"
                          onClick={() => speichereUserRechte()}
                          sx={{ marginTop: '1rem' }}
                        >
                          Berechtigungen Speichern
                        </Button>
                      </Grid>
                    </Fragment>
                  );
                } else {
                  return null;
                }
              })}
            </Grid>
          </Grid>
        </Formular>
        <LoadingIndicator isLoading={isLoading} />
        {openBenutzerAnlegenDialog && (
          <BenutzerAnlegenModal
            openBenutzerAnlegenDialog={openBenutzerAnlegenDialog}
            setOpenBenutzerAnlegenDialog={setOpenBenutzerAnlegenDialog}
            neuerBenutzer={neuerBenutzer}
            isLoading={isLoading}
            setNeuerBenutzer={setNeuerBenutzer}
            fuegeBenutzerZuCognitoHinzu={fuegeBenutzerZuCognitoHinzu}
          />
        )}
      </>
    )
  );
}

export function setzeMandantenRecht(userRechte: Array<Mandantenrechte> | null | undefined, mandantKuerzel: string, zuVeraenderndeRecht: string) {
  const alleUserRechte = userRechte ?? [];

  const mandantenrechte = new Set(alleUserRechte.find((mandantRechte) => mandantRechte?.mandantenKuerzel === mandantKuerzel)?.rechte);
  if (hatUserSpezifischesRechtFuerMandant([...mandantenrechte], zuVeraenderndeRecht)) {
    mandantenrechte.delete(zuVeraenderndeRecht);
  } else {
    mandantenrechte.add(zuVeraenderndeRecht);
  }

  const aktualisierteUserRechte: { [key: string]: Array<string | null> | null } = {};
  alleUserRechte.forEach((mr) => {
    aktualisierteUserRechte[mr.mandantenKuerzel] = mr?.rechte ?? [];
  });
  aktualisierteUserRechte[mandantKuerzel] = [...mandantenrechte];

  return Object.entries(aktualisierteUserRechte).map(([key, value]) => ({ mandantenKuerzel: key, rechte: value }) as Mandantenrechte);
}

function hatUserIrgendwelcheMandantenRechte(user: UserMitRechten) {
  return user?.mandantenrechte;
}

function hatUserSpezifischesRechtFuerMandant(mandantenrechte: (string | null)[], zuPruefendesRecht: string) {
  return mandantenrechte.includes(zuPruefendesRecht);
}
