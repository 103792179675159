import './Unterschriften.css';
import React, { useEffect, useState } from 'react';
import { HIS } from '../../components/HIS';
import { DSGVO } from '../../components/DSGVO';
import { Abtretungserklaerung } from '../../components/Abtretungserklaerung';
import { Auftragsbestaetigung } from '../../components/Auftragsbestaetigung';
import { useVorgangContext } from '../../contexts/vorgangContext';
import { Gutachtenart, Unterzeichner } from '../../shared/constants';
import { useHistory, useLocation } from 'react-router';
import { WarningDialog } from '../../components/dialog/WarningDialog';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../../components/common/Alert';
import { useSnackbar } from 'notistack';
import { Unterschriften as UnterschriftenType } from '../../types';
import { S3Client } from '../../shared/s3Client';
import { Signature } from '../../components/common/Signature';
import { useDateiUrl } from '../../hooks/useDateiUrl';
import Formular from '../../components/common/Formular';

export type UnterschriftenCheckboxState = {
  his: boolean;
  dsgvo: boolean;
  abtretungserklaerung: boolean;
  auftragsbestaetigung: boolean;
};

export default function Unterschriften(): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { vorgang, isLoading, aktualisiereVorgang, setzeVorgang } = useVorgangContext();

  const history = useHistory();
  const location = useLocation();

  const [unterzeichnerAuswahlGlobal, setUnterzeichnerAuswahlGlobal] = useState(Unterzeichner.AUFTRAGGEBER);
  const [newLocation, setNewLocation] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);
  const [signDate, setSignDate] = useState<string | undefined>(new Date().toISOString());
  const [signatureKey, setSignatureKey] = useState<string | undefined>(undefined);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [mandatoryFieldsChecked, setMandatoryFieldsChecked] = useState<boolean>(false);
  const [checkboxState, setCheckboxState] = useState<UnterschriftenCheckboxState>({
    his: !!vorgang.unterschriften?.his?.key,
    dsgvo: !!vorgang.unterschriften?.dsgvo?.key,
    abtretungserklaerung: !!vorgang.unterschriften?.abtretungserklaerung?.key,
    auftragsbestaetigung: !!vorgang.unterschriften?.auftragsbestaetigung?.key
  });

  const signatureUrl = useDateiUrl(signatureKey);

  useEffect(() => {
    let found = false;
    for (const typ of Object.keys(checkboxState)) {
      if (vorgang.unterschriften?.[typ as keyof UnterschriftenType]?.key) {
        if (!found) {
          setSignDate(vorgang.unterschriften[typ as keyof UnterschriftenType]?.datum);
          setSignatureKey(vorgang.unterschriften[typ as keyof UnterschriftenType]?.key);
        }
        found = true;
      }
    }
    if (!found) {
      setSignDate(new Date().toISOString());
      setSignatureKey(undefined);
    }

    setMandatoryFieldsChecked((checkboxState.abtretungserklaerung || checkboxState.auftragsbestaetigung) && checkboxState.dsgvo);
  }, [checkboxState, vorgang.unterschriften]);

  const handleSign = async (signature: string, datum: string) => {
    try {
      setIsUpdating(true);
      if (!checkboxState.abtretungserklaerung && !checkboxState.auftragsbestaetigung && !checkboxState.dsgvo && !checkboxState.his) {
        enqueueSnackbar('Es wurde keiner AGB zugestimmt', ERROR_MESSAGE);
        return;
      }
      for (const typ of Object.keys(checkboxState)) {
        if (checkboxState[typ as keyof UnterschriftenCheckboxState]) {
          await handleSignFor(typ as keyof UnterschriftenType)(signature, datum, unterzeichnerAuswahlGlobal);
        }
      }

      enqueueSnackbar('Die Unterschrift wurde erfolgreich gespeichert.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Unterschrift konnte nicht gespeichert werden.', ERROR_MESSAGE);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleSignFor = (typ: keyof UnterschriftenType) => async (signature: string, datum: string, unterzeichnerAuswahl: Unterzeichner) => {
    const response = await fetch(signature);
    const blob = await response.blob();
    const contentType = blob.type;
    const key = `${vorgang.id}/unterschriften/${typ}.png`;
    await S3Client.put(key, blob, contentType);
    const aktualisierterVorgang = await makeGraphqlQuery(mutations.leisteUnterschrift, {
      vorgangId: vorgang.id,
      typ,
      key,
      datum,
      unterzeichner: unterzeichnerAuswahl
    });
    setzeVorgang(aktualisierterVorgang);
  };

  const handleDeleteSignature = async () => {
    try {
      setIsUpdating(true);
      for (const typ of Object.keys(checkboxState)) {
        await S3Client.remove(`${vorgang.id}/unterschriften/${typ}.png`);
        const aktualisierterVorgang = await makeGraphqlQuery(mutations.entferneUnterschrift, {
          vorgangId: vorgang.id,
          typ
        });
        setzeVorgang(aktualisierterVorgang);
      }
      enqueueSnackbar('Die Unterschrift wurde erfolgreich entfernt.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      console.error({ error });
      enqueueSnackbar('Die Unterschrift konnte nicht entfernt werden.', ERROR_MESSAGE);
    } finally {
      setIsUpdating(false);
    }
  };

  const updateUnterzeichner = (unterzeichner: Unterzeichner) => {
    setIsUpdating(true);
    for (const typ of Object.keys(checkboxState)) {
      if (vorgang.unterschriften?.[typ as keyof UnterschriftenType]?.key) {
        aktualisiereVorgang({
          unterschriften: {
            ...vorgang.unterschriften,
            [typ]: {
              ...(vorgang.unterschriften ? vorgang.unterschriften[typ as keyof UnterschriftenType] : {}),
              unterzeichner
            }
          }
        });
      }
    }
    setIsUpdating(false);
  };

  useEffect(() => {
    history.block((tx) => {
      if (location.pathname === tx.pathname) {
        return;
      }
      setNewLocation(tx.pathname);

      let signaturesComplete = Boolean(vorgang.unterschriften?.dsgvo?.key);
      if (vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS) {
        signaturesComplete = signaturesComplete && Boolean(vorgang.unterschriften?.his?.key);
      }
      if (vorgang?.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG) {
        signaturesComplete = signaturesComplete && Boolean(vorgang.unterschriften?.auftragsbestaetigung?.key);
      } else {
        signaturesComplete = signaturesComplete && Boolean(vorgang.unterschriften?.abtretungserklaerung?.key);
      }

      if (!signaturesComplete) {
        setShowPrompt(true);
        return false;
      } else {
        setShowPrompt(false);
      }
    });
  }, [history, location.pathname, vorgang.gutachtenart, vorgang.unterschriften]);

  function updateCheckboxState(newState: any) {
    setCheckboxState((current) => {
      return { ...current, ...newState };
    });
  }

  return (
    <>
      <WarningDialog
        open={showPrompt}
        onAbort={() => {
          setShowPrompt(false);
        }}
        text="Es wurden noch nicht alle Unterschriften bestätigt. Bitte prüfen Sie, ob alle Unterschriften abgegeben wurden."
        title="Änderungen verwerfen?"
        onChooseYes={() => {
          history.block(() => {
            //weiterleiten
          });
          history.push(newLocation);
        }}
        confirmText="Weiter"
        abortText="Abbrechen"
      />
      <Formular ueberschrift="Unterschriften">
        {vorgang?.gutachtenart !== Gutachtenart.REPARATURNACHWEIS && (
          <>
            <HIS vorgang={vorgang} isLoading={isLoading} setzeVorgang={setzeVorgang} checkboxState={checkboxState} updateCheckboxState={updateCheckboxState} />
            <br />
          </>
        )}
        <DSGVO vorgang={vorgang} isLoading={isLoading} checkboxState={checkboxState} updateCheckboxState={updateCheckboxState} />
        <br />
        {vorgang?.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG ? (
          <Auftragsbestaetigung vorgang={vorgang} isLoading={isLoading} checkboxState={checkboxState} updateCheckboxState={updateCheckboxState} />
        ) : (
          <Abtretungserklaerung vorgang={vorgang} isLoading={isLoading} checkboxState={checkboxState} updateCheckboxState={updateCheckboxState} />
        )}
        <h6>* Pflichtfelder</h6>
        {isUpdating ? (
          <div className="signature__skeleton"></div>
        ) : (
          <Signature
            onSign={handleSign}
            onDeleteSignature={handleDeleteSignature}
            signatureUrl={signatureUrl}
            isLoading={isLoading}
            vorgang={vorgang}
            unterzeichnerAuswahl={unterzeichnerAuswahlGlobal}
            setUnterzeichnerAuswahl={(unterzeichner) => {
              setUnterzeichnerAuswahlGlobal(unterzeichner);
            }}
            updateUnterzeichner={updateUnterzeichner}
            date={signDate}
            mandatoryFieldsChecked={mandatoryFieldsChecked}
          />
        )}
      </Formular>
    </>
  );
}
