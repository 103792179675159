/*
 * Copyright 2017-2017 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

import * as React from 'react';
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from './AuthPiece';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField/TextField';
import Grid from '@mui/material/Grid/Grid';
import { SPACING_BETWEEN_FORM_FIELDS } from '../components/common/spacings';
import Link from '@mui/material/Link/Link';
import { AuthenticatorPage } from '../components/AuthenticatorPage';
import { completeNewPassword } from '../shared/Auth';
import { CognitoUser } from '../shared/constants';

export class RequireNewPassword extends AuthPiece<IAuthPieceProps, IAuthPieceState> {
  constructor(props: IAuthPieceProps) {
    super(props);

    this._validAuthStates = ['requireNewPassword'];
    this.change = this.change.bind(this);
    this.checkContact = this.checkContact.bind(this);
  }

  checkContact(user: CognitoUser) {
    this.changeState('signedIn', user);
  }

  change() {
    const user = this.props.authData;
    const { password } = this.inputs;

    completeNewPassword(user, password)
      .then((user) => {
        this.checkContact(user);
      })
      .catch((err) => this.error(err));
  }

  showComponent() {
    const { hide } = this.props;
    if (hide.includes(RequireNewPassword)) {
      return null;
    }

    return (
      <AuthenticatorPage title="Passwort ändern">
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12}>
            <TextField
              autoFocus
              variant="standard"
              fullWidth
              label="Neues Passwort"
              key="password"
              name="password"
              type="password"
              onChange={this.handleInputChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container columns={2}>
              <Grid item xs={1}>
                <Link href="#" onClick={() => this.changeState('signIn')}>
                  Zurück zur Anmeldung
                </Link>
              </Grid>
              <Grid item xs={1} display="flex" justifyContent="flex-end">
                <Button color="primary" variant="contained" onClick={this.change}>
                  Passwort ändern
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </AuthenticatorPage>
    );
  }
}
