import * as queries from '../graphql/queries';
import { SuchePersonenResponse } from '../types';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';

export async function sucheNachPersonen(suchbegriffe: string, mandant: string, personenTyp: string): Promise<SuchePersonenResponse> {
  return await makeGraphqlQuery(queries.suchePersonen, {
    suchbegriff: suchbegriffe,
    mandant,
    typ: personenTyp
  });
}
