import '../screens/Vorgang/Unterschriften.css';
import React, { ChangeEvent, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { Signature } from './common/Signature';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { toDateString } from '../shared/dateTime';
import { holeGeschaedigten } from '../shared/frontend/holeGeschaedigten';
import { name, ort, plz, strasse } from '../shared/frontend/adresse';
import { Vorgang } from '../types';
import { Unterzeichner } from '../shared/constants';
import Button from '@mui/material/Button';
import { Modal } from './Modal';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import { UnterschriftenCheckboxState } from '../screens/Vorgang/Unterschriften';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly updateCheckboxState: (newState: Partial<UnterschriftenCheckboxState>) => void;
  readonly checkboxState: UnterschriftenCheckboxState;
};

export function Auftragsbestaetigung({ vorgang, isLoading, updateCheckboxState, checkboxState }: Props): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkboxState.auftragsbestaetigung}
            onChange={(event: ChangeEvent<HTMLInputElement>) => updateCheckboxState({ auftragsbestaetigung: event.target.checked })}
            name="auftragsbestaetigung"
            color="primary"
            data-testid="auftragsbestaetigungCheckbox"
          />
        }
        label={
          <Link
            onClick={(event) => {
              event.preventDefault();
              setIsModalOpen(true);
            }}
            data-testid="auftragsbestaetigungLink"
          >
            Auftragsbestätigung*
          </Link>
        }
      />
      <AuftragsbestaetigungModal vorgang={vorgang} isLoading={isLoading} isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />
    </>
  );
}

type ModalProps = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly isModalOpen: boolean;
  readonly onClose: () => void;
};

function AuftragsbestaetigungModal({ vorgang, isLoading, isModalOpen, onClose }: ModalProps): JSX.Element {
  const auftragsbestaetigungUrl = useDateiUrl(vorgang?.unterschriften?.auftragsbestaetigung?.key);
  const signDate = vorgang?.unterschriften?.auftragsbestaetigung?.datum ?? new Date().toISOString();

  const auftraggeber = holeGeschaedigten(vorgang);

  return (
    <Modal
      openModal={isModalOpen}
      setOpenModal={onClose}
      title="Auftragsbestätigung"
      body={
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS} data-testid="auftragsbestaetigungModal">
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={4}>
                Zur Vorgangsnummer
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="vorgangsnummer">
                {vorgang.vorgangsnummer}
              </Grid>
              <Grid item xs={4}>
                Auftrag vom
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="date">
                {toDateString(vorgang?.besichtigungen?.items[0]?.besichtigungstermin)}
              </Grid>
              <Grid item xs={4}>
                Auftragsgeber
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="auftraggeber">
                {name(auftraggeber)} {strasse(auftraggeber)} {ort(auftraggeber)} {plz(auftraggeber)}
              </Grid>
              <Grid item xs={4}>
                Fahrzeug
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="fahrzeug">
                {vorgang?.fahrzeugdaten?.herstellername} {vorgang?.fahrzeugdaten?.untertypname}
              </Grid>
              <Grid item xs={4}>
                amtl. Kennzeichen
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="kennzeichen">
                {vorgang?.fahrzeugdaten?.kennzeichen}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2">
              Hiermit beauftrage ich die Firma Experts24 Tassone GmbH mit der Erstellung einer Fahrzeugbewertung für das o.g. Fahrzeug.
            </Typography>
            <br />
            <Typography variant="body2">
              Die Fahrzeugbewertung beinhaltet eine Gebrauchtfahrzeug-Bewertung nach DAT-System des externen Datenlieferanten Deutsche Automobil Treuhand GmbH
              und die Erstellung von Lichtbildern.
            </Typography>
            <br />
            <br />
            <Typography variant="body2">
              Die Experts24 Tassone GmbH berechnet einen Pauschalbetrag von 98€ inkl. MwSt. für die Erstellung dieser Fahrzeugbewertung. Die Zahlung erfolgt
              nach Rechnungslegung innerhalb von 14 Werktagen.
            </Typography>
            <br />
            <Typography variant="body2">
              Die Experts24 Tassone GmbH verpflichtet sich, die nach diesem Vertrag zu erbringenden Leistungen fachgerecht auszuführen. Zusätzliche Leistungen,
              die nicht Teil der Beschreibung einer Fahrzeugbewertung sind und welche durch den Auftraggeber angewiesen werden, werden gegen gesonderte
              Vergütung ausgeführt.
            </Typography>
            <br />
            <Typography variant="body2">
              Der Auftragnehmer unterliegt, soweit dies nicht durch die Natur des Auftrages vorgegeben ist, bei der Erfüllung des Vertrages bzw. bei der
              Durchführung der von ihm übernommenen Tätigkeit hinsichtlich Zeiteinteilung und Gestaltung des Tätigkeitsablaufes keinerlei Weisungen des
              Auftraggebers.
            </Typography>
            <br />
            <Typography variant="body2">
              Die Leistungen der Experts24 Tassone GmbH gelten als erfüllt und abgenommen, wenn der Auftraggeber nicht unverzüglich Einwände erhebt.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Signature
              onSign={() => {}}
              onDeleteSignature={() => {}}
              signatureUrl={auftragsbestaetigungUrl}
              isLoading={isLoading}
              vorgang={vorgang}
              unterzeichnerAuswahl={vorgang?.unterschriften?.auftragsbestaetigung?.unterzeichner ?? Unterzeichner.AUFTRAGGEBER}
              date={signDate}
              readOnly={true}
            />
          </Grid>
        </Grid>
      }
      footer={
        <div className="unterschriften__modal-button">
          <Button color="primary" variant="contained" onClick={onClose}>
            Schließen
          </Button>
        </div>
      }
    />
  );
}
