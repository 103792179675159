import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { SPACING_TO_APPBAR, SPACING_TO_FAB } from './spacings';

type Props = {
  isLoading: boolean;
  fixed?: boolean;
};

export default function LoadingIndicator({ isLoading, fixed }: Props): JSX.Element | null {
  let isFixed = true;

  if (typeof fixed === 'boolean') {
    isFixed = fixed;
  }

  return isLoading ? (
    <Fab
      sx={(theme) =>
        isFixed
          ? {
              position: 'fixed',
              top: theme.spacing(SPACING_TO_APPBAR + SPACING_TO_FAB),
              right: theme.spacing(SPACING_TO_FAB),
              zIndex: theme.zIndex.tooltip - 1
            }
          : null
      }
      size="small"
      color="primary"
      disableRipple
      disableFocusRipple
      variant="circular"
    >
      <CircularProgress color="secondary" size={24} data-testid="loadingIndicator" />
    </Fab>
  ) : null;
}
