import React from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { EmailTextField } from '../common/EmailTextField';
import { deprecated_Benutzer } from '../../types';
import { Modal } from '../Modal';

type Props = {
  readonly openBenutzerAnlegenDialog: boolean;
  readonly setOpenBenutzerAnlegenDialog: (value: boolean) => void;
  readonly neuerBenutzer: deprecated_Benutzer;
  readonly isLoading: boolean;
  readonly setNeuerBenutzer: (benutzer: deprecated_Benutzer) => void;
  readonly fuegeBenutzerZuCognitoHinzu: (benutzer: deprecated_Benutzer) => void;
  readonly resetPassword?: () => void;
};

export default function BenutzerAnlegenModal({
  openBenutzerAnlegenDialog,
  setOpenBenutzerAnlegenDialog,
  neuerBenutzer,
  isLoading,
  setNeuerBenutzer,
  fuegeBenutzerZuCognitoHinzu,
  resetPassword
}: Props): JSX.Element {
  const isValid =
    neuerBenutzer.id && neuerBenutzer.given_name && neuerBenutzer.family_name && neuerBenutzer.kuerzel && neuerBenutzer.mandant && neuerBenutzer.email;

  return (
    <Modal
      openModal={openBenutzerAnlegenDialog}
      setOpenModal={setOpenBenutzerAnlegenDialog}
      ariaLabelledby="benutzerAnlegenModal"
      dataTestid={neuerBenutzer.sub ? 'benutzerAendernModal' : 'benutzerAnlegenModal'}
      title="Benutzer anlegen"
      body={
        <>
          <Grid item xs={12}>
            <TextField
              variant="standard"
              autoFocus
              label="Benutzername"
              value={neuerBenutzer.id}
              disabled={isLoading || Boolean(neuerBenutzer.sub)}
              onChange={(event) => {
                setNeuerBenutzer({
                  ...neuerBenutzer,
                  id: event.target.value
                });
              }}
              fullWidth
              data-testid="benutzername"
            />
            <TextField
              variant="standard"
              label="Nachname"
              value={neuerBenutzer.family_name}
              disabled={isLoading}
              onChange={(event) => {
                setNeuerBenutzer({
                  ...neuerBenutzer,
                  family_name: event.target.value
                });
              }}
              fullWidth
              data-testid="nachname"
            />
            <TextField
              variant="standard"
              label="Vorname"
              value={neuerBenutzer.given_name}
              disabled={isLoading}
              onChange={(event) => {
                setNeuerBenutzer({
                  ...neuerBenutzer,
                  given_name: event.target.value
                });
              }}
              fullWidth
              data-testid="vorname"
            />
            <TextField
              variant="standard"
              label="Kürzel"
              value={neuerBenutzer.kuerzel}
              disabled={isLoading || Boolean(neuerBenutzer.sub)}
              onChange={(event) => {
                setNeuerBenutzer({
                  ...neuerBenutzer,
                  kuerzel: event.target.value
                });
              }}
              fullWidth
              data-testid="kuerzel"
            />
            <TextField variant="standard" label="Mandant" value={neuerBenutzer.mandant.toUpperCase()} disabled={true} fullWidth data-testid="mandant" />
            <EmailTextField
              label="E-Mail-Adresse"
              defaultValue={neuerBenutzer.email ?? ''}
              disabled={isLoading}
              onChange={(event) => {
                setNeuerBenutzer({
                  ...neuerBenutzer,
                  email: event.target.value
                });
              }}
              fullWidth
              data-testid="email"
            />
          </Grid>
          {Boolean(neuerBenutzer.sub) && (
            <Grid item xs={12}>
              <Button onClick={resetPassword} variant="contained" color="primary" disabled={isLoading}>
                Passwort zurücksetzen
              </Button>
            </Grid>
          )}
        </>
      }
      footer={
        <>
          <Grid item>
            <Button onClick={() => setOpenBenutzerAnlegenDialog(false)} variant="contained" color="secondary" disabled={isLoading}>
              Abbrechen
            </Button>
          </Grid>
          <Grid item>
            <Button
              onClick={() => fuegeBenutzerZuCognitoHinzu(neuerBenutzer)}
              disabled={isLoading || !isValid}
              variant="contained"
              color="primary"
              data-testid="submit"
            >
              {neuerBenutzer.sub ? 'Ändern' : 'Anlegen'}
            </Button>
          </Grid>
        </>
      }
    ></Modal>
  );
}
