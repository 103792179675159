import * as develop from './aws-develop';
import * as staging from './aws-staging';
import * as prod from './aws-prod';
import * as cypress from './aws-cypress';

const env: string | undefined = process.env.REACT_APP_BRANCH;

if (!process.env.AWS_REGION) {
  console.log(`our current environment: '${env}'`);
}

export function configForEnvironment(): any {
  if (env === 'prod') {
    return prod;
  } else if (env === 'staging') {
    return staging;
  } else if (env === 'cypress') {
    return cypress;
  }
  return develop;
}

export const environment = configForEnvironment().config;
