import './FileInput.css';
import React from 'react';
import BackupIcon from '@mui/icons-material/Backup';
import Dropzone from 'react-dropzone';

type Props = {
  readonly accept?: string;
  readonly disabled?: boolean;
  readonly dataTestid?: string;
  readonly onChange: (files: File[]) => void;
  readonly children: React.ReactNode;
};

export function FileInput({ accept, disabled, dataTestid, onChange, children }: Props): JSX.Element {
  return (
    <Dropzone onDrop={(acceptedFiles: File[]) => onChange(acceptedFiles)} disabled={disabled}>
      {({ getRootProps, getInputProps }) => (
        <span className="file-input" {...getRootProps()} data-testid={dataTestid}>
          <input {...getInputProps()} accept={accept} />
          <BackupIcon color="primary" className="file-input__icon" /> {children}
        </span>
      )}
    </Dropzone>
  );
}
