import React from 'react';
import Link from '@mui/material/Link';
import { useDateiUrl } from '../../hooks/useDateiUrl';

type Props = {
  readonly s3key: string;
  readonly children: React.ReactNode;
  readonly underline?: 'none' | 'hover' | 'always';
  readonly target?: string;
  readonly color?: 'primary';
};

export function S3Link({ s3key, children, underline, target, color }: Props): JSX.Element {
  const href = useDateiUrl(s3key);

  return (
    <Link href={href ?? ''} underline={underline} target={target} color={color} data-testid="dokument">
      {children}
    </Link>
  );
}
