import moment from 'moment';
import { Vorgang } from '../../types';

export function berechneFahrzeugalterInMonaten(vorgang: Vorgang) {
  const herstellungsdatumISOString = vorgang?.fahrzeugdaten?.erstesZulassungsdatum;
  if (!herstellungsdatumISOString) {
    return 999;
  } else {
    return Math.ceil(moment().diff(moment(herstellungsdatumISOString), 'months', true));
  }
}
