import { Auth } from '@aws-amplify/auth';
import { CognitoRefreshToken } from 'amazon-cognito-identity-js';
import { CognitoUser } from './constants';
import { Credentials } from '@aws-amplify/core';

export function getCredentials(): Promise<any> {
  return Credentials.get();
}

export function signOut(): Promise<void> {
  return Auth.signOut().then(() => {
    //   /**
    //    * TODO location.reload() entfernen
    //    * Amplifys Hub.listen('auth', () => void); wurde aus Authenticator.tsx entfernt.
    //    * Sobald auf SignIn über eine eigene Route navigiert werden kann, kann localtion.reload()
    //    * wieder entfernt werden.
    //    */
    location.reload();
  });
}

export async function getAccessToken(): Promise<string | null> {
  const cognitoUser = await Auth.currentAuthenticatedUser();
  const refreshToken = await getRefreshToken();

  return new Promise((resolve) => {
    cognitoUser.refreshSession(refreshToken, (err: any, session: any) => {
      if (err) {
        console.log('Failed to refresh token', err);
        resolve(null);
      } else {
        resolve(session.idToken.jwtToken);
      }
    });
  });
}

export function forgotPassword(username: string): Promise<void> {
  return Auth.forgotPassword(username).then(() => undefined);
}

export function forgotPasswordSubmit(email: string, confirmationCode: string, newPassword: string): Promise<void> {
  return Auth.forgotPasswordSubmit(email, confirmationCode, newPassword).then(() => undefined);
}

export function signIn(email: string, password: string): Promise<CognitoUser> {
  return Auth.signIn({ username: email, password });
}

export function completeNewPassword(user: Partial<CognitoUser> | undefined, password: string): Promise<CognitoUser> {
  return Auth.completeNewPassword(user, password);
}

export async function getJwtToken(): Promise<string> {
  return (await Auth.currentSession()).getAccessToken().getJwtToken();
}

async function getRefreshToken(): Promise<CognitoRefreshToken> {
  return (await Auth.currentSession()).getRefreshToken();
}

export async function getCurrentUser(): Promise<CognitoUser> {
  const currentAuthenticatedUser = await Auth.currentAuthenticatedUser({ bypassCache: true });
  return toCognitoUser(currentAuthenticatedUser);
}

function toCognitoUser(raw: Record<string, any>): CognitoUser {
  return {
    username: raw.username,
    attributes: {
      email: raw?.attributes?.email,
      family_name: raw?.attributes?.family_name,
      given_name: raw?.attributes?.given_name,
      'custom:mandant': raw?.attributes['custom:mandant'],
      'custom:kuerzel': raw?.attributes['custom:kuerzel']
    },
    signInUserSession: {
      idToken: {
        payload: {
          'custom:kuerzel': raw?.signInUserSession?.idToken?.payload['custom:kuerzel'],
          'cognito:groups': raw?.signInUserSession?.idToken?.payload['cognito:groups']
        },
        jwtToken: raw?.signInUserSession?.idToken?.jwtToken
      }
    },
    unverified: {
      email: raw?.unverified?.email
    },
    challengeName: raw.challengeName
  };
}
