import createTheme from '@mui/material/styles/createTheme';
import { deDE } from '@mui/material/locale';

const primaryColor = '#00a651';
const secondaryColor = '#fff';
const errorColor = '#cc0000';

export const theme = createTheme(
  {
    palette: {
      primary: {
        main: primaryColor
      },
      secondary: {
        main: secondaryColor
      },
      error: {
        main: errorColor
      }
    }
  },
  deDE
);
