import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Status } from '../../shared/constants';

const button = {
  marginBottom: '1rem'
};

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onChooseStatus: (status: Status) => void;
  readonly title: string;
  readonly text: string;
  readonly optionA: Status;
  readonly optionB: Status;
};

export function StatusChangeDialog({ open, onClose, onChooseStatus, title, text, optionA, optionB }: Props): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="gutachten">
      <DialogTitle id="gutachten">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          sx={button}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            onChooseStatus(optionA);
            onClose();
          }}
          data-testid={`dialog-${optionA}`}
        >
          {optionA}
        </Button>
        <Button
          sx={button}
          fullWidth
          variant="contained"
          color="primary"
          onClick={() => {
            onChooseStatus(optionB);
            onClose();
          }}
          data-testid={`dialog-${optionB}`}
        >
          {optionB}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
