import React from 'react';
import Grid from '@mui/material/Grid';
import { holeGeschaedigten } from '../../shared/frontend/holeGeschaedigten';
import { Vorgang } from '../../types';

type Props = {
  readonly vorgang: Vorgang;
};

export default function VorgangInfoBar({ vorgang }: Props): JSX.Element {
  const auftraggeber = holeGeschaedigten(vorgang);

  let auftraggeberName = '-';
  if (auftraggeber) {
    if (auftraggeber.firmenname) {
      auftraggeberName = auftraggeber.firmenname;
    } else {
      auftraggeberName = `${auftraggeber.vorname ?? ''} ${auftraggeber.nachname ?? ''}`;
    }
  }

  return (
    <Grid container className="vorgang-toolbar__info">
      <Grid item>{auftraggeberName}</Grid>
      <Grid item>{vorgang?.fahrzeugdaten?.kennzeichen ?? '-'}</Grid>
      <Grid item>{vorgang?.fahrzeugdaten?.herstellername ?? '-'}</Grid>
      <Grid item>{vorgang?.fahrzeugdaten?.haupttypname ?? '-'}</Grid>
    </Grid>
  );
}
