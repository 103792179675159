import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@mui/material/Input';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import Formular from './common/Formular';
import WarningSign from './common/WarningSign';
import UnternehmenVerwaltung from './UnternehmenVerwaltung';
import { useHistory } from 'react-router';
import { useUser } from '../hooks/useUser';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as mutations from '../graphql/mutations';
import { Unternehmen, Vorgang } from '../types';
import getVersicherungAnzeigeText from '../domain/getVersicherungAnzeigeText';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_UNFALLDATEN_ERFASSEN } from '../frontendConstants';
import SearchPersonUnternehmen from './common/SearchPersonUnternehmen';
import { Fahrzeugart, Gutachtenart, UnternehmenTyp } from '../shared/constants';
import { CustomDatePicker } from './common/customDatePickers';
import { LandAutocomplete } from './LandTextField';

const fahrzeugarten = [
  { label: 'PKW', value: Fahrzeugart.PKW },
  { label: 'LKW', value: Fahrzeugart.LKW },
  { label: 'Kraftrad', value: Fahrzeugart.KRAFTRAD },
  { label: 'Kraftomnibus', value: Fahrzeugart.KRAFTOMNIBUS },
  { label: 'Fahrrad', value: Fahrzeugart.FAHRRAD },
  { label: 'Sonstiges', value: Fahrzeugart.SONSTIGES }
];

type Props = {
  readonly vorgang: Vorgang;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly setLoading: (value: boolean) => void;
  readonly isLoading: boolean;
};

export function Unfalldaten({ vorgang, setLoading, isLoading, speichereVorgang, setzeVorgang, aktualisiereVorgang }: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();
  const history = useHistory();

  const [editVersicherungModalOpen, setEditVersicherungModalOpen] = useState(false);

  useEffect(() => {
    if (history.location.pathname.includes('unfalldaten') && vorgang?.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG) {
      history.push(history.location.pathname.replace('/unfalldaten', ''));
    }
  }, [history, vorgang]);

  const handleVersicherungAusgeaehlt = (unternehmen: Unternehmen) => {
    speichereVorgang({ versicherungId: unternehmen.id });
  };

  const handleVersicherungAendernDialog = () => {
    setEditVersicherungModalOpen(true);
  };

  const handleVersicherungAnlegenDialog = () => {
    setEditVersicherungModalOpen(true);
  };

  const handleVersicherungLoeschen = () => {
    speichereVorgang({ versicherungId: null });
  };

  const handleVersicherungAendern = async (versicherung: Unternehmen) => {
    const aktualisiertesUnternehmen = await makeGraphqlQuery(mutations.updateUnternehmen, {
      input: versicherung
    });
    setzeVorgang({ ...vorgang, versicherung: aktualisiertesUnternehmen });
    setEditVersicherungModalOpen(false);
  };

  const handleVersicherungAnlegen = async (versicherung: Unternehmen) => {
    setLoading(true);
    const neuesUnternehmen = await makeGraphqlQuery(mutations.legeUnternehmenAn, { unternehmen: JSON.stringify(versicherung) });
    speichereVorgang({ versicherungId: neuesUnternehmen.id });
    setEditVersicherungModalOpen(false);
    setLoading(false);
  };

  const isDisabled = !vorgang || isLoading || !aktionErlaubt(AKTION_UNFALLDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  return (
    <Formular ueberschrift="Unfalldaten">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12} sm={6}>
          <LandAutocomplete
            disabled={isDisabled}
            value={vorgang?.unfalldaten?.land}
            onChange={(value) => {
              aktualisiereVorgang({
                unfalldaten: {
                  ...vorgang.unfalldaten,
                  land: value
                }
              });
            }}
            dataTestId="unfalldaten-land"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="Unfallort"
            defaultValue={vorgang?.unfalldaten?.unfallort ?? ''}
            disabled={isDisabled}
            onChange={(event) => {
              aktualisiereVorgang({
                unfalldaten: {
                  ...vorgang.unfalldaten,
                  unfallort: event.target.value
                }
              });
            }}
            fullWidth
            data-testid="unfalldaten-unfallort"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomDatePicker
            label="Datum"
            disableFuture
            value={vorgang.unfalldaten.datum}
            disabled={isDisabled}
            onDateSelect={(date) => {
              aktualisiereVorgang({
                unfalldaten: { ...vorgang.unfalldaten, datum: date }
              });
            }}
            fullWidth
            data-testid="unfalldaten-datum"
          />
        </Grid>
        {vorgang?.gutachtenart !== Gutachtenart.SCHADENSGUTACHTEN_KASKO && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                label="Kennzeichen Unfallgegner"
                defaultValue={vorgang?.unfalldaten?.kennzeichen ?? ''}
                disabled={isDisabled}
                inputProps={{
                  style: { textTransform: 'uppercase' }
                }}
                onChange={(event) => {
                  aktualisiereVorgang({
                    unfalldaten: {
                      ...vorgang.unfalldaten,
                      kennzeichen: event.target.value.toUpperCase()
                    }
                  });
                }}
                fullWidth
                data-testid="unfalldaten-kennzeichen"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="standard"
                label="Fahrzeughersteller"
                defaultValue={vorgang?.unfalldaten?.fahrzeughersteller ?? ''}
                disabled={isDisabled}
                onChange={(event) => {
                  aktualisiereVorgang({
                    unfalldaten: {
                      ...vorgang.unfalldaten,
                      fahrzeughersteller: event.target.value
                    }
                  });
                }}
                fullWidth
                data-testid="unfalldaten-fahrzeughersteller"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl variant="standard" fullWidth>
                <InputLabel>Fahrzeugart</InputLabel>
                <Select
                  variant="standard"
                  label="Fahrzeugart"
                  value={vorgang?.unfalldaten?.fahrzeugart ?? ''}
                  disabled={isDisabled}
                  onChange={(event) => {
                    aktualisiereVorgang({
                      unfalldaten: {
                        ...vorgang.unfalldaten,
                        fahrzeugart: event.target.value
                      }
                    });
                  }}
                  data-testid="unfalldaten-fahrzeugart"
                >
                  {fahrzeugarten.map((f) => (
                    <MenuItem value={f.value} key={f.value} data-testid={f.label}>
                      {f.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </>
        )}
        {vorgang?.unfalldaten?.fahrzeugart === 'SONSTIGES' ? (
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="Sonstige Fahrzeugart"
              defaultValue={vorgang?.unfalldaten?.sonstigeFahrzeugart ?? ''}
              disabled={isDisabled}
              onChange={(event) => {
                aktualisiereVorgang({
                  unfalldaten: {
                    ...vorgang.unfalldaten,
                    sonstigeFahrzeugart: event.target.value
                  }
                });
              }}
              fullWidth
              data-testid="unfalldaten-sonstigeFahrzeugart"
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={6} />
        )}
        <Grid item xs={12}>
          <SearchPersonUnternehmen
            personenUnternehmenTyp={UnternehmenTyp.VERSICHERUNG}
            isDisabled={isDisabled}
            label="Versicherung suchen"
            onSelect={(unternehmen) => handleVersicherungAusgeaehlt(unternehmen as Unternehmen)}
            testPraefix="unfalldaten-versicherung"
          />
        </Grid>
        <Grid item xs={12}>
          <WarningSign show={Boolean(vorgang?.versicherung === null && vorgang?.versicherungId)} text="Die Versicherung wurde gelöscht." />
          <FormControl variant="standard" disabled={true} fullWidth>
            <InputLabel>zugeordnete Versicherung</InputLabel>
            <Input
              data-testid="unfalldaten-versicherung"
              value={getVersicherungAnzeigeText(vorgang?.versicherung ?? null)}
              onChange={() => undefined}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    disabled={!vorgang?.versicherungId || isDisabled}
                    onClick={() => {
                      handleVersicherungAendernDialog();
                    }}
                    data-testid="versicherung-bearbeiten"
                    size="large"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      handleVersicherungAnlegenDialog();
                    }}
                    disabled={isDisabled}
                    data-testid="versicherung-anlegen"
                    size="large"
                  >
                    <AddBoxIcon />
                  </IconButton>
                  <IconButton
                    disabled={!vorgang?.versicherungId || isDisabled}
                    onClick={() => {
                      handleVersicherungLoeschen();
                    }}
                    data-testid="versicherung-loeschen"
                    size="large"
                  >
                    <DeleteIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="Versicherungsnummer"
            defaultValue={vorgang?.unfalldaten?.versicherungsnummer ?? ''}
            disabled={isDisabled}
            onChange={(event) => {
              aktualisiereVorgang({
                unfalldaten: {
                  ...vorgang.unfalldaten,
                  versicherungsnummer: event.target.value
                }
              });
            }}
            fullWidth
            data-testid="unfalldaten-versicherungsnummer"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="Schadennummer"
            defaultValue={vorgang?.unfalldaten?.schadennummer ?? ''}
            disabled={isDisabled}
            onChange={(event) => {
              aktualisiereVorgang({
                unfalldaten: {
                  ...vorgang.unfalldaten,
                  schadennummer: event.target.value
                }
              });
            }}
            fullWidth
            data-testid="unfalldaten-schadennummer"
          />
        </Grid>
        {vorgang.gutachtenart === Gutachtenart.SCHADENSGUTACHTEN_KASKO && (
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              label="Selbstbeteiligung"
              defaultValue={vorgang?.unfalldaten?.selbstbeteiligung ?? ''}
              disabled={isDisabled}
              onChange={(event) => {
                aktualisiereVorgang({
                  unfalldaten: {
                    ...vorgang.unfalldaten,
                    selbstbeteiligung: event.target.value
                  }
                });
              }}
              fullWidth
              data-testid="unfalldaten-selbstbeteiligung"
            />
          </Grid>
        )}
        <Grid item xs={12} sm={12}>
          <Button variant="contained" color="primary" href="https://www.zentralruf.de/online-anfrage/anfrageformular" target="_blank">
            GDV
          </Button>
        </Grid>
      </Grid>
      {editVersicherungModalOpen && (
        <UnternehmenVerwaltung
          unternehmenObjekt={vorgang?.versicherung ?? {}}
          typ={UnternehmenTyp.VERSICHERUNG}
          aktualisiereUnternehmen={handleVersicherungAendern}
          legeUnternehmenAn={handleVersicherungAnlegen}
          open={editVersicherungModalOpen}
          onClose={() => setEditVersicherungModalOpen(false)}
          isDisabled={isLoading}
          testPraefix="versicherung"
        />
      )}
    </Formular>
  );
}
