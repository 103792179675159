import Berechtigungen from '../Berechtigungen';
import { Status } from '../shared/constants';

const statusMap = {
  [Status.OFFEN]: 0,
  [Status.TERMINFESTLEGUNG]: 1,
  [Status.BESICHTIGUNG]: 2,
  [Status.BEWEISSICHERUNG]: 3,
  [Status.GUTACHTENAUSARBEITUNG]: 4,
  [Status.QUALITAETSSICHERUNG]: 5,
  [Status.DRUCK_VERSAND]: 6,
  [Status.MAHNWESEN]: 7,
  [Status.ABGESCHLOSSEN]: 8
};

type Berechtigung = {
  gruppe: string;
  aktion: string;
  erlaubt: (statusIndex: number) => boolean;
};

let berechtigungen: Berechtigung[] | null = null;

function zuBerechtigung(werte: string[]) {
  if (werte.length !== 11) {
    throw new Error(`Berechtigungsdefinition ist ungültig: "${werte}"`);
  }
  if (werte.some((it) => it.trim() === '')) {
    throw new Error(`Berechtigungsdefinition beinhaltet ungültige Werte: "${werte}"`);
  }

  const rechte = werte
    .slice(2)
    .map((it) => it.trim())
    .map((it) => Number(it));

  if (rechte.some((it) => isNaN(it))) {
    throw new Error(`Berechtigungsdefinition beinhaltet ungültige Rechte: "${werte}"`);
  }

  return {
    gruppe: werte[0].trim(),
    aktion: werte[1].trim(),
    erlaubt: (statusIndex: number) => rechte[statusIndex] === 1
  };
}

export function einlesen(csvString: string): Berechtigung[] {
  return csvString
    .split(/\r\n|\n/)
    .filter((it) => it.trim() !== '')
    .filter((it) => !it.startsWith(','))
    .map((it) => zuBerechtigung(it.split(',')));
}

export function aktionErlaubt(aktion: string, gruppen: string[], status: Status) {
  if (!berechtigungen) {
    berechtigungen = einlesen(Berechtigungen);
  }

  const statusIndex = statusMap[status];

  for (const gruppe of gruppen) {
    const erlaubt = berechtigungen
      .filter((it: Berechtigung) => it.aktion === aktion)
      .filter((it: Berechtigung) => it.gruppe === gruppe)
      .map((it: Berechtigung) => it.erlaubt(statusIndex))[0];

    if (erlaubt === true) {
      return true;
    }
  }

  return false;
}
