import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { dialogActions } from './Dialog';
import { EmailTextField } from '../common/EmailTextField';
import { EmailEmpfaenger, Person, Unternehmen, Vorgang } from '../../types';
import { EmailEmpfaengerTyp } from '../../shared/constants';

function schreibeErstenBuchstabenGross(textInGrossOderKleinbuchstaben = '') {
  return textInGrossOderKleinbuchstaben.toLowerCase().replace(/^\w/, (v) => v.toUpperCase());
}

function toViewModel(person: Person | Unternehmen, typ: EmailEmpfaengerTyp, dieEmpfaengerListe: (EmailEmpfaenger & { checked: boolean })[]) {
  const { email: emailAdressen } = person;

  for (const emailAdresse of emailAdressen ?? []) {
    dieEmpfaengerListe.push({
      emailAdresse: emailAdresse ?? '',
      typ,
      checked: typ === 'AUFTRAGGEBER' || typ === 'VERSICHERUNG'
    });
  }
}

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onSend: (empfaengerListe: EmailEmpfaenger[]) => void;
  readonly isLoading: boolean;
  readonly vorgang: Vorgang;
};

export function GutachtenVersendenDialog({ open, onClose, onSend, isLoading, vorgang }: Props): JSX.Element {
  const [email, setEmail] = useState('');
  const [empfaengerListe, setEmpfaengerListe] = useState<(EmailEmpfaenger & { checked: boolean })[]>([]);

  useEffect(() => {
    setEmpfaengerListe(erstelleEmpfaengerListe(vorgang));
  }, [vorgang]);

  const sendEmail = () => {
    const tmp = [...empfaengerListe];

    if (email !== '') {
      tmp.push({
        emailAdresse: email,
        checked: true,
        typ: EmailEmpfaengerTyp.SONSTIGE
      });
    }

    onSend(tmp.filter((it) => it.checked).map((it) => ({ emailAdresse: it.emailAdresse, typ: it.typ })));
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="versendenTitle">
      <DialogTitle id="versendenTitle">Gutachten versenden</DialogTitle>
      <DialogContent>
        <DialogContentText>Bitte geben Sie die Empfänger des Gutachtens ein.</DialogContentText>

        {empfaengerListe.map((empfaenger) => (
          <FormControlLabel
            key={`${empfaenger.emailAdresse} ${empfaenger.typ}`}
            control={
              <Checkbox
                color="primary"
                checked={empfaenger.checked}
                onChange={(event) => {
                  empfaenger.checked = event.target.checked;
                  setEmpfaengerListe([...empfaengerListe]);
                }}
              />
            }
            label={`${schreibeErstenBuchstabenGross(empfaenger.typ)} ${empfaenger.emailAdresse}`}
          />
        ))}

        <EmailTextField autoFocus id="name" label="E-Mail-Addresse" onChange={(event) => setEmail(event.target.value)} fullWidth />
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button onClick={onClose} color="secondary" variant="contained">
          Abbrechen
        </Button>
        <Button onClick={sendEmail} color="primary" variant="contained" disabled={isLoading}>
          Senden
        </Button>
      </DialogActions>
    </Dialog>
  );
}
function erstelleEmpfaengerListe(vorgang: Vorgang) {
  const dieEmpfaengerListe: (EmailEmpfaenger & { checked: boolean })[] = [];
  if (vorgang?.auftraggeber) {
    toViewModel(vorgang?.auftraggeber, EmailEmpfaengerTyp.AUFTRAGGEBER, dieEmpfaengerListe);
  }
  if (!vorgang?.fahrzeughalterIstAuftraggeber && vorgang?.fahrzeughalter) {
    toViewModel(vorgang?.fahrzeughalter, EmailEmpfaengerTyp.FAHRZEUGHALTER, dieEmpfaengerListe);
  }
  if (vorgang?.besichtigungen?.items) {
    const vermittlerEmailsSet = new Set(vorgang.besichtigungen.items.flatMap((besichtigungsdatum) => besichtigungsdatum.vermittler?.email ?? []));

    if (vermittlerEmailsSet.size) {
      toViewModel({ email: Array.from(vermittlerEmailsSet) }, EmailEmpfaengerTyp.VERMITTLER, dieEmpfaengerListe);
    }
  }
  if (vorgang?.versicherung) {
    toViewModel(vorgang.versicherung, EmailEmpfaengerTyp.VERSICHERUNG, dieEmpfaengerListe);
  }
  if (vorgang?.rechtsanwalt) {
    toViewModel(vorgang.rechtsanwalt, EmailEmpfaengerTyp.RECHTSANWALT, dieEmpfaengerListe);
  }
  if (vorgang?.bearbeiter) {
    toViewModel({ email: [vorgang.bearbeiter.email ?? null] }, EmailEmpfaengerTyp.SACHVERSTAENDIGER, dieEmpfaengerListe);
  }
  return dieEmpfaengerListe;
}
