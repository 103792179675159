import '../screens/Vorgang/Unterschriften.css';
import React, { ChangeEvent, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from './common/Alert';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { useDateiUrl } from '../hooks/useDateiUrl';
import { formatDDMMYYYY, toDateTimeString_plain } from '../shared/dateTime';
import WarningSign from './common/WarningSign';
import { HISVersendenDialog } from './dialog/HISVersendenDialog';
import { useUser } from '../hooks/useUser';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as queries from '../graphql/queries';
import * as mutations from '../graphql/mutations';
import { holeGeschaedigten } from '../shared/frontend/holeGeschaedigten';
import { name } from '../shared/frontend/adresse';
import { Foto, Mandant, Vorgang } from '../types';
import { useSnackbar } from 'notistack';
import { Modal } from './Modal';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Signature } from './common/Signature';
import { Unterzeichner } from '../shared/constants';
import Link from '@mui/material/Link';
import { UnterschriftenCheckboxState } from '../screens/Vorgang/Unterschriften';
import { S3Client } from '../shared/s3Client';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly updateCheckboxState: (newState: Partial<UnterschriftenCheckboxState>) => void;
  readonly checkboxState: UnterschriftenCheckboxState;
};

export function HIS({ vorgang, isLoading, setzeVorgang, updateCheckboxState, checkboxState }: Props): JSX.Element {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checkboxState.his}
              onChange={(event: ChangeEvent<HTMLInputElement>) => updateCheckboxState({ his: event.target.checked })}
              name="HIS"
              color="primary"
              data-testid="hisCheckbox"
            />
          }
          label={
            <Link
              onClick={(event) => {
                event.preventDefault();
                setIsModalOpen(true);
              }}
              data-testid="hisLink"
            >
              HIS
            </Link>
          }
        />
      </Grid>
      <HisModal vorgang={vorgang} isLoading={isLoading} isModalOpen={isModalOpen} setzeVorgang={setzeVorgang} onClose={() => setIsModalOpen(false)} />
    </>
  );
}

type ModalProps = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly isModalOpen: boolean;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly onClose: () => void;
};

function HisModal({ vorgang, isLoading, isModalOpen, setzeVorgang, onClose }: ModalProps): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const { user } = useUser();

  const hisUrl = useDateiUrl(vorgang?.unterschriften?.his?.key);
  const signDate = vorgang?.unterschriften?.dsgvo?.datum ?? new Date().toISOString();

  const geschaedigter = holeGeschaedigten(vorgang);

  const [mandant, setMandant] = useState<Partial<Mandant>>({});
  const [hisGenerierungMoeglich, setHisGenerierungMoeglich] = useState(false);
  const [openSendHis, setOpenSendHis] = useState(false);

  useEffect(() => {
    const holeMandantDaten = async () => {
      const mandant = await makeGraphqlQuery(queries.holeMandant, {
        mandant: vorgang.mandant
      });
      setMandant(mandant);
    };
    if (vorgang.mandant) {
      holeMandantDaten();
    }
  }, [vorgang.mandant]);

  useEffect(() => {
    const geschaedigterVollstaendig =
      ((geschaedigter?.nachname !== '' && geschaedigter?.vorname !== '') || geschaedigter?.firmenname) &&
      geschaedigter?.strasse !== '' &&
      geschaedigter?.plz !== '' &&
      geschaedigter?.ort !== '';

    const vorgangVollstaendig =
      vorgang?.fahrzeugdaten?.kennzeichen &&
      vorgang?.fahrzeugdaten?.fahrgestellnummer &&
      vorgang?.fahrzeugdaten?.erstesZulassungsdatum &&
      vorgang?.unterschriften?.his?.key &&
      vorgang?.fotos?.some((foto: Foto) => (foto.kategorien ?? []).includes('Fahrzeugschein'));

    const vehicleVollstaendig = (vorgang?.fahrzeugdaten?.herstellername?.length ?? 0) > 0 && (vorgang?.fahrzeugdaten?.untertypname?.length ?? 0) > 0;

    setHisGenerierungMoeglich(Boolean(geschaedigterVollstaendig && vorgangVollstaendig && vehicleVollstaendig));
  }, [vorgang, geschaedigter]);

  const generiereHisPdf = async () => {
    const aktualisierterVorgang = await makeGraphqlQuery(mutations.generiereHisDokument, {
      vorgangId: vorgang.id
    });
    setzeVorgang(aktualisierterVorgang);
  };

  const showHisPdf = async () => {
    if (vorgang?.his?.datei?.key) {
      window.open(await S3Client.get(vorgang?.his?.datei?.key), '_blank');
    }
  };

  const openSendHisDialog = () => {
    setOpenSendHis(true);
  };

  const sendHisDocument = async (email: string[]) => {
    const vorname = user?.attributes.given_name;
    const nachname = user?.attributes.family_name;

    try {
      const aktualisierterVorgang = await makeGraphqlQuery(mutations.versendeHis, {
        vorgangId: vorgang.id,
        emailAdressen: email,
        vorname,
        nachname
      });
      setzeVorgang(aktualisierterVorgang);
      enqueueSnackbar(`Das Dokument wurde erfolgreich an ${email} versendet.`, SUCCESS_MESSAGE_AUTO_HIDE);
      setOpenSendHis(false);
    } catch (error) {
      console.error({ error });
      if (error instanceof Error) {
        enqueueSnackbar(`Das HIS-Dokument konnte nicht versandt werden: ${error.message}`, ERROR_MESSAGE);
      } else {
        enqueueSnackbar('Das HIS-Dokument konnte nicht versandt werden', ERROR_MESSAGE);
      }
    }
  };

  return (
    <>
      <Modal
        openModal={isModalOpen}
        setOpenModal={onClose}
        title="informa HIS GmbH"
        body={
          <>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h6">Antrag auf Selbstauskunft für Fahrzeug:</Typography>
              </Grid>
              <Grid item xs={12}>
                (die Selbstauskunft ist kostenfrei)
              </Grid>
            </Grid>
            <br />
            <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS} data-testid="hisModal">
              <Grid item xs={4}>
                Vor- und Nachname
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="name">
                {geschaedigter?.firmenname && geschaedigter?.firmenname !== '' ? geschaedigter?.firmenname : name(geschaedigter)}
              </Grid>
              <Grid item xs={4}>
                Straße und Nr.
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="strasse">
                {geschaedigter?.strasse}
              </Grid>
              <Grid item xs={4}>
                PLZ, Ort
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="plzOrt">
                {geschaedigter?.plz} {geschaedigter?.ort}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Möchten Sie erfahren, ob und welche Informationen wir zu Ihrem Kraftfahrzeug gespeichert haben, übersenden Sie uns bitte einen Halter- bzw.
                  Eigentümernachweis, z.B. durch eine
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">Kopie der Zulassungsbescheinigung Teil I oder II.</Typography>
                Soweit aus diesem nicht eindeutig ersichtlich, erbitten wir zusätzlich folgende Informationen:
              </Grid>
              <Grid item xs={4}>
                Kfz-Kennzeichen
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="kennzeichen">
                {vorgang?.fahrzeugdaten?.kennzeichen}
              </Grid>
              <Grid item xs={4}>
                Fahrzeugidentifikations-Nr.
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="fahrgestellnummer">
                {vorgang?.fahrzeugdaten?.fahrgestellnummer}
              </Grid>
              <Grid item xs={4}>
                Erstzulassungsdatum
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="zulassungsdatum">
                {formatDDMMYYYY(vorgang?.fahrzeugdaten?.erstesZulassungsdatum)}
              </Grid>
              <Grid item xs={4}>
                Hersteller und Typ-Angabe
              </Grid>
              <Grid item xs={1}>
                :
              </Grid>
              <Grid item xs={7} data-testid="herstellerTyp">
                {vorgang?.fahrzeugdaten?.herstellername} {vorgang?.fahrzeugdaten?.untertypname}
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">Es wird Auskunft über alle vorliegenden Informationen zu dem angefragten Fahrzeug erteilt.</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Eine <u>weitere</u> Ausfertigung der Auskunft soll direkt und unverzüglich an die Sachverständigenorganisation Experts24 Tassone GmbH,
                  Hildenerstr.28, 42697 Solingen per E-Mail im PDF-Format an <a href="mailto:gutachten@experts24.de">gutachten@experts24.de</a> und auf dem
                  Postweg zugesandt werden.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Für die weitere Verwendung dieser Daten (z.B. Gutachtenerstellung) erfolgt hiermit ausdrücklich die Genehmigung an die
                  Sachverständigenorganisation Experts24 Tassone GmbH.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  Gemäß Art. 12 Abs. 3 DSGVO wird die Auskunft <u>unverzüglich</u> erteilt. Kosten, die durch Verzögerungen der Auskunftserteilung entstehen,
                  haben die Verantwortlichen (informa HIS GmbH) zu tragen. Nach Erteilung der Auskunft wird die unverzügliche und vollständige <u>Löschung</u>{' '}
                  bei HIS und ggf. bei Prüfdienstleistern, wie z.B. ControlExperts, beantragt. Die Löschung bitten wir mit der Auskunftserteilung zu bestätigen.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Signature
                  onSign={() => {}}
                  onDeleteSignature={() => {}}
                  signatureUrl={hisUrl}
                  isLoading={isLoading}
                  vorgang={vorgang}
                  unterzeichnerAuswahl={vorgang?.unterschriften?.dsgvo?.unterzeichner ?? Unterzeichner.AUFTRAGGEBER}
                  date={signDate}
                  readOnly={true}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={!hisGenerierungMoeglich || Boolean(vorgang?.his?.wurdeHisVersand)}
                  variant="contained"
                  color="primary"
                  onClick={generiereHisPdf}
                  data-testid="pdfGenerieren"
                >
                  PDF generieren
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={!vorgang?.his?.datei?.key} variant="contained" color="primary" onClick={showHisPdf} data-testid="pdfOeffnen">
                  PDF
                </Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!vorgang?.his?.datei?.key || !!vorgang?.his?.wurdeHisVersand}
                  variant="contained"
                  color="primary"
                  onClick={openSendHisDialog}
                  data-testid="pdfVersenden"
                >
                  PDF Versenden
                </Button>
              </Grid>
              {vorgang?.his?.wurdeHisVersand && (
                <Grid item xs={12}>
                  HIS-Dokument wurde am {toDateTimeString_plain(vorgang?.his?.versandDatum)} versandt.
                </Grid>
              )}
              <Grid item>
                <WarningSign
                  text="HIS Dokument-Generierung nur möglich, wenn Daten zum Geschädigten, Fahrzeug
          und der Fahrzeugschein vorliegen."
                  show={!hisGenerierungMoeglich}
                />
              </Grid>
            </Grid>
          </>
        }
        footer={
          <div className="unterschriften__modal-button">
            <Button color="primary" variant="contained" onClick={onClose}>
              Schließen
            </Button>
          </div>
        }
      />
      {openSendHis && (
        <HISVersendenDialog
          open={openSendHis}
          onClose={() => setOpenSendHis(false)}
          onSend={(email) => sendHisDocument(email)}
          isLoading={isLoading}
          hisEmail={mandant.hisEmail ?? ''}
        />
      )}
    </>
  );
}
