import React, { useCallback, useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import Switch from '@mui/material/Switch';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';
import { SPACING_BETWEEN_BOXES } from './common/spacings';
import Formular from './common/Formular';
import { Regel, Textbaustein as TextbausteinType, Vorgang, VorgangTextbaustein } from '../types';

type SortProps = {
  oldIndex: number;
  newIndex: number;
};

export type TextbausteinDisplayInfo = {
  disabled?: boolean | null;
  expanded?: boolean;
};

const LEERER_TEXTBAUSTEIN: Partial<TextbausteinType & TextbausteinDisplayInfo> = {
  ueberschrift: '',
  text: '',
  regel: Regel.IMMER,
  reihenfolge: 0
};

type SortItemProps = {
  textbaustein: Partial<TextbausteinType & TextbausteinDisplayInfo>;
  textbausteinIndex: number;
  aktualisiereTextbaustein: (textbausteinId: string, attribut: keyof (TextbausteinType & TextbausteinDisplayInfo), wert: string | boolean) => void;
  changeExpandedStatus: (textbausteinId: string, wert: boolean) => void;
  isLoading: boolean;
};

const SortItem = SortableElement<SortItemProps>(
  ({ textbaustein, textbausteinIndex, aktualisiereTextbaustein, changeExpandedStatus, isLoading }: SortItemProps): JSX.Element => {
    return (
      <Grid item xs={12} lg={6} key={textbaustein.id}>
        <Textbaustein
          key={textbaustein.id}
          index={textbausteinIndex}
          textbaustein={textbaustein}
          aktualisiereTextbaustein={aktualisiereTextbaustein}
          changeExpandedStatus={changeExpandedStatus}
          isLoading={isLoading}
        />
      </Grid>
    );
  }
);

type SortableListProps = {
  items: Partial<TextbausteinType & TextbausteinDisplayInfo>[];
  aktualisiereTextbaustein: (textbausteinId: string, attribut: keyof (TextbausteinType & TextbausteinDisplayInfo), wert: string | boolean) => void;
  changeExpandedStatus: (textbausteinId: string, wert: boolean) => void;
  isLoading: boolean;
};

const SortableList = SortableContainer<SortableListProps>(
  ({ items: textbausteine, aktualisiereTextbaustein, changeExpandedStatus, isLoading }: SortableListProps): JSX.Element => {
    return (
      <Grid container spacing={3}>
        {textbausteine.map((textbaustein, index) => (
          <SortItem
            key={`item-${textbaustein.id}`}
            index={index}
            textbaustein={textbaustein}
            textbausteinIndex={index}
            aktualisiereTextbaustein={aktualisiereTextbaustein}
            changeExpandedStatus={changeExpandedStatus}
            isLoading={isLoading}
          />
        ))}
      </Grid>
    );
  }
);

type Props = {
  textbausteine: Partial<TextbausteinType & TextbausteinDisplayInfo>[];
  setTextbausteine: (textbausteine: Partial<TextbausteinType & TextbausteinDisplayInfo>[]) => void;
  areTextbausteineLoading: boolean;
  vorgang: Vorgang;
  isLoading: boolean;
  aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function Textbausteine({ textbausteine, setTextbausteine, areTextbausteineLoading, vorgang, isLoading, aktualisiereVorgang }: Props): JSX.Element {
  const [customTextbaustein, setCustomTextbaustein] = useState<Partial<TextbausteinType & TextbausteinDisplayInfo>>(LEERER_TEXTBAUSTEIN);

  const fuegeCustomTextbausteinHinzu = useCallback(() => {
    customTextbaustein.reihenfolge = textbausteine.length;
    setTextbausteine([...textbausteine, customTextbaustein]);
    aktualisiereVorgang({
      vorgangTextbausteine: [...(vorgang.vorgangTextbausteine ? vorgang.vorgangTextbausteine : []), toCleanedTextbaustein(customTextbaustein)]
    });
    setCustomTextbaustein(LEERER_TEXTBAUSTEIN);
  }, [customTextbaustein, textbausteine, setTextbausteine, aktualisiereVorgang, vorgang.vorgangTextbausteine]);

  const aktualisiereTextbaustein = useCallback(
    (textbausteinId: string, attribut: string, wert: string | boolean) => {
      const textbausteinAenderungen = textbausteine.map((textbaustein) => {
        if (textbaustein.id === textbausteinId) {
          return {
            ...textbaustein,
            [attribut]: wert
          };
        }
        return textbaustein;
      });
      setTextbausteine(textbausteinAenderungen);
      aktualisiereVorgang({ vorgangTextbausteine: textbausteinAenderungen.map(toCleanedTextbaustein) });
    },
    [aktualisiereVorgang, setTextbausteine, textbausteine]
  );

  const changeExpandedStatus = useCallback(
    (textbausteinId: string, wert: boolean) => {
      const textbausteinAenderungen = textbausteine.map((textbaustein) => {
        if (textbaustein.id === textbausteinId) {
          return {
            ...textbaustein,
            expanded: wert
          };
        }
        return textbaustein;
      });
      setTextbausteine(textbausteinAenderungen);
    },
    [setTextbausteine, textbausteine]
  );

  const onSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortProps) => {
      const sorted = arrayMove(textbausteine, oldIndex, newIndex).map((tbs, index) => {
        return { ...tbs, reihenfolge: index };
      });

      setTextbausteine(sorted);
      aktualisiereVorgang({ vorgangTextbausteine: sorted.map(toCleanedTextbaustein) });
    },
    [aktualisiereVorgang, setTextbausteine, textbausteine]
  );

  return (
    <Grid container spacing={SPACING_BETWEEN_BOXES}>
      <Grid item xs={12}>
        <Formular ueberschrift="Textbausteine" {...(areTextbausteineLoading ? {} : { 'data-anzahl-textbausteine': textbausteine.length })}>
          <Grid container spacing={SPACING_BETWEEN_BOXES}>
            {areTextbausteineLoading ? (
              <Grid
                item
                xs={12}
                sx={{
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <CircularProgress />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <SortableList
                    axis="xy"
                    pressDelay={300}
                    items={textbausteine}
                    onSortEnd={onSortEnd}
                    aktualisiereTextbaustein={aktualisiereTextbaustein}
                    changeExpandedStatus={changeExpandedStatus}
                    isLoading={isLoading}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Card raised>
                    <CardHeader title="Neuer Textbaustein" />
                    <CardContent>
                      <TextField
                        variant="standard"
                        value={customTextbaustein.ueberschrift}
                        data-testid={`ueberschrift-${customTextbaustein.reihenfolge}`}
                        onChange={(event) =>
                          setCustomTextbaustein({
                            ...customTextbaustein,
                            ueberschrift: event.target.value
                          })
                        }
                      />
                      <TextField
                        variant="standard"
                        fullWidth
                        multiline
                        value={customTextbaustein.text}
                        inputProps={{
                          'data-testid': `text-${customTextbaustein.reihenfolge}`
                        }}
                        onChange={(event) =>
                          setCustomTextbaustein({
                            ...customTextbaustein,
                            text: event.target.value
                          })
                        }
                      />
                    </CardContent>
                    <CardActions>
                      <IconButton
                        color="primary"
                        onClick={() => fuegeCustomTextbausteinHinzu()}
                        disabled={
                          isLoading ||
                          !customTextbaustein.ueberschrift ||
                          customTextbaustein.ueberschrift === '' ||
                          !customTextbaustein.text ||
                          customTextbaustein.text === ''
                        }
                        data-testid={`loeschen-${customTextbaustein.reihenfolge}`}
                        size="large"
                      >
                        <AddIcon />
                      </IconButton>
                    </CardActions>
                  </Card>
                </Grid>
              </>
            )}
          </Grid>
        </Formular>
      </Grid>
    </Grid>
  );
}

type TextbausteinProps = {
  textbaustein: Partial<TextbausteinType & TextbausteinDisplayInfo>;
  aktualisiereTextbaustein: (id: string, attribut: keyof (TextbausteinType & TextbausteinDisplayInfo), wert: string | boolean) => void;
  changeExpandedStatus: (id: string, expanded: boolean) => void;
  isLoading: boolean;
  index: number;
};

const Textbaustein = React.memo(function TextbausteinMemo({
  textbaustein,
  aktualisiereTextbaustein,
  changeExpandedStatus,
  isLoading,
  index
}: TextbausteinProps): JSX.Element {
  return (
    <Grid item xs={12}>
      <Card raised>
        <Accordion
          expanded={textbaustein.expanded ?? false}
          onChange={(event, expanded) => {
            changeExpandedStatus(textbaustein.id ?? '', expanded);
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>
              <Tooltip title={textbaustein.disabled ? 'Textbaustein einblenden' : 'Textbaustein ausblenden'}>
                <>
                  {index + 1}
                  <Switch
                    color="primary"
                    disabled={isLoading}
                    onChange={(event) => aktualisiereTextbaustein(textbaustein.id ?? '', 'disabled', !event.target.checked)}
                    checked={!textbaustein.disabled}
                  />
                </>
              </Tooltip>
              {textbaustein.ueberschrift}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextField
              variant="standard"
              fullWidth
              multiline
              disabled={Boolean(isLoading || textbaustein.disabled)}
              value={textbaustein.text}
              onChange={(event) => aktualisiereTextbaustein(textbaustein.id ?? '', 'text', event.target.value)}
            />
          </AccordionDetails>
        </Accordion>
      </Card>
    </Grid>
  );
});

function toCleanedTextbaustein(textbaustein: Partial<TextbausteinType & TextbausteinDisplayInfo>): VorgangTextbaustein {
  return {
    id: textbaustein.id,
    ueberschrift: textbaustein.ueberschrift ?? '',
    text: textbaustein.text ?? '',
    regel: textbaustein.regel ?? Regel.IMMER,
    reihenfolge: textbaustein.reihenfolge ?? 0,
    disabled: textbaustein.disabled ?? false
  };
}
