import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import Tab from '@mui/material/Tab';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SPACING_BETWEEN_COLUMNS, SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { EuroFormat, IntegerFormat } from './common/inputFormats';
import TabPanel from './common/TabPanel';
import { useUser } from '../hooks/useUser';
import { MEHRWERTSTEUER } from '../shared/domain/vorgang';
import { berechneFahrzeugalterInMonaten } from '../shared/frontend/berechneFahrzeugalterInMonaten';
import { isAenderbar } from '../domain/isAenderbar';
import { Bewertung, Vorgang } from '../types';
import { textfieldInlineButton } from '../assets/sharedStyles';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_BEWERTUNG_ERFASSEN } from '../frontendConstants';
import { Gutachtenart } from '../shared/constants';
import TextField from '@mui/material/TextField';

const multiLinedMenuItem = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start'
};

export function mitMehrwertsteuer(wert: number | null | undefined): number {
  return (wert ?? 0) * (MEHRWERTSTEUER / 100 + 1);
}

export function berechneMerkantilenMinderwertNachMFM(
  wiederbeschaffungswert: number,
  neupreis: number,
  reparaturkosten: number,
  fahrzeugalterInMonaten: number,
  schadenumfang: number,
  faktorMarktgaengigkeit: number,
  faktorVorschaeden: number
): number {
  // [(VW/100) + (VW/NP x RK x SU x M)] x FM x FV
  const alterskorrektur = bestimmeAlterskorrektur(fahrzeugalterInMonaten);

  const zwischensummand = (wiederbeschaffungswert / neupreis) * reparaturkosten * schadenumfang * alterskorrektur;

  function rundeAufHundert(wert: number): number {
    return Math.ceil(wert * 0.01) * 100;
  }

  return rundeAufHundert((wiederbeschaffungswert / 100 + zwischensummand) * faktorMarktgaengigkeit * faktorVorschaeden);
}

export function berechneMerkantilenMinderwertNachHalbgewachs(
  wiederbeschaffungswert: number,
  reparaturkosten: number,
  fahrzeugalterInMonaten: number,
  veraeusserungswert: number,
  lohnkosten: number,
  materialkosten: number
): number {
  //100 (-1,831*10⁻⁷  M³+1,954*10⁻⁵  M²-1051*10⁻³  M+0,03175
  const xM =
    100 *
    (-1.831 * Math.pow(10, -7) * Math.pow(fahrzeugalterInMonaten, 3) +
      1.954 * Math.pow(10, -5) * Math.pow(fahrzeugalterInMonaten, 2) -
      1.051 * Math.pow(10, -3) * fahrzeugalterInMonaten +
      0.03175);

  //100 (-7,7828*10⁻⁷  K²+2,0810*10⁻⁴  K- 1,0722*10⁻³)
  const k = (100 * reparaturkosten) / veraeusserungswert;
  const xK = 100 * (-7.7828 * Math.pow(10, -7) * Math.pow(k, 2) + 2.081 * Math.pow(10, -4) * k - 1.0722 * Math.pow(10, -3));

  //100 (0,015354 ln(A) – 0,055549)
  const a = (100 * lohnkosten) / materialkosten;
  const xA = 100 * (0.015354 * Math.log(a) - 0.055549);

  const faktor = xK + xA + xM;
  const minderwert = rundeAufFuenfzig((faktor * (wiederbeschaffungswert + reparaturkosten)) / 100);
  return minderwert >= 100 ? minderwert : 0;
}

export function rundeAufFuenfzig(wert: number): number {
  return Math.round(wert / 50) * 50;
}

export function bestimmeAlterskorrektur(fahrzeugalter: number): number {
  const alterskorrekturen = [
    { alter: 0, alterskorrektur: 0.25 },
    { alter: 6, alterskorrektur: 0.2417 },
    { alter: 12, alterskorrektur: 0.2236 },
    { alter: 24, alterskorrektur: 0.1734 },
    { alter: 60, alterskorrektur: 0.0338 },
    { alter: 96, alterskorrektur: 0.0535 },
    { alter: 114, alterskorrektur: 0.0206 },
    { alter: 120, alterskorrektur: 0.0 }
  ];
  return alterskorrekturen.reduce((ak, { alter, alterskorrektur }) => (fahrzeugalter >= alter ? alterskorrektur : ak), alterskorrekturen[0].alterskorrektur);
}

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function MerkantilerMinderwert({ vorgang, isLoading, aktualisiereVorgang }: Props): JSX.Element {
  const { gruppenVonMandant } = useUser();
  const [tabIndex, setTabIndex] = useState(0);
  const [schadenumfang, setSchadenumfang] = useState(0.2);
  const [faktorMarktgaengigkeit, setFaktorMarktgaengigkeit] = useState(1);
  const [faktorVorschaeden, setFaktorVorschaeden] = useState(1);
  const [faktorMarktgaengigkeitOpen, setFaktorMarktgaengigkeitOpen] = useState(false);
  const [schadenumfangOpen, setSchadenumfangOpen] = useState(false);

  const schadenumfaenge = [
    {
      value: 0.2,
      label: '0,2',
      description: 'Erneuerung/Instandsetzung von Anbauteilen'
    },
    {
      value: 0.4,
      label: '0,4',
      description: 'Erneuerung/Instandsetzung von geschraubten Karosserieteilen'
    },
    {
      value: 0.6,
      label: '0,6',
      description: 'Geringe Instandsetzungsarbeiten von (mit-)tragenden Karosserieteilen'
    },
    {
      value: 0.8,
      label: '0,8',
      description: 'Erhebliche Instandsetzungsarbeiten/Erneuerung von (mit-)tragenden Karosserieteilen'
    },
    {
      value: 1.0,
      label: '1,0',
      description: 'Erneuerung und/oder erhebliche Instandsetzung von tragenden Karosserieteilen'
    }
  ];

  const faktorMarktgaengigkeiten = [
    {
      value: 0.6,
      label: 'sehr gut',
      description: 'Die Nachfrage übersteigt das Angebot deutlich'
    },
    { value: 0.8, label: 'gut', description: 'erhöhte Nachfrage' },
    {
      value: 1.0,
      label: 'normal',
      description: 'ausgeglichenes Verhältnis zwischen Angebot und Nachfrage'
    },
    { value: 1.2, label: 'schlecht', description: 'erhöhtes Angebot' },
    {
      value: 1.4,
      label: 'sehr schlecht',
      description: 'Fahrzeug ist schwer verkäuflich'
    }
  ];

  const faktorVorschaedenListe = [
    { value: 0.2, label: 'erheblicher Vorschaden' },
    { value: 0.4, label: 'hoher Vorschaden' },
    { value: 0.6, label: 'mittlerer Vorschaden' },
    { value: 0.8, label: 'geringer Vorschaden' },
    { value: 1.0, label: 'ohne Vorschaden' }
  ];

  const handleChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    setTabIndex(newValue);
  };

  const merkantilerMinderwertNachMFM =
    berechneMerkantilenMinderwertNachMFM(
      parseFloat(vorgang?.bewertung?.wiederbeschaffungswert ?? '0'),
      parseFloat(vorgang?.bewertung?.neupreis ?? '0'),
      parseFloat(vorgang?.bewertung?.reparaturkosten ?? '0'),
      parseFloat(vorgang?.bewertung?.fahrzeugalter ?? '0'),
      schadenumfang,
      faktorMarktgaengigkeit,
      faktorVorschaeden
    ) || '0';

  const merkantilerMinderwertNachHalbgewachs =
    berechneMerkantilenMinderwertNachHalbgewachs(
      parseFloat(vorgang?.bewertung?.wiederbeschaffungswert ?? '0'),
      parseFloat(vorgang?.bewertung?.reparaturkosten ?? '0'),
      parseFloat(vorgang?.bewertung?.fahrzeugalter ?? '0'),
      parseFloat(vorgang?.bewertung?.veraeusserungswert ?? '0'),
      parseFloat(vorgang?.bewertung?.lohnkosten ?? '0'),
      parseFloat(vorgang?.bewertung?.materialkosten ?? '0')
    ) || '0';

  const isDisabled =
    isLoading ||
    !isAenderbar(vorgang) ||
    vorgang?.gutachtenart === Gutachtenart.KURZGUTACHTEN ||
    !aktionErlaubt(AKTION_BEWERTUNG_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  const aktualisiereBewertung = (key: keyof Bewertung, wert: any) => {
    aktualisiereVorgang({
      bewertung: { ...vorgang.bewertung, [key]: wert }
    });
  };

  return (
    <Accordion data-testid="merkantilerMinderwertAccordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6" gutterBottom>
          Merkantiler Minderwert
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box pt={2}>
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={6} md={4}>
              <TextField
                variant="standard"
                label="Neupreis"
                value={vorgang.bewertung?.neupreis ?? '0'}
                onChange={(event) => {
                  aktualisiereBewertung('neupreis', event.target.value);
                }}
                fullWidth
                data-testid="neupreis"
                disabled={isDisabled}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.neupreisBrutto ?? '0'}
                disabled={true}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  startAdornment: (
                    <Tooltip title="Neupreis aus DAT übernehmen">
                      <IconButton
                        sx={textfieldInlineButton}
                        color="primary"
                        onClick={() => {
                          aktualisiereBewertung('neupreis', vorgang?.dat?.neupreisBrutto);
                        }}
                        disabled={!vorgang?.dat?.neupreisBrutto || isDisabled}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                data-testid="DATNeupreis"
              />
            </Grid>
            <Hidden mdDown>
              <Grid item md={4} />
            </Hidden>
            <Grid item xs={6} md={4}>
              <TextField
                variant="standard"
                fullWidth
                InputProps={{
                  inputComponent: IntegerFormat,
                  endAdornment: <InputAdornment position="end">Monate</InputAdornment>
                }}
                label="Fahrzeugalter"
                value={vorgang.bewertung?.fahrzeugalter ?? ''}
                onChange={(event) => aktualisiereBewertung('fahrzeugalter', event.target.value)}
                data-testid="fahrzeugalterInMonaten"
                disabled={isDisabled}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                variant="standard"
                label="DAT"
                fullWidth
                value={berechneFahrzeugalterInMonaten(vorgang) ?? '0'}
                disabled={true}
                InputProps={{
                  inputComponent: IntegerFormat,
                  endAdornment: <InputAdornment position="end">Monate</InputAdornment>,
                  startAdornment: (
                    <Tooltip title="Fahrzeugalter aus DAT übernehmen">
                      <IconButton
                        disabled={isDisabled}
                        sx={textfieldInlineButton}
                        color="primary"
                        onClick={() => {
                          const fahrzeugalter = berechneFahrzeugalterInMonaten(vorgang);
                          aktualisiereBewertung('fahrzeugalter', fahrzeugalter);
                        }}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                data-testid="DATfahrzeugalterInMonaten"
              />
            </Grid>
            <Hidden mdDown>
              <Grid item xs={4} />
            </Hidden>
            <Grid item xs={6} md={4}>
              <TextField
                variant="standard"
                label="Veräusserungswert"
                value={vorgang.bewertung?.veraeusserungswert ?? '0'}
                onChange={(event) => {
                  aktualisiereBewertung('veraeusserungswert', event.target.value);
                }}
                fullWidth
                data-testid="veraeusserungswert"
                disabled={isDisabled}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6} md={4}>
              <TextField
                variant="standard"
                fullWidth
                label="Wiederbeschaffungswert"
                value={vorgang?.bewertung?.wiederbeschaffungswert ?? '0'}
                disabled={true}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  startAdornment: (
                    <Tooltip title="Wiederbeschaffungswert übernehmen">
                      <IconButton
                        sx={textfieldInlineButton}
                        color="primary"
                        onClick={() => {
                          aktualisiereBewertung('veraeusserungswert', vorgang?.bewertung?.wiederbeschaffungswert);
                        }}
                        disabled={!vorgang?.bewertung?.wiederbeschaffungswert || !isAenderbar(vorgang)}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  )
                }}
                data-testid="DATVeraeusserungswert"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          <Tabs variant="fullWidth" value={tabIndex} indicatorColor="primary" textColor="primary" onChange={handleChange} aria-label="disabled tabs example">
            <Tab label="Marktrelevanz- und Faktorenmethode" data-testid="Marktrelevanz- und Faktorenmethode" />
            <Tab label="Halbgewachs DEKRA" data-testid="Halbgewachs DEKRA" />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
              <Grid item container xs={12} sm={4}>
                <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS} />
              </Grid>
              <Grid item container xs={12} sm={4}>
                <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Schadenumfang</InputLabel>
                      <Select
                        variant="standard"
                        onOpen={() => setSchadenumfangOpen(true)}
                        onClose={() => setSchadenumfangOpen(false)}
                        label="Schadenumfang"
                        value={schadenumfang}
                        onChange={(event) => setSchadenumfang(parseFloat(event?.target?.value as string))}
                        data-testid="mfm-schadenumfang"
                        disabled={isDisabled}
                      >
                        {schadenumfaenge.map((s) => (
                          <MenuItem key={s.value} value={s.value} data-testid={s.label} sx={multiLinedMenuItem}>
                            {s.label}
                            {schadenumfangOpen && <Typography variant="caption">{s.description}</Typography>}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Faktor Marktgängigkeit</InputLabel>
                      <Select
                        variant="standard"
                        onOpen={() => setFaktorMarktgaengigkeitOpen(true)}
                        onClose={() => setFaktorMarktgaengigkeitOpen(false)}
                        label="Faktor Marktgängigkeit"
                        value={faktorMarktgaengigkeit}
                        onChange={(event) => setFaktorMarktgaengigkeit(parseFloat(event.target.value as string))}
                        data-testid="mfm-faktorMarktgaengigkeit"
                        disabled={isDisabled}
                      >
                        {faktorMarktgaengigkeiten.map((f) => (
                          <MenuItem key={f.value} value={f.value} data-testid={f.label} sx={multiLinedMenuItem}>
                            {f.label}
                            {faktorMarktgaengigkeitOpen && <Typography variant="caption">{f.description}</Typography>}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl variant="standard" fullWidth>
                      <InputLabel>Faktor Vorschäden</InputLabel>
                      <Select
                        variant="standard"
                        label="Faktor Vorschäden"
                        value={faktorVorschaeden}
                        onChange={(event) => setFaktorVorschaeden(parseFloat(event.target.value as string))}
                        data-testid="mfm-faktorVorschaeden"
                        disabled={isDisabled}
                      >
                        {faktorVorschaedenListe.map((f) => (
                          <MenuItem key={f.value} value={f.value} data-testid={f.label}>
                            {f.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid item container xs={false} sm={4} />
              </Hidden>
              <Grid item container xs={12} sm={4}>
                <TextField
                  variant="standard"
                  disabled
                  label="Merkantiler Minderwert"
                  value={merkantilerMinderwertNachMFM}
                  fullWidth
                  data-testid="mfm-merkantilerMinderwert"
                  InputProps={{
                    inputComponent: EuroFormat,
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={2}>
                <div>
                  <Button
                    disabled={isDisabled}
                    variant="contained"
                    color="primary"
                    onClick={() => aktualisiereBewertung('merkantilerMinderwert', merkantilerMinderwertNachMFM)}
                    data-testid="mfm-uebernehmen"
                  >
                    Übernehmen
                  </Button>
                </div>
              </Grid>
              <Hidden smDown>
                <Grid item container xs={false} sm={2} />
              </Hidden>
            </Grid>
          </TabPanel>
          <TabPanel value={tabIndex} index={1}>
            <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
              <Grid item container xs={12} sm={4}>
                <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS} />
              </Grid>
              <Grid item container xs={12} sm={4}>
                <Grid item container spacing={SPACING_BETWEEN_FORM_FIELDS}>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="Lohnkosten"
                      value={vorgang.bewertung?.lohnkosten ?? '0'}
                      onChange={(event) => {
                        aktualisiereBewertung('lohnkosten', event.target.value);
                      }}
                      fullWidth
                      data-testid="halbgewachs-lohnkosten"
                      disabled={isDisabled}
                      InputProps={{
                        inputComponent: EuroFormat,
                        endAdornment: <InputAdornment position="end">€</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="DAT"
                      fullWidth
                      value={mitMehrwertsteuer(vorgang?.dat?.lohnkosten)}
                      disabled={true}
                      InputProps={{
                        inputComponent: EuroFormat,
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        startAdornment: (
                          <Tooltip title="Lohnkosten aus DAT übernehmen">
                            <IconButton
                              sx={textfieldInlineButton}
                              color="primary"
                              onClick={() => {
                                const lohnkostenMitMwst = mitMehrwertsteuer(vorgang?.dat?.lohnkosten);
                                aktualisiereBewertung('lohnkosten', lohnkostenMitMwst);
                              }}
                              disabled={!mitMehrwertsteuer(vorgang?.dat?.lohnkosten) || isDisabled}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }}
                      data-testid="DATHalbgewachsLohnkosten"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="Materialkosten"
                      value={vorgang.bewertung?.materialkosten ?? '0'}
                      onChange={(event) => {
                        aktualisiereBewertung('materialkosten', event.target.value);
                      }}
                      fullWidth
                      data-testid="halbgewachs-materialkosten"
                      disabled={isDisabled}
                      InputProps={{
                        inputComponent: EuroFormat,
                        endAdornment: <InputAdornment position="end">€</InputAdornment>
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      variant="standard"
                      label="DAT"
                      fullWidth
                      value={mitMehrwertsteuer(vorgang?.dat?.materialkosten)}
                      disabled={true}
                      InputProps={{
                        inputComponent: EuroFormat,
                        endAdornment: <InputAdornment position="end">€</InputAdornment>,
                        startAdornment: (
                          <Tooltip title="Materialkosten aus DAT übernehmen">
                            <IconButton
                              sx={textfieldInlineButton}
                              color="primary"
                              onClick={() => {
                                const materialKostenMitMwst = mitMehrwertsteuer(vorgang?.dat?.materialkosten);
                                aktualisiereBewertung('materialkosten', materialKostenMitMwst);
                              }}
                              disabled={!mitMehrwertsteuer(vorgang?.dat?.materialkosten) || isDisabled}
                            >
                              <ArrowBackIcon />
                            </IconButton>
                          </Tooltip>
                        )
                      }}
                      data-testid="DATHalbgewachsMaterialkosten"
                    />
                  </Grid>
                  <Hidden smDown>
                    <Grid
                      item
                      xs={false}
                      sm={12}
                      sx={(theme) => ({
                        height: theme.spacing(7)
                      })}
                    />
                  </Hidden>
                </Grid>
              </Grid>
              <Hidden smDown>
                <Grid item container xs={false} sm={4} />
              </Hidden>
              <Grid item container xs={12} sm={4}>
                <TextField
                  variant="standard"
                  disabled
                  label="Merkantiler Minderwert"
                  value={merkantilerMinderwertNachHalbgewachs}
                  fullWidth
                  data-testid="halbgewachs-merkantilerMinderwert"
                  InputProps={{
                    inputComponent: EuroFormat,
                    endAdornment: <InputAdornment position="end">€</InputAdornment>
                  }}
                />
              </Grid>
              <Grid item container xs={12} sm={2}>
                <div>
                  <Button
                    disabled={isDisabled}
                    variant="contained"
                    color="primary"
                    onClick={() => aktualisiereBewertung('merkantilerMinderwert', merkantilerMinderwertNachHalbgewachs)}
                    data-testid="halbgewachs-uebernehmen"
                  >
                    Übernehmen
                  </Button>
                </div>
              </Grid>
              <Hidden smDown>
                <Grid item container xs={false} sm={2} />
              </Hidden>
            </Grid>
          </TabPanel>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}
