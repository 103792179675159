import React from 'react';
import { useHistory, useLocation } from 'react-router';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useVorgangContext } from '../../contexts/vorgangContext';
import FolderIcon from '@mui/icons-material/Folder';
import PeopleIcon from '@mui/icons-material/People';
import WarningIcon from '@mui/icons-material/Warning';
import GestureIcon from '@mui/icons-material/Gesture';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ChatIcon from '@mui/icons-material/Chat';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import GavelIcon from '@mui/icons-material/Gavel';
import DescriptionIcon from '@mui/icons-material/Description';
import HistoryIcon from '@mui/icons-material/History';
import { COGNITO_GRUPPE_VERWALTUNG, Gutachtenart, Status } from '../../shared/constants';
import { Verwaltungscheckbox } from './Verwaltungscheckbox';
import { Aufgabenliste } from './Aufgabenliste';
import { useUser } from '../../hooks/useUser';
import { navigationItemClass } from './Navigation';
import ReceiptIcon from '@mui/icons-material/Receipt';

type Props = {
  onClick?: () => void;
};

export function VorgangNavigationListe({ onClick }: Props): JSX.Element | null {
  const { vorgang, speichereVorgang } = useVorgangContext();
  const { hatGruppeVonMandant } = useUser();

  const location = useLocation();
  const history = useHistory();

  const navigiereZu = (pathname: string) => {
    if (pathname !== location.pathname) {
      history.push(pathname);
    }
    if (onClick) {
      onClick();
    }
  };

  return vorgang.id ? (
    <>
      <ListItem>
        <ListItemIcon>
          <FolderIcon />
        </ListItemIcon>
        <ListItemText primary={vorgang.vorgangsnummer} />
      </ListItem>
      <List disablePadding>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/kunde`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/kunde`}
          className={navigationItemClass}
          data-testid="Kundendaten"
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Kundendaten" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/unfalldaten`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/unfalldaten`}
          disabled={vorgang.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG}
          className={navigationItemClass}
          data-testid="Unfalldaten"
        >
          <ListItemIcon>
            <WarningIcon />
          </ListItemIcon>
          <ListItemText primary="Unfalldaten" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/unterschriften`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/unterschriften`}
          className={navigationItemClass}
          data-testid="Unterschriften"
        >
          <ListItemIcon>
            <GestureIcon />
          </ListItemIcon>
          <ListItemText primary="Unterschriften" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/fahrzeug`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/fahrzeug`}
          className={navigationItemClass}
          data-testid="Fahrzeugdaten"
        >
          <ListItemIcon>
            <DriveEtaIcon />
          </ListItemIcon>
          <ListItemText primary="Fahrzeugdaten" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/fotos`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/fotos`}
          className={navigationItemClass}
          data-testid="Fotos"
        >
          <ListItemIcon>
            <PhotoLibraryIcon />
          </ListItemIcon>
          <ListItemText primary="Fotos" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/schadenbemerkungen`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/schadenbemerkungen`}
          className={navigationItemClass}
          disabled={vorgang.gutachtenart === Gutachtenart.FAHRZEUGBEWERTUNG}
          data-testid="Bemerkungen zum Schaden"
        >
          <ListItemIcon>
            <ChatIcon />
          </ListItemIcon>
          <ListItemText primary="Bemerkungen zum Schaden" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/bewertung`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/bewertung`}
          className={navigationItemClass}
          data-testid="Werte"
        >
          <ListItemIcon>
            <EqualizerIcon />
          </ListItemIcon>
          <ListItemText primary="Werte" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/gutachten`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/gutachten`}
          className={navigationItemClass}
          data-testid="Gutachten"
        >
          <ListItemIcon>
            <GavelIcon />
          </ListItemIcon>
          <ListItemText primary="Gutachten" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/dokumente`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/dokumente`}
          className={navigationItemClass}
          data-testid="Dokumente"
        >
          <ListItemIcon>
            <DescriptionIcon />
          </ListItemIcon>
          <ListItemText primary="Dokumente" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/rechnungen`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/rechnungen`}
          className={navigationItemClass}
          data-testid="Rechnungen"
        >
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="Rechnungen" />
        </ListItem>
        <ListItem
          button
          onClick={() => navigiereZu(`/vorgang/${vorgang.id}/protokoll`)}
          selected={location.pathname === `/vorgang/${vorgang.id}/protokoll`}
          className={navigationItemClass}
          data-testid="Protokoll"
        >
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary="Protokoll" />
        </ListItem>
      </List>
      {hatGruppeVonMandant(vorgang?.mandant || '', COGNITO_GRUPPE_VERWALTUNG) ? (
        <Verwaltungscheckbox vorgang={vorgang} speichereVorgang={speichereVorgang} />
      ) : null}
      {vorgang?.status === Status.GUTACHTENAUSARBEITUNG && <Aufgabenliste vorgang={vorgang} speichereVorgang={speichereVorgang} />}
    </>
  ) : null;
}
