import React from 'react';
import Grid from '@mui/material/Grid';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { Unternehmen } from '../types';
import { anredeAuswahl } from '../frontendConstants';

type Props = { readonly unternehmen: Unternehmen; readonly testPraefix: string };

export default function UnternehmenAnzeige({ unternehmen, testPraefix }: Props): JSX.Element {
  return (
    <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS} data-testid={`${testPraefix}-anzeige`}>
      <Grid container item xs={12} sm={6}>
        <Grid item xs={6}>
          Firmenname:
        </Grid>
        <Grid item xs={6}>
          {unternehmen?.firmenname ?? ''}
        </Grid>
        <Grid item xs={12}>
          {anredeAuswahl.find((anrede) => anrede.value === unternehmen?.anrede)?.label ?? ''}
        </Grid>

        <Grid item xs={12}>
          {unternehmen?.vorname ?? ''} {unternehmen?.nachname ?? ''}
        </Grid>
        <Grid item xs={12}>
          {unternehmen?.strasse ?? ''} {unternehmen?.plz ?? ''} {unternehmen?.ort ?? ''}
        </Grid>
      </Grid>
      <Grid item container xs={12} sm={6} spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          Tel.: {unternehmen?.telefon?.join(', ') ?? ''}
        </Grid>
        <Grid item xs={12}>
          E-Mail: {unternehmen?.email?.join(', ') ?? ''}
        </Grid>
      </Grid>
    </Grid>
  );
}
