import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { dialogActions } from './Dialog';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onDelete: () => void;
};

export function DokumentLoeschenBestaetigungsdialog({ open, onClose, onDelete }: Props): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="dokumentLoeschenBestaetigen">
      <DialogTitle style={{ cursor: 'move' }} id="dokumentLoeschenBestaetigen">
        Dokument löschen
      </DialogTitle>
      <DialogContent>
        <DialogContentText>Wollen Sie das Dokument wirklich löschen? Dieser Vorgang kann nicht rückgängig gemacht werden.</DialogContentText>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary">
          Abbrechen
        </Button>
        <Button onClick={onDelete} variant="contained" color="primary">
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
