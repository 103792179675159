import React from 'react';
import Box from '@mui/material/Box';
import { SPACING_BETWEEN_FORM_FIELDS } from './spacings';

type Props = {
  readonly children: React.ReactElement;
  readonly value: number;
  readonly index: number;
};

export default function TabPanel({ children, value, index, ...other }: Props): JSX.Element {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Box pt={SPACING_BETWEEN_FORM_FIELDS}>{children}</Box>}
    </div>
  );
}
