import { useState, useEffect } from 'react';
import { S3Client } from '../shared/s3Client';

export function useDateiUrl(key: string | undefined | null): string | null {
  const [url, setUrl] = useState<string | null>(null);

  useEffect(() => {
    let didCancel = false;
    async function loadUrl(key: string) {
      const storageUrl = await S3Client.get(key.replace(/^public\//, ''));
      if (!didCancel) {
        setUrl(storageUrl);
      }
    }
    if (key && !didCancel) {
      loadUrl(key);
    } else if (url !== null) {
      setUrl(null);
    }
    return () => {
      didCancel = true;
    };
  }, [key, url]);
  return url;
}
