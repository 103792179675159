import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import Accordion from '@mui/material/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SPACING_BETWEEN_FORM_FIELDS } from '../common/spacings';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from '../common/Alert';
import { makeGraphqlQuery } from '../../graphql/makeGraphqlQuery';
import * as mutations from '../../graphql/mutations';
import { Mandant } from '../../types';
import { EmailTextField } from '../common/EmailTextField';

type HisConfigProps = {
  readonly setLoading: (loading: boolean) => void;
  readonly isLoading: boolean;
  readonly mandant: Mandant;
};

export function HisConfig({ setLoading, isLoading, mandant }: HisConfigProps): JSX.Element {
  const [error, setError] = useState<boolean>(false);
  const [hisEmail, setHisEmail] = useState<string>(mandant.hisEmail ?? '');
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (mandant.id && mandant.hisEmail) {
      setHisEmail(mandant.hisEmail);
    }
  }, [mandant]);

  const checkEmail = (email: string): void => {
    const check = new RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ).test(email);
    setError(!check);
  };

  const speichereHisEmail = async (): Promise<void> => {
    try {
      setLoading(true);
      await makeGraphqlQuery(mutations.aktualisiereMandant, {
        mandantId: mandant.id,
        hisEmail: hisEmail,
        honorartabelle: mandant.honorartabelle
      });
      enqueueSnackbar('Die Daten wurden erfolgreich gespeichert.', SUCCESS_MESSAGE_AUTO_HIDE);
    } catch (error) {
      enqueueSnackbar('Die Daten konnten nicht gespeichert werden.', ERROR_MESSAGE);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Accordion data-testid="hisAccordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
        <Typography variant="h6" gutterBottom>
          HIS-Kontakt
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={6}>
            <EmailTextField
              fullWidth
              label="HIS Kontakt E-Mail"
              defaultValue={hisEmail ?? ''}
              data-testid="email"
              onChange={(event) => {
                checkEmail(event.target.value);
                setHisEmail(event.target.value);
              }}
              error={error}
            />
          </Grid>
          <Grid item>
            <Button onClick={speichereHisEmail} color="primary" variant="contained" disabled={isLoading || error} data-testid="submit">
              Speichern
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}
