import React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import { Dossier } from '../../../amplify/backend/function/data/lib/dat/services/dat.types';
import { Modal } from '../Modal';

type Props = {
  readonly open: boolean;
  readonly fahrzeugKandidaten: string[];
  readonly onClose: () => void;
  readonly onSelect: (fahrzeug: string) => void;
};

export function FahrzeugAuswahlDialog({ open, fahrzeugKandidaten, onClose, onSelect }: Props): JSX.Element {
  return (
    <Modal
      title="Fahrzeugauswahl"
      openModal={open}
      setOpenModal={() => onClose()}
      body={
        <List>
          {fahrzeugKandidaten.map((fahrzeug, index) => {
            return (
              <ListItemButton key={index} onClick={() => onSelect(fahrzeug)} sx={{ flexDirection: 'column', alignItems: 'start' }}>
                <VehicleInformation vehicleString={fahrzeug} />
              </ListItemButton>
            );
          })}
        </List>
      }
    />
  );
}

function VehicleInformation({ vehicleString }: { readonly vehicleString: string }): JSX.Element {
  const dossier = JSON.parse(vehicleString) as Dossier;
  const vehicle = dossier.Vehicle;

  return (
    <>
      <span>
        {vehicle?.ManufacturerName?.$value} {vehicle?.SalesDescription} {vehicle?.BaseModelName?.$value}
      </span>
      <small>{vehicle?.DATECodeEquipment?.EquipmentPosition.find((position) => position.Description.toLowerCase().includes('motor'))?.Description}</small>
    </>
  );
}
