import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { anredeAuswahl, DEFAULT_UNTERNEHMEN } from '../frontendConstants';
import MandantenAuswahl from './common/MandantenAuswahl';
import { EmailTextField } from './common/EmailTextField';
import { Unternehmen } from '../types';
import UnternehmentypAuswahl from './common/UnternehmentypAuswahl';
import { UnternehmenTyp } from '../shared/constants';
import { Modal } from './Modal';

type Props = {
  readonly unternehmenObjekt: Unternehmen | null | undefined;
  readonly typ: UnternehmenTyp;
  readonly aktualisiereUnternehmen: (unternehmen: Unternehmen) => void;
  readonly legeUnternehmenAn: (unternehmen: Unternehmen, vermittlerAnlage: boolean) => void;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly testPraefix: string;
  readonly isDisabled?: boolean;
  readonly setAusgewaehlterMandant?: (mandant: string) => void;
  readonly mandantenAuswahl?: boolean;
};

export default function UnternehmenVerwaltung({
  unternehmenObjekt,
  typ,
  aktualisiereUnternehmen,
  legeUnternehmenAn,
  open,
  onClose,
  testPraefix,
  isDisabled = false,
  setAusgewaehlterMandant = undefined,
  mandantenAuswahl = false
}: Props): JSX.Element {
  const [unternehmen, setUnternehmen] = useState<Unternehmen>(DEFAULT_UNTERNEHMEN);
  const [vermittlerAnlage, setVermittlerAnlage] = useState(false);

  useEffect(() => {
    if (unternehmenObjekt && open) {
      if (!unternehmenObjekt.id) {
        unternehmenObjekt.typ = typ;
      }
      setUnternehmen({ ...DEFAULT_UNTERNEHMEN, ...unternehmenObjekt });
    }
  }, [unternehmenObjekt, typ, open]);

  const aktualisiereUnternehmenAttribute = (attribute: string, wert: string) => {
    setUnternehmen({ ...unternehmen, [attribute]: wert });
  };

  const aktualisiereTelefonnummern = (index: number, wert: string) => {
    const nummern = [...(unternehmen.telefon ?? [])];
    nummern[index] = wert;
    setUnternehmen({ ...unternehmen, telefon: nummern });
  };

  const aktualisiereEmailadressen = (index: number, wert: string) => {
    const emailadressen = [...(unternehmen.email ?? [])];
    emailadressen[index] = wert;
    setUnternehmen({ ...unternehmen, email: emailadressen });
  };

  const emailHinzufuegen = () => {
    const vorhandeneEmailadressen = unternehmen?.email ? unternehmen.email : [];
    setUnternehmen({ ...unternehmen, email: [...vorhandeneEmailadressen, ''] });
  };

  const telefonnummerHinzufuegen = () => {
    const vorhandeneTelefonnr = unternehmen?.telefon ? unternehmen.telefon : [];
    setUnternehmen({ ...unternehmen, telefon: [...vorhandeneTelefonnr, ''] });
  };

  const loescheTelefonnummer = (index: number) => {
    const telefonnummer = [...(unternehmen?.telefon ?? [])];
    telefonnummer.splice(index, 1);
    setUnternehmen({ ...unternehmen, telefon: [...telefonnummer] });
  };

  const loescheEmail = (index: number) => {
    const emailadressen = [...(unternehmen?.email ?? [])];
    emailadressen.splice(index, 1);
    setUnternehmen({ ...unternehmen, email: [...emailadressen] });
  };

  const speichern = () => {
    if (unternehmen.id) {
      aktualisiereUnternehmen(unternehmen);
    } else {
      legeUnternehmenAn(unternehmen, vermittlerAnlage);
    }
  };

  const pflichtFelderAusgefuellt = () => {
    return unternehmen.typ === UnternehmenTyp.HAENDLER || unternehmen.typ === UnternehmenTyp.RECHTSANWALT
      ? unternehmen.email && unternehmen.email.length > 0 && unternehmen.email[0] !== ''
      : unternehmen?.firmenname && unternehmen.email && unternehmen?.firmenname !== '' && unternehmen.email?.length > 0 && unternehmen.email[0] !== '';
  };

  return (
    <Modal
      openModal={open}
      setOpenModal={onClose}
      ariaLabelledby="texterkennungModalUeberschrift"
      title="Unternehmenverwaltung"
      body={
        <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
          <Grid item xs={12} sm={12}>
            <FormControl variant="standard">
              <UnternehmentypAuswahl
                disabled={isDisabled}
                unternehmentyp={unternehmen?.typ ?? ''}
                setUnternehmentyp={(t) => aktualisiereUnternehmenAttribute('typ', t)}
                dataTestid={`${testPraefix}-unternehmenverwaltung-typ`}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl variant="standard" fullWidth>
              <InputLabel>Anrede</InputLabel>
              <Select
                variant="standard"
                disabled={isDisabled}
                value={unternehmen?.anrede ?? ''}
                onChange={(event) => aktualisiereUnternehmenAttribute('anrede', event.target.value)}
                data-testid={`${testPraefix}-unternehmenverwaltung-anrede`}
              >
                {anredeAuswahl.map((a) => (
                  <MenuItem value={a.value} key={a.value} data-testid={a.label}>
                    {a.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              disabled={isDisabled}
              label="Firma"
              defaultValue={unternehmen?.firmenname ?? ''}
              onChange={(event) => aktualisiereUnternehmenAttribute('firmenname', event.target.value)}
              fullWidth
              data-testid={`${testPraefix}-unternehmenverwaltung-firmenname`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              disabled={isDisabled}
              label="Vorname"
              defaultValue={unternehmen?.vorname ?? ''}
              onChange={(event) => aktualisiereUnternehmenAttribute('vorname', event.target.value)}
              fullWidth
              data-testid={`${testPraefix}-unternehmenverwaltung-vorname`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              disabled={isDisabled}
              label="Nachname"
              defaultValue={unternehmen?.nachname ?? ''}
              onChange={(event) => aktualisiereUnternehmenAttribute('nachname', event.target.value)}
              fullWidth
              data-testid={`${testPraefix}-unternehmenverwaltung-nachname`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              disabled={isDisabled}
              label="Straße"
              defaultValue={unternehmen?.strasse ?? ''}
              onChange={(event) => aktualisiereUnternehmenAttribute('strasse', event.target.value)}
              fullWidth
              data-testid={`${testPraefix}-unternehmenverwaltung-strasse`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              disabled={isDisabled}
              label="PLZ"
              defaultValue={unternehmen?.plz ?? ''}
              onChange={(event) => aktualisiereUnternehmenAttribute('plz', event.target.value)}
              fullWidth
              data-testid={`${testPraefix}-unternehmenverwaltung-plz`}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              variant="standard"
              disabled={isDisabled}
              label="Ort"
              defaultValue={unternehmen?.ort ?? ''}
              onChange={(event) => aktualisiereUnternehmenAttribute('ort', event.target.value)}
              fullWidth
              data-testid={`${testPraefix}-unternehmenverwaltung-ort`}
            />
          </Grid>
          <Grid item xs={12} sm={6} />
          <Grid item container xs={12} sm={6} spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <Typography>Telefonnummern</Typography>
            </Grid>
            {unternehmen?.telefon?.map((telefonnummer, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  variant="standard"
                  disabled={isDisabled}
                  defaultValue={telefonnummer}
                  onChange={(event) => aktualisiereTelefonnummern(index, event.target.value)}
                  fullWidth
                  data-testid={`${testPraefix}-unternehmenverwaltung-telefon-${index}`}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => loescheTelefonnummer(index)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    )
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <IconButton onClick={telefonnummerHinzufuegen} disabled={isDisabled} size="large">
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={6} spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={12}>
              <Typography>Email</Typography>
            </Grid>
            {unternehmen?.email?.map((email, index) => (
              <Grid item xs={12} key={index}>
                <EmailTextField
                  disabled={isDisabled}
                  defaultValue={email}
                  onChange={(event) => aktualisiereEmailadressen(index, event.target.value)}
                  fullWidth
                  data-testid={`${testPraefix}-unternehmenverwaltung-email-${index}`}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={() => loescheEmail(index)} size="large">
                        <DeleteIcon />
                      </IconButton>
                    )
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <IconButton onClick={emailHinzufuegen} disabled={isDisabled} size="large">
                <AddCircleOutlineOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
          {!unternehmen.id && unternehmen.typ !== UnternehmenTyp.VERSICHERUNG && (
            <>
              <Grid item xs={4}>
                <FormControlLabel
                  label="zusätzlich als Vermittler anlegen"
                  control={
                    <Checkbox
                      checked={vermittlerAnlage}
                      onChange={(event) => setVermittlerAnlage(event.target.checked)}
                      name="Vermittleranlange"
                      color="primary"
                    />
                  }
                />
              </Grid>
              {vermittlerAnlage && mandantenAuswahl && (
                <Grid item xs={8}>
                  <MandantenAuswahl
                    onSelectMandant={(mandant) => {
                      if (setAusgewaehlterMandant) {
                        setAusgewaehlterMandant(mandant);
                      }
                    }}
                  />
                </Grid>
              )}
            </>
          )}
        </Grid>
      }
      footer={
        <>
          <Grid item>
            <Button color="secondary" variant="contained" onClick={() => onClose()} data-testid={`${testPraefix}-unternehmenverwaltung-abbrechen`}>
              Abbrechen
            </Button>
          </Grid>
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              onClick={() => speichern()}
              disabled={!pflichtFelderAusgefuellt()}
              data-testid={`${testPraefix}-unternehmenverwaltung-speichern`}
            >
              Speichern
            </Button>
          </Grid>
        </>
      }
    ></Modal>
  );
}
