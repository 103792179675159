import { Datei, Foto, Vorgang } from '../types';
import { DOKUMENTTYPEN, FOTOTYPEN } from '../frontendConstants';
import ImageBlobReduce from 'image-blob-reduce';
import imageCompression from 'browser-image-compression';
import Pica from 'pica';
import { Dateityp } from '../shared/constants';
import { S3Client } from '../shared/s3Client';
import { uuid } from '../shared/uuid';

const compressionOptions = {
  maxSizeMB: 1, // (default: Number.POSITIVE_INFINITY)
  // maxWidthOrHeight: 2048, // compressedFile will scale down by ratio to a point that width or height is smaller than maxWidthOrHeight (default: undefined)
  useWebWorker: false, // optional, use multi-thread web worker, fallback to run in main-thread (default: true)
  maxIteration: 100 // optional, max number of iteration to compress the image (default: 10)
  // exifOrientation: number, // optional, see https://stackoverflow.com/a/32490603/10395024
  // onProgress: Function, // optional, a function takes one progress argument (percentage from 0 to 100)
  // fileType: string // optional, fileType override
};

type AngebotMitFile = {
  firmenname?: string;
  strasse?: string;
  plz?: string;
  ort?: string;
  telefon?: string;
  wert?: string;
  dokument?: File | null;
};

type DateiMitS3Info = {
  key: string;
  datei: File;
  createdAt: string;
};

export async function ladeDateienHoch(vorgang: Vorgang, files: File[]): Promise<Datei[]> {
  const dateienUndKeys = await _ladeDateienHoch(vorgang, files, Dateityp.DOKUMENT);
  return [
    ...(vorgang.dateien ?? []),
    ...dateienUndKeys.map(({ datei, key, createdAt }) => ({
      key,
      dateiname: datei.name,
      kategorien: [],
      uploaddatum: createdAt,
      typ: Dateityp.DOKUMENT
    }))
  ];
}

export async function ladeFotosHoch(vorgang: Vorgang, files: File[]): Promise<Foto[]> {
  const dateien = await _ladeFotosHoch(vorgang, files);
  return [...(vorgang.fotos ?? []), ...dateien];
}

export async function fuegeAngebotHinzu(vorgang: Vorgang, angebot: AngebotMitFile): Promise<Partial<Vorgang>> {
  const { dokument, ...zuErzeugendesAngebot } = angebot;

  const sortierteAngebote = [...(vorgang.bewertung?.angebote ?? []), angebot].sort(
    (left, right) => parseInt(right.wert ?? '0', 10) - parseInt(left.wert ?? '0', 10)
  );

  let dateienMitS3Info: DateiMitS3Info[] = [];
  if (dokument) {
    dateienMitS3Info = await _ladeDateienHoch(vorgang, [dokument], Dateityp.DOKUMENT);
  }

  return {
    dateien: [
      ...(vorgang.dateien ?? []),
      ...dateienMitS3Info.map(({ datei, key, createdAt }) => ({
        key,
        dateiname: datei.name,
        kategorien: ['Angebot'],
        uploaddatum: createdAt,
        typ: Dateityp.DOKUMENT,
        restwertangebot: true
      }))
    ],
    bewertung: {
      ...vorgang.bewertung,
      restwert: sortierteAngebote[0].wert,
      angebote: [
        ...(vorgang.bewertung?.angebote ?? []),
        {
          ...zuErzeugendesAngebot,
          key: dateienMitS3Info[0] ? dateienMitS3Info[0].key : ''
        }
      ]
    }
  };
}

async function _ladeDateienHoch(vorgang: Vorgang, dateien: File[], typ: Dateityp): Promise<DateiMitS3Info[]> {
  if (!dateien.every((datei) => typ === Dateityp.DOKUMENT && DOKUMENTTYPEN.includes(datei.type))) {
    throw new Error('Der Dateityp wird nicht unterstützt.');
  }
  const typVerzeichnisMap: Record<string, string> = {
    [Dateityp.DOKUMENT]: 'dokumente'
  };
  const dateienMitS3Info = [];

  for (const datei of dateien) {
    const keyName = eindeutigerDateiname(datei.name);
    const key = `${vorgang.id}/${typVerzeichnisMap[typ]}/${keyName}`;

    await S3Client.put(key, datei, datei.type);
    const createdAt = await S3Client.getLastModifiedISOString(key).catch(() => new Date().toISOString());

    dateienMitS3Info.push({
      datei: datei,
      key: `public/${key}`,
      createdAt
    });
  }

  return dateienMitS3Info;
}

async function _ladeFotosHoch(vorgang: Vorgang, fotos: File[]): Promise<Foto[]> {
  if (!fotos.every((foto) => FOTOTYPEN.includes(foto.type))) {
    throw new Error('Der Dateityp wird nicht unterstützt.');
  }
  const dateien = [];

  for (const foto of fotos) {
    const keyName = eindeutigerDateiname(foto.name);
    const key = `${vorgang.id}/fotos/${keyName}`;
    const thumbnailKey = `${vorgang.id}/fotos/thumbnail_${keyName}`;
    let compressedFile = null;
    const pica = Pica({ features: ['js', 'wasm', 'cib'] });
    if (foto.name.startsWith('bearbeitet_')) {
      compressedFile = foto;
    } else {
      // pica hier instanziiert, siehe: https://github.com/nodeca/image-blob-reduce/issues/17#issuecomment-751550135
      compressedFile = await imageCompression(
        await new ImageBlobReduce({ pica }).toBlob(foto, {
          max: 3840
        }),
        compressionOptions
      );
    }

    const compressedThumbnailFile = new File(
      [
        await imageCompression(
          await new ImageBlobReduce({ pica }).toBlob(foto, {
            max: 200
          }),
          compressionOptions
        )
      ],
      `thumbnail_${foto.name}`
    );

    await S3Client.put(key, compressedFile, foto.type);
    await S3Client.put(thumbnailKey, compressedThumbnailFile, foto.type);
    const createdAt = await S3Client.getLastModifiedISOString(key).catch(() => new Date().toISOString());

    dateien.push({
      key: key,
      dateiname: foto.name,
      kategorien: [],
      typ: Dateityp.FOTO,
      thumbnailname: 'thumbnail_' + foto.name,
      thumbnailkey: thumbnailKey,
      uploaddatum: createdAt
    });
  }

  return dateien;
}

function eindeutigerDateiname(fileName: string): string {
  const lastDotIndex = fileName.lastIndexOf('.');
  if (lastDotIndex !== -1) {
    return `${fileName.substring(0, lastDotIndex)}.${uuid()}${fileName.substring(lastDotIndex)}`;
  }
  return fileName + '-' + uuid();
}
