import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Formular from './common/Formular';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import { useUser } from '../hooks/useUser';
import { isAenderbar } from '../domain/isAenderbar';
import { YesNoFormControl } from './YesNoFormControl';
import { BemerkungenZumSchaden, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_SCHADENBEMERKUNG_ERFASSEN } from '../frontendConstants';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function Reparaturweg({ vorgang, isLoading, aktualisiereVorgang }: Props) {
  const { gruppenVonMandant } = useUser();

  const isDisabled =
    isLoading || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_SCHADENBEMERKUNG_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  const aktualisiereBemerkungenZumSchadenDaten = (key: keyof BemerkungenZumSchaden, wert: any) => {
    aktualisiereVorgang({ bemerkungenZumSchaden: { ...vorgang.bemerkungenZumSchaden, [key]: wert } });
  };
  const aktualisiereBemerkungenZumSchadenMehrfachAenderung = (aenderungen: any) => {
    aktualisiereVorgang({ bemerkungenZumSchaden: { ...vorgang.bemerkungenZumSchaden, ...aenderungen } });
  };

  const aktualisiereAchsvermessungDurchgefuehrt = (achsvermessungDurchgefuehrt: boolean) => {
    const aenderungen: Partial<BemerkungenZumSchaden> = {};
    aenderungen.achsvermessungDurchgefuehrt = achsvermessungDurchgefuehrt;
    if (achsvermessungDurchgefuehrt) {
      aenderungen.achsvermessungErforderlich = null;
    } else {
      aenderungen.achsvermessungMitSchadenfeststellung = null;
    }
    aktualisiereBemerkungenZumSchadenMehrfachAenderung(aenderungen);
  };

  return (
    <Formular ueberschrift="Details zum Schadenumfang">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Prüfarbeiten erforderlich"
            value={vorgang?.bemerkungenZumSchaden?.pruefarbeitenErforderlich}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('pruefarbeitenErforderlich', jaNein);
            }}
            dataTestid="pruefarbeitenErforderlich"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Achsvermessung durchgeführt"
            value={vorgang?.bemerkungenZumSchaden?.achsvermessungDurchgefuehrt}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereAchsvermessungDurchgefuehrt(jaNein);
            }}
            dataTestid="achsvermessungDurchgefuehrt"
            fullWidth
          />
        </Grid>
        {!vorgang?.bemerkungenZumSchaden?.achsvermessungDurchgefuehrt && (
          <Grid item xs={12} sm={6}>
            <YesNoFormControl
              labelText="Achsvermessung erforderlich"
              value={vorgang?.bemerkungenZumSchaden?.achsvermessungErforderlich}
              disabled={isDisabled}
              onChange={(jaNein) => {
                aktualisiereBemerkungenZumSchadenDaten('achsvermessungErforderlich', jaNein);
              }}
              dataTestid="achsvermessungErforderlich"
              fullWidth
            />
          </Grid>
        )}
        {vorgang?.bemerkungenZumSchaden?.achsvermessungDurchgefuehrt && (
          <Grid item xs={12} sm={6}>
            <YesNoFormControl
              labelText="Achsvermessung mit Schadenfeststellung"
              value={vorgang?.bemerkungenZumSchaden?.achsvermessungMitSchadenfeststellung}
              disabled={isDisabled}
              onChange={(jaNein) => {
                aktualisiereBemerkungenZumSchadenDaten('achsvermessungMitSchadenfeststellung', jaNein);
              }}
              dataTestid="achsvermessungMitSchadenfeststellung"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Lenkgetriebe erneuern"
            value={vorgang?.bemerkungenZumSchaden?.lenkgetriebeErneuern}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('lenkgetriebeErneuern', jaNein);
            }}
            dataTestid="lenkgetriebeErneuern"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Achsweise Erneuerung der Reifen"
            value={vorgang?.bemerkungenZumSchaden?.achsweiseErneuerungDerReifen}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('achsweiseErneuerungDerReifen', jaNein);
            }}
            dataTestid="achsweiseErneuerungDerReifen"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Achsweisen Erneuerung der Stoßdämpfer"
            value={vorgang?.bemerkungenZumSchaden?.achsweiseErneuerungDerStossdaempfer}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('achsweiseErneuerungDerStossdaempfer', jaNein);
            }}
            dataTestid="achsweiseErneuerungDerStossdaempfer"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Karosserievermessung erforderlich"
            value={vorgang?.bemerkungenZumSchaden?.karosserievermessungErforderlich}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('karosserievermessungErforderlich', jaNein);
            }}
            dataTestid="karosserievermessungErforderlich"
            fullWidth
          />
        </Grid>
        {!vorgang?.bemerkungenZumSchaden?.karosserievermessungErforderlich && (
          <Grid item xs={12} sm={6}>
            <YesNoFormControl
              labelText="Richtbank erforderlich"
              value={vorgang?.bemerkungenZumSchaden?.richtbankErforderlich}
              disabled={isDisabled}
              onChange={(jaNein) => {
                aktualisiereBemerkungenZumSchadenDaten('richtbankErforderlich', jaNein);
              }}
              dataTestid="richtbankErforderlich"
              fullWidth
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Beilackierung notwendig"
            value={vorgang?.bemerkungenZumSchaden?.beilackierungNotwendig}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('beilackierungNotwendig', jaNein);
            }}
            dataTestid="beilackierungNotwendig"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Anhängerkupplung aus Sicherheitsgründen erneuern"
            value={vorgang?.bemerkungenZumSchaden?.anhaengerkupplungAusSicherheitsgruendenErneuern}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('anhaengerkupplungAusSicherheitsgruendenErneuern', jaNein);
            }}
            dataTestid="anhaengerkupplungAusSicherheitsgruendenErneuern"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Fehlerspeicher auslesen"
            value={vorgang?.bemerkungenZumSchaden?.fehlerspeicherAuslesen}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('fehlerspeicherAuslesen', jaNein);
            }}
            dataTestid="fehlerspeicherAuslesen"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <YesNoFormControl
            labelText="Probefahrt erforderlich"
            value={vorgang?.bemerkungenZumSchaden?.probefahrtErforderlich}
            disabled={isDisabled}
            onChange={(jaNein) => {
              aktualisiereBemerkungenZumSchadenDaten('probefahrtErforderlich', jaNein);
            }}
            dataTestid="probefahrtErforderlich"
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            label="Bemerkungen zum Schadenumfang"
            multiline
            defaultValue={vorgang?.bemerkungenZumSchaden?.bemerkungen ?? ''}
            disabled={isDisabled}
            onChange={(event) => aktualisiereBemerkungenZumSchadenDaten('bemerkungen', event.target.value)}
            fullWidth
            inputProps={{
              'data-testid': 'BemerkungenZumSchadenumfang'
            }}
          />
        </Grid>
      </Grid>
    </Formular>
  );
}
