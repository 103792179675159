import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import Formular from './common/Formular';
import WarningSign from './common/WarningSign';
import { AKTION_UNFALLDATEN_ERFASSEN } from '../frontendConstants';
import UnternehmenVerwaltung from './UnternehmenVerwaltung';
import UnternehmenAnzeige from './UnternehmenAnzeige';
import { useUser } from '../hooks/useUser';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as mutations from '../graphql/mutations';
import { Unternehmen, Vorgang } from '../types';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import SearchPersonUnternehmen from './common/SearchPersonUnternehmen';
import { PersonenTyp, UnternehmenTyp } from '../shared/constants';
import { getMessageFromError } from '../shared/throw';
import { ERROR_MESSAGE } from './common/Alert';
import { useSnackbar } from 'notistack';
import { aktualisierePerson } from '../domain/aktualisierePerson';

type Props = {
  readonly vorgang: Vorgang;
  readonly speichereVorgang: (aenderungen?: Partial<Vorgang>) => void;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly setLoading: (value: boolean) => void;
};

export function Rechtsanwalt({ vorgang, setzeVorgang, speichereVorgang, setLoading }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();
  const [dialog, setDialog] = useState({ anlegen: false, aendern: false });

  const handleRechtsanwaltAusgewaehlt = (unternehmen: Unternehmen) => {
    speichereVorgang({ rechtsanwaltId: unternehmen.id });
  };

  const handleAktualisiereRechtsanwalt = async (unternehmen: Unternehmen) => {
    const aktualisiertesUnternehmen = await makeGraphqlQuery(mutations.updateUnternehmen, { input: unternehmen });
    setzeVorgang({ ...vorgang, rechtsanwalt: aktualisiertesUnternehmen });
    setDialog({ anlegen: false, aendern: false });
  };

  const handleLegeRechtsanwaltAn = async (unternehmen: Unternehmen, vermittlerAnlage: boolean) => {
    setLoading(true);
    const angelegtesUnternehmen = await makeGraphqlQuery(mutations.legeUnternehmenAn, { unternehmen: JSON.stringify(unternehmen) });

    speichereVorgang({ rechtsanwaltId: angelegtesUnternehmen.id });

    if (vermittlerAnlage) {
      //@ts-ignore
      aktualisierePerson({
        ...unternehmen,
        mandant: vorgang.mandant,
        weitereBearbeiter: [],
        typ: [PersonenTyp.VERMITTLER]
      })
        .then(() => setDialog({ anlegen: false, aendern: false }))
        .catch((error) => enqueueSnackbar(getMessageFromError(error) ?? 'Vermittler konnte nicht gespeichert werden', ERROR_MESSAGE))
        .finally(() => setLoading(false));
    } else {
      setDialog({ anlegen: false, aendern: false });
      setLoading(false);
    }
  };

  const handleRechtsanwaltAendernDialog = () => {
    setDialog((current) => ({ ...current, aendern: true }));
  };

  const handleRechtsanwaltAnlegenDialog = () => {
    setDialog((current) => ({ ...current, anlegen: true }));
  };

  const handleRechtsanwaltLoeschen = () => {
    speichereVorgang({ rechtsanwaltId: null });
  };

  const isDisabled = !vorgang || !aktionErlaubt(AKTION_UNFALLDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);

  return (
    <Formular ueberschrift="Rechtsanwalt">
      <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
        <Grid item xs={12}>
          <SearchPersonUnternehmen
            personenUnternehmenTyp={UnternehmenTyp.RECHTSANWALT}
            isDisabled={isDisabled}
            label="Rechtsanwalt suchen"
            onSelect={(unternehmen) => handleRechtsanwaltAusgewaehlt(unternehmen as Unternehmen)}
            testPraefix="rechtsanwalt"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton
            disabled={isDisabled || !vorgang?.rechtsanwaltId}
            onClick={() => handleRechtsanwaltAendernDialog()}
            data-testid="rechtsanwalt-bearbeiten"
            size="large"
          >
            <Tooltip title="Ändern">
              <EditIcon />
            </Tooltip>
          </IconButton>
          <IconButton disabled={isDisabled} onClick={() => handleRechtsanwaltAnlegenDialog()} data-testid="rechtsanwalt-anlegen" size="large">
            <Tooltip title="Anlegen">
              <AddBoxIcon />
            </Tooltip>
          </IconButton>
          <IconButton
            disabled={isDisabled || !vorgang?.rechtsanwaltId}
            onClick={() => handleRechtsanwaltLoeschen()}
            data-testid="rechtsanwalt-loeschen"
            size="large"
          >
            <Tooltip title="Zuorgnung löschen">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <WarningSign
            show={vorgang?.rechtsanwalt === null && vorgang?.rechtsanwaltId !== null}
            text="Der Rechtsanwalt wurde gelöscht oder Sie haben nicht die erforderlichen Rechte, diesen zu sehen."
          />
          {vorgang?.rechtsanwalt?.typ && <UnternehmenAnzeige unternehmen={vorgang?.rechtsanwalt} testPraefix="rechtsanwalt" />}
        </Grid>
      </Grid>
      {(dialog.anlegen || dialog.aendern) && (
        <UnternehmenVerwaltung
          unternehmenObjekt={dialog.aendern ? vorgang?.rechtsanwalt : ({} as Unternehmen)}
          typ={UnternehmenTyp.RECHTSANWALT}
          aktualisiereUnternehmen={handleAktualisiereRechtsanwalt}
          legeUnternehmenAn={handleLegeRechtsanwaltAn}
          open={dialog.anlegen || dialog.aendern}
          onClose={() => setDialog({ anlegen: false, aendern: false })}
          testPraefix="rechtsanwalt"
        />
      )}
    </Formular>
  );
}
