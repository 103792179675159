import React from 'react';
import { dialogActions } from './Dialog';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type Props = {
  readonly open: boolean;
  readonly onClose: () => void;
  readonly onDelete: () => void;
  readonly title: string;
  readonly text: string;
};

export default function Bestaetigungsdialog({ open, onClose, onDelete, title, text }: Props): JSX.Element {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions sx={dialogActions}>
        <Button autoFocus onClick={onClose} variant="contained" color="secondary" data-testid="cancel">
          Abbrechen
        </Button>
        <Button onClick={onDelete} variant="contained" color="primary" data-testid="confirm">
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  );
}
