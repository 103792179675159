import React, { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

type Props = {
  sx: object;
  alt: string;
  src: string;
  children: any;
};

export function ImageTooltip({ sx, alt = '', src, children }: Props): JSX.Element {
  const [onEnter, setOnEnter] = useState(false);
  const [loaded, setLoaded] = useState(false);

  return loaded ? (
    <Tooltip
      PopperProps={{
        sx: {
          '& .MuiTooltip-tooltip': {
            backgroundColor: 'rgba(97, 97, 97, 0.3)',
            maxWidth: 'none',
            padding: '0px 5px'
          }
        }
      }}
      enterDelay={100}
      enterTouchDelay={100}
      title={<Box component="img" sx={{ ...sx, paddingTop: '5px' }} src={src} alt={alt} />}
    >
      {children}
    </Tooltip>
  ) : (
    <>
      {onEnter && <img src={src} alt={alt} style={{ display: 'none' }} onLoad={() => setLoaded(true)} />}

      {<div onMouseEnter={() => setOnEnter(true)}>{children}</div>}
    </>
  );
}
