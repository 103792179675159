import { exchange } from './exchange';
import { ApiRequest } from '../types';

interface InterceptorFn {
  onBefore?: (request: ApiRequest) => void;
  onThen?: <T = any>(request: ApiRequest, response: T) => void;
  onError?: <T = any>(request: ApiRequest, error: any) => T;
  onFinally?: () => void;
}

export class Api {
  constructor(private interceptors: InterceptorFn[] = []) {}

  addInterceptor(interceptor: InterceptorFn) {
    this.interceptors.push(interceptor);
  }

  async request<T>({ context = {} as T, ...incomingRequest }: ApiRequest<T>): Promise<T> {
    const request = { ...incomingRequest, context };

    try {
      for (const fn of this.findFn('onBefore')) {
        fn && fn(request);
      }
      const response = await exchange<T>(request);
      for (const fn of this.findFn('onThen')) {
        fn && fn(request, response);
      }
      return response;
    } catch (error) {
      for (const fn of this.findFn('onError')) {
        const handled = fn && fn<T>(request, error);
        if (handled) {
          return handled;
        }
      }
      throw error;
    } finally {
      for (const fn of this.findFn('onFinally')) {
        fn && fn();
      }
    }
  }

  findFn<T extends keyof InterceptorFn>(fn: T) {
    return this.interceptors.map((interceptor) => interceptor[fn]);
  }
}
