import React from 'react';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import { Modal } from '../Modal';
import { Person } from '../../types';

type Props = {
  readonly personen: Person[];
  readonly onAuswahlClick: (person: Person) => void;
  readonly open: boolean;
  readonly onClose: () => void;
  readonly handleNeuAnlegen: () => void;
  readonly onBearbeitenClick: (person: Person) => void;
  readonly isNeuanlage: boolean;
};

export function PersonAuswahlDialog({ personen, onAuswahlClick, open, onClose, handleNeuAnlegen, onBearbeitenClick, isNeuanlage }: Props): JSX.Element {
  return (
    <Modal
      openModal={open}
      setOpenModal={() => onClose()}
      title="Personenauswahl"
      body={
        <>
          <span>Wählen Sie eine Person aus der Liste, bearbeiten Sie diese oder legen Sie eine neue Person an.</span>
          <List>
            {personen.map((person) => {
              return (
                <ListItem key={person.id} button>
                  <ListItemIcon onClick={() => onAuswahlClick(person)}>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText
                    onClick={() => onAuswahlClick(person)}
                    primary={`${person?.firmenname ?? ''} ${person?.vorname ?? ''} ${person?.nachname ?? ''},  ${person?.strasse ?? ''} ${person?.ort ?? ''} ${
                      person?.plz ?? ''
                    }`}
                  />
                  <IconButton onClick={() => onBearbeitenClick(person)} data-testid="fahrzeughalter-bearbeiten" size="large">
                    <Tooltip title="Bearbeiten">
                      <EditIcon />
                    </Tooltip>
                  </IconButton>
                </ListItem>
              );
            })}
          </List>
          <DialogActions>
            <Button onClick={onClose} variant="contained" color="secondary">
              Abbrechen
            </Button>
            <Button onClick={handleNeuAnlegen} variant="contained" color="primary">
              Speichern
            </Button>
          </DialogActions>
        </>
      }
    />
  );
}
