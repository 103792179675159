import { getReparaturkosten } from './getReparaturkosten';
import { getWiederbeschaffungswert } from './getWiederbeschaffungswert';
import { berechneFahrzeugalterInMonaten } from './berechneFahrzeugalterInMonaten';
import { Regel, ReparierteVorschadenEnum, Vorgang } from '../../types';
import { Zulassungsbescheinigung } from '../constants';

export function istTextbausteinregelErfuellt(regel: Regel, vorgang: Vorgang) {
  if (!vorgang) {
    return false;
  }

  if (regel === Regel.IMMER) {
    return true;
  } else if (regel === Regel.NACHBESICHTIGUNG) {
    return vorgang.besichtigungen?.items?.length > 1;
  } else if (regel === Regel.REPARATURKOSTEN_GROESSER_WIEDERBESCHAFFUNGSWERT) {
    return getReparaturkosten(vorgang) > getWiederbeschaffungswert(vorgang);
  } else if (regel === Regel.REPARATURKOSTEN_KLEINER_WIEDERBESCHAFFUNGSWERT) {
    return getReparaturkosten(vorgang) <= getWiederbeschaffungswert(vorgang);
  } else if (regel === Regel.REPARATURWUERDIG) {
    return getReparaturkosten(vorgang) / getWiederbeschaffungswert(vorgang) <= 1;
  } else if (regel === Regel.REPARATURGRAENZE) {
    const v = getReparaturkosten(vorgang) / getWiederbeschaffungswert(vorgang);
    return v > 1.0 && v <= 1.3;
  } else if (regel === Regel.AELTER_ALS_3_JAHRE_UND_SCHECKHEFT) {
    return berechneFahrzeugalterInMonaten(vorgang) > 36 && vorgang.fahrzeugdaten.scheckheftgepflegt && !vorgang.werkstatt;
  } else if (regel === Regel.REPARATURWERKSTATT_ANGEGEBEN) {
    return Boolean(vorgang.werkstatt);
  } else if (regel === Regel.AELTER_ALS_3_JAHRE_UND_NICHT_SCHECKHEFT) {
    return berechneFahrzeugalterInMonaten(vorgang) > 36 && !vorgang.fahrzeugdaten?.scheckheftgepflegt && !vorgang.werkstatt;
  } else if (regel === Regel.FAHRZEUG_JUENGER_ALS_3_JAHRE) {
    return berechneFahrzeugalterInMonaten(vorgang) <= 36 && !vorgang.werkstatt;
  } else if (regel === Regel.REPARATURKOSTEN_KLEINER_50PROZENT_WIEDERBESCHAFFUNGSWERT) {
    return (
      getReparaturkosten(vorgang) <= getWiederbeschaffungswert(vorgang) / 2 ||
      (getReparaturkosten(vorgang) > getWiederbeschaffungswert(vorgang) / 2 &&
        (!vorgang?.bewertung?.restwert || isNaN(Number.parseFloat(vorgang.bewertung.restwert)) || Number.parseInt(vorgang.bewertung.restwert) <= 0))
    );
  } else if (regel === Regel.REPARATURKOSTEN_GROESSER_50PROZENT_WIEDERBESCHAFFUNGSWERT) {
    return getReparaturkosten(vorgang) > getWiederbeschaffungswert(vorgang) / 2 && Number.parseInt(vorgang?.bewertung?.restwert ?? '0') > 0;
  } else if (regel === Regel.FAHRZEUGALTER_GROESSER_120_MONATE) {
    return berechneFahrzeugalterInMonaten(vorgang) > 120 && !vorgang.fahrzeugdaten?.regelbesteuert;
  } else if (regel === Regel.FAHRZEUGALTER_KLEINER_120_MONATE) {
    return berechneFahrzeugalterInMonaten(vorgang) <= 120 && !vorgang.fahrzeugdaten?.regelbesteuert;
  } else if (regel === Regel.MARKIERUNG_FAHRZEUGGRAFIK_IN_PROEXPERT) {
    return (vorgang?.fahrzeugdaten?.reparierteSchadenbereiche ?? []).length > 0 || false;
  } else if (regel === Regel.MERKANTILER_MINDERWERT_VORHANDEN) {
    return Number.parseFloat(vorgang.bewertung?.merkantilerMinderwert ?? '0') >= 100;
  } else if (regel === Regel.MERKANTILER_MINDERWERT_KLEINER_100_EURO) {
    return Number.parseFloat(vorgang.bewertung?.merkantilerMinderwert ?? '0') < 100;
  } else if (regel === Regel.RICHTBANK_ERFORDERLICH) {
    return Boolean(vorgang.bemerkungenZumSchaden?.richtbankErforderlich);
  } else if (regel === Regel.VERMESSUNG_VORDERACHSE) {
    return Boolean(vorgang.bemerkungenZumSchaden?.achsvermessungErforderlich) && !vorgang.bemerkungenZumSchaden?.achsvermessungMitSchadenfeststellung;
  } else if (regel === Regel.PRUEFARBEITEN_ERFORDERLICH) {
    return Boolean(vorgang.bemerkungenZumSchaden?.pruefarbeitenErforderlich);
  } else if (regel === Regel.FAHRBEREIT) {
    return Boolean(vorgang.bemerkungenZumSchaden?.fahrbereit);
  } else if (regel === Regel.NICHT_FAHRBEREIT) {
    return (
      !vorgang.bemerkungenZumSchaden?.fahrbereit &&
      !vorgang.bemerkungenZumSchaden?.verkehrssicher &&
      !vorgang.bemerkungenZumSchaden?.verkehrssicherNachNotreparatur
    );
  } else if (regel === Regel.VERKEHRSSICHERHEIT) {
    return Boolean(vorgang.bemerkungenZumSchaden?.verkehrssicher);
  } else if (regel === Regel.VERKEHRSSICHER_NACH_NOTREPARATUR) {
    return Boolean(vorgang.bemerkungenZumSchaden?.verkehrssicherNachNotreparatur);
  } else if (regel === Regel.ZULASSUNGSBESCHEINIGUNG_TEIL_1) {
    return Boolean(vorgang.fahrzeugdaten?.zulassungsbescheinigung === Zulassungsbescheinigung.ZULASSUNGSBESCHEINIGUNG_I);
  } else if (regel === Regel.ZULASSUNGSBESCHEINIGUNG_TEIL_2) {
    return Boolean(vorgang.fahrzeugdaten?.zulassungsbescheinigung === Zulassungsbescheinigung.ZULASSUNGSBESCHEINIGUNG_II);
  } else if (regel === Regel.DURCHGEFUEHRTE_ACHSVERMESSUNG_MIT_SCHADENSFESTSTELLUNG) {
    return Boolean(vorgang.bemerkungenZumSchaden?.achsvermessungMitSchadenfeststellung);
  } else if (regel === Regel.DURCHGEFUEHRTE_ACHSVERMESSUNG_OHNE_SCHADENSFESTSTELLUNG) {
    return Boolean(vorgang.bemerkungenZumSchaden?.achsvermessungDurchgefuehrt) && !vorgang.bemerkungenZumSchaden?.achsvermessungMitSchadenfeststellung;
  } else if (regel === Regel.BEILACKIERUNG_ERFORDERLICH) {
    return Boolean(vorgang.bemerkungenZumSchaden?.beilackierungNotwendig);
  } else if (regel === Regel.ACHSWEISE_ERNEUERUNG_DER_REIFEN) {
    return Boolean(vorgang.bemerkungenZumSchaden?.achsweiseErneuerungDerReifen);
  } else if (regel === Regel.ACHSWEISE_ERNEUERUNG_DER_STOSSDAEMPFER) {
    return Boolean(vorgang.bemerkungenZumSchaden?.achsweiseErneuerungDerStossdaempfer);
  } else if (regel === Regel.LENKGETRIEBE_ERNEUERN) {
    return Boolean(vorgang.bemerkungenZumSchaden?.lenkgetriebeErneuern);
  } else if (regel === Regel.FAHRZEUGZUSTAND) {
    return false;
  } else if (regel === Regel.WERTVERBESSERUNG) {
    return Number.parseFloat(vorgang.bewertung?.wertverbesserung ?? '0') > 0;
  } else if (regel === Regel.LACKSCHICHTENDICKENMESSUNG) {
    return Boolean(
      vorgang.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden?.find((vorschaden) => {
        return vorschaden?.lackschichtendickenmessung && vorschaden?.lackschichtendickenmessung?.trim().length > 0;
      }) !== undefined ||
        vorgang.fahrzeugdaten?.reparierteVorschaeden?.schadenVorhandenAuswahl ===
          ReparierteVorschadenEnum.LACKSCHICHTENDICKENMESSUNG_DURCHGEFUEHRT_UND_KEINE_REPARIERTEN_VORSCHAEDEN
    );
  } else if (regel === Regel.KAROSSERIEVERMESSUNG) {
    return Boolean(vorgang.bemerkungenZumSchaden?.karosserievermessungErforderlich);
  } else if (regel === Regel.ANHAENGERKUPPLUNG) {
    return Boolean(vorgang.bemerkungenZumSchaden?.anhaengerkupplungAusSicherheitsgruendenErneuern);
  } else if (regel === Regel.REPARATURKOSTEN_GROESSER_200_PROZENT_WIEDERBESCHAFFUNGSWERT_KEIN_RESTWERT) {
    return (
      Boolean(
        getReparaturkosten(vorgang) > getWiederbeschaffungswert(vorgang) * 2 &&
          (vorgang.bewertung?.restwert ? parseFloat(vorgang.bewertung.restwert) === 0 : true)
      ) || Boolean(vorgang.entsorgen)
    );
  } else if (regel === Regel.MIETWAGENKLASSE_VORHANDEN) {
    return Boolean(vorgang.fahrzeugdaten?.mietwagenklasse && vorgang.fahrzeugdaten?.mietwagenklasse !== '');
  } else if (regel === Regel.NUTZUNGSAUSFALLKLASSE_VORHANDEN) {
    return Boolean(vorgang.fahrzeugdaten?.nutzungsausfallKlasseOhneFahrzeugAlter && vorgang.fahrzeugdaten?.nutzungsausfallKlasseOhneFahrzeugAlter !== '');
  } else if (regel === Regel.PROBEFAHRT_ERFORDERLICH) {
    return Boolean(vorgang.bemerkungenZumSchaden?.probefahrtErforderlich);
  } else if (regel === Regel.FEHLERSPEICHER_AUSLESEN) {
    return Boolean(vorgang.bemerkungenZumSchaden?.fehlerspeicherAuslesen);
  } else if (regel === Regel.ALTSCHAEDEN_ODER_VORSCHAEDEN_VORHANDEN) {
    return Boolean(
      (vorgang.fahrzeugdaten?.reparierteVorschaeden?.vorschaeden ?? []).length > 0 || (vorgang.fahrzeugdaten?.altschaden?.schaeden ?? []).length > 0
    );
  } else if (regel === Regel.KORREKTUR_DURCH_VORSCHAEDEN_GROESSER_0) {
    return Number.parseFloat(vorgang.bewertung?.korrekturWiederbeschaffungswert ?? '0') > 0;
  } else if (regel === Regel.REGELBESTEUERUNG_JA) {
    return Boolean(vorgang.fahrzeugdaten?.regelbesteuert);
  }

  return false;
}
