import { Api } from './Api';
import { getItem, setItem } from '../shared/storage';
import { environment } from '../env';
import { signOut, getAccessToken } from '../shared/Auth';
import { ApiRequest } from '../types';

export const api = new Api();

const accessTokenInterceptor = {
  onBefore: (request: ApiRequest) => {
    request.url = request.url.startsWith('http') ? request.url : environment.apiGateway + request.url;
    request.headers = request.headers ?? {};
    request.headers['Authorization'] = getItem('accessToken') ?? '';
  }
};

const accessTokenExpiredInterceptor = {
  onError: (request: ApiRequest<{ tokenRefreshed: boolean }>, error: { status: number }): any => {
    if (error.status === 401 && !request.context?.tokenRefreshed) {
      return getAccessToken().then((token) => {
        setItem('accessToken', token);
        return api.request({ ...request, context: { tokenRefreshed: true } });
      });
    } else if (error.status === 401 && request.context?.tokenRefreshed) {
      signOut();
    }
  }
};

const forbiddenAccessInterceptor = {
  onError: (_: ApiRequest, error: { status: number }): any => {
    if (error.status === 403) {
      throw new Error('Zugriff nicht erlaubt');
    }
  }
};

api.addInterceptor(accessTokenInterceptor);
api.addInterceptor(forbiddenAccessInterceptor);
api.addInterceptor(accessTokenExpiredInterceptor);
