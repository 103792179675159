import React, { useCallback, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import InputAdornment from '@mui/material/InputAdornment';
import { useHistory, useLocation } from 'react-router';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { SPACING_BETWEEN_FORM_FIELDS } from './common/spacings';
import Formular from './common/Formular';
import { ERROR_MESSAGE, SUCCESS_MESSAGE_AUTO_HIDE } from './common/Alert';
import { useDateiUrl } from '../hooks/useDateiUrl';
import WarningSign from './common/WarningSign';
import { EuroFormat, FloatFormat } from './common/inputFormats';
import { useUser } from '../hooks/useUser';
import { generiereToken } from '../domain/generiereToken';
import { sindFahrzeugdatenVollstaendig } from '../domain/sindFahrzeugdatenVollstaendig';
import { berechneReparaturdauer } from '../shared/frontend/berechneReparaturdauer';
import { isAenderbar } from '../domain/isAenderbar';
import { pruefeReparaturkostenAbweichung } from '../domain/pruefeReparaturkostenAbweichung';
import { Bewertung, Datei, DatResponse, Vorgang } from '../types';
import { useSnackbar } from 'notistack';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_BEWERTUNG_ERFASSEN } from '../frontendConstants';
import { Gutachtenart } from '../shared/constants';
import { berechneFahrzeugalterInMonaten } from '../shared/frontend/berechneFahrzeugalterInMonaten';
import { textfieldInlineButton } from '../assets/sharedStyles';
import { getMessageFromError } from '../shared/throw';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import * as mutations from '../graphql/mutations';

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly setLoading: (value: boolean) => void;
  readonly setzeVorgang: (vorgang: Vorgang) => void;
  readonly speichereVorgang: () => Promise<void>;
  readonly hatAenderungen: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function Bewertungsdaten({ vorgang, isLoading, setLoading, setzeVorgang, speichereVorgang, hatAenderungen, aktualisiereVorgang }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { gruppenVonMandant } = useUser();
  const location = useLocation();

  const calculationDocumentUrl = useDateiUrl(vorgang?.dat?.calculationDocument?.key);
  const webScanUrl = useDateiUrl(vorgang?.dateien?.find((datei: Datei) => (datei?.kategorien ?? []).includes('WebScan'))?.key);
  const vehicleEvaluationDocumentUrl = useDateiUrl(vorgang?.dat?.vehicleEvaluationDocument?.key);
  const [datBewertungClicked, setDatBewertungClicked] = useState(false);
  const [datKalkulationClicked, setDatKalkulationClicked] = useState(false);

  const syncWithDat = useCallback(async () => {
    setLoading(true);

    const datAntwort = await makeGraphqlQuery<DatResponse<Vorgang>>(mutations.synchronisiereMitDat, {
      vorgangId: vorgang.id
    });

    if (!datAntwort.ok) {
      throw new Error(datAntwort.error);
    }

    return datAntwort;
  }, [setLoading, vorgang.id]);

  useEffect(() => {
    if (datKalkulationClicked && !hatAenderungen) {
      syncWithDat()
        .then(() => generiereToken(vorgang.mandant))
        .then((tokens) => {
          window.location.href = `/dat-calculateExpert-bewertung.html?contractId=${vorgang?.dat?.contractId}&token=${tokens.calculateExpertToken}&redirectUrl=${window.location.href}?readyDatKalkulation`;
        })
        .catch((error) => {
          console.error({ error });
          enqueueSnackbar(error.message, ERROR_MESSAGE);
        })
        .finally(() => {
          setLoading(false);
          setDatKalkulationClicked(false);
        });
    }
  }, [datKalkulationClicked, enqueueSnackbar, setLoading, syncWithDat, vorgang, hatAenderungen]);

  useEffect(() => {
    if (datBewertungClicked && !hatAenderungen) {
      syncWithDat()
        .then(() => generiereToken(vorgang.mandant))
        .then((tokens) => {
          window.location.href = `/dat-valueExpert-bewertung.html?dossierId=${vorgang.dat?.dossierId ?? ''}&token=${tokens.valuateExpertToken}&redirectUrl=${
            window.location.href
          }?readyDatBewertung`;
        })
        .catch((error) => {
          console.error({ error });
          enqueueSnackbar(error.message, ERROR_MESSAGE);
        })
        .finally(() => {
          setLoading(false);
          setDatBewertungClicked(false);
        });
    }
  }, [datBewertungClicked, hatAenderungen, setLoading, syncWithDat, vorgang, enqueueSnackbar]);

  useEffect(() => {
    if (vorgang?.id && (location.search.includes('readyDatBewertung') || location.search.includes('readyDatKalkulation')) && !isLoading) {
      syncWithDat()
        .then((datAntwort) => {
          if (datAntwort.ok) {
            if (datAntwort.data) {
              enqueueSnackbar('Der Vorgang wurde erfolgreich mit DAT synchronisiert.', SUCCESS_MESSAGE_AUTO_HIDE);
              history.replace(history.location.pathname);
              setzeVorgang(datAntwort.data);
            } else {
              enqueueSnackbar('Vorgang in datAntwort nicht gefunden', ERROR_MESSAGE);
            }
          } else {
            enqueueSnackbar(datAntwort.error, ERROR_MESSAGE);
          }
        })
        .catch((error) => {
          console.error({ error });
          enqueueSnackbar(getMessageFromError(error), ERROR_MESSAGE);
        })
        .finally(() => setLoading(false));
    }
  }, [location, vorgang, isLoading, syncWithDat, enqueueSnackbar, history, setzeVorgang, setLoading]);

  const isDisabled = !vorgang || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_BEWERTUNG_ERFASSEN, gruppenVonMandant(vorgang?.mandant || ''), vorgang?.status);

  const handleDatBewertungClick = async () => {
    await speichereVorgang();
    setDatBewertungClicked(true);
  };

  const handleDatKalkulationClick = async () => {
    await speichereVorgang();
    setDatKalkulationClicked(true);
  };

  const handleShowDatKalkulationClick = () => {
    if (calculationDocumentUrl) {
      window.open(calculationDocumentUrl, '_blank');
    }
  };

  const handleShowDatBewertungClick = () => {
    if (vehicleEvaluationDocumentUrl) {
      window.open(vehicleEvaluationDocumentUrl, '_blank');
    }
  };

  const handleShowWebScanClick = () => {
    if (webScanUrl) {
      window.open(webScanUrl, '_blank');
    }
  };

  const aktualisiereBewertung = (key: keyof Bewertung, wert: string | number | null | undefined) => {
    aktualisiereVorgang({
      bewertung: { ...vorgang.bewertung, [key]: wert }
    });
  };

  return (
    <>
      <WarningSign
        show={parseFloat(vorgang?.bewertung?.reparaturkosten ?? '0') > parseFloat(vorgang?.bewertung?.wiederbeschaffungswert ?? '0')}
        text="Da die Reparaturkosten über dem Wiederbeschaffungswert
  liegen, ist ein wirtschaftlicher Totalschaden eingetreten."
      />
      {vorgang?.gutachtenart !== Gutachtenart.FAHRZEUGBEWERTUNG ? (
        <Formular ueberschrift="Bewertung">
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Wiederbeschaffungswert"
                value={vorgang.bewertung?.wiederbeschaffungswert ?? '0'}
                onChange={(event) => {
                  aktualisiereBewertung('wiederbeschaffungswert', event.target.value);
                }}
                fullWidth
                data-testid="wiederbeschaffungswert"
                disabled={isDisabled || vorgang?.gutachtenart === Gutachtenart.KURZGUTACHTEN}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.wiederbeschaffungswertBrutto ?? '0'}
                disabled={true}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  startAdornment: (
                    <Tooltip title="Wiederbeschaffungswert aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereBewertung('wiederbeschaffungswert', vorgang?.dat?.wiederbeschaffungswertBrutto);
                          }}
                          disabled={!vorgang?.dat?.wiederbeschaffungswertBrutto || isDisabled || vorgang?.gutachtenart === Gutachtenart.KURZGUTACHTEN}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATWiederbeschaffungswert"
              />
            </Grid>

            <Grid item xs={12} lg={3}>
              <Button
                fullWidth
                disabled={
                  !vorgang?.dat?.contractId ||
                  isDisabled ||
                  isLoading ||
                  !sindFahrzeugdatenVollstaendig(vorgang) ||
                  Boolean(vorgang?.fahrzeugdaten?.phantomkalkulation)
                }
                color="primary"
                variant="contained"
                startIcon={<DriveEtaIcon />}
                onClick={handleDatBewertungClick}
              >
                DAT-Bewertung
              </Button>
            </Grid>
            <Grid item xs={12} lg={1}>
              <Tooltip title="WebScan Dokument">
                <div>
                  <Button
                    fullWidth
                    disabled={(vorgang?.dateien ?? []).filter((datei: Datei) => (datei?.kategorien ?? []).includes('WebScan')).length === 0}
                    color="primary"
                    variant="contained"
                    onClick={handleShowWebScanClick}
                  >
                    <Hidden lgDown>PDF</Hidden>
                    <Hidden lgUp>WebScan PDF</Hidden>
                  </Button>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Korrektur durch Vorschäden"
                value={vorgang.bewertung?.korrekturWiederbeschaffungswert ?? '0'}
                onChange={(event) => {
                  aktualisiereBewertung('korrekturWiederbeschaffungswert', event.target.value);
                }}
                fullWidth
                data-testid="korrekturWiederbeschaffungswert"
                disabled={isDisabled}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6} lg={8} />
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Reparaturkosten brutto"
                value={vorgang.bewertung?.reparaturkosten ?? '0'}
                onChange={(event) => {
                  aktualisiereBewertung('reparaturkosten', event.target.value);
                }}
                fullWidth
                data-testid="reparaturkosten"
                disabled={isDisabled}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                fullWidth
                label="DAT"
                value={vorgang?.dat?.reparaturkostenBrutto ?? '0'}
                disabled={true}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  startAdornment: (
                    <Tooltip title="Reparaturkosten aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereBewertung('reparaturkosten', vorgang?.dat?.reparaturkostenBrutto);
                          }}
                          disabled={!vorgang?.dat?.reparaturkostenBrutto || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATReparaturkosten"
              />
            </Grid>
            <Grid item xs={6} lg={1}>
              <Button
                fullWidth
                disabled={!vorgang?.dat?.contractId || isLoading || !sindFahrzeugdatenVollstaendig(vorgang) || isDisabled || !calculationDocumentUrl}
                color="primary"
                variant="contained"
                startIcon={<DriveEtaIcon />}
                onClick={handleShowDatKalkulationClick}
              >
                PDF
              </Button>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                disabled={!vorgang?.fahrzeugdaten?.fahrgestellnummer || !vorgang?.dat?.contractId || isLoading || isDisabled}
                color="primary"
                variant="contained"
                startIcon={<DriveEtaIcon />}
                onClick={handleDatKalkulationClick}
              >
                <Hidden lgDown>DAT-Kalkulation</Hidden>
                <Hidden lgUp>Kalkulation</Hidden>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <WarningSign show={pruefeReparaturkostenAbweichung(vorgang)} text="Reparaturkosten weichen ab." />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Wertverbesserung"
                value={vorgang.bewertung?.wertverbesserung ?? '0'}
                disabled={isDisabled}
                onChange={(event) => {
                  aktualisiereBewertung('wertverbesserung', event.target.value);
                }}
                fullWidth
                data-testid="wertverbesserung"
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="DAT"
                fullWidth
                value={vorgang?.dat?.wertverbesserung ?? '0'}
                disabled={true}
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>,
                  startAdornment: (
                    <Tooltip title="Wertverbesserung aus DAT übernehmen">
                      <div>
                        <IconButton
                          sx={textfieldInlineButton}
                          color="primary"
                          onClick={() => {
                            aktualisiereBewertung('wertverbesserung', vorgang?.dat?.wertverbesserung);
                          }}
                          disabled={!vorgang?.dat?.wertverbesserung || isDisabled}
                          size="large"
                        >
                          <ArrowBackIcon />
                        </IconButton>
                      </div>
                    </Tooltip>
                  )
                }}
                data-testid="DATWertverbesserung"
              />
            </Grid>
            <Hidden lgDown>
              <Grid item lg={4} />
            </Hidden>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Merkantiler Minderwert"
                disabled={isDisabled || vorgang?.gutachtenart === Gutachtenart.KURZGUTACHTEN}
                value={vorgang.bewertung?.merkantilerMinderwert ?? '0'}
                onChange={(event) => {
                  aktualisiereBewertung('merkantilerMinderwert', event.target.value);
                }}
                fullWidth
                data-testid="merkantilerMinderwert"
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
              <WarningSign
                text="Bitte prüfen Sie den merkantilen Minderwert, da das Fahrzeug jünger als 10 Jahre ist."
                show={parseFloat(vorgang.bewertung?.merkantilerMinderwert ?? '0') <= 0 && berechneFahrzeugalterInMonaten(vorgang) <= 120}
              ></WarningSign>
            </Grid>
            <Hidden lgDown>
              <Grid item lg={4} />
              <Grid item lg={4} />
            </Hidden>

            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Restwert"
                value={vorgang.bewertung?.restwert ?? '0'}
                disabled
                onChange={(event) => {
                  aktualisiereBewertung('restwert', event.target.value);
                }}
                fullWidth
                data-testid="restwert"
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Hidden lgDown>
              <Grid item lg={4} />
              <Grid item lg={4} />
            </Hidden>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Wiederbeschaffungsdauer"
                value={vorgang.bewertung?.wiederbeschaffungsdauer ?? '0'}
                disabled={isDisabled || vorgang?.gutachtenart === Gutachtenart.KURZGUTACHTEN}
                onChange={(event) => {
                  aktualisiereBewertung('wiederbeschaffungsdauer', event.target.value);
                }}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">Arbeitstage</InputAdornment>
                }}
                data-testid="wiederbeschaffungsdauer"
              />
            </Grid>
            <Grid item xs={3} lg={4} />
            <Grid item xs={3} lg={4} />

            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Reparaturdauer"
                value={vorgang.bewertung?.reparaturdauer ?? ''}
                disabled={isDisabled}
                onChange={(event) => {
                  aktualisiereBewertung('reparaturdauer', event.target.value);
                }}
                fullWidth
                InputProps={{
                  endAdornment: <InputAdornment position="end">Kalendertage</InputAdornment>
                }}
                data-testid="reparaturdauer"
              />
            </Grid>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                label="Berechnung Reparaturdauer"
                fullWidth
                value={berechneReparaturdauer(vorgang) ?? '0'}
                disabled={true}
                InputProps={{
                  inputComponent: FloatFormat,
                  startAdornment: (
                    <Tooltip title="Reparaturdauer aus Berechnung übernehmen">
                      <IconButton
                        sx={textfieldInlineButton}
                        color="primary"
                        onClick={() => {
                          const reparaturdauer = berechneReparaturdauer(vorgang);
                          aktualisiereBewertung('reparaturdauer', reparaturdauer);
                        }}
                        disabled={berechneReparaturdauer(vorgang) === 0 || isDisabled}
                      >
                        <ArrowBackIcon />
                      </IconButton>
                    </Tooltip>
                  ),
                  endAdornment: <InputAdornment position="end">Kalendertage</InputAdornment>
                }}
                data-testid="BerechnungReparaturdauer"
              />
            </Grid>
            <Grid item xs={4} />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="Bemerkungen (Deckblatt)"
                multiline
                value={vorgang.bewertung?.bemerkung ?? ''}
                disabled={isDisabled}
                onChange={(event) => {
                  aktualisiereBewertung('bemerkung', event.target.value);
                }}
                fullWidth
                inputProps={{
                  'data-testid': 'Bemerkung'
                }}
              />
            </Grid>
          </Grid>
        </Formular>
      ) : (
        <Formular ueberschrift="Bewertung">
          <Grid container spacing={SPACING_BETWEEN_FORM_FIELDS}>
            <Grid item xs={6} lg={4}>
              <TextField
                variant="standard"
                fullWidth
                label="Fahrzeugwert"
                value={vorgang?.dat?.wiederbeschaffungswertBrutto ?? '0'}
                disabled={true}
                data-testid="DATWiederbeschaffungswert"
                InputProps={{
                  inputComponent: EuroFormat,
                  endAdornment: <InputAdornment position="end">€</InputAdornment>
                }}
              />
            </Grid>
            <Grid item xs={6} lg={4} />

            <Grid item xs={12} lg={3}>
              <Button
                fullWidth
                disabled={
                  !vorgang?.dat?.contractId ||
                  isDisabled ||
                  isLoading ||
                  !sindFahrzeugdatenVollstaendig(vorgang) ||
                  Boolean(vorgang?.fahrzeugdaten?.phantomkalkulation)
                }
                color="primary"
                variant="contained"
                startIcon={<DriveEtaIcon />}
                onClick={handleDatBewertungClick}
              >
                DAT-Bewertung
              </Button>
            </Grid>
            <Grid item xs={12} lg={1}>
              <Tooltip title="WebScan Dokument">
                <div>
                  <Button
                    fullWidth
                    disabled={vorgang?.dateien?.filter((datei: Datei) => (datei?.kategorien ?? []).includes('WebScan')).length === 0}
                    color="primary"
                    variant="contained"
                    onClick={handleShowWebScanClick}
                  >
                    <Hidden lgDown>PDF</Hidden>
                    <Hidden lgUp>WebScan PDF</Hidden>
                  </Button>
                </div>
              </Tooltip>
            </Grid>
            <Grid item xs={12} lg={8} />
            <Grid item xs={6} lg={1}>
              <Button
                fullWidth
                disabled={!vorgang?.dat?.contractId || isLoading || !sindFahrzeugdatenVollstaendig(vorgang) || isDisabled || !calculationDocumentUrl}
                color="primary"
                variant="contained"
                startIcon={<DriveEtaIcon />}
                onClick={handleShowDatKalkulationClick}
              >
                PDF
              </Button>
            </Grid>
            <Grid item xs={6} lg={3}>
              <Button
                fullWidth
                disabled={!vorgang?.fahrzeugdaten?.fahrgestellnummer || !vorgang?.dat?.contractId || isDisabled || isLoading}
                color="primary"
                variant="contained"
                startIcon={<DriveEtaIcon />}
                onClick={handleDatKalkulationClick}
              >
                <Hidden lgDown>DAT-Kalkulation</Hidden>
                <Hidden lgUp>Kalkulation</Hidden>
              </Button>
            </Grid>
            <Grid item xs={6} lg={8} />
            <Grid item xs={6} lg={4}>
              <Button
                fullWidth
                disabled={!vorgang?.dat?.dossierId || isDisabled || isLoading || !sindFahrzeugdatenVollstaendig(vorgang) || !vehicleEvaluationDocumentUrl}
                color="primary"
                variant="contained"
                startIcon={<DriveEtaIcon />}
                onClick={handleShowDatBewertungClick}
              >
                Bewertung
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="standard"
                label="Bemerkungen"
                multiline
                value={vorgang.bewertung?.bemerkung ?? ''}
                disabled={isDisabled}
                onChange={(event) => {
                  aktualisiereBewertung('bemerkung', event.target.value);
                }}
                fullWidth
                inputProps={{
                  'data-testid': 'Bemerkung'
                }}
              />
            </Grid>
          </Grid>
        </Formular>
      )}
    </>
  );
}
