import { GraphQLResult } from './types';
import { getJwtToken } from '../shared/Auth';

/**
 * Klasse basiert auf
 * https://github.com/aws-amplify/amplify-js/blob/0dfb727491928bcb40a3ebdd1d8afe12d4a9ee72/packages/api-graphql/src/internals/InternalGraphQLAPI.ts
 */
export class GraphQLAPI {
  private readonly endpoint: string;

  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }

  private async headerBasedAuth() {
    try {
      const jwt = await getJwtToken();
      return {
        Authorization: jwt
      };
    } catch (error) {
      throw new Error('No current user');
    }
  }

  async graphql<T>(query: string, variables: object = {}): Promise<GraphQLResult<T>> {
    const headers = await this.headerBasedAuth();
    const body = {
      query,
      variables
    };

    let response;

    try {
      response = await fetch(this.endpoint, {
        method: 'POST',
        headers,
        body: JSON.stringify(body)
      });

      const ok = response.status >= 200 && response.status < 400;
      response = ok ? await response.json() : await response.text();

      if (!ok) {
        console.error(response);
        response = {
          data: {},
          errors: [new Error(response)]
        };
      }
    } catch (error) {
      console.error(error);
      response = {
        data: {},
        errors: [new Error(String(error))]
      };
    }

    return response;
  }
}
