import React, { useEffect, useState } from 'react';
import { useUser } from '../../hooks/useUser';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

type Props = {
  onSelectMandant: (mandant: string) => void;
  gruppe?: string;
};

export default function MandantenAuswahl({ onSelectMandant, gruppe }: Props): JSX.Element {
  const { mandanten, mandantenZuGruppen } = useUser();

  const [aktuellerMandant, setAktuellerMandant] = useState('');
  const [aktuellerMandantId, setAktuellerMandantId] = useState('');

  useEffect(() => {
    if (aktuellerMandantId !== '') {
      setAktuellerMandant(aktuellerMandantId);
      onSelectMandant(aktuellerMandantId);
    }
  }, [aktuellerMandantId, mandanten, onSelectMandant]);

  useEffect(() => {
    if (gruppe && mandantenZuGruppen && mandantenZuGruppen[gruppe] && mandantenZuGruppen[gruppe].length > 0) {
      setAktuellerMandantId(mandantenZuGruppen[gruppe][0]);
    }
    if (!gruppe && mandanten.length > 0) {
      setAktuellerMandantId(mandanten[0]);
    }
  }, [gruppe, mandanten, mandantenZuGruppen]);

  const filterMandanten = () => {
    if (gruppe) {
      return mandantenZuGruppen[gruppe] || [];
    } else {
      return mandanten;
    }
  };

  return filterMandanten().length > 1 ? (
    <FormControl variant="standard" sx={{ minWidth: '64px' }}>
      <InputLabel id="mandant-label">Mandant</InputLabel>
      <Select
        variant="standard"
        labelId="mandant-label"
        value={aktuellerMandant}
        onChange={(event) => {
          setAktuellerMandantId(event.target.value);
        }}
      >
        {filterMandanten().map((mandant: string) => (
          <MenuItem key={mandant} value={mandant}>
            {mandant.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  ) : (
    <div>{filterMandanten().length === 1 && filterMandanten()[0].toUpperCase()}</div>
  );
}
