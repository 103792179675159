import React, { useState } from 'react';
import { Person } from '../../types';
import { PersonenTyp } from '../../shared/constants';
import PersonEingabeMaskeDialog from './PersonEingabeMaskeDialog';
import { getDefaultPersonMitTyp } from '../../frontendConstants';
import { PersonAuswahlDialog } from './PersonAuswahlDialog';
import { sucheNachPersonen } from '../../domain/sucheNachPersonen';
import { useSnackbar } from 'notistack';
import { ERROR_MESSAGE } from '../common/Alert';

type Props = {
  readonly personDatensatz: Person | null;
  readonly typ: PersonenTyp;
  readonly aktualisierePerson: (person: Person) => void;
  readonly onClose: () => void;
  readonly isDisabled: boolean;
  readonly testPraefix: string;
  readonly mandant: string;
  readonly withoutRequiredFields?: boolean;
};

export function PersonEingabeMaske(props: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();

  const [gefundenePersonen, setGefundenePersonen] = useState<Person[]>([]);
  const [personAusMaske, setPersonAusMaske] = useState<Person>(props.personDatensatz ?? getDefaultPersonMitTyp(props.typ));

  function handleEingabeMaske(personAusMaske: Person) {
    setPersonAusMaske(personAusMaske);
    const suchString = `${personAusMaske.vorname ?? ''} ${personAusMaske.nachname ?? ''}`.toLowerCase();

    sucheNachPersonen(suchString, personAusMaske.mandant ?? props.mandant, props.typ)
      .then((matchingPersons) => {
        if (matchingPersons.fehler) {
          enqueueSnackbar(matchingPersons.fehler, ERROR_MESSAGE);
        } else {
          const gefiltertePersonen = matchingPersons.items.filter((p) => p.id !== personAusMaske?.id);
          if (gefiltertePersonen.length > 0) {
            setGefundenePersonen(gefiltertePersonen);
          } else {
            props.aktualisierePerson(personAusMaske);
          }
        }
      })
      .catch((error: Error) => {
        enqueueSnackbar(`Fehler bei der Verarbeitung ${error.message}`, ERROR_MESSAGE);
      });
  }

  return (
    <>
      {gefundenePersonen.length > 0 && (
        <PersonAuswahlDialog
          personen={gefundenePersonen}
          isNeuanlage={!personAusMaske.id}
          onAuswahlClick={props.aktualisierePerson}
          open={true}
          onClose={() => setGefundenePersonen([])}
          handleNeuAnlegen={() => props.aktualisierePerson(personAusMaske)}
          onBearbeitenClick={(person) => {
            setPersonAusMaske(person);
            setGefundenePersonen([]);
          }}
        />
      )}
      <PersonEingabeMaskeDialog key={personAusMaske.id} {...props} personDatensatz={personAusMaske} aktualisierePerson={handleEingabeMaske} />;
    </>
  );
}
