import { Status } from './constants';

const statusReihenfolge = Object.values(Status);

export function istFruehererStatus(alterStatus: Status, neuerStatus: Status): boolean {
  return statusReihenfolge.indexOf(alterStatus) > statusReihenfolge.indexOf(neuerStatus);
}

export function istSpaetererStatus(alterStatus: Status, neuerStatus: Status): boolean {
  return statusReihenfolge.indexOf(neuerStatus) > statusReihenfolge.indexOf(alterStatus);
}

export function naechsterStatus(status: Status): Status {
  const aktuellePosition = statusReihenfolge.indexOf(status);
  return statusReihenfolge.length - 1 === aktuellePosition ? status : statusReihenfolge[aktuellePosition + 1];
}

export function vorherigerStatus(status: Status): Status {
  const aktuellePosition = statusReihenfolge.indexOf(status);
  return aktuellePosition === 0 ? status : statusReihenfolge[aktuellePosition - 1];
}
