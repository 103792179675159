import React, { useEffect, useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputAdornment from '@mui/material/InputAdornment';
import InfoIcon from '@mui/icons-material/Info';
import { SPACING_BETWEEN_COLUMNS } from './common/spacings';
import { EuroFormat, FloatFormat } from './common/inputFormats';
import WarningSign from './common/WarningSign';
import Formular from './common/Formular';
import { useUser } from '../hooks/useUser';
import { berechneFahrzeugalterInMonaten } from '../shared/frontend/berechneFahrzeugalterInMonaten';
import { isAenderbar } from '../domain/isAenderbar';
import * as queries from '../graphql/queries';
import { makeGraphqlQuery } from '../graphql/makeGraphqlQuery';
import { DatResponse, Vorgang } from '../types';
import { ERROR_MESSAGE } from './common/Alert';
import { useSnackbar } from 'notistack';
import { aktionErlaubt } from '../domain/aktionErlaubt';
import { AKTION_FAHRZEUGDATEN_ERFASSEN } from '../frontendConstants';
import { Lackart } from '../shared/constants';
import TextField from '@mui/material/TextField';

const DEKRA = 'DEKRA';
const HERSTELLER = 'HERSTELLER';
const FREIE_WERKSTATT = 'FREIE_WERKSTATT';

const STUNDENVERRECHNUNGSSATZ_TEXTE = {
  [DEKRA]: 'Dekra',
  [HERSTELLER]: 'Hersteller',
  [FREIE_WERKSTATT]: 'Freie Werkstatt'
};

type Props = {
  readonly vorgang: Vorgang;
  readonly isLoading: boolean;
  readonly aktualisiereVorgang: (vorgang: Partial<Vorgang>) => void;
};

export function Stundenverrechnungssaetze({ vorgang, isLoading, aktualisiereVorgang }: Props): JSX.Element {
  const { enqueueSnackbar } = useSnackbar();
  const { gruppenVonMandant } = useUser();

  const [showError, setShowError] = useState(false);
  const [errorText, setErrorText] = useState('');
  const [lackart, setLackart] = useState(vorgang?.stundenverrechnungssaetze?.lackart ?? '');
  const [datLackarten, setDatLackarten] = useState<Lackart[]>([]);
  const [datLacksystem, setDatLacksystem] = useState<string>(vorgang?.stundenverrechnungssaetze?.lacquerMethod ?? '');

  useEffect(() => {
    if (vorgang?.stundenverrechnungssaetze?.lackart && vorgang?.stundenverrechnungssaetze?.lackart !== lackart) {
      setLackart(vorgang?.stundenverrechnungssaetze?.lackart);
    }
  }, [vorgang, lackart]);

  useEffect(() => {
    if (vorgang?.stundenverrechnungssaetze?.lacquerMethod && vorgang?.stundenverrechnungssaetze?.lacquerMethod !== datLacksystem) {
      setDatLacksystem(vorgang?.stundenverrechnungssaetze?.lacquerMethod);
    }
  }, [vorgang, datLacksystem]);

  useEffect(() => {
    let didCancel = false;
    if (vorgang?.dat?.manufacturer && vorgang?.dat?.vehicleType && vorgang?.dat?.baseModel) {
      holeLackarten(vorgang.dat.manufacturer, vorgang.dat.vehicleType, vorgang.dat.baseModel, vorgang.mandant, datLacksystem)
        .then((datAntwort) => {
          if (!didCancel && !datAntwort.ok) {
            console.error(datAntwort.error);
            enqueueSnackbar(datAntwort.error, ERROR_MESSAGE);
          }
          if (!didCancel && datAntwort.ok) {
            if (datAntwort.data) {
              setDatLackarten(datAntwort.data);
            } else {
              enqueueSnackbar('Lackarten konnten nicht ermittelt werden.', ERROR_MESSAGE);
            }
          }
        })
        .catch((error) => {
          console.error(error);
          enqueueSnackbar('Zu diesem Fahrzeug konnten keine Lackarten ermittelt werden.', ERROR_MESSAGE);
        });
    }
    return () => {
      didCancel = true;
    };
  }, [enqueueSnackbar, vorgang?.dat?.baseModel, vorgang?.dat?.manufacturer, vorgang?.dat?.vehicleType, vorgang?.mandant, datLacksystem]);

  const aelterAls3JahreUndKeinScheckheft = berechneFahrzeugalterInMonaten(vorgang) > 36 && !vorgang.fahrzeugdaten?.scheckheftgepflegt;

  const fahrzeugJuengerAls3JahreOderScheckheft = berechneFahrzeugalterInMonaten(vorgang) <= 36 || vorgang.fahrzeugdaten?.scheckheftgepflegt;

  const typen = useMemo(
    () => [
      {
        value: FREIE_WERKSTATT,
        label: STUNDENVERRECHNUNGSSATZ_TEXTE[FREIE_WERKSTATT],
        disabled: false
      },
      {
        value: DEKRA,
        label: STUNDENVERRECHNUNGSSATZ_TEXTE[DEKRA],
        disabled: fahrzeugJuengerAls3JahreOderScheckheft
      },
      {
        value: HERSTELLER,
        label: STUNDENVERRECHNUNGSSATZ_TEXTE[HERSTELLER],
        disabled: aelterAls3JahreUndKeinScheckheft
      }
    ],
    [aelterAls3JahreUndKeinScheckheft, fahrzeugJuengerAls3JahreOderScheckheft]
  );

  useEffect(() => {
    const gespeicherterTyp = typen.find((typ) => typ.value === vorgang?.stundenverrechnungssaetze?.typ);
    if (gespeicherterTyp && gespeicherterTyp.disabled) {
      setShowError(true);
      if (aelterAls3JahreUndKeinScheckheft && vorgang?.stundenverrechnungssaetze?.typ === HERSTELLER) {
        setErrorText(
          'Der ausgewählte Stundenverrechnungssatz-Typ ist Hersteller. Da das Fahrzeug älter als drei Jahre alt ist und nicht Scheckheft gepflegt ist, ist Hersteller als Typ nicht erlaubt.'
        );
      }
      if (fahrzeugJuengerAls3JahreOderScheckheft && vorgang?.stundenverrechnungssaetze?.typ === DEKRA) {
        setErrorText(
          'Der ausgewählte Stundenverrechnungssatz-Typ ist DEKRA. Da das Fahrzeug nicht älter als drei Jahre alt ist und/ oder Scheckheft gepflegt ist, ist DEKRA als Typ nicht erlaubt.'
        );
      }
    } else {
      setShowError(false);
      setErrorText('');
    }
  }, [typen, vorgang, aelterAls3JahreUndKeinScheckheft, fahrzeugJuengerAls3JahreOderScheckheft]);

  const isDisabled = !vorgang || !isAenderbar(vorgang) || !aktionErlaubt(AKTION_FAHRZEUGDATEN_ERFASSEN, gruppenVonMandant(vorgang.mandant), vorgang.status);
  const typeValue = vorgang?.stundenverrechnungssaetze?.typ ?? FREIE_WERKSTATT;

  const handleTypeChange = (typ: string) => {
    const stundenverrechnungssaetze = {
      ...vorgang.stundenverrechnungssaetze,
      typ
    };

    if (typ === DEKRA) {
      stundenverrechnungssaetze.aufschlagErsatzteil = null;
      stundenverrechnungssaetze.verbringungszeit = null;
      stundenverrechnungssaetze.elektrik = null;
      stundenverrechnungssaetze.inklusiveMaterial = false;
    }
    aktualisiereVorgang({ stundenverrechnungssaetze: stundenverrechnungssaetze });
  };

  return (
    <Formular ueberschrift="Stundenverrechnungssätze">
      <Grid container spacing={SPACING_BETWEEN_COLUMNS}>
        <Grid item xs={12}>
          <WarningSign show={showError} text={errorText} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Ermittlung mit</InputLabel>
            <Select
              variant="standard"
              label="Stundenverrechnungssätze"
              value={vorgang?.stundenverrechnungssaetze?.typ ?? FREIE_WERKSTATT}
              disabled={isDisabled || isLoading}
              onChange={(event) => handleTypeChange(event.target.value)}
              data-testid="stundenverrechnungssaetze-typ"
            >
              {typen.map((g) => (
                <MenuItem key={g.value} value={g.value} data-testid={g.label} disabled={Boolean(g.disabled)}>
                  {g.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={8} />
        <Grid item xs={12} sm={4}>
          <TextField
            variant="standard"
            label="Mechanik"
            value={vorgang.stundenverrechnungssaetze?.mechanik ?? ''}
            disabled={isDisabled}
            onChange={(event) =>
              aktualisiereVorgang({
                stundenverrechnungssaetze: {
                  ...vorgang.stundenverrechnungssaetze,
                  mechanik: event.target.value
                }
              })
            }
            fullWidth
            data-testid="stundenverrechnungssaetze-mechanik"
            InputProps={{
              inputComponent: EuroFormat,
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            variant="standard"
            label="Karosserie"
            value={vorgang.stundenverrechnungssaetze?.karosserie ?? ''}
            disabled={isDisabled}
            onChange={(event) =>
              aktualisiereVorgang({
                stundenverrechnungssaetze: {
                  ...vorgang.stundenverrechnungssaetze,
                  karosserie: event.target.value
                }
              })
            }
            fullWidth
            data-testid="stundenverrechnungssaetze-karosserie"
            InputProps={{
              inputComponent: EuroFormat,
              endAdornment: <InputAdornment position="end">€</InputAdornment>
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          sx={{
            display: 'flex'
          }}
        >
          <Grid item xs={12} sm={8}>
            <TextField
              variant="standard"
              label="Lackierung"
              value={vorgang.stundenverrechnungssaetze?.lackierung ?? ''}
              disabled={isDisabled}
              onChange={(event) =>
                aktualisiereVorgang({
                  stundenverrechnungssaetze: {
                    ...vorgang.stundenverrechnungssaetze,
                    lackierung: event.target.value
                  }
                })
              }
              data-testid="stundenverrechnungssaetze-lackierung"
              InputProps={{
                inputComponent: EuroFormat,
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </Grid>
          {typeValue !== DEKRA && (
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                label="inkl. Material"
                labelPlacement="bottom"
                sx={{
                  textAlign: 'center'
                }}
                control={
                  <Checkbox
                    color="primary"
                    checked={vorgang?.stundenverrechnungssaetze?.inklusiveMaterial ?? false}
                    disabled={isDisabled}
                    onChange={(event) => {
                      aktualisiereVorgang({
                        stundenverrechnungssaetze: {
                          ...vorgang.stundenverrechnungssaetze,
                          inklusiveMaterial: event.target.checked
                        }
                      });
                    }}
                  />
                }
                data-testid="stundenverrechnungssaetze-inklusiveMaterial"
              />
            </Grid>
          )}
        </Grid>
        {typeValue !== DEKRA && (
          <Grid item xs={12} sm={4}>
            <TextField
              variant="standard"
              label="Elektrik"
              value={vorgang.stundenverrechnungssaetze?.elektrik ?? ''}
              disabled={isDisabled}
              onChange={(event) =>
                aktualisiereVorgang({
                  stundenverrechnungssaetze: {
                    ...vorgang.stundenverrechnungssaetze,
                    elektrik: event.target.value
                  }
                })
              }
              fullWidth
              data-testid="stundenverrechnungssaetze-elektrik"
              InputProps={{
                inputComponent: EuroFormat,
                endAdornment: <InputAdornment position="end">€</InputAdornment>
              }}
            />
          </Grid>
        )}
        <Grid item xs={12} sm={4}>
          <TextField
            variant="standard"
            label="Lackmaterialaufschlag"
            value={vorgang.stundenverrechnungssaetze?.aufschlagLackmaterial ?? ''}
            disabled={isDisabled || Boolean(vorgang?.stundenverrechnungssaetze?.inklusiveMaterial)}
            onChange={(event) =>
              aktualisiereVorgang({
                stundenverrechnungssaetze: {
                  ...vorgang.stundenverrechnungssaetze,
                  aufschlagLackmaterial: parseFloat(event.target.value) || null
                }
              })
            }
            InputProps={{
              inputComponent: FloatFormat,
              endAdornment: <InputAdornment position="end">%</InputAdornment>
            }}
            fullWidth
            data-testid="stundenverrechnungssaetze-aufschlagLackmaterial"
          />
        </Grid>
        {typeValue !== DEKRA && (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                label="Ersatzteilaufschlag"
                value={vorgang.stundenverrechnungssaetze?.aufschlagErsatzteil ?? ''}
                disabled={isDisabled}
                onChange={(event) =>
                  aktualisiereVorgang({
                    stundenverrechnungssaetze: {
                      ...vorgang.stundenverrechnungssaetze,
                      aufschlagErsatzteil: parseFloat(event.target.value) || null
                    }
                  })
                }
                InputProps={{
                  inputComponent: FloatFormat,
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
                fullWidth
                data-testid="stundenverrechnungssaetze-aufschlagErsatzteil"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                variant="standard"
                label="Verbringungszeit"
                value={vorgang.stundenverrechnungssaetze?.verbringungszeit ?? ''}
                disabled={isDisabled}
                onChange={(event) =>
                  aktualisiereVorgang({
                    stundenverrechnungssaetze: {
                      ...vorgang.stundenverrechnungssaetze,
                      verbringungszeit: parseFloat(event.target.value) || null
                    }
                  })
                }
                InputProps={{
                  inputComponent: FloatFormat,
                  endAdornment: <InputAdornment position="end">Std</InputAdornment>
                }}
                fullWidth
                data-testid="stundenverrechnungssaetze-verbringungszeit"
              />
            </Grid>{' '}
          </>
        )}
        <Grid item xs={12} sm={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>Lackart</InputLabel>
            <Select
              variant="standard"
              label="Lackart"
              value={lackart ?? ''}
              disabled={isDisabled || isLoading}
              onChange={(event) => {
                setLackart(event.target.value);
                aktualisiereVorgang({
                  stundenverrechnungssaetze: {
                    ...vorgang.stundenverrechnungssaetze,
                    lackart: event.target.value
                  }
                });
              }}
              data-testid="stundenverrechnungssaetze-lackart"
            >
              {datLackarten.map((l) => (
                <MenuItem key={l.key} value={l.key} data-testid={l.description}>
                  {l.description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl variant="standard" fullWidth>
            <InputLabel>DAT-Lacksystem</InputLabel>
            <Select
              variant="standard"
              label="DAT-Lacksystem"
              value={datLacksystem ?? ''}
              disabled={isDisabled || isLoading}
              onChange={(event) => {
                setDatLacksystem(event.target.value);
                aktualisiereVorgang({
                  stundenverrechnungssaetze: {
                    ...vorgang.stundenverrechnungssaetze,
                    lacquerMethod: event.target.value
                  }
                });
              }}
              data-testid="stundenverrechnungssaetze-datlacksystem"
            >
              <MenuItem value="MANUFACTURER_SPECIFIC">Lack-Hersteller-System</MenuItem>
              <MenuItem value="EURO_LACQUER">Eurolack</MenuItem>
              <MenuItem value="AZT">AZT</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} xl={4}>
          <Button
            fullWidth
            color="primary"
            variant="contained"
            startIcon={<InfoIcon />}
            target="_blank"
            href="https://www.dekra.de/de/stundenverrechnungssaetze/"
          >
            DEKRA Stundensätze
          </Button>
        </Grid>
      </Grid>
    </Formular>
  );
}

async function holeLackarten(
  manufacturer: number,
  vehicleType: number,
  baseModel: number,
  mandant: string,
  paintMethod: string
): Promise<DatResponse<Lackart[]>> {
  return makeGraphqlQuery(queries.holeLackarten, {
    manufacturer,
    vehicleType,
    baseModel,
    mandant,
    paintMethod
  });
}
